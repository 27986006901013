import { styled } from '@stitches/react'
import { Meta, StoryFn } from '@storybook/react'
import Button from 'components/button/button'
import Box from 'components/layout/box'
import { useToast } from './use-toast'

const StyledContainer = styled(Box, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 20,
})

function ToastExample() {
  const toast = useToast()

  return (
    <Box>
      <StyledContainer css={{ marginTop: 200 }}>
        <Button
          onClick={() =>
            toast({
              content: 'Account',
              duration: 4000,
            })
          }
        >
          Toast Default
        </Button>
        <Button
          onClick={() =>
            toast({
              content: 'Account added successfully',
              status: 'success',
              duration: 4000,
            })
          }
        >
          Toast Success
        </Button>
        <Button
          onClick={() =>
            toast({
              status: 'error',
              content: 'Failed to add account',
              duration: 4000,
            })
          }
        >
          Toast Error
        </Button>

        <Button
          onClick={() =>
            toast({
              status: 'success',
              size: 'sm',
              content: 'Account added successfully',
            })
          }
        >
          Toast Success Small
        </Button>
      </StyledContainer>
      <StyledContainer>
        <Button
          onClick={() =>
            toast({
              content: 'Account',
              duration: 4000,
              position: 'bottom-right',
            })
          }
        >
          Toast Bottom Right
        </Button>
        <Button
          onClick={() =>
            toast({
              content: 'Account added successfully',
              status: 'success',
              position: 'bottom-left',
              duration: 4000,
            })
          }
        >
          Toast Botton Left
        </Button>
        <Button
          onClick={() =>
            toast({
              status: 'error',
              content: 'Failed to add account',
              position: 'top-left',
              duration: 4000,
            })
          }
        >
          Toast Top Left
        </Button>

        <Button
          onClick={() =>
            toast({
              status: 'success',
              size: 'sm',
              position: 'top',
              content: 'Account added successfully',
            })
          }
        >
          Toast Top
        </Button>
        <Button
          onClick={() =>
            toast({
              status: 'success',
              size: 'sm',
              position: 'bottom',
              content: 'Account added successfully',
            })
          }
        >
          Toast Bottom
        </Button>
      </StyledContainer>
    </Box>
  )
}

export default {
  title: 'Toast',
  component: ToastExample,
  argTypes: {},
} as Meta<typeof ToastExample>

const Template: StoryFn<typeof ToastExample> = () => <ToastExample />
export const Default = Template.bind({})
