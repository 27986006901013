import { zodResolver } from '@hookform/resolvers/zod'
import Button from 'components/button/button'
import { CollapsibleSection } from 'components/drawer/collapsible-section'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { useToast } from 'components/toast'
import {
  useCreateTaxMutation,
  useTaxesQuery,
} from 'generated/__generated_graphql'
import { StyledDrawerInput } from 'pages/settings/settings.styles'
import React from 'react'
import { useForm } from 'react-hook-form'
import { HiDocumentAdd } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { z } from 'zod'

type AddTaxRateDrawerProps = {
  visible: boolean
  onClose: () => void
}

const formSchema = z.object({
  name: z.string().min(3, 'Please enter a name for the tax rate'),
  rate: z.string().refine((rate) => !isNaN(Number.parseInt(rate)), {
    message: 'Please enter a number greater than zero as the tax rate',
  }),
  description: z
    .string()
    .min(3, 'Please add a description. Eg: Get 30% off every $5000 purchase'),
})

type FormFields = z.infer<typeof formSchema>

const AddTaxRateDrawer: React.FC<AddTaxRateDrawerProps> = ({
  visible,
  onClose,
}) => {
  const notify = useToast()

  const [, refetchTaxes] = useTaxesQuery()

  const [, createTax] = useCreateTaxMutation()

  const {
    register,
    reset,
    watch,
    formState: { errors, isSubmitting, isValid },
    getValues,
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  const { rate } = watch()

  async function onSubmit() {
    try {
      const values = getValues()
      const response = await createTax({
        input: { ...values, rate: Number(values.rate) },
      })
      const error = extractGraphqlErrors(response, 'register')

      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: 'A new tax rate has been created successfully',
        status: 'success',
      })

      refetchTaxes({
        requestPolicy: 'network-only',
      })

      onReset()
    } catch (error) {
      notify({
        content:
          'Something went wrong while creating an a tax rate. Please try again',
        status: 'error',
      })
    }
  }

  function onReset() {
    reset()
    onClose()
  }

  return (
    <Drawer
      title="New Tax Rate"
      titleIcon={<HiDocumentAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onReset}
      footer={<Flex gutterX="2"></Flex>}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        onReset={onReset}
        noValidate
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <>
          <CollapsibleSection title="Rate details">
            <Flex direction="column" gutterY="5">
              <StyledDrawerInput
                {...(register('name') as any)}
                label="Name"
                placeholder="Enter tax name"
                error={errors.name?.message}
                required
              />

              <StyledDrawerInput
                {...(register('rate', {
                  setValueAs: (rate) => String(Number.parseFloat(rate)),
                }) as any)}
                value={rate}
                label="Rate"
                placeholder="Enter tax rate"
                type="number"
                error={errors.rate?.message}
                required
              />

              <StyledDrawerInput
                {...(register('description') as any)}
                label="Description"
                placeholder="Enter tax description"
                error={errors.description?.message}
                required
              />
            </Flex>
          </CollapsibleSection>

          {/* <CollapsibleSection title="Tax Properties">
            <Flex direction="column" gutterY="5">
              <Select
                label="Category"
                placeholder="Direct Tax"
                options={[
                  {
                    label: 'Direct Tax',
                    value: 'Direct Tax',
                  },
                  {
                    label: 'Indirect Asset',
                    value: 'Indirect Asset',
                  },
                ]}
                error={errors.name?.message}
                name="name"
                required
                onChange={(value) => {
                  console.log(value)
                  setInputValue('name', Number(value))
                }}
              />
              <Select
            label="Type"
            placeholder="PAYE"
            options={[
              {
                label: 'PAYE',
                value: 'paye',
              },
              {
                label: 'PAYER',
                value: 'payer',
              },
            ]}
          />
          <Select
            label="Industry"
            placeholder="Agriculture"
            options={[
              {
                label: 'Agriculture',
                value: 'agric',
              },
              {
                label: 'Manufacturing',
                value: 'manufacturing',
              },
            ]}
          />
            </Flex>
          </CollapsibleSection> */}
        </>

        <Flex
          gutterX="2"
          css={{
            flexGrow: 1,
            justifySelf: 'end',
            alignSelf: 'end',
            bottom: 0,
            px: '2.4rem',
            alignItems: 'end',
            justifyContent: 'end',
          }}
        >
          <Button
            type="reset"
            size="md"
            appearance="secondary"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="md"
            isLoading={isSubmitting}
            disabled={isSubmitting || !isValid}
          >
            Add Rate
          </Button>
        </Flex>
      </form>
    </Drawer>
  )
}

export default AddTaxRateDrawer
