import Button from 'components/button/button'
import Drawer from 'components/drawer/drawer'
import { Box, Flex, Stack } from 'components/layout'
import Separator from 'components/separator/separator'
import Text from 'components/text/text'
import { format } from 'date-fns'
import { Purchase, PurchaseEnum } from 'generated/__generated_graphql'
import { useAppProvider } from 'providers/app-provider'
import { HiDocumentAdd } from 'react-icons/hi'
import { styled } from 'stitches/stitches.config'
import { formatMoney } from 'utils/helpers'
import ExpensePreviewer from './expense-previewer'

interface ExpenseDetailProps {
  visible: boolean
  onClose: () => void
  onSend?: () => void
  expense: Purchase
}

const Container = styled(Box, {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  height: '100%',
})

const ExpenseDetail: React.FC<ExpenseDetailProps> = ({
  visible,
  onClose,
  onSend,
  expense,
}) => {
  const { organisation } = useAppProvider()
  const subTotal =
    expense?.purchaseItems?.reduce((acc, item) => {
      return acc + (item.quantity ?? 0) * (item.unitPrice ?? 0)
    }, 0) ?? 0

  const discount =
    expense?.discounts && expense?.discounts.length > 0
      ? expense?.discounts[0].rate
      : 0
  const tax =
    expense?.taxes && expense?.taxes.length > 0 ? expense?.taxes[0].rate : 0
  const discountValue = (discount / 100) * subTotal
  const taxValue = (tax / 100) * subTotal
  const gross = Math.max(0, subTotal! - discountValue!)
  const total = gross + taxValue!
  const symbol = organisation?.currency.symbol
  const hasPayments =
    expense.purhcasePayments && expense.purhcasePayments.length > 0

  return (
    <Drawer
      title={expense.reference}
      titleIcon={<HiDocumentAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      size="large"
      onClose={onClose}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
          {expense.purchaseType === PurchaseEnum.PurchaseOrder && (
            <Button size="md" onClick={onSend}>
              Send Purchase Order
            </Button>
          )}
        </Flex>
      }
    >
      <Box css={{ padding: '28px 24px' }}>
        <Container>
          <Box css={{ borderRight: '1px solid #EEF2F4', px: 10 }}>
            <Stack spacing={5}>
              <Text size="sm">{formatMoney(expense.amount, symbol)}</Text>
              <Text size="xs">
                Due on {format(new Date(expense.dueDate), 'dd MMM, yyyy')}
              </Text>
            </Stack>
            <Stack css={{ mt: 20 }} spacing={8}>
              <Text color="$secondary" size="xxs" weight="bold">
                Created for
              </Text>
              <Text size="xs" weight="bold">
                {expense?.supplier?.name}
              </Text>
              <Text size="xs" weight="bold">
                {expense?.supplier?.country}
              </Text>
            </Stack>
            <Separator color="#EEF2F4" />
            <Stack css={{ mt: 20 }} spacing={8}>
              <Text color="$secondary" size="xxs" weight="bold">
                Items
              </Text>
              {expense.purchaseItems?.map((item) => (
                <Box key={item.id}>
                  <Flex justify="between" align="end">
                    <Stack spacing={5}>
                      <Text size="xs" weight="bold">
                        {item.name}
                      </Text>
                      <Text size="xs" weight="bold">
                        {formatMoney(item.unitPrice ?? 0, symbol)} x{' '}
                        {item.quantity}
                      </Text>
                    </Stack>
                    <Text size="xs" weight="bold">
                      {formatMoney(
                        (item.unitPrice ?? 0) * (item.quantity ?? 0),
                        symbol
                      )}
                    </Text>
                  </Flex>
                  <Separator color="#EEF2F4" />
                </Box>
              ))}
            </Stack>
            <Stack spacing={5}>
              <Flex justify="between" align="center">
                <Text size="xs" weight="bold" color="$secondary">
                  Subtotal
                </Text>
                <Text size="xs" weight="bold" color="$secondary">
                  {formatMoney(subTotal, symbol)}
                </Text>
              </Flex>

              <Flex justify="between" align="center">
                <Text size="xs" weight="bold" color="$secondary">
                  Tax
                </Text>
                <Text size="xs" weight="bold" color="$secondary">
                  {formatMoney(taxValue, symbol)}
                </Text>
              </Flex>

              <Flex justify="between" align="center">
                <Text size="xs" weight="bold" color="$secondary">
                  Discount
                </Text>
                <Text size="xs" weight="bold" color="$secondary">
                  {formatMoney(discountValue, symbol)}
                </Text>
              </Flex>

              <Flex justify="between" align="center">
                <Text size="xs" weight="bold">
                  Total
                </Text>
                <Text size="xs" weight="bold">
                  {formatMoney(total, symbol)}
                </Text>
              </Flex>
            </Stack>
            <Separator color="#EEF2F4" />
            <Stack spacing={8}>
              <Text color="$secondary" size="xxs" weight="bold">
                Account
              </Text>
              <Stack spacing={5}>
                <Text size="sm" weight="bold">
                  {expense.bankAccount?.title}
                </Text>
                <Text size="xs" weight="bold">
                  {expense.bankAccount?.name ??
                    expense.bankAccount?.routingNumber}
                </Text>
                <Text size="xs" weight="bold">
                  {expense.bankAccount?.accountNumber}
                </Text>
              </Stack>
            </Stack>
            <Separator color="#EEF2F4" />
            {hasPayments && (
              <Stack spacing={8}>
                <Text color="$secondary" size="xxs" weight="bold">
                  Payments
                </Text>
                {expense.purhcasePayments?.map((payment) => (
                  <Box key={payment.id}>
                    <Stack spacing={5}>
                      <Text size="sm" weight="bold">
                        {formatMoney(payment.amount, symbol)}
                      </Text>
                      <Text size="xs" weight="bold">
                        Paid on {format(new Date(payment.date), 'dd MMM, yyyy')}
                      </Text>
                    </Stack>
                    <Separator color="#EEF2F4" />
                  </Box>
                ))}
              </Stack>
            )}

            <Stack spacing={8}>
              <Text color="$secondary" size="xxs" weight="bold">
                Outstanding
              </Text>
              <Text size="sm" weight="bold">
                {formatMoney(expense.amountPending, symbol)}
              </Text>
            </Stack>
          </Box>

          <Box>
            <ExpensePreviewer
              expense={expense}
              totals={{ subTotal, discountValue, taxValue, total }}
              showActions
            />
          </Box>
        </Container>
      </Box>
    </Drawer>
  )
}

export default ExpenseDetail
