import Button from 'components/button/button'
import CheckBox from 'components/checkbox/checkbox'
import DateInput from 'components/date-input/date-input'
import Drawer from 'components/drawer/drawer'
import { InputLabel } from 'components/input'
import Input from 'components/input/input'
import { Flex } from 'components/layout'
import Text from 'components/text/text'
import { useToast } from 'components/toast'
import { addDays, addMonths } from 'date-fns'
import {
  useCreateCustomReportMutation,
  useReportTypesQuery,
} from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import React from 'react'
import {
  HiCalendar,
  HiChevronDown,
  HiPresentationChartLine,
} from 'react-icons/hi'
import {
  MdOutlineAccountBalanceWallet,
  MdOutlineBusinessCenter,
} from 'react-icons/md'
import { TbReportMoney } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { extractGraphqlErrors } from 'utils/helpers'

interface CreaeCustomeReportDrawerProps {
  visible: boolean
  onClose: () => void
  type?: string
}

const reportsIcons: Record<string, JSX.Element> = {
  '1': <MdOutlineBusinessCenter size="2rem" />,
  '2': <TbReportMoney size="2rem" />,
  '3': <MdOutlineAccountBalanceWallet size="2rem" />,
}

function CreateCustomReportDrawer({
  visible,
  onClose,
}: // type = '1',
CreaeCustomeReportDrawerProps) {
  const navigate = useNavigate()
  const toast = useToast()
  function notify(error?: string, status?: 'success' | 'error') {
    toast({
      content: error ?? 'something went wrong',
      status: status,
    })
  }

  const [{ data }] = useReportTypesQuery()
  const reportTypes = data?.reportTypes
  const [typesIds, setTypesIds] = React.useState<string[]>(
    reportTypes?.map((type) => type.id) ?? ['1', '2', '3']
  )

  const { register, values, setInputValue, errors, formIsComplete, clearForm } =
    useForm({
      fields: {
        title: '',
        reportDate: '',
        reportType: '',
      },
      optional: ['reportType'],
    })

  function resetAndClose() {
    clearForm()
    setTypesIds(['1', '2', '3'])
    onClose()
  }

  const [{ fetching }, createReport] = useCreateCustomReportMutation()

  async function handleGenerateReport() {
    // get next month
    const nextMonth = addMonths(new Date(values.reportDate), 1)
    const nextMonthDate = new Date(nextMonth)
    const nextMonthString = nextMonthDate.toISOString().split('T')[0]

    try {
      const response = await createReport({
        input: {
          title: values.title,
          reportDate: nextMonthString,
          reportType: typesIds,
        },
      })

      const error = extractGraphqlErrors(response, 'createCustomReport')
      if (error) {
        notify(error, 'error')
        return
      }

      resetAndClose()
      navigate('/dashboard/reports/custom-reports')
    } catch (error) {
      notify(error as string, 'error')
    }
  }

  return (
    <Drawer
      title="Create custom report"
      titleIcon={<HiPresentationChartLine size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={resetAndClose}>
            Cancel
          </Button>
          <Button
            isLoading={fetching}
            disabled={!formIsComplete}
            onClick={handleGenerateReport}
            size="md"
          >
            Generate report
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="5" css={{ p: '$5' }}>
        <Input
          required
          label="Report Title"
          placeholder="Add a title"
          {...register('title')}
          error={errors.title}
        />
        <DateInput
          prepend={<HiCalendar color="#ABB3B9" />}
          append={<HiChevronDown size="1.9rem" color="#ABB3B9" />}
          maximumDate={addDays(new Date(), 0)}
          label="Report Date"
          required
          placeholder="Select Report Date"
          showDefaultDate={false}
          isMonthPicker
          onChange={(date) =>
            setInputValue('reportDate', date.target.value as string)
          }
        />
        <Flex direction="column" gutter="3">
          <InputLabel required htmlFor="reportType">
            Report Type
          </InputLabel>
          {reportTypes?.map((item) => (
            <CheckBox
              key={item?.id}
              direction="right"
              defaultChecked
              disabled={item?.id === '1'}
              onChange={() => {
                if (typesIds?.includes(item?.id)) {
                  if (item?.id === '1') return
                  setTypesIds(typesIds.filter((id) => id !== item?.id))
                  return
                }
                setTypesIds([...typesIds, item?.id])
              }}
              label={() => (
                <Flex gutter="2">
                  {reportsIcons[item?.id]}
                  <Flex direction="column" gutter={1}>
                    <Text size="xs" weight="bold">
                      {item?.name}
                    </Text>
                    <Text size="xxs" color="$secondary">
                      {item?.description}
                    </Text>
                  </Flex>
                </Flex>
              )}
            />
          ))}
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default CreateCustomReportDrawer
