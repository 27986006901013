import { zodResolver } from '@hookform/resolvers/zod'
import { Avatar } from 'components/avatar/avatar'
import Button from 'components/button/button'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import Loader from 'components/loader/loader'
import PageHeader from 'components/page-header/page-header'
import Separator from 'components/separator/separator'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select'
import UploadFileInput from 'components/upload-file-input'
import {
  useOrganisationQuery,
  useProfileQuery,
  useUpdateOrganisationMutation,
} from 'generated/__generated_graphql'
import useFileUpload, { ActionStatus, UploadObject } from 'hooks/useFileUpload'
import { useAppProvider } from 'providers/app-provider'

import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { countries } from 'utils/countries'
import { extractGraphqlErrors } from 'utils/helpers'
import { email, website } from 'utils/schemas'
import { z } from 'zod'
import { DeleteOrganisationDrawer } from '../components/general/delete-organisation-drawer'
import { StyledContent, StyledText } from '../organisation.styles'

const formSchema = z.object({
  name: z
    .string()
    .min(3, 'Please enter your organisation name (at least 3 characters)'),
  website,
  email,
  countryCode: z
    .string()
    .min(2, `Please select your organisation's country of operation`),
})

type FormFields = z.infer<typeof formSchema>

const General = () => {
  const [showDrawer, setShowDrawer] = React.useState(false)

  const [, queryProfile] = useProfileQuery()
  const [, queryOrganisation] = useOrganisationQuery()

  const { organisation } = useAppProvider()
  const {
    name,
    website,
    countryCode,
    email,
    id,
    upload,
    profileImageUrl,
    currency,
  } = organisation ?? {}

  const {
    control,
    register,
    formState: { errors, isSubmitting, isDirty },
    getValues,
    reset,
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    if (organisation && !isDirty) {
      reset({
        name: name ?? '',
        website: website ?? '',
        email: email ?? '',
        countryCode: countryCode ?? '',
      })
    }
  }, [organisation])

  const notify = useToast()

  const [, updateOrganisation] = useUpdateOrganisationMutation()
  const [uploadedObject, setUploadedObject] = useState<UploadObject>()

  const fileUpload = useFileUpload({
    uploadOnChange: true,
    maxMegabyte: 3,
    onUploadStatusChange,
    getUploadedObject: setUploadedObject,
  })

  const { uploadObject, handleRemove, uploadStatus } = fileUpload

  async function callUpdateOrgMutation(removePhoto = false) {
    try {
      // a user's profilePic url is unset if you call updateOrganisation without an upload id(upload.id)
      // i.e uploadId == undefined
      const uploadId = removePhoto
        ? undefined
        : uploadedObject?.id
        ? String(uploadedObject?.id)
        : upload?.id
        ? String(upload?.id)
        : undefined

      const { countryCode, email, name, website } = getValues()

      const response = await updateOrganisation({
        input: {
          countryCode,
          email,
          name,
          website,
          id: id!,
          uploadId,
        },
      })

      const error = extractGraphqlErrors(response!, 'updateOrganisation')

      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }

      queryProfile({ requestPolicy: 'network-only' })
      queryOrganisation({ requestPolicy: 'network-only' })

      notify({
        status: 'success',
        content: 'Organisation information has been successfully updated',
      })
    } catch (error) {
      notify({
        status: 'error',
        content:
          'Something went wrong while updating organisation information. Please try again',
      })
    }
  }

  async function onUploadStatusChange(status: ActionStatus) {
    switch (status) {
      case 'success': {
        await callUpdateOrgMutation()
        return
      }
    }
  }

  async function onRemovePhoto() {
    handleRemove()
    await callUpdateOrgMutation(true)
  }

  async function onSubmit() {
    await callUpdateOrgMutation()
  }

  if (!organisation)
    return (
      <Flex
        direction={'column'}
        justify="center"
        align={'center'}
        css={{ height: '100vh', width: '100%' }}
      >
        <Loader size={'md'} />
      </Flex>
    )

  return (
    <Flex direction="column" gutter="3">
      <PageHeader
        title="General"
        subtitle="Manage your organisation settings"
        type="plain"
      />

      <StyledContent direction="column" gutter="3">
        <Text
          css={{ mb: '$5', px: '$4', fontFamily: '$bold' }}
          size="md"
          color="$primary"
        >
          Organisation Information
        </Text>
        <Flex css={{ px: '$4' }} direction="column" gutter="2">
          <Avatar
            size="xtraLarge"
            src={profileImageUrl ?? uploadObject?.url ?? undefined}
            backgroundImage="linear-gradient(180deg, #FCBA7D -18.75%, #F7D6D4 100%);"
          />
          <Text size="xs" css={{ mt: '$5', fontFamily: '$bold' }}>
            Organisation Logo
          </Text>
          <Text size="xs" color="$secondary">
            Choose a logo for your organisation.
          </Text>
          <Flex gutter="3" css={{ mt: '$4' }}>
            <UploadFileInput {...fileUpload} />
            <Button
              appearance="secondary"
              onClick={onRemovePhoto}
              disabled={uploadStatus === 'loading'}
            >
              Remove Photo
            </Button>
          </Flex>
        </Flex>
        <Separator color="#EEF2F4" />
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Flex direction="column" gutter="4" css={{ px: '$4', width: '70%' }}>
            <Input
              {...(register('name') as any)}
              required
              label="Organisation Name"
              appearance="outline"
              placeholder="Enter organisation's registered legal name"
              error={errors.name?.message}
            />
            <Input
              {...(register('website') as any)}
              required
              label="Organisation URL"
              appearance="outline"
              placeholder="www.xyz.com"
              error={errors.website?.message}
              prepend={<Text css={{ color: '$gray' }}>https://</Text>}
            />
            <Input
              {...(register('email', {
                required: `Please enter organisation's email address`,
              }) as any)}
              required
              label="Email Address"
              type={'email'}
              appearance="outline"
              placeholder="Enter organisation's email address"
              error={errors.email?.message}
            />
            <Controller
              name="countryCode"
              control={control}
              render={({ field: { value } }) => {
                return (
                  <TypeAheadSelect
                    // defaultValue={value}
                    required
                    label="Country"
                    isDisabled
                    placeholder="Select country of operation"
                    options={countries.map((country) => ({
                      label: country.name,
                      value: country.code,
                    }))}
                    value={value}
                    // onChange={onChange}
                    error={errors.countryCode?.message}
                  />
                )
              }}
            />
            <TypeAheadSelect
              label="Currency"
              isDisabled
              value={currency?.code}
              required
              options={[{ label: currency?.name, value: currency?.code }]}
            />
            {/* <Select
            isClearable
            label="Time Zone"
            placeholder="GMT 00:00"
            options={[
              {
                label: 'GMT 00:00',
                value: 'this',
              },
              {
                label: 'GMT 12:00',
                value: 'last',
              },
            ]}
          /> */}
          </Flex>

          <Flex
            justify="end"
            css={{ background: '$settingsFooterbg', p: '$3', mt: '$4' }}
          >
            <Button
              isLoading={isSubmitting}
              type="submit"
              disabled={uploadStatus === 'loading' || isSubmitting || !isDirty}
            >
              Update
            </Button>
          </Flex>
        </form>
      </StyledContent>
      {/* <StyledContent direction="column" gutter="3" css={{ mt: '$4' }}>
        <Text
          css={{ mb: '$5', px: '$4', fontFamily: '$bold' }}
          size="md"
          color="$primary"
        >
          Organisation Properties{' '}
          <Span css={{ color: '$secondary' }}> (Invoicing) </Span>
        </Text>
        <Flex direction="column" gutter="4" css={{ px: '$4', width: '70%' }}>
          <Input label="VAT Label" appearance="outline" placeholder="VAT" />
          <Input
            label="Invoice Prefix"
            appearance="outline"
            placeholder="INV"
          />
          <Input
            label="Start of Invoice Numbering"
            appearance="outline"
            placeholder="0"
          />
          <Input
            label="Purchase Prefix"
            appearance="outline"
            placeholder="BL"
          />
          <Input
            label="Purchase Orders Prefix"
            appearance="outline"
            placeholder="PO"
          />
          <Input label="Assets Prefix" appearance="outline" placeholder="FA" />
        </Flex>

        <Flex
          justify="end"
          css={{ background: '$settingsFooterbg', p: '$3', mt: '$4' }}
        >
          <Button>Update</Button>
        </Flex>
      </StyledContent> */}

      <StyledContent
        align="start"
        direction="column"
        gutter="2"
        css={{ px: '$4', pb: '$4' }}
      >
        <Text
          css={{ mb: '$2', fontFamily: '$bold' }}
          size="sm"
          color="$primary"
        >
          Delete your organisation
        </Text>
        <StyledText size="xs">
          Deleting {name} means you will lose all your account related data and
          any automated reports/foresight connected to any external
          integrations. You can go ahead and click the button below if you agree
          to this.
        </StyledText>
        <Button appearance="danger" onClick={() => setShowDrawer(true)}>
          Delete organisation
        </Button>
        <DeleteOrganisationDrawer
          visible={showDrawer}
          onClose={() => setShowDrawer(false)}
        />
      </StyledContent>
    </Flex>
  )
}

export default General
