/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import Flex from 'components/layout/flex'
import { useState } from 'react'
import { StyledButtonTag, StyledNativeTag } from './tag.styles'
import { TagProps } from './tag.types'

const Tag: React.FC<TagProps> = ({
  content,
  prependIcon,
  appendIcon,
  interactive,
  css,
  ref,
  children,
  ...rest
}) => {
  const [active, setActive] = useState(false)

  const handleOnClick = () => {
    setActive((prevActive) => !prevActive)
  }

  return (
    <>
      {interactive ? (
        <StyledButtonTag
          css={css}
          {...rest}
          onClick={handleOnClick}
          state={active ? 'active' : 'inactive'}
        >
          <Flex align="center" gutterX="3">
            {prependIcon}
            {children ?? content}
            {appendIcon}
          </Flex>
        </StyledButtonTag>
      ) : (
        <StyledNativeTag css={css} {...rest}>
          <Flex align="center" gutterX="2">
            {prependIcon}
            {children ?? content}
            {appendIcon}
          </Flex>
        </StyledNativeTag>
      )}
    </>
  )
}

Tag.displayName = 'Tag'

export default Tag
