import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import React from 'react'

import AccountSwitcher from 'components/account-switcher/account-switcher'
import MenuBar from 'components/menu-bar/menu-bar'

import { ReactComponent as AccounteerLogo } from 'assets/svg/accounteer-logo.svg'
import { Text } from 'components/text/text'
import { StyledCol, StyledRow } from './layouts.styles'

import QuickFind from 'components/quick-find/quick-find'
import Tag from 'components/tag/tag'
import { motion } from 'framer-motion'
import {
  HiOutlinePresentationChartLine,
  HiPresentationChartLine,
} from 'react-icons/hi'
import { DashboardLayoutProps } from './layout.types'

const layoutItems = [
  {
    path: '/dashboard',
    key: 'reports',
    icon: <HiOutlinePresentationChartLine />,
    iconActive: <HiPresentationChartLine />,
    label: 'Reports',
  },
  // {
  //   path: '/dashboard/meetings',
  //   key: 'meetings',
  //   icon: <HiOutlineCalendar />,
  //   iconActive: <HiCalendar />,
  //   label: 'Meetings',
  // },
]

const sidebarWidth = 270

const ServiceReportLayout: React.FC<DashboardLayoutProps> = (props) => {
  const { children } = props

  return (
    <motion.div
      initial={{ opacity: 0, scale: 1.2 }}
      animate={{
        opacity: 1,
        scale: 1,
        transition: {
          duration: 0.5,
          ease: 'easeInOut',
        },
      }}
      exit={{ opacity: 0, scale: 1.2 }}
    >
      <StyledRow>
        <StyledCol background="white" css={{ width: sidebarWidth }}>
          <Flex
            direction="column"
            justify="start"
            gutter="5"
            css={{ p: '$3', height: '100%' }}
          >
            <Box
              css={{
                position: 'absolute',
                top: '$3',
                left: '$3',
                width: sidebarWidth - 38,
              }}
            >
              <AccountSwitcher />
            </Box>
            <QuickFind />
            <MenuBar data={layoutItems} />

            <Flex
              align="center"
              justify="between"
              css={{
                width: sidebarWidth - 38,
                mt: 'auto',
              }}
            >
              <AccounteerLogo
                style={{
                  width: '9rem',
                }}
              />
              <Tag>
                <Text
                  color="$secondary"
                  size="xxs"
                  css={{
                    textTransform: 'lowercase',
                  }}
                >
                  v 2.1.0
                </Text>
              </Tag>
            </Flex>
          </Flex>
        </StyledCol>
        <StyledCol
          css={{
            width: `calc(100vw - ${sidebarWidth}px)`,
            position: 'relative',
            // isolation: 'isolate',
            // zIndex: -1,
          }}
        >
          {children}
        </StyledCol>
      </StyledRow>
    </motion.div>
  )
}

export default ServiceReportLayout
