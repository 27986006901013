import type * as Stitches from '@stitches/react'
import Flex from 'components/layout/flex'
import Portal from 'components/portal/portal'
import { Text } from 'components/text/text'
import {
  AnimatePresence,
  AnimationControls,
  useAnimationControls,
  Variants,
} from 'framer-motion'
import React, { ReactNode, useEffect, useState } from 'react'
import { HiX } from 'react-icons/hi'
import { CSS } from 'stitches/stitches.config'
import {
  StyledButton,
  StyledContent,
  StyledDrawer,
  StyledDrawerBackdrop,
  StyledDrawerPopup,
  StyledFooter,
  StyledHeader,
  ZIndexDrawerWrapper,
} from './drawer.styles'

const backdropMotionVariants: Variants = {
  initial: { opacity: 0 },

  animate: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.25,
      ease: [0.4, 0, 1, 1],
    },
  },
}

const drawerMotionVariants: Variants = {
  initial: (position: 'left' | 'right') => {
    return { x: position === 'right' ? '100vw' : '-92rem' }
  },
  animate: (position: 'left' | 'right') => {
    return {
      x: position === 'right' ? 'calc(100vw - 34.5vw)' : '0',
      transition: {
        duration: 0.3,
        ease: [0.4, 0, 0.2, 1],
      },
    }
  },

  exit: (position: 'left' | 'right') => {
    return {
      x: position === 'right' ? '100vw' : '-92rem',
      transition: {
        duration: 0.2,
        ease: [0.4, 0, 1, 1],
      },
    }
  },
}

export interface IDrawerProps
  extends Stitches.VariantProps<typeof StyledDrawer> {
  css?: CSS
  closable?: boolean
  fullHeight?: boolean
  destroyOnClose?: boolean
  footer?: ReactNode
  popupFooter?: ReactNode
  visible: boolean
  openPopup?: boolean
  title?: ReactNode
  popupTitle?: ReactNode
  placement?: 'left' | 'right'
  maskClosable?: boolean
  children: ReactNode
  popupContent?: ReactNode
  titleIcon?: ReactNode
  footerAlignment?: Stitches.VariantProps<typeof Flex>['justify']
  onClose: () => void
  onPopupClose?: () => void
  afterVisibleChange?: (visible: boolean) => void
}

export const Drawer = React.forwardRef<HTMLDivElement, IDrawerProps>(
  (props, ref) => {
    const {
      visible,
      onClose,
      afterVisibleChange,
      destroyOnClose = true,
      placement = 'left',
    } = props

    const backdropControls = useAnimationControls()
    const drawerControls = useAnimationControls()

    const [animatedToOpen, setAnimatedToOpen] = useState(false)

    useEffect(() => {
      if (!destroyOnClose) {
        if (visible && !animatedToOpen) {
          Promise.all([
            backdropControls.start('animate'),
            drawerControls.start('animate'),
          ]).then(() => {
            setAnimatedToOpen(true)
          })
        }
      }

      afterVisibleChange?.(visible)
    }, [visible])

    function onBackdropClicked() {
      Promise.all([
        backdropControls.start('exit'),
        drawerControls.start('exit'),
      ]).then(() => {
        setAnimatedToOpen(false)
        onClose()
      })
    }

    if (destroyOnClose) {
      return (
        <AnimatePresence initial>
          {visible && (
            // <Portal>
            <MainDrawer
              {...props}
              ref={ref}
              onClose={onBackdropClicked}
              drawerControls={drawerControls}
              backdropControls={backdropControls}
              destroyOnClose={destroyOnClose}
              placement={placement}
            />
            // </Portal>
          )}
        </AnimatePresence>
      )
    }

    return (
      // <Portal>
      <MainDrawer
        {...props}
        ref={ref}
        onClose={onBackdropClicked}
        drawerControls={drawerControls}
        backdropControls={backdropControls}
        destroyOnClose={destroyOnClose}
        placement={placement}
      />
      // </Portal>
    )
  }
)

type MainDrawerProps = IDrawerProps & {
  backdropControls: AnimationControls
  drawerControls: AnimationControls
}

const MainDrawer = React.forwardRef<HTMLDivElement, MainDrawerProps>(
  (props, ref) => {
    const {
      size,
      children,
      visible,
      openPopup,
      onClose,
      onPopupClose,
      title,
      popupTitle,
      popupContent,
      titleIcon,
      footer,
      popupFooter,
      css,
      backdropControls,
      drawerControls,
      placement,
      footerAlignment = 'end',
      destroyOnClose = true,
      maskClosable = true,
      closable = true,
      fullHeight = true,
    } = props

    return (
      <>
        {visible && (
          <StyledDrawerBackdrop
            css={{
              cursor: maskClosable ? 'pointer' : 'not-allowed',
            }}
            onClick={maskClosable ? onClose : undefined}
            variants={backdropMotionVariants}
            initial={'initial'}
            animate={destroyOnClose ? 'animate' : backdropControls}
            exit={'exit'}
          />
        )}
        <Portal>
          <ZIndexDrawerWrapper>
            <StyledDrawer
              ref={ref}
              fullHeight={fullHeight}
              size={size}
              css={css}
              variants={drawerMotionVariants}
              initial={'initial'}
              animate={destroyOnClose ? 'animate' : drawerControls}
              exit={'exit'}
              custom={placement}
            >
              <StyledDrawerPopup
                stretchy
                direction="column"
                justify="between"
                wrap="nowrap"
                openPopup={openPopup}
              >
                <StyledHeader align="center" justify="between">
                  {typeof title === 'string' ? (
                    <Flex align="center" gutterX={2}>
                      {titleIcon}
                      <Text color="$primary">{popupTitle}</Text>
                    </Flex>
                  ) : (
                    popupTitle
                  )}

                  {closable && (
                    <StyledButton appearance="ghost" onClick={onPopupClose}>
                      <HiX size="1.8rem" color="#ABB3B9" />
                    </StyledButton>
                  )}
                </StyledHeader>
                <StyledContent>{popupContent}</StyledContent>
                {popupFooter && (
                  <StyledFooter justify={footerAlignment}>
                    {popupFooter}
                  </StyledFooter>
                )}
              </StyledDrawerPopup>
              <Flex stretchy direction="column" justify="between" wrap="nowrap">
                <StyledHeader align="center" justify="between">
                  {typeof title === 'string' ? (
                    <Flex align="center" gutterX={2}>
                      {titleIcon}
                      <Text color="$primary">{title}</Text>
                    </Flex>
                  ) : (
                    title
                  )}

                  {closable && (
                    <StyledButton appearance="ghost" onClick={onClose}>
                      <HiX size="1.8rem" color="#ABB3B9" />
                    </StyledButton>
                  )}
                </StyledHeader>
                <StyledContent>{children}</StyledContent>
                {footer && (
                  <StyledFooter justify={footerAlignment}>
                    {footer}
                  </StyledFooter>
                )}
              </Flex>
            </StyledDrawer>
          </ZIndexDrawerWrapper>
        </Portal>
      </>
    )
  }
)

MainDrawer.displayName = 'MainDrawer'

export default Drawer

Drawer.displayName = 'Drawer'
