import { InputValidator } from './input.types'

function validateEmail(value: string): string {
  const validEmailSignature =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (!value || !validEmailSignature.test(value)) {
    return 'please enter a valid email address'
  }

  return ''
}

export const defaultValidators: Record<string, InputValidator> = {
  email(value) {
    return validateEmail(value)
  },
  password(value) {
    if (value.length < 8) {
      return 'Password should be min 8 characters'
    }
    if (!/\d/.test(value)) {
      return 'Password should contain at least one digit'
    }
    if (!/[A-Z]/.test(value)) {
      return 'Password should contain at least one Capital Letter'
    }
    if (!/[a-z]/.test(value)) {
      return 'Password should contain at least one small Letter'
    }
    if (!/\W/.test(value)) {
      return 'Password should contain at least one special character'
    }
    return ''
  },

  // for custom emails in refer-and-earn page
  email_0(value: string) {
    return validateEmail(value)
  },
  email_1(value: string) {
    return validateEmail(value)
  },
  email_2(value: string) {
    return validateEmail(value)
  },
  email_3(value: string) {
    return validateEmail(value)
  },
  email_4(value: string) {
    return validateEmail(value)
  },

  any(value: string) {
    if (value === '' || value === undefined || value === null) {
      return 'this field cannot be empty'
    }
    return ''
  },
}
