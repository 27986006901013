import Box from 'components/layout/box'
import PageHeader from 'components/page-header/page-header'
import { Tabs } from 'components/tabs/tabs'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import React from 'react'
import { ItemsTable } from '../components/items-table'

interface ItemsProps {}

const Items: React.FC<ItemsProps> = () => {
  return (
    <>
      <PageHeader title="Items" />
      <StyledLayoutContent>
        {/* <PageBanner
          title="View and manage your inventory items"
          description="Items are the products and services you sell. You can add items to your account and manage them here."
          actionText="Learn more"
          bgSvg={background}
          appearance="blue"
        /> */}
        <Box css={{ background: '$white', borderRadius: '$3' }}>
          <Tabs
            activeKey="1"
            tabs={[
              {
                key: '1',
                title: 'All Items',
                content: <ItemsTable />,
              },
            ]}
          />
        </Box>
      </StyledLayoutContent>
    </>
  )
}

export default Items
