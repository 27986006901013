import { ReactComponent as AccounteerLogo } from 'assets/svg/accounteer-logo.svg'
import React from 'react'

import Button from 'components/button/button'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import { useNavigate } from 'react-router-dom'
import {
  StyledCol,
  StyledContent,
  StyledLogoContainer,
  StyledRow,
} from '../auth.styles'
import { Footer } from '../components/footer/footer'
import AuthBackground from '../components/background'

const RegisterSuccesful: React.FC = () => {
  const navigate = useNavigate()

  return (
    <StyledRow gutter={1}>
      <StyledCol span={4}>
        <StyledContent>
          <StyledLogoContainer>
            <AccounteerLogo />
          </StyledLogoContainer>
          <div>
            <Flex className="flex" gutterY={2} direction={'column'}>
              <Text variant="h1" size="lg">
                You have mail!
              </Text>
              <Text variant="body2">
                We have sent you an email with further instructions on how to
                activate your account. Check it to proceed ...
              </Text>
            </Flex>

            <Flex
              gutterY={4}
              direction={'column'}
              css={{ mt: '3rem', mb: '5.5rem' }}
            >
              <Button onClick={() => navigate('/')}>Back to Login</Button>
            </Flex>

            <Footer
              justify="center"
              align="center"
              direction="column"
              gutterY={1}
            />
          </div>
        </StyledContent>
      </StyledCol>
      <AuthBackground />
    </StyledRow>
  )
}

export default RegisterSuccesful
