import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import { FileUpload } from 'components/file-upload/file-upload'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import {
  useDownloadTemplateMutation,
  useImportProductMutation,
} from 'generated/__generated_graphql'
import React from 'react'
import { HiArrowCircleUp } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'

interface IProps {
  visible: boolean
  onClose: () => void
  refetchProducts: () => void
}

export const ImportCsvDrawer: React.FC<IProps> = ({
  visible,
  onClose,
  refetchProducts,
}) => {
  const toast = useToast()
  const [uploadValues, setUploadValues] = React.useState<any[]>([])

  const [{ fetching: isDownloading }, downloadTemplate] =
    useDownloadTemplateMutation()
  const [{ fetching }, importCsv] = useImportProductMutation()

  function notify(error?: string, status?: 'success' | 'error') {
    toast({
      content: error ?? 'something went wrong',
      status: status,
    })
  }

  async function handleDownloadTemplate() {
    const response = await downloadTemplate({
      input: {
        key: 'product_import_template',
      },
    })

    const error = extractGraphqlErrors(response, 'downloadTemplate')

    if (error) {
      notify(error.message, 'error')
      return
    }

    const url = response?.data?.downloadTemplate?.templateUrl
    const a = document.createElement('a')
    a.href = url as string
    a.click()
    URL.revokeObjectURL(a.href)

    notify('Template downloaded', 'success')
  }

  async function handleImportCsv() {
    try {
      const response = await importCsv({
        input: {
          uploadId: uploadValues[0].id,
        },
      })
      const error = extractGraphqlErrors(response, 'createCustomer')

      if (error) {
        notify(error, 'error')
        return
      }
      notify('Products imported successfully', 'success')
      refetchProducts()
      onClose()
    } catch (error) {
      notify(error as string, 'error')
    }
  }

  return (
    <Drawer
      title="Import Products"
      titleIcon={<HiArrowCircleUp size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary">
            Cancel
          </Button>
          <Button
            disabled={!uploadValues.length}
            onClick={handleImportCsv}
            isLoading={fetching}
            size="md"
          >
            Continue
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="3" css={{ p: '$5' }}>
        <Text size="xxs" color="$secondary">
          Your contact CSV file should contain contact name, description,
          purchase_price, quantity, sale_price, show_on_invoices,
          show_on_purchases, sku, tracked
        </Text>
        <FileUpload accept=".csv" onChange={(val) => setUploadValues(val)} />
        <Flex align="center" gutter={1}>
          <Text size="xxs" color="$secondary">
            Need a hand getting started?
          </Text>
          <Button
            isLoading={isDownloading}
            disabled={isDownloading}
            size="xs"
            color="ghost"
            appearance="ghost"
            onClick={handleDownloadTemplate}
          >
            Download template
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}
