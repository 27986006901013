import Box from 'components/layout/box'
import Journals from 'pages/accounting/journals/journals'
import { Route, Routes } from 'react-router-dom'
import { ChartsOfAccountsPage } from './screens/chart-of-accounts/chart-of-accounts'

export const Accounting = () => {
  return (
    <Box>
      <Routes>
        <Route
          path="/charts-of-accounts"
          element={<ChartsOfAccountsPage />}
        ></Route>
        <Route path="/journals" element={<Journals />}></Route>
      </Routes>
    </Box>
  )
}
