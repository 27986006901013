import type * as Stitches from '@stitches/react'
import { InputProps } from 'components/input/input.types'
import React, { ReactNode } from 'react'
import { StyledTableContainer } from './table.styles'

export type ISortDirection = 'descending' | 'ascending'
export type IFilterOperator = 'BETWEEN' | 'INCLUDES' | '=' | '<' | '>'
type Sorter = (a: string | number | Date, b: string | number | Date) => void

export interface ITableColumn<T = any> {
  title: string
  dataIndex: keyof T
  key: string
  render?: (text: any, data: T) => React.ReactNode
  sorter?: Sorter // sorts the table with this column
  sortDirections?: ISortDirection[]
  filter?: ITableFilter
  shouldNotRender?: boolean
}

export interface ITableDataSource {
  id: string
  [x: string]: any
}

export enum ITableFilterTypes {
  TEXT = 'text', // show search input
  MONEY = 'money', // custom money filter
  NUMBER = 'number', // show search input
  CHECKBOX = 'checkbox', // show checkboxes
  SELECT = 'select', // show select
  DATE = 'date', // show date picker
}

export interface ITableFilterOptions {
  label: string
  value: string | number | Date
}

export interface ITableFilter {
  type: ITableFilterTypes
  operator?: IFilterOperator
  options?: ITableFilterOptions[]
  header?: ReactNode
}

export interface IFilterComponent extends ITableFilter {
  colKey: string
  placeholder?: string
  data?: ITableDataSource[]
  onFilter?: (
    key: string,
    type: ITableFilterTypes,
    value: ITableFilterOptions['value'][],
    operator?: IFilterOperator
  ) => void
}

export interface ITablePagination {
  totalCount: number
  currentPage?: number
  perPage?: number
  totalPages?: number
  onPageChange?: (currentPage: number) => void
  onPaginationClick?: (type: 'previous' | 'next') => void
}

export interface ITableEmptyNotice {
  icon?: ReactNode
  title?: string
  subtitle?: string
  action?: ReactNode
  type?: 'cta' | 'notice'
  loading?: boolean
}
export type TableProps<T = any> = React.ComponentProps<
  typeof StyledTableContainer
> & {
  columns: ITableColumn<T>[]
  dataSource: ITableDataSource[]
  hideHead?: boolean
  pagination?: ITablePagination
  tdPadding?: Stitches.CSS['font']
  showExport?: boolean
  showGeneralFilter?: boolean
  showDownload?: boolean
  showSearch?: boolean
  searchProps?: InputProps
  secondaryFilter?: ReactNode
  actions?: ReactNode
  emptyProps?: ITableEmptyNotice
  selectable?: boolean
  loading?: boolean
  onRowSelect?: (ids: ITableDataSource['id'][]) => void
  onRowClick?: (data: ITableDataSource) => void
}
