import {
  FinancialAccountsQuery,
  useFinancialAccountsQuery,
} from 'generated/__generated_graphql'
import { BankRulesPage } from 'pages/financial/screens/bank-rules/bank-rules'
import { Route, Routes } from 'react-router-dom'

import { FinancialAccounts } from './screens/accounts'
import { FinancialTransactions } from './screens/transactions'

export type BankAccountPagination = Exclude<
  FinancialAccountsQuery,
  undefined | null
>['financialAccounts']

export type CleanFinancialAccount = Exclude<
  BankAccountPagination,
  undefined | null
>['data'][number]

export type FinancialProps = {
  fetching?: boolean
  refetchFinancialAccounts: () => void
  financialAccounts: CleanFinancialAccount[]
}

export const Financial = () => {
  const [{ fetching, data: queryData }, refetchFinancialAccounts] =
    useFinancialAccountsQuery()

  const { data: financialAccounts } = queryData?.financialAccounts ?? {}

  return (
    <Routes>
      <Route
        path="/accounts"
        element={
          <FinancialAccounts
            fetching={fetching}
            refetchFinancialAccounts={() =>
              refetchFinancialAccounts({
                requestPolicy: 'network-only',
              })
            }
            financialAccounts={financialAccounts ?? []}
          />
        }
      />
      <Route
        path="/transaction/:id"
        element={
          <FinancialTransactions
            refreshFinancialAccounts={() =>
              refetchFinancialAccounts({
                requestPolicy: 'network-only',
              })
            }
            financialAccounts={financialAccounts ?? []}
          />
        }
      />
      <Route path="/bank-rules" element={<BankRulesPage />} />
    </Routes>
  )
}
