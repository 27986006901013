import { styled } from 'stitches/stitches.config'
import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import Input from 'components/input/input'
import Button from 'components/button/button'

export const SettingsWrapper = styled(Box, {})

export const StyledTableContainer = styled('div', {
  backgroundColor: '$white',
  borderRadius: '1rem',
  boxShadow: '$tiny',
  width: '92rem',

  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },
})

export const StyledDrawerInput = styled(Input, {
  '& .input-wrapper': {
    maxHeight: '4.4rem',
  },
})

export const StyledBadge = styled('div', {
  padding: '0.2rem 0.8rem',
  display: 'grid',
  placeItems: 'center',
  textTransform: 'capitalize',
  borderRadius: '0.4rem',
  width: 'fit-content',

  variants: {
    status: {
      active: {
        color: '$success',
        backgroundColor: '$successBg',
      },

      inactive: {
        color: '$primary',
        backgroundColor: '$background',
      },
      paid: {
        color: '$success',
        backgroundColor: '$successBg',
      },

      declined: {
        color: '$error',
        backgroundColor: '$errorBg',
      },
      draft: {
        color: '$primary',
        backgroundColor: '$background',
      },
    },
  },
})

export const StyledOptionsContainer = styled(Flex, {
  minWidth: '15rem',
})

export const StyledOptions = styled(Button, {
  boxShadow: 'none !important',
  padding: '0 !important',
  height: 'fit-content !important',
})
