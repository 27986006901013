import { ReactComponent as EmptyIcon } from 'assets/svg/empty-table.svg'
import Button from 'components/button/button'
import { Box } from 'components/layout'
import Flex from 'components/layout/flex'
import { styled } from 'stitches/stitches.config'

export const StyledTableContainer = styled('div', {
  width: '100%',
})

export const StyledTableActions = styled(Flex, {
  padding: '1.6rem 2rem',
  // width: '100%',
})

export const StyledInputWrap = styled(Flex, {
  flexGrow: 1,
})

export const StyledTable = styled('table', {
  width: '100%',
  maxWidth: '100%',
  // position: 'relative',
  variants: {
    loading: {
      true: {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    },
  },
})

export const StyledTh = styled('th', {
  padding: '1.4rem 2rem',
  borderBottom: '0.1rem solid $border',
})

export const StyledTd = styled('td', {
  padding: '1.6rem 2rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  variants: {
    border: {
      true: {
        borderBottom: '0.1rem solid $border',
      },
      false: { borderBottom: 'none' },
    },
  },
})

export const StyledPagination = styled(Flex, {
  borderTop: '0.1rem solid $border',
  padding: '1.6rem 2rem 1.6rem 2.4rem',
  variants: {
    loading: {
      true: {
        opacity: 0.5,
        pointerEvents: 'none',
      },
    },
  },
})

// filter styles

export const StyledFilterButton = styled('div', {
  borderRadius: 4,
  cursor: 'pointer',
  width: '1.6rem',
  height: '1.6rem',
  display: 'grid',
  placeItems: 'center',

  variants: {
    active: {
      true: {
        backgroundColor: '$highlightBg',

        '& svg': {
          color: '$highlight',
        },
      },
    },
  },

  '&:focus-within': {
    backgroundColor: '$highlightBg',

    '& svg': {
      color: '$highlight',
    },
  },
})

export const StyledFilterHeader = styled(Flex, {
  padding: '1.2rem 1.6rem',
  position: 'sticky',
  bottom: 0,
  borderBottom: '0.1rem solid $border',
})

export const StyledFilterFooter = styled(Flex, {
  padding: '1.2rem 0.8rem 0.8rem 1.6rem',
  position: 'sticky',
  bottom: 0,
})

export const StyledFilterClearButton = styled(Button, {
  padding: '0 !important',
  width: 'fit-content !important',
  height: 'fit-content !important',
  boxShadow: 'none !important',
})

export const StyledFilterOkButton = styled(Button, {
  padding: '0 !important',
  width: '6rem !important',
  height: '3.2rem !important',
})

// notice styles
export const StyledNotice = styled(Flex, {
  padding: '4.8rem',
  minHeight: '25rem',
  width: '100%',
})

export const StyledEmptyIcon = styled(EmptyIcon, {
  width: '5.6rem',
  height: '5.2rem',
})

export const StyledLoader = styled(Box, {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

export const StyledBody = styled('tbody', {
  variants: {
    loading: {
      true: {
        opacity: 0.3,
        pointerEvents: 'none',
      },
    },
  },
})

export const StyledTr = styled('tr', {})
