import Button from 'components/button/button'
import { Box, Flex, Stack } from 'components/layout'
import PageLoader from 'components/page-loader/page-loader'
import { Tabs } from 'components/tabs/tabs'
import { StyledRadixTabTriggerList } from 'components/tabs/tabs.styles'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import {
  BillingCycle,
  Plan,
  PlanTypeEnum,
  useOrganisationQuery,
  usePlansQuery,
  useSavePlanMutation,
} from 'generated/__generated_graphql'
import React, { useState } from 'react'
import { usePaystackPayment } from 'react-paystack'
import { styled } from 'stitches/stitches.config'
import { extractGraphqlErrors, formatMoney } from 'utils/helpers'

const Main = styled(Box, {
  width: '80%',
  margin: '0 auto',
  marginTop: 65,
})

const PlanCard = styled(Flex, {
  borderRadius: '$2',
  background: '$white',
  p: '$4',
  variants: {
    preferred: {
      true: {
        background: '$highlightBg',
      },
      false: {
        background: '$white',
      },
    },
  },
})

const PlanTab = styled(Tabs, {
  [`& ${StyledRadixTabTriggerList}`]: {
    p: '1.25rem .75rem',
    pb: '1.55rem',
    background: '$inputBg',
    borderRadius: '$3',
    columnGap: '$1',
    m: '0 auto',
    width: 'fit-content',
  },
  mb: '3.2rem',
})

const PlanItem = styled(Text, {
  py: '$4',
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  borderBottom: '1px solid $border',
})

export const TabButton = styled(Text, {
  gap: '$2',
  color: '$secondary',
  padding: '$2 $4',
  borderRadius: '$1',
  border: '1px solid transparent',
  fontFamily: '$bold',
  boxShadow: '0 .2rem .4rem transparent',
  transition: 'all .3s ease-in-out',

  variants: {
    active: {
      true: {
        color: '$blue',
        background: '$white',
        border: '1px solid $border',
        boxShadow: '$subtle',
        borderRadius: '$1',
      },
    },
  },
})

type PlanType = Exclude<PlanTypeEnum, PlanTypeEnum.All>

const SubscribeToPlan: React.FC = () => {
  const query = new URLSearchParams(window.location.search)
  const [planType, setPlanType] = useState<PlanType>(PlanTypeEnum.Product)
  const notify = useToast()

  const [billingCycle, setBillingCycle] = useState<BillingCycle>(
    BillingCycle.Monthly
  )

  const [{ fetching: fetchingPlans, data: plans }] = usePlansQuery({
    variables: { type: planType },
  })

  const [{ fetching: fetchingOrganisation, data: organisation }] =
    useOrganisationQuery()

  const [{ fetching: savingPlan }, savePlan] = useSavePlanMutation()

  const userPlan = organisation?.organisation?.plan
  const cyclePlans =
    plans?.plans?.filter((plan) => plan.billingCycle === billingCycle) ?? []

  const [selectedPlan, setSelectedPlan] = useState('')

  const config = {
    email:
      localStorage.getItem('email') ??
      (organisation?.organisation?.users?.[0]?.email as string),
    amount: 100,
    plan: selectedPlan,
    publicKey: process.env.REACT_APP_PAYSTACK_KEY as string,
    metadata: {
      custom_fields: [
        {
          display_name: 'organisation',
          variable_name: 'organisationId',
          value: organisation?.organisation?.id,
        },
      ],
    },
  }

  const initializePayment = usePaystackPayment(config)

  React.useEffect(() => {
    const queryStatus = query.get('planType') as PlanType
    if (queryStatus) {
      setPlanType(queryStatus)
    }
  }, [query])

  React.useEffect(() => {
    const onSuccess = (reference?: any) => {
      if (reference.status === 'success') {
        window.location.replace(`${window.location.origin}/dashboard`)
      }
    }

    initializePayment(onSuccess)
  }, [selectedPlan])

  const isBusinessInNigeria =
    window.localStorage.getItem('countryCode') === 'NG' ||
    organisation?.organisation?.countryCode === 'NG'

  async function handleCheckout(plan: Plan) {
    try {
      if (!isBusinessInNigeria) {
        notify({
          content: 'Initiating checkout',
          status: 'success',
          duration: 500,
        })

        const response = await savePlan({
          input: {
            planId: plan.id,
            successUrl: `${window.location.origin}/dashboard`,
            cancelUrl: `${window.location.origin}/onboarding?status=plans`,
          },
        })

        const error = extractGraphqlErrors(response, 'savePlan')

        if (error) {
          notify({
            content: error,
            status: 'error',
          })
          return
        }

        const { url } = response.data?.savePlan ?? {}
        window.location.replace(url!)
      } else {
        setSelectedPlan(plan.paystackPlanId as string)
      }
    } catch (error) {
      notify({
        content: 'Something went wrong',
        status: 'error',
      })
    }
  }

  if (fetchingPlans || fetchingOrganisation) {
    return <PageLoader />
  }

  return (
    <Main>
      <Box>
        <Stack css={{ mb: 50 }} spacing={4} align="center">
          <Text color="#848C96" size="xs">
            STEP 4 OF 4
          </Text>
          <Text weight="bold" variant="h3" size="md">
            Choose a plan that works for your business
          </Text>
          <Text color="#848C96" size="xxs">
            Upgrade to a paid plan to use all of our great features. Your card
            won’t be charged until after your trial ends in 30 days.
          </Text>
        </Stack>

        <Flex justify="center" align={'center'} css={{ width: '100%' }}>
          <Flex direction={'column'} align={'center'}>
            {planType === PlanTypeEnum.Product && (
              <PlanTab
                activeKey={billingCycle}
                fullWidth={false}
                hideDefaultIndicator
                hideBottomBorder
                onChange={(id) => setBillingCycle(id as BillingCycle)}
                tabs={[
                  {
                    key: BillingCycle.Monthly,
                    title: (
                      <TabButton
                        active={billingCycle === BillingCycle.Monthly}
                        size="xs"
                        role="button"
                      >
                        Billed Monthly
                      </TabButton>
                    ),
                  },
                  {
                    key: BillingCycle.Quarterly,
                    title: (
                      <TabButton
                        active={billingCycle === BillingCycle.Quarterly}
                        size="xs"
                        role="button"
                      >
                        Billed Quarterly
                      </TabButton>
                    ),
                  },
                  {
                    key: BillingCycle.Yearly,
                    title: (
                      <TabButton
                        active={billingCycle === BillingCycle.Yearly}
                        size="xs"
                        role="button"
                      >
                        Billed Yearly
                      </TabButton>
                    ),
                  },
                ]}
              />
            )}
          </Flex>
        </Flex>

        <Flex justify={'center'} css={{ width: '100%' }}>
          {cyclePlans?.map((plan) => (
            <PlanCard preferred={plan.id === userPlan?.id} key={plan.id}>
              <Flex
                direction="column"
                css={{ width: '22rem', rowGap: '10rem' }}
              >
                <Flex direction="column" gutter="1" css={{ height: '14rem' }}>
                  <Text
                    css={{ mb: '$2', fontFamily: '$bold' }}
                    size="sm"
                    weight="bold"
                  >
                    {plan.name}
                  </Text>
                  {isBusinessInNigeria ? (
                    <Text size="lg">
                      {formatMoney(plan?.paystackPrice ?? (0 as number), '₦')}
                    </Text>
                  ) : (
                    <Text size="lg">{formatMoney(plan.price, '$')}</Text>
                  )}

                  <Text size="sm" color="$secondary">
                    per user /{' '}
                    {plan.billingCycle === 'monthly' ? 'month' : 'year'}
                  </Text>
                  <Button
                    css={{ mt: '$6' }}
                    disabled={savingPlan}
                    onClick={() => handleCheckout(plan)}
                    appearance={'primary'}
                  >
                    <Text color="$white" size="xs" weight="bold">
                      Select Plan
                    </Text>
                  </Button>
                </Flex>
                <Flex direction="column">
                  <Text size="sm" weight="bold">
                    &nbsp;
                  </Text>
                  {plan.features.map((feature) => (
                    <PlanItem key={feature} size="xs">
                      {feature}
                    </PlanItem>
                  ))}
                </Flex>
              </Flex>
            </PlanCard>
          ))}
        </Flex>
      </Box>
    </Main>
  )
}

export default SubscribeToPlan
