import EmptyStateImage from 'assets/png/service dashboard.png'
import Button from 'components/button/button'
import { Flex } from 'components/layout'
import { Table } from 'components/table/table'
import { StyledTable } from 'components/table/table.styles'
import { ITableColumn } from 'components/table/table.types'
import Tag from 'components/tag/tag'
import Text from 'components/text/text'
import { format } from 'date-fns'
import { SmartReportStatus } from 'generated/__generated_graphql'
import React from 'react'
import { getApiUrl } from 'utils/helpers'
import { CleanServiceReport, statusIcons } from '../service-report'
import ProcessedReportsTableDataAction from './processed-service-reports-table-action'

export type ProcessedReportsTableData = {
  id: string
  date: string
  documentName: string

  status: string
  report: CleanServiceReport
}

const columns: ITableColumn<ProcessedReportsTableData>[] = [
  {
    key: 'date',
    title: 'DATE',
    dataIndex: 'date',
    render: (date: string) => (
      <Text size={'xs'} color="$secondary">
        {format(Date.parse(date), 'dd MMM, yyyy')}
      </Text>
    ),
  },
  {
    key: 'documentName',
    title: 'REPORT DOCUMENT',
    dataIndex: 'documentName',
    render: (documentName) => (
      <Text size={'xs'} color="$grayDark2">
        {documentName}
      </Text>
    ),
  },

  {
    key: 'status',
    title: 'STATUS',
    dataIndex: 'status',
    render: (status) => (
      <Tag
        type={'success'}
        css={{
          py: '.6rem',
        }}
      >
        <Flex align="center" gutterX="1" justify="between">
          {statusIcons[SmartReportStatus.Completed].icon}
          {status}
        </Flex>
      </Tag>
    ),
  },
  {
    key: 'action',
    title: '',
    dataIndex: 'id',
    render: (_, { report }) => {
      return <ProcessedReportsTableDataAction report={report} />

      return (
        <Flex stretchx justify="end">
          <Button
            appearance={'ghost'}
            as={'a'}
            // @ts-ignore
            href={`${getApiUrl()}/smart-reports/${report.id}.pdf`}
            target="_blank"
            css={{
              width: '100%',
              justifyContent: 'end',
              cursor: 'pointer',

              '&:hover': {
                color: '$blue',
              },
            }}
          >
            Download report
          </Button>
        </Flex>
      )
    },
  },
]

type ProcessedReportsTableProps = {
  reports: CleanServiceReport[]
}

const ProcessedReportsTable: React.FC<ProcessedReportsTableProps> = (props) => {
  const { reports } = props

  const tableData: ProcessedReportsTableData[] =
    reports.map((report) => ({
      id: report.id,
      date: report.createdAt,
      documentName: report.name ?? '',
      status: 'Completed',
      report: report,
    })) ?? []

  if (tableData.length < 1) {
    return (
      <Flex
        direction="column"
        justify="center"
        align="center"
        gutter="1"
        css={{
          width: '100%',
          padding: '4rem',
          backgroundColor: '#fff',
        }}
      >
        <img
          src={EmptyStateImage}
          style={{ height: '12rem', transform: 'translateX(-5.5rem)' }}
        />
        <Flex direction="column" align="center" css={{ gap: '1.1rem' }}>
          <Text
            size="sm"
            weight="bold"
            css={{ fontFamily: '$space', color: '$black', fontWeight: '500' }}
          >
            No completed reports available yet
          </Text>
          <Text
            size="xxs"
            align="center"
            css={{ color: '$secondary', width: '70%', marginInline: 'auto' }}
          >
            You will be notified when your information has been processed and
            your report is ready
          </Text>
        </Flex>
      </Flex>
      // <Flex
      //   direction={'column'}
      //   justify={'center'}
      //   align={'center'}
      //   css={{
      //     width: '100%',
      //     height: '47.4rem',
      //     backgroundColor: '#fff',
      //     gap: '4rem',
      //   }}
      // >
      //   <img
      //     src={EmptyStateImage}
      //     style={{ width: '5.6rem', height: '5.2rem' }}
      //   />
      //   <Flex direction={'column'} align={'center'} css={{ gap: '1.1rem' }}>
      //     <Text size={'md'} weight="bold" css={{ color: '$primary' }}>
      //       No completed reports available yet
      //     </Text>
      //     <Text size={'sm'} css={{ color: '$secondary' }}>
      //       You will be notified when your information has been processed and
      //       your report is ready
      //     </Text>
      //   </Flex>
      // </Flex>
    )
  } else {
    return (
      <Table
        columns={columns}
        dataSource={tableData}
        css={{
          h4: {
            color: '$grayDark2',
          },
          [`& ${StyledTable}`]: {
            backgroundColor: '#fff',
          },
        }}
      />
    )
  }
}

export default ProcessedReportsTable
