import { Box, Flex } from 'components/layout'
import { styled } from 'stitches/stitches.config'

export const Header = styled(Box, {
  padding: '20px',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.04)',
})

export const Logout = styled(Flex, {
  padding: 5,
  cursor: 'pointer',
  transition: 'all .2s ease-in-out',

  '&:hover': {
    background: '$highlightBg',
    borderRadius: '$2',
  },
})
