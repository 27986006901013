import Box from 'components/layout/box'
import { Route, Routes } from 'react-router-dom'
import Account from './screens/account/account'
import Organisation from './screens/organisation/organisation'
import Product from './screens/product/product'

const Settings = () => {
  return (
    <Box>
      <Routes>
        <Route path="/account/*" element={<Account />}></Route>
        <Route path="/product/*" element={<Product />}></Route>
        <Route path="/organisation/*" element={<Organisation />}></Route>
      </Routes>
    </Box>
  )
}

export default Settings
