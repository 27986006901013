import type * as Stitches from '@stitches/react'
import React from 'react'
import { StyledText } from './text.styles'

enum TEXVARIANTS {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  body1 = 'p',
  body2 = 'p',
  caption = 'small',
}

enum FONTSIZES {
  xxl = 'xxl',
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
  xxs = 'xxs',
}

export type ITextProps = React.ComponentProps<typeof StyledText> & {
  variant?: keyof typeof TEXVARIANTS
  size?: keyof typeof FONTSIZES
  weight?: 'regular' | 'medium' | 'semi' | 'bold'
  font?: 'inherit' | 'space'
  align?: Stitches.CSS['textAlign']
  color?: Stitches.CSS['color']
  style?: Stitches.CSS['fontStyle']
  css?: Stitches.CSS
  lineheight?: Stitches.CSS['lineHeight']
  decoration?: Stitches.CSS['textDecoration']
  children: React.ReactNode
  disabled?: boolean
}

export const Text = React.forwardRef<HTMLElement, ITextProps>(
  (
    {
      variant = TEXVARIANTS.body1,
      align,
      color,
      size,
      font,
      weight = 'semi',
      lineheight,
      decoration,
      disabled,
      style,
      children,
      css,
      ...props
    },
    ref
  ) => {
    const sizeMap = {
      caption: FONTSIZES.xxs,
      body1: FONTSIZES.sm,
      body2: FONTSIZES.xs,
      h1: FONTSIZES.xxl,
      h2: FONTSIZES.xl,
      h3: FONTSIZES.lg,
      h4: FONTSIZES.md,
      ['' as TEXVARIANTS]: FONTSIZES, // temporary trick type fix
    }

    const castedVariants: { [idx: string]: TEXVARIANTS } = TEXVARIANTS

    return (
      <StyledText
        ref={ref}
        as={castedVariants[variant]}
        size={size ?? sizeMap[variant]}
        disabled={disabled}
        css={{
          textAlign: align,
          color: color,
          font: font,
          lineHeight: lineheight,
          textDecoration: decoration,
          style: style,
          fontFamily: `'accounteer ${weight}', sans-serif`,
          ...css,
        }}
        {...props}
      >
        {children}
      </StyledText>
    )
  }
)

export default Text

Text.displayName = 'Text'
