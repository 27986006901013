import Box from 'components/layout/box'
import { styled } from 'stitches/stitches.config'

export const StyledDropdownWrapper = styled('div', {
  width: 'fit-content',
})

export const StyledDropdownTrigger = styled('div', {})

export const StyledDropdown = styled('div', {
  backgroundColor: '$white',
  position: 'fixed',
  borderRadius: 8,
  boxShadow: '0 0.8rem 2rem rgb(0 0 0 / 8%)',
  border: '0.1rem solid $gray1',
  zIndex: 99,
  pointerEvents: 'auto',
  maxHeight: '36rem',
  overflow: 'auto',
  width: 'fit-content',
})

export const DropdownViewport = styled(Box, {
  maxHeight: '36rem',
  overflowY: 'scroll',
})

export const StyledDropdownMenu = styled('ul', {
  padding: '0',
  margin: 0,
  // minWidth: '24.8rem',
})

export const StyledDropdownMenuItem = styled('li', {
  display: 'flex',
  alignItems: 'center',
  // px: '1.6rem',
  // py: '0.8rem',
  minHeight: '3.6rem',
  fontFamily: '$medium',
  fontSize: '$2',
  // cursor: 'pointer',
  userSelect: 'none',
  color: '$primary',

  // '&:hover': {
  //   color: '$highlight',
  //   backgroundColor: '$highlightBg',

  //   '& *': {
  //     color: '$highlight',
  //   },
  // },
  // '& *': {
  //   color: '$highlight',
  // },
  // },
  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        opacity: 0.6,
      },
      false: {
        pointerEvents: 'auto',
        opacity: 1,
      },
    },
  },
})
