import PageHeader from 'components/page-header/page-header'
import { Tabs } from 'components/tabs/tabs'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import { StyledTableContainer } from 'pages/sales/sales.styles'
import { useState } from 'react'
import { InvoiceTable } from './components/table'
import InvoiceProvider from './provider/invoice-provider'

type Status = 'all' | 'draft'

export const Invoices = ({ type }: { type: 'invoice' | 'offer' }) => {
  const isInvoice = type === 'invoice'
  const [status, setStatus] = useState<Status>('all')
  return (
    <InvoiceProvider>
      <PageHeader title={isInvoice ? 'Invoices' : 'Offers'} />
      <StyledLayoutContent>
        {/* <PageBanner
          title={`Send ${isInvoice ? 'invoices' : 'offers'} to your customers`}
          description={`Create and send ${
            isInvoice ? 'invoices' : 'offers'
          } to your customers. You can also send reminders to your customers to pay their ${
            isInvoice ? 'invoices' : 'offers'
          }.`}
          actionText="Watch demo"
          bgSvg={isInvoice ? InvoiceBg : OffersBg}
        /> */}
        <StyledTableContainer fullWidth>
          <Tabs
            activeKey={status}
            tabs={[
              {
                key: 'all',
                title: isInvoice ? 'All Invoices' : 'All Offers',
              },
              { key: 'drafts', title: 'Drafts' },
            ]}
            onChange={(val) => {
              setStatus(val as Status)
            }}
          />
          <InvoiceTable status={status} type={type} />
        </StyledTableContainer>
      </StyledLayoutContent>
    </InvoiceProvider>
    // </>
  )
}
