import { DashboardLayoutProps } from '../components/layouts/layout.types'

import ReportPage from 'pages/service-report/service-report'
import Settings from 'pages/settings/settings'
import ServiceReportLayout from '../components/layouts/service-reports-layout'
import SettingsLayout from '../components/layouts/settings-layout'

type DashboardRoute<T = any> = {
  path?: string
  layout?: React.FC<T>
  layoutProps?: DashboardLayoutProps
  element: React.FC<T>
  provider?: any
  isIndex?: boolean
}

const serviceDashboardRoutes: Array<DashboardRoute> = [
  {
    path: '/',
    isIndex: true,
    element: ReportPage,
    layout: ServiceReportLayout,
  },
  // {
  //   path: '/meetings',
  //   element: MeetingsPage,
  //   layout: ServiceReportLayout,
  // },
  {
    path: '/settings/*',
    element: Settings,
    layout: SettingsLayout,
  },
]

export default serviceDashboardRoutes
