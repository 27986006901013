import Flex from 'components/layout/flex'
import Loader from 'components/loader/loader'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import Tag from 'components/tag/tag'
import { Text } from 'components/text/text'
import { format } from 'date-fns'
import { useLogsQuery } from 'generated/__generated_graphql'

import { useState } from 'react'
import LogsAccordion from './logs-accordion'

interface LogsTableData {
  id: string
  action: any
  category: string

  ipAddress: string
  time: string
}

const columns: ITableColumn<LogsTableData>[] = [
  {
    key: 'action',
    title: 'Action',
    dataIndex: 'action',
    render: (value: LogsTableData['action']) => (
      <Flex css={{ width: '100%' }}>
        <LogsAccordion css={{ width: '90%' }} data={value}>
          <Flex direction="column" css={{ mt: '$4' }} gutter="2">
            <Flex justify="between">
              <Text color="$secondary" size="xs">
                User
              </Text>
              <Text size="xs">{value.email}</Text>
            </Flex>
            <Flex justify="between">
              <Text color="$secondary" size="xs">
                Browser
              </Text>
              <Text size="xs">{value.browser}</Text>
            </Flex>
            <Flex justify="between">
              <Text color="$secondary" size="xs">
                Operating System
              </Text>
              <Text size="xs">{value.operatingSystem}</Text>
            </Flex>
            <Flex justify="between">
              <Text color="$secondary" size="xs">
                Device
              </Text>
              <Text size="xs">{value.device}</Text>
            </Flex>
            <Flex justify="between">
              <Text color="$secondary" size="xs">
                Action
              </Text>
              <Text size="xs">{value.action}</Text>
            </Flex>
            <Flex justify="between">
              <Text color="$secondary" size="xs">
                Role
              </Text>
              <Text size="xs">{value.role}</Text>
            </Flex>
          </Flex>
        </LogsAccordion>
      </Flex>
    ),
  },
  {
    key: 'category',
    title: 'Category',
    dataIndex: 'category',
    render: (value: LogsTableData['category']) => (
      <Tag
        size="xs"
        // css={{ color: '#6759A1', background: '#F6F3FF' }}
        content={value}
      />
    ),
  },

  {
    key: 'ipAddress',
    title: 'IP ADDRESS',
    dataIndex: 'ipAddress',
  },
  {
    key: 'time',
    title: 'TIME',
    dataIndex: 'time',
  },
]

// const DateFilter = () => (
//   <Flex stretchy align="stretch">
//     <Flex>
//       <StyledSelectPrefix css={{ minWidth: '20rem' }}>
//         <Text size="xs" color="$secondary">
//           Showing activities for
//         </Text>
//       </StyledSelectPrefix>
//       <StyledSelectWrapper css={{ minWidth: '16rem' }}>
//         <StyledDateInput
//           prepend={<HiCalendar size="1.8rem" color="#ABB3B9" />}
//           append={<HiChevronDown size="2.8rem" color="#ABB3B9" />}
//           placeholder="Select Date"
//           css={{ height: '100%' }}
//         />
//       </StyledSelectWrapper>
//     </Flex>

//     <Select
//       css={{ ml: '$3' }}
//       isClearable
//       prepend={<HiFilter color="#ABB3B9" size="1.5rem" />}
//       placeholder="Filter"
//       options={[
//         {
//           label: 'This Year',
//           value: 'this',
//         },
//         {
//           label: 'Last Year',
//           value: 'last',
//         },
//         {
//           label: 'Newest Update',
//           value: 'newest_update',
//         },
//         {
//           label: 'Last Update',
//           value: 'latest_update',
//         },
//       ]}
//     />
//   </Flex>
// )

export const LogsTable = () => {
  const [paginationPage, setPaginationPage] = useState(1)
  const [{ data: logsQueryData, fetching: queringLogs }] = useLogsQuery({
    variables: {
      pagination: {
        per: 10,
        page: paginationPage,
      },
    },
  })

  const { logs: queryData } = logsQueryData ?? {}
  const { data: logs, pagination: logsPagination } = queryData ?? {}

  const tableData =
    logs?.map<LogsTableData>((log) => ({
      id: log.id,
      action: {
        name: log.user.firstName + ' ' + log.user.lastName,
        action: log.description,
        email: log.user.email,
        browser: log.browser,
        operatingSystem: log.os,
        device: log.device,
        role: log.user.role?.name,
      },
      category: log.itemType,

      ipAddress: log.remoteIp,

      time:
        format(Date.parse(log.createdAt), 'MMM dd') +
        '. ' +
        format(Date.parse(log.createdAt), 'K:mm a'),
    })) ?? []

  if (!logs)
    return (
      <Flex
        align={'center'}
        justify="center"
        css={{ width: '100%', height: '100vh' }}
      >
        <Loader size={'md'} />
      </Flex>
    )

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      loading={queringLogs}
      pagination={{
        totalCount: logsPagination?.totalCount ?? 0,
        currentPage: logsPagination?.currentPage ?? 0,
        perPage: 10,
        onPageChange: () => null,
        totalPages: logsPagination?.totalPages ?? 0,
        onPaginationClick: (type) => {
          if (type === 'next') {
            paginationPage < 10 && setPaginationPage(paginationPage + 1)
          } else {
            paginationPage > 1 && setPaginationPage(paginationPage - 1)
          }
        },
        // onPaginationClick: (type) => {
        //   if (type === 'next') {
        //     if (paginationPage === logsPagination?.totalPages) return
        //     setPaginationPage(paginationPage + 1)
        //   } else {
        //     if (paginationPage === 1) return
        //     setPaginationPage(paginationPage - 1)
        //   }
        // },
      }}
    />
  )
}
