import { Avatar } from 'components/avatar/avatar'
import Button from 'components/button/button'
import DrawerAccordion from 'components/drawer-accordion/drawer-accordion'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import {
  useCountriesQuery,
  useCurrenciesQuery,
} from 'generated/__generated_graphql'
import React from 'react'
import { HiOfficeBuilding, HiPlus } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { formatMoney } from 'utils/helpers'
import {
  StyledDetails,
  StyledProductDetails,
  StyledProductItem,
} from '../contacts.styles'
import { CleanCustomer } from './contacts-table'
import InvoiceTable from './invoice-table'

interface ContactsDetailsDrawerProps {
  visible: boolean
  onClose: () => void
  handleEditContact: () => void
  customer: CleanCustomer
}

export const ContactsDetailsDrawer: React.FC<ContactsDetailsDrawerProps> = (
  props
) => {
  const navigate = useNavigate()
  const { visible, onClose, customer, handleEditContact } = props
  const { bankAccount, invoices } = customer

  const [{ data: currenciesQueryData }] = useCurrenciesQuery()
  const { currencies } = currenciesQueryData ?? {}
  const currency = currencies?.find(
    (currency) => currency.id === bankAccount?.currencyId
  )?.name

  const [{ data: countriesData }] = useCountriesQuery({
    variables: {
      all: true,
    },
    pause: !customer.country,
  })
  const country = countriesData?.countries.find(
    (country) => country.code === customer.country
  )?.name

  function handleEdit() {
    onClose()
    handleEditContact()
  }

  return (
    <Drawer
      title={customer.name}
      titleIcon={<HiOfficeBuilding size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      css={{ minWidth: '75rem' }}
    >
      <Flex direction="column" gutter="5" css={{ p: '$5' }}>
        <StyledProductItem align="center" justify="start" gutter="3">
          <Avatar size="medium" title={customer.name} />
          <Flex gutter="1" direction="column">
            <Text weight="bold" size="xs">
              {customer.name}
            </Text>
            <Text size="xxs">{customer.address}</Text>
          </Flex>
        </StyledProductItem>

        <DrawerAccordion
          label="About"
          action={
            <Button appearance="ghost" onClick={handleEdit}>
              <Text size="xs" color="$blue">
                Edit
              </Text>
            </Button>
          }
        >
          <StyledProductDetails direction="column" gutterY="2">
            <DrawerAccordion border label="Info" css={{ p: '$3' }}>
              <Flex direction="column" gutter="2">
                <Flex justify="between">
                  <Text size="xs" color="$secondary">
                    Name
                  </Text>
                  <Text size="xs">{customer.name}</Text>
                </Flex>
                <Flex justify="between">
                  <Text size="xs" color="$secondary">
                    VAT
                  </Text>
                  <Text size="xs">{customer.tin}</Text>
                </Flex>
                <Flex justify="between">
                  <Text size="xs" color="$secondary">
                    Country
                  </Text>
                  <Text size="xs">{country}</Text>
                </Flex>
                <Flex justify="between">
                  <Text size="xs" color="$secondary">
                    Zip Code
                  </Text>
                  <Text size="xs">{customer.zipCode}</Text>
                </Flex>
                <Flex justify="between">
                  <Text size="xs" color="$secondary">
                    Address
                  </Text>
                  <Text size="xs" css={{ textAlign: 'right' }}>
                    {customer.address}
                  </Text>
                </Flex>
              </Flex>
            </DrawerAccordion>
            {!!customer.customerContacts?.length && (
              <DrawerAccordion
                border
                label="Organisation Contacts"
                css={{ p: '$3 $4' }}
              >
                <Flex direction="column" gutter="2">
                  {customer.customerContacts?.map((customer) => (
                    <Flex
                      key={customer.id}
                      direction="column"
                      gutter={2}
                      css={{ pb: '$4' }}
                    >
                      <Flex justify="between">
                        <Text size="xs" color="$secondary">
                          Name
                        </Text>
                        <Text size="xs">{customer.name}</Text>
                      </Flex>
                      <Flex justify="between">
                        <Text size="xs" color="$secondary">
                          Email Address
                        </Text>
                        <Text size="xs">{customer.email}</Text>
                      </Flex>
                      <Flex justify="between">
                        <Text size="xs" color="$secondary">
                          Phone
                        </Text>
                        <Text size="xs">{customer.phone}</Text>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </DrawerAccordion>
            )}

            {!!bankAccount && (
              <DrawerAccordion
                border
                label="Banking Details"
                css={{ p: '$3 $4' }}
              >
                <Flex direction="column" gutter="2">
                  <Flex justify="between">
                    {bankAccount?.name ? (
                      <>
                        <Text size="xs" color="$secondary">
                          Bank Name
                        </Text>
                        <Text size="xs">{bankAccount?.name}</Text>
                      </>
                    ) : (
                      <>
                        <Text size="xs" color="$secondary">
                          Routing number
                        </Text>
                        <Text size="xs">{bankAccount?.routingNumber}</Text>
                      </>
                    )}
                  </Flex>
                  <Flex justify="between">
                    <Text size="xs" color="$secondary">
                      Account Number
                    </Text>
                    <Text size="xs">{bankAccount?.accountNumber}</Text>
                  </Flex>
                  <Flex justify="between">
                    <Text size="xs" color="$secondary">
                      Currency
                    </Text>
                    <Text size="xs">{currency}</Text>
                  </Flex>
                </Flex>
              </DrawerAccordion>
            )}
          </StyledProductDetails>
        </DrawerAccordion>

        <DrawerAccordion
          label="Invoices"
          action={
            <Flex align="center" stretchx justify="end" gutter="2">
              <Text
                size="xs"
                color="$blue"
                role="button"
                onClick={() =>
                  navigate(
                    `/dashboard/sales/invoices?openDrawer=true?id=${customer.id}`
                  )
                }
              >
                Create New Invoice
              </Text>
              <HiPlus size="1.4rem" color="#398AFA" />
            </Flex>
          }
        >
          <Flex direction="column" gutter="4">
            <Flex align="center" gutter="4">
              <StyledDetails direction="column" gutter="2">
                <Text size="xs" color="$secondary">
                  Total issued
                </Text>
                <Text size="lg">
                  {formatMoney(customer.totalIssued, customer.currency.symbol)}
                </Text>
              </StyledDetails>
              <StyledDetails direction="column" gutter="2">
                <Text size="xs" color="$secondary">
                  Amount Paid
                </Text>
                <Text size="lg">
                  {formatMoney(customer.amountPaid, customer.currency.symbol)}
                </Text>
              </StyledDetails>
              <StyledDetails direction="column" gutter="2">
                <Text size="xs" color="$secondary">
                  Outstanding amount
                </Text>
                <Text size="lg">
                  {formatMoney(
                    customer.outstandingAmount,
                    customer.currency.symbol
                  )}
                </Text>
              </StyledDetails>
            </Flex>
            <InvoiceTable customerInvoices={invoices ?? []} />
          </Flex>
        </DrawerAccordion>

        {/* <DrawerAccordion label="Purchases / Expenses">
          <Table
            columns={[]}
            dataSource={[]}
            emptyProps={{
              title: 'Purchases / Expenses',
              type: 'cta',
              subtitle: `You haven't added any purchases or expenses yet`,
              action: (
                <Button prepend={<HiOutlinePlus color="#fff" />} size="xl">
                  Register Expense
                </Button>
              ),
            }}
          />
        </DrawerAccordion> */}
      </Flex>
    </Drawer>
  )
}
