import { Dropdown } from 'components/dropdown/dropdown'
import Flex from 'components/layout/flex'
import { AccountStatusEnum } from 'generated/__generated_graphql'
import {
  StyledOptions,
  StyledOptionsContainer,
} from 'pages/settings/settings.styles'
import { useAppProvider } from 'providers/app-provider'
import React, { useState } from 'react'
import { HiDotsHorizontal } from 'react-icons/hi'
import { Member } from '../../../pages/members'
import DeleteMemberDrawer from './delete-member-drawer'
import { EditMemberDrawer } from './edit-member-drawer'

type MembersTableDataActionProps = { member: Member }

const MembersTableDataAction: React.FC<MembersTableDataActionProps> = ({
  member,
}) => {
  const [showEditMemberDrawer, setShowEditMemberDrawer] = useState(false)
  const [showDeleteMemberDrawer, setShowDeleteMemberDrawer] = useState(false)
  const { profile } = useAppProvider()

  return (
    <>
      <Flex stretchx justify="end">
        <Dropdown
          placement="bottomRight"
          menu={[
            ...(member.accountStatus !== AccountStatusEnum.PendingInviteAccept
              ? [
                  {
                    label: 'Edit User Permissions',
                    onClick: () => setShowEditMemberDrawer(true),
                  },
                ]
              : []),
            ...(member.id !== profile?.id
              ? [
                  {
                    label: 'Delete User',
                    onClick: () => setShowDeleteMemberDrawer(true),
                  },
                ]
              : []),
          ]}
        >
          <StyledOptionsContainer stretchx align="center" justify="end">
            <StyledOptions appearance="ghost">
              <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
            </StyledOptions>
          </StyledOptionsContainer>
        </Dropdown>
      </Flex>

      <EditMemberDrawer
        visible={showEditMemberDrawer}
        onClose={() => setShowEditMemberDrawer(false)}
        member={member}
      />

      <DeleteMemberDrawer
        member={member}
        visible={showDeleteMemberDrawer}
        onClose={() => setShowDeleteMemberDrawer(false)}
      />
    </>
  )
}

export default MembersTableDataAction
