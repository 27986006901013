import { Flex } from 'components/layout'
import Text from 'components/text/text'
import CommandButton from './command-button'

function MenuItem({
  item,
}: {
  item: {
    icon: React.ReactNode
    title: string
    action: () => void
    character?: string
    extra?: React.ReactNode
  }
}) {
  return (
    <Flex
      className="item"
      gutter={2}
      role="button"
      align="center"
      onClick={item.action}
    >
      {item.icon}
      <Text
        color="$secondary"
        size="xs"
        css={{
          cursor: 'pointer',
          transition: 'color 0.2s ease',
          '&:hover': {
            color: '$primary',
          },
        }}
      >
        {item.title}
      </Text>
      {item?.character && (
        <CommandButton css={{ ml: 'auto' }} character={item.character}>
          {item?.extra}
        </CommandButton>
      )}
    </Flex>
  )
}

export default MenuItem
