import { styled } from 'stitches/stitches.config'
import * as RadixAccordion from '@radix-ui/react-accordion'

export const Root = styled(RadixAccordion.Root, {})

export const Item = styled(RadixAccordion.Item, {
  '&:not(:last-child)': { mb: '$4' },
})

export const Trigger = styled(RadixAccordion.Trigger, {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '$2',
  // padding: '$2',
  backgroundColor: '$white',
  border: 'none',
  textAlign: 'left',
  cursor: 'pointer',

  '.icon': { size: '$6', color: '$gray' },
  '&[data-state=closed]': { '.active-icon': { display: 'none' } },
  '&[data-state=open]': {
    '.inactive-icon': { display: 'none' },
    '.active-color': { color: '$highlight' },
  },
})

export const Content = styled(RadixAccordion.Content, {
  // width: '100%',
  ml: '4.25rem',
  mb: '$4',
})
