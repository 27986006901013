import actionCable from 'actioncable'
import EmptyStateImage from 'assets/png/service dashboard.png'
import Avatar from 'components/avatar'
import Button from 'components/button/button'
import { Box, Flex } from 'components/layout'
import Text from 'components/text/text'
import { useToast } from 'components/toast'
import { format } from 'date-fns'
import { motion } from 'framer-motion'
import {
  AdminUser,
  MessagesQuery,
  MessageTypeEnum,
  useCreateMessageMutation,
  useMessagesQuery,
  useProfileQuery,
  User,
} from 'generated/__generated_graphql'
import React from 'react'
import { RiSendPlaneFill } from 'react-icons/ri'
import { extractGraphqlErrors, getSocketUrl } from 'utils/helpers'

type ChatProps = {
  id: string
  setIsResolved?: React.Dispatch<React.SetStateAction<boolean>>
}

type CleanMessage = Exclude<MessagesQuery['messages'], null | undefined>[number]

export default function Chat(props: ChatProps) {
  const { id, setIsResolved } = props

  const toast = useToast()
  const chatRef = React.useRef<HTMLDivElement>(null)
  const [{ data: profileQuery }] = useProfileQuery()
  const { profile } = profileQuery ?? {}
  const [, createMessage] = useCreateMessageMutation()

  const [{ data: messagesQuery, error }, refetch] = useMessagesQuery({
    variables: {
      chatId: id,
    },
  })

  const { messages: msgs } = messagesQuery ?? {}

  const [messages, setMessages] = React.useState<CleanMessage[]>(msgs ?? [])

  React.useEffect(() => {
    setMessages(msgs ?? [])
  }, [msgs])

  const cable = actionCable.createConsumer(getSocketUrl())

  React.useEffect(() => {
    cable.subscriptions.create(
      { channel: 'MessagesChannel', custom_report_chat_id: id },
      {
        received: () => {
          refetch({
            requestPolicy: 'network-only',
          })
        },
      }
    )
  }, [messages])

  async function handleSendChat(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault()
      const message = (e.currentTarget.elements[0] as HTMLInputElement).value
      e.currentTarget.reset()

      if (!message) {
        return
      }

      setMessages([
        ...messages,
        {
          createdAt: new Date().toISOString(),
          id: messages[messages.length - 1]?.id + 1,
          message,
          messageType: MessageTypeEnum.Text,
          updatedAt: new Date().toISOString(),
          sender: {
            ...profile!,
          },
        },
      ])

      const response = await createMessage({
        input: {
          chatId: id,
          message,
          messageType: MessageTypeEnum.Text,
        },
      })

      const error = extractGraphqlErrors(response, 'createMessage')
      if (error) {
        toast({
          content: 'something went wrong',
          status: 'error',
        })
        return
      }

      setIsResolved?.(false)
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    chatRef.current?.scrollTo(0, chatRef.current.scrollHeight)
  }, [messages])

  if (error) {
    // toast({
    //   content: 'something went wrong',
    //   status: 'error',
    // })
    console.log(error.message)

    return (
      <Flex
        css={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text align={'center'}>
          An error occurred whiles retrieving your messages: {error.message}
        </Text>
      </Flex>
    )
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 100, height: '74%' }}
      animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
    >
      <Flex
        ref={chatRef}
        direction="column"
        css={{
          p: '$3',
          width: '100%',
          height: '100%',
          overflowY: 'scroll',
        }}
      >
        <Flex
          direction="column"
          css={{
            width: '100%',
          }}
        >
          {messages?.length === 0 && (
            <Flex
              direction="column"
              justify="center"
              align="center"
              gutter="1"
              css={{
                width: '100%',
                mt: '4rem',
                padding: '4rem',
                backgroundColor: '#fff',
              }}
            >
              <img
                src={EmptyStateImage}
                style={{
                  height: '7rem',
                  transform: 'translateX(-3rem)',
                }}
              />
              <Flex direction="column" align="center" css={{ gap: '1.1rem' }}>
                <Text
                  align="center"
                  size="sm"
                  weight="bold"
                  css={{
                    fontFamily: '$space',
                    color: '$black',
                    fontWeight: '500',
                  }}
                >
                  No messages yet
                </Text>
                <Text
                  size="xxs"
                  align="center"
                  css={{
                    color: '$secondary',
                    width: '70%',
                    marginInline: 'auto',
                  }}
                >
                  Start a conversation by sending a message
                </Text>
              </Flex>
            </Flex>
          )}
          {messages?.map((msg) => {
            const { id, message, createdAt, sender } = msg
            const isIncoming = Number(profile?.id) === Number(sender.id)

            return (
              <Box key={id}>
                <ChatMessage
                  {...{
                    senderName:
                      sender.__typename === 'AdminUser'
                        ? (sender as AdminUser).fullName!
                        : (sender as User).firstName +
                          ' ' +
                          (sender as User).lastName,
                    message,
                    createdAt,
                    isIncoming,
                  }}
                />
              </Box>
            )
          })}
        </Flex>
      </Flex>

      <Box
        css={{
          height: '15%',
          width: '100%',
          p: '$2',
          mt: '$2',
          position: 'relative',
          '.input': {
            all: 'unset',
            appearance: 'none',
            fontFamily: '$primary',
            color: '$primary',
            fontSize: '1.25rem',
            width: '75%',
            padding: '0 $2',
            outline: 'none',

            pr: '$7',

            height: '4rem',
            background: '#F8F8F8',
            border: '1px solid #EEEEEE',
            borderRadius: '0px 0px 11px 11px',

            position: 'absolute',
            bottom: 0,
            left: '$2',

            transition: 'all 0.2s ease-in-out',

            '&::placeholder': { color: '$secondary' },

            '&:focus': {
              background: '$white',
              '& ~ *': {
                opacity: 1,
              },
            },
          },

          '.btn': {
            position: 'absolute',
            top: '50%',
            right: '$3',
            transform: 'translateY(-40%)',
            p: '$1 $2',
            borderRadius: '.5rem',

            opacity: 0,
            transition: 'all 0.2s ease-in-out',
          },
        }}
      >
        <form onSubmit={handleSendChat}>
          <Flex align="center" stretchx>
            <input
              className="input"
              type="text"
              placeholder="Type your message"
            />
            <Button
              className="btn"
              append={<RiSendPlaneFill />}
              type="submit"
              size="sm"
            />
          </Flex>
        </form>
      </Box>
    </motion.div>
  )
}

type ChatMessageProps = {
  senderName: string
  message: string
  createdAt: string
  isIncoming: boolean
}

function ChatMessage(props: ChatMessageProps) {
  const { senderName, message, createdAt, isIncoming } = props

  return (
    <Flex
      direction="column"
      gutterY={1}
      css={{
        width: '85%',
        float: isIncoming ? 'right' : 'left',
        mt: isIncoming ? '$2' : '$1',
      }}
    >
      <Text
        size="xxs"
        color="$secondary"
        weight="bold"
        css={{
          alignSelf: isIncoming ? 'flex-end' : 'flex-start',
          fontSize: '1rem',
        }}
      >
        {senderName}
      </Text>
      <Flex
        align="center"
        gutter="2"
        css={{
          p: '$2',
          border: '1px solid #Eee',
          backgroundColor: '#FFFFFF',
          borderRadius: isIncoming
            ? '24px 0px 24px 24px'
            : '0px 24px 24px 24px',
        }}
      >
        {isIncoming && (
          <>
            <Text
              size="xxs"
              weight="bold"
              color="$primary"
              css={{
                width: '85%',
              }}
            >
              {message}
            </Text>
            <Avatar
              title={senderName}
              css={{
                width: '3rem',
                height: '3rem',
                fontSize: '1rem',
                alignSelf: 'flex-start',
              }}
            />
          </>
        )}
        {!isIncoming && (
          <>
            <Avatar
              title={senderName}
              css={{
                width: '3rem',
                height: '3rem',
                fontSize: '1rem',
                alignSelf: 'flex-start',
              }}
            />
            <Text
              size="xxs"
              weight="bold"
              color="$primary"
              css={{
                width: '85%',
              }}
            >
              {message}
            </Text>
          </>
        )}
      </Flex>
      <Text
        size="xxs"
        color="$gray"
        weight="bold"
        css={{
          alignSelf: isIncoming ? 'flex-end' : 'flex-start',
          fontSize: '1rem',
        }}
      >
        {createdAt && format(new Date(createdAt), 'h:mm a')}
      </Text>
    </Flex>
  )
}
