import React from 'react'
import * as RadixAccordion from '@radix-ui/react-accordion'
import { Content, Item, Root, Trigger } from './accordion.styles'
import { AccordionProps } from './accordion.types'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import { Text } from 'components/text/text'
import { Avatar } from 'components/avatar/avatar'
import Box from 'components/layout/box'

const Accordion = ({
  tabs,
  css,
  children,
  type = 'multiple',
  ...props
}: AccordionProps) => {
  return (
    <Root {...(props as any)} type={type} css={css}>
      {tabs.map((tab, index) => (
        <Item value={tab.value ?? `item-${index}`} key={`item-${index}`}>
          <RadixAccordion.Header>
            <Trigger>
              <Box className="active-icon">
                <Avatar
                  shape="square"
                  background="$highlightBg"
                  icon={
                    <HiChevronUp aria-hidden className="icon active-color" />
                  }
                />
              </Box>
              <Box className="inactive-icon">
                <Avatar
                  shape="square"
                  background="$highlightBg"
                  icon={<HiChevronDown className="icon" aria-hidden />}
                />
              </Box>
              <Text size="xs" color="$primary">
                {tab.title}
              </Text>
            </Trigger>
          </RadixAccordion.Header>
          <Content>
            <Text size="xxs" color="$secondary">
              {tab.content}
            </Text>
          </Content>
        </Item>
      ))}
      {children}
    </Root>
  )
}
export default Accordion
