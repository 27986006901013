import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import Flex from 'components/layout/flex'
import React, { useState } from 'react'
import { HiDotsHorizontal } from 'react-icons/hi'
import { CleanTransaction } from '../screens/transactions'
import DeleteTransactionDrawer from './delete-transaction-drawer'

export type TransactionsTableOptionProps = {
  transaction: CleanTransaction
  refetchTransactions: () => void
  refetchFinancialAccounts: () => void
}

const TransactionsTableOption: React.FC<TransactionsTableOptionProps> = (
  props
) => {
  const { transaction, refetchFinancialAccounts, refetchTransactions } = props
  const [showDeleteTransactionDrawer, setShowDeleteTransactionDrawer] =
    useState(false)

  return (
    <>
      <Flex stretchx justify="end">
        <Dropdown
          placement="bottomRight"
          menu={[
            {
              label: 'Delete',
              onClick: () => setShowDeleteTransactionDrawer(true),
            },
          ]}
        >
          <Flex stretchx align="center" justify="end">
            <Button appearance="ghost">
              <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
            </Button>
          </Flex>
        </Dropdown>
      </Flex>

      <DeleteTransactionDrawer
        refetchFinancialAccounts={refetchFinancialAccounts}
        refetchTransactions={refetchTransactions}
        visible={showDeleteTransactionDrawer}
        onClose={() => setShowDeleteTransactionDrawer(false)}
        transaction={transaction}
      />
    </>
  )
}

export default TransactionsTableOption
