import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import { Text } from 'components/text/text'
import { format } from 'date-fns'
import { StyledBadge } from 'pages/settings/settings.styles'
import { CleanOrganisation } from 'providers/app-provider'
import React from 'react'
import { formatMoney } from 'utils/helpers'

interface SubscriptionHistoryTableData {
  id: string
  date: string
  amount: string | number
  status: 'paid' | 'declined'
  plan: string
}

type SubscriptionHistoryProps = {
  billings: Exclude<CleanOrganisation['billings'], null | undefined>
  isBusinessInNigeria: boolean
}

const columns: ITableColumn<SubscriptionHistoryTableData>[] = [
  {
    key: 'date',
    title: 'Date',
    dataIndex: 'date',
    render: (text: string) => (
      <Text size="xs" color="inherit" weight="semi">
        {text}
      </Text>
    ),
  },
  {
    key: 'amount',
    title: 'Amount',
    dataIndex: 'amount',
    render: (text: string) => (
      <Text size="xs" color="inherit" weight="semi">
        {text}
      </Text>
    ),
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (text: SubscriptionHistoryTableData['status']) => (
      <StyledBadge status={text}>
        <Text size="xs" color="inherit" weight="semi">
          {text}
        </Text>
      </StyledBadge>
    ),
  },
  {
    key: 'plan',
    title: 'plan',
    dataIndex: 'plan',
  },
]

export const SubscriptionHistory: React.FC<SubscriptionHistoryProps> = ({
  billings,
  isBusinessInNigeria,
}) => {

  const data = billings?.map<SubscriptionHistoryTableData>((billing) => {
    return {
      amount: isBusinessInNigeria ? formatMoney(billing.plan.paystackPrice ?? 0, '₦') : formatMoney(billing.plan.price, '$'),
      date: format(Date.parse(billing.updatedAt), 'MMM dd, yyyy'),
      id: billing.id,
      plan: billing.plan.name,
      status: billing.status === 'paid' ? 'paid' : 'declined',
    }
  })

  return <Table columns={columns} dataSource={data} />
}
