import EmptyStateImage from 'assets/png/service dashboard.png'
import { Flex } from 'components/layout'
import { Table } from 'components/table/table'
import { StyledTable } from 'components/table/table.styles'
import { ITableColumn } from 'components/table/table.types'
import Tag from 'components/tag/tag'
import Text from 'components/text/text'
import { format } from 'date-fns'
import React from 'react'
import {
  CleanServiceReport,
  statusColors,
  statusIcons,
} from '../service-report'

import UnProcessedReportsTableAction from './table-action-unprocessed-service-reports'

export type UnProcessedReportsTableData = {
  id: string
  date: string
  reportName: string
  status: string
  report: CleanServiceReport
}

const columns: ITableColumn<UnProcessedReportsTableData>[] = [
  {
    key: 'date',
    title: 'DATE',
    dataIndex: 'date',
    render: (date: string) => (
      <Text size={'xs'} color="$secondary">
        {format(Date.parse(date), 'dd MMM, yyyy')}
      </Text>
    ),
  },
  {
    key: 'reportName',
    title: 'REPORT NAME',
    dataIndex: 'reportName',
    render: (reportName) => (
      <Text size={'xs'} color="$grayDark2">
        {reportName}
      </Text>
    ),
  },
  {
    key: 'status',
    title: 'STATUS',
    dataIndex: 'status',
    render: (_, { report }) => (
      <Tag
        css={{
          py: '.6rem',
          color: statusColors[report.status].color,
          backgroundColor: statusColors[report.status].bgColor,
        }}
      >
        <Flex align="center" gutterX="1" justify="between">
          {statusIcons[report.status].icon}
          {report.status}
        </Flex>
      </Tag>
    ),
  },
  {
    key: 'action',
    title: '',
    dataIndex: 'status',
    render: (_, { report }) => (
      <UnProcessedReportsTableAction report={report} />
    ),
  },
]

type UnProcessedReportsTableProps = {
  reports: CleanServiceReport[]
}

const UnProcessedReportsTable: React.FC<UnProcessedReportsTableProps> = (
  props
) => {
  const { reports } = props

  const tableData: UnProcessedReportsTableData[] =
    reports.map((report) => ({
      id: report.id,
      date: report.createdAt,
      reportName: report.name ?? '',
      status: 'Completed',
      report: report,
    })) ?? []

  if (tableData.length < 1) {
    return (
      <Flex
        direction="column"
        justify="center"
        align="center"
        gutter="1"
        css={{
          width: '100%',
          padding: '4rem',
          backgroundColor: '#fff',
        }}
      >
        <img
          src={EmptyStateImage}
          style={{ height: '12rem', transform: 'translateX(-5.5rem)' }}
        />
        <Flex direction="column" align="center" css={{ gap: '1.1rem' }}>
          <Text
            size="sm"
            weight="bold"
            css={{ fontFamily: '$space', color: '$black', fontWeight: '500' }}
          >
            No completed reports available yet
          </Text>
          <Text
            size="xxs"
            align="center"
            css={{ color: '$secondary', width: '70%', marginInline: 'auto' }}
          >
            You will be notified when your information has been processed and
            your report is ready
          </Text>
        </Flex>
      </Flex>
    )
  } else {
    return (
      <Table
        columns={columns}
        dataSource={tableData}
        css={{
          table: {
            borderRadius: '0 0 $4 $2',
          },
          h4: {
            color: '$grayDark2',
          },
          [`& ${StyledTable}`]: {
            backgroundColor: '#fff',
          },
        }}
      />
    )
  }
}

export default UnProcessedReportsTable
