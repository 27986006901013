import { Dropdown } from 'components/dropdown/dropdown'
import Flex from 'components/layout/flex'

import { useToast } from 'components/toast'
import {
  useTaxesQuery,
  useUpdateTaxMutation,
} from 'generated/__generated_graphql'
import {
  StyledOptions,
  StyledOptionsContainer,
} from 'pages/settings/settings.styles'
import { useState } from 'react'
import { HiDotsHorizontal } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import DeleteTaxRateDrawer from './delete-tax-rate-drawer'
import EditTaxRateDrawer from './edit-tax-rate-drawer'
import { TaxRatesTableData } from './tax-rates-table'

const TaxesTableAction: React.FC<{
  tax: TaxRatesTableData['tax']
}> = ({ tax }) => {
  const { description, id, isActive, name, rate } = tax
  const [showEditTaxDrawer, setShowEditTaxDrawer] = useState(false)
  const [showDeleteTaxDrawer, setShowDeleteTaxDrawer] = useState(false)

  const [, updateTax] = useUpdateTaxMutation()
  const [, queryTaxes] = useTaxesQuery()
  const notify = useToast()

  async function activateDeactivateTax() {
    try {
      const response = await updateTax({
        input: { isActive: !isActive, description, id, name, rate },
      })

      const error = extractGraphqlErrors(response, 'updateTax')

      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content:
          `${tax.name} has been ` + (isActive ? 'deactivated' : 'activated'),
        status: 'success',
        duration: 2500,
      })

      queryTaxes({
        requestPolicy: 'network-only',
      })
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  return (
    <>
      <Flex stretchx justify="end">
        <Dropdown
          placement="bottomRight"
          menu={[
            {
              label: isActive ? 'Deactivate' : 'Activate',
              onClick: activateDeactivateTax,
            },
            {
              label: 'Edit',
              onClick: () => setShowEditTaxDrawer(true),
            },
            {
              label: 'Delete',
              onClick: () => setShowDeleteTaxDrawer(true),
            },
          ]}
        >
          <StyledOptionsContainer stretchx align="center" justify="end">
            <StyledOptions appearance="ghost">
              <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
            </StyledOptions>
          </StyledOptionsContainer>
        </Dropdown>
      </Flex>

      <EditTaxRateDrawer
        tax={tax}
        visible={showEditTaxDrawer}
        onClose={() => setShowEditTaxDrawer(false)}
      />

      <DeleteTaxRateDrawer
        tax={tax}
        visible={showDeleteTaxDrawer}
        onClose={() => setShowDeleteTaxDrawer(false)}
      />
    </>
  )
}

export default TaxesTableAction
