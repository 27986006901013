import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import {
  Purchase,
  useDeletePurchaseOrderMutation,
} from 'generated/__generated_graphql'
import React from 'react'
import { HiTrash } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'

interface DeletePurchaseDrawerProps {
  visible: boolean
  onClose: () => void
  purchase: Purchase
  onSuccess?: () => void
}

const DeletePurchaseOrderDrawer: React.FC<DeletePurchaseDrawerProps> = (
  props
) => {
  const { visible, onClose, purchase, onSuccess } = props
  const { id } = purchase

  const notify = useToast()

  const [{ fetching: deleting }, deletePurchaseOrder] =
    useDeletePurchaseOrderMutation()

  async function onDeletePurchaseOrder() {
    try {
      const response = await deletePurchaseOrder({
        input: { id: id ?? '' },
      })
      const error = extractGraphqlErrors(response, 'deletePurchase')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: 'Purchase order deleted successfully',
        status: 'success',
      })
      onSuccess?.()
      onClose()
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  return (
    <Drawer
      title="Delete Purchase Order"
      titleIcon={<HiTrash size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      closable={!deleting}
      maskClosable={!deleting}
      fullHeight={false}
    >
      <Flex css={{ p: '$5', height: '100%' }} direction="column" gutter="3">
        <Text size="xs">
          Are you sure you want to remove this purchase order?
        </Text>

        <Flex
          gutterX="2"
          css={{ alignSelf: 'end', alignItems: 'end', flexGrow: 1 }}
        >
          <Button
            size="md"
            appearance="secondary"
            disabled={deleting}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            appearance="danger"
            size="md"
            disabled={deleting}
            isLoading={deleting}
            onClick={onDeletePurchaseOrder}
          >
            Delete Purchase Order
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default DeletePurchaseOrderDrawer
