import { Avatar } from 'components/avatar/avatar'
import { Flex } from 'components/layout'
import Loader from 'components/loader/loader'
import Separator from 'components/separator/separator'
import { Text } from 'components/text/text'
import { format } from 'date-fns'
import { Invoice } from 'generated/__generated_graphql'
import {
  StyledPreviewControl,
  StyledPreviewControls,
  StyledPreviewFrame,
  StyledPreviewSection,
} from 'pages/sales/sales.styles'
import { useAppProvider } from 'providers/app-provider'
import { HiDocumentDownload } from 'react-icons/hi'
import { formatMoney } from 'utils/helpers'

type ITotal = {
  subTotal: number
  taxValue: number
  discountValue: number
  total: number
}

interface InvoicePreviewProps {
  invoice: Invoice
  totals: ITotal
  showActions?: boolean
  onAction?: () => void
  downloading?: boolean
}

const InvoicePreviewer: React.FC<InvoicePreviewProps> = ({
  invoice,
  totals,
  showActions = false,
  downloading,
  onAction,
}) => {
  const { organisation } = useAppProvider()
  const currency = organisation?.currency.symbol
  return (
    <StyledPreviewSection direction="column" stretchx stretchy gutterY="4">
      <StyledPreviewFrame css={{ p: 20 }}>
        <Flex justify="between" align="start">
          <Flex justify="start" direction="column" css={{ rowGap: 5 }}>
            <Text size="xxs">
              Date:{' '}
              <Text color="#171717" size="xxs">
                {format(new Date(invoice?.issueDate), 'dd MMM, yyyy')}
              </Text>
            </Text>
            <Text size="xxs">
              Due on:{' '}
              <Text color="#171717" size="xxs">
                {format(new Date(invoice?.dueDate), 'dd MMM, yyyy')}
              </Text>
            </Text>
            <Text size="xxs">
              Ref:{' '}
              <Text color="#171717" size="xxs">
                {invoice?.reference}
              </Text>
            </Text>
          </Flex>
          {organisation?.profileImageUrl && (
            <Avatar size="xtraLarge" src={organisation.profileImageUrl} />
          )}
        </Flex>

        <Separator color="#EEF2F4" />

        <Flex justify="between">
          <Flex justify="start" direction="column" css={{ rowGap: 10 }}>
            <Text size="xxs">From</Text>
            <Text size="xxs">{organisation?.name}</Text>
            <Text size="xxs">{organisation?.countryCode}</Text>
          </Flex>
          <Flex justify="start" direction="column" css={{ rowGap: 10 }}>
            <Text size="xxs">Prepared for</Text>
            <Text size="xxs">{invoice.customer?.name}</Text>
            <Text size="xxs">{invoice.customer?.country}</Text>
          </Flex>
        </Flex>
        <Flex direction="column" css={{ rowGap: 5, mt: 20 }}>
          <Flex
            css={{
              p: 5,
              borderRadius: 3,
              background: '#2870D2',
              color: '$white',
            }}
          >
            <Text size="xxs" css={{ flexBasis: '25%' }}>
              Name
            </Text>
            <Text size="xxs" css={{ flexBasis: '25%' }}>
              Quantity
            </Text>
            <Text size="xxs" css={{ flexBasis: '25%' }}>
              Unit Price
            </Text>
            <Text size="xxs" css={{ flexBasis: '25%' }}>
              Total
            </Text>
          </Flex>
          {invoice?.invoiceItems?.map((item, i) => (
            <Flex key={i}>
              <Text size="xxs" css={{ flexBasis: '25%' }}>
                {item.name}
              </Text>
              <Text size="xxs" css={{ flexBasis: '25%' }}>
                {item.quantity}
              </Text>
              <Text size="xxs" css={{ flexBasis: '25%' }}>
                {formatMoney(item.unitPrice, currency)}
              </Text>
              <Text size="xxs" css={{ flexBasis: '25%' }}>
                {formatMoney(item.quantity * item.unitPrice, currency)}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Separator color="#EEF2F4" />
        <Flex justify="end" css={{ mt: 20 }}>
          <Flex direction="column" css={{ rowGap: 5 }}>
            <Flex justify="between">
              <Text size="xxs" color="#171717" css={{ mr: 20 }}>
                Sub Total:
              </Text>
              <Text size="xxs">{formatMoney(totals.subTotal, currency)}</Text>
            </Flex>
            <Flex justify="between">
              <Text size="xxs" color="#171717" css={{ mr: 20 }}>
                Tax:
              </Text>
              <Text size="xxs">{formatMoney(totals.taxValue, currency)}</Text>
            </Flex>
            <Flex justify="between">
              <Text size="xxs" color="#171717" css={{ mr: 20 }}>
                Discount:
              </Text>
              <Text size="xxs">
                {formatMoney(totals.discountValue, currency)}
              </Text>
            </Flex>
            <Flex justify="between">
              <Text size="xxs" color="#171717" css={{ mr: 20 }}>
                Total:
              </Text>
              <Text size="xxs">{formatMoney(totals.total, currency)}</Text>
            </Flex>
          </Flex>
        </Flex>
        {showActions && (
          <StyledPreviewControls
            align="center"
            justify="center"
            css={{ cursor: 'pointer' }}
            onClick={onAction}
          >
            <StyledPreviewControl>
              {downloading ? (
                <Loader size="xs" />
              ) : (
                <HiDocumentDownload color="$primary" />
              )}
            </StyledPreviewControl>
          </StyledPreviewControls>
        )}
      </StyledPreviewFrame>
    </StyledPreviewSection>
  )
}

export default InvoicePreviewer
