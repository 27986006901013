import { getCalApi } from '@calcom/embed-react'
import * as Accordion from '@radix-ui/react-accordion'
import Avatar from 'components/avatar/avatar'
import Button from 'components/button/button'
import { StyledButton } from 'components/button/button.styles'
import { Box } from 'components/layout'
import Flex from 'components/layout/flex'
import PageHeader from 'components/page-header/page-header'
import { Text } from 'components/text/text'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import React, { useEffect } from 'react'
import { FaChevronUp } from 'react-icons/fa'
import { FiClock } from 'react-icons/fi'
import { HiDocumentDownload } from 'react-icons/hi'
import { MdReviews } from 'react-icons/md'
import { RiUploadCloudFill } from 'react-icons/ri'
import { styled } from 'stitches/stitches.config'

import { BsCalendarFill } from 'react-icons/bs'
import MeetingsTable, { MeetingStatus } from './components/meetings-table'

interface MeetingsPageProps {}

const BookButton = styled(StyledButton, {})

export const BookMeetingButton = () => {
  useEffect(() => {
    // prettier-ignore
    (async function () {
      const cal = await getCalApi()

      cal('ui', {
        theme: 'auto',
        styles: {
          branding: { brandColor: '#3C85C3' },
        },
      })

      // cal('on', {
      //   action: 'bookingSuccessful',
      //   callback: ({ detail }) => {
      //     console.log(detail)
      //   },
      // })
    })()
  }, [])

  return (
    <BookButton data-cal-link="accounteer-accountant" appearance={'secondary'}>
      <FiClock size="1.9rem" />
      Book a meeting
    </BookButton>
  )
}

const MeetingsPage: React.FC<MeetingsPageProps> = () => {
  return (
    <>
      <PageHeader
        css={{ position: 'sticky' }}
        title={'Meetings'}
        extra={<BookMeetingButton />}
      />

      <StyledLayoutContent>
        {/* <PageBanner
          title="Welcome to your meetings"
          description="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit"
          actionText="Learn more"
          bgSvg={background}
          appearance="yellow"
        /> */}

        <Flex direction={'column'} css={{ mt: 33, gap: 6 }}>
          <Text
            size="md"
            css={{
              fontFamily: '$space',
              mb: '$4',
              fontWeight: 500,
              color: '$black',
            }}
          >
            Schedule a meeting with a human accountant in just 3 steps:
          </Text>
          <Flex align="center" justify="between" gutter="3">
            <Step
              avatar={<RiUploadCloudFill color="#262626" />}
              title="1. Book a meeting"
              description="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit"
            />
            <Step
              avatar={<MdReviews color="#262626" />}
              title="2. Wait for the scheduled time"
              description="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit"
            />
            <Step
              avatar={<HiDocumentDownload color="#262626" />}
              title="3. Join the meeting"
              description="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit"
            />
          </Flex>
        </Flex>

        <StyledAccordionRoot type="single" collapsible defaultValue="item-1">
          <StyledAccordionItem value="item-1">
            <Flex
              justify={'between'}
              css={{
                height: '4rem',
                width: '100%',
                padding: 21,
                paddingBottom: 0,
              }}
            >
              <Flex css={{ gap: '$2' }} align={'center'}>
                <Text size={'xs'} color="$grayDark2">
                  Upcoming meetings
                </Text>
              </Flex>

              <StyledAccordionTrigger>
                <Button
                  appearance={'secondary'}
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 5,
                    height: '3.2rem',
                    width: 'fit-content',
                  }}
                >
                  <Text>Collapse</Text>
                  <StyledChevron size={12} />
                </Button>
              </StyledAccordionTrigger>
            </Flex>

            <StyledAccordionContent>
              <Flex
                direction="column"
                css={{ width: 'inherit', gap: '$4', borderRadius: '$4' }}
              >
                {[] && [].length > 0 ? (
                  <MeetingsTable meetings={[]} />
                ) : (
                  <BookButton
                    data-cal-link="prince-ofori"
                    appearance={'ghost'}
                    css={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minHeight: 140,
                      boxShadow: '$subtle',
                      padding: '4.4rem 8.6rem',
                      gap: '2.4rem',
                      border: '1px dashed #EEF2F4',
                      borderRadius: '$3',
                      cursor: 'pointer',
                      margin: '2rem',
                    }}
                  >
                    <Flex
                      justify={'center'}
                      align={'center'}
                      css={{
                        width: '5.2rem',
                        height: '5.2rem',
                        boxShadow: '$subtle',
                        border: '1px solid #EEF2F4',
                        borderRadius: '$3',
                      }}
                    >
                      <BsCalendarFill style={{ color: 'rgb(57, 138, 250)' }} />
                    </Flex>
                    <Flex direction={'column'} align={'start'}>
                      <Text size={'xs'}>
                        Click here to book your first meeting with a human
                        accountant
                      </Text>
                    </Flex>
                  </BookButton>
                )}
              </Flex>
            </StyledAccordionContent>
          </StyledAccordionItem>
        </StyledAccordionRoot>

        <Box css={{ my: '3rem' }}>
          <Text
            size="md"
            css={{
              fontFamily: '$space',
              fontWeight: 500,
              color: '$black',
            }}
          >
            Past meetings
          </Text>
        </Box>

        <Box
          css={{
            width: '100%',
            borderRadius: '$4',
            overflow: 'hidden',
            boxShadow: '$tiny',
          }}
        >
          <MeetingsTable
            meetings={
              [
                {
                  date: new Date().toISOString(),
                  id: '1',
                  name: '10 mins meeting to discuss general stuff',
                  status: MeetingStatus.Canceled,
                },
                {
                  date: new Date().toISOString(),
                  id: '2',
                  name: '25 mins meeting',
                  status: MeetingStatus.Completed,
                },
              ] ?? []
            }
          />
        </Box>
      </StyledLayoutContent>
    </>
  )
}

const StyledAccordionRoot = styled(Accordion.Root, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: '$white',
  boxShadow: '$subtle',
  gap: '2rem',
  marginTop: 31,

  // borderBottom: '3px solid #1B65CC',
  borderRadius: '$4',
})

const StyledAccordionItem = styled(Accordion.Item, {
  borderRadius: '$4',
})

const StyledChevron = styled(FaChevronUp, {})

const StyledAccordionTrigger = styled(Accordion.Trigger, {
  all: 'unset',
  borderRadius: '$4',

  '[data-state=closed] &': {
    '& svg': {
      transform: 'rotate(180deg)',
      transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
    },
  },

  '[data-state=open] &': {
    '& svg': {
      transform: 'rotate(0deg)',
      transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
    },
  },
})

const StyledAccordionContent = styled(Accordion.Content, {
  display: 'inline-block',
  width: 'stretch',
  mt: 20,
  borderRadius: '$4',
})

export default MeetingsPage

function Step({
  avatar,
  title,
  description,
}: {
  avatar: React.ReactNode
  title: string
  description: string
}) {
  return (
    <Flex
      align="center"
      gutter={4}
      css={{ backgroundColor: '#ededed', p: '$4', borderRadius: '$4' }}
    >
      <Avatar background="white" size="large" icon={avatar} />
      <Flex direction="column" gutter="1">
        <Text size="xs" color="black" weight="bold">
          {title}
        </Text>
        <Text size="xxs">{description}</Text>
      </Flex>
    </Flex>
  )
}
