import Button from 'components/button/button'
import DateInput from 'components/date-input/date-input'
import { Dropdown } from 'components/dropdown/dropdown'
import { Box, Flex, Stack } from 'components/layout'
import PageHeader from 'components/page-header/page-header'
import PageLoader from 'components/page-loader/page-loader'
import { TableEmptyNotice } from 'components/table/components/table-empty-notice'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import { Span } from 'components/text/span'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select/type-ahead-select'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import {
  GlAccount,
  Journal,
  JournalEntry,
  User,
  useGeneralLedgerAccountsQuery,
  useJournalEntryTypesQuery,
  useJournalsQuery,
} from 'generated/__generated_graphql'
import useDisclosure from 'hooks/useDisclosure'
import useForm from 'hooks/useForm'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import { useAppProvider } from 'providers/app-provider'
import { useEffect, useState } from 'react'
import {
  HiArrowCircleUp,
  HiCalendar,
  HiChevronDown,
  HiDocumentAdd,
  HiOutlinePlus,
  HiX,
} from 'react-icons/hi'
import { MdModeEdit } from 'react-icons/md'
import { styled } from 'stitches/stitches.config'
import {
  downloadFileFromUrl,
  formatMoney,
  getApiUrl,
  snakeCaseToWord,
} from 'utils/helpers'
import JournalDetail from './journal-detail'
import { TableContainer, TableTop, TextWithHover } from './journals.styles'
import NewJournalDrawer from './new-journal-drawer'
import UserJournal from './user-journal'

const DropdownItem = styled(Flex, {
  px: '1.6rem',
  py: '0.8rem',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  '&:hover': {
    color: '$highlight',
    backgroundColor: '$highlightBg',

    '& *': {
      color: '$highlight',
    },
  },
})

const FlexWithHover = styled(Flex, {
  '&:hover': {
    color: '$highlight',
    transition: 'all 0.2s',
  },
})

function getTextColor(amount: number, isTotal?: boolean) {
  if (isTotal) {
    return '#171717'
  }
  return amount > 0 ? '$primary' : '$gray'
}

function getTotal(entries: JournalEntry[]) {
  const totalCredit = entries.reduce((acc, entry) => acc + entry.credit, 0)
  const totalDebit = entries.reduce((acc, entry) => acc + entry.debit, 0)
  return {
    glAccount: {
      name: 'Total',
    },
    credit: totalCredit,
    debit: totalDebit,
    id: 'total',
  }
}
const startOfMonthDate = startOfMonth(new Date())
const endOfMonthDate = endOfMonth(new Date())

const Journals: React.FC = () => {
  const query = new URLSearchParams(window.location.search)
  const notify = useToast()
  const { organisation } = useAppProvider()
  const currency = organisation?.currency.symbol

  const { values, setInputValue, bulkUpdate } = useForm({
    fields: {
      startDate: startOfMonthDate,
      endDate: endOfMonthDate,
      journalType: '',
    },
  })

  const { isOpen: showJournalDrawer, toggle: toggleShowJournalDrawer } =
    useDisclosure()

  const {
    isOpen: showJournalDetailDrawer,
    toggle: toggleShowJournalDetailDrawer,
  } = useDisclosure()

  const { isOpen: showUserJournalDrawer, toggle: toggleshowUserJournalDrawer } =
    useDisclosure()

  const [{ data: journals, fetching }, refreshJournals] = useJournalsQuery({
    variables: {
      pagination: {},
      startDate: values.startDate,
      endDate: values.endDate,
      entryType: values.journalType,
    },
  })

  const [{ data: journalEntryTypes, fetching: loadingEntryTypes }] =
    useJournalEntryTypesQuery({})

  const [{ data: accounts, fetching: loadingAccounts }] =
    useGeneralLedgerAccountsQuery()

  const [downloading, setDownloading] = useState(false)

  const [currentGlAccount, setCurrentGlAccount] = useState<GlAccount>()
  const [currentUser, setCurrentUser] = useState<User>()
  const [currentJournal, setCurrentJournal] = useState<Journal>()
  const [showClearFilter, setShowClearFilter] = useState(false)

  function onJournalAddSuccess() {
    refreshJournals({ requestPolicy: 'network-only' })
    toggleShowJournalDrawer()
  }

  function onJournalDetail(account: GlAccount) {
    setCurrentGlAccount(account)
    toggleShowJournalDetailDrawer()
  }

  function onJournalEdit(journal: Journal) {
    setCurrentJournal(journal)
    toggleShowJournalDrawer()
  }

  function onNewJournalClose() {
    setCurrentJournal(undefined)
    setCurrentUser(undefined)
    toggleShowJournalDrawer()
  }

  function resetFilters() {
    bulkUpdate({
      startDate: startOfMonthDate,
      endDate: endOfMonthDate,
      journalType: '',
    })
    setShowClearFilter(false)
  }

  function onUser(user: User) {
    setCurrentUser(user)
    toggleshowUserJournalDrawer()
  }

  async function downloadJournal() {
    const url = getApiUrl()
    try {
      setDownloading(true)
      await downloadFileFromUrl(
        `${url}/journals.csv?start_date=${values.startDate.toISOString()}&end_date=${values.endDate.toISOString()}`,
        'journal',
        'csv',
        false
      )
      notify({ content: 'Journals downloaded successfully', status: 'success' })
      setDownloading(false)
    } catch {
      notify({ content: 'Something went wrong', status: 'error' })
      setDownloading(false)
    }
  }

  useEffect(() => {
    const id = query.get('glAccountId')
    if (!id) {
      return
    }
    const account = accounts?.generalLedgerAccounts.find(
      (account) => account.id === id
    )
    if (account) {
      setCurrentGlAccount(account as GlAccount)
      toggleShowJournalDetailDrawer()
    }
  }, [query])

  const entryTypes = journalEntryTypes?.journalEntryTypes?.map((entryType) => {
    return { label: snakeCaseToWord(entryType), value: entryType }
  })

  const columns: ITableColumn<JournalEntry>[] = [
    {
      key: 'account',
      title: 'Account',
      dataIndex: 'glAccount',
      render: (account, { id }) => {
        if (id === 'total') {
          return (
            <Text size="xs" color={'#171717'} weight={'bold'}>
              {account?.name}
            </Text>
          )
        }
        return (
          <Button appearance="ghost">
            <TextWithHover size="xs" color={'$primary'} weight={'semi'}>
              {account?.name}
            </TextWithHover>
          </Button>
        )
      },
    },
    {
      key: 'debit',
      title: 'Debit',
      dataIndex: 'debit',
      render: (amount, { id }) => (
        <Text
          size="xs"
          color={getTextColor(amount, id === 'total')}
          weight={id === 'total' ? 'bold' : 'semi'}
        >
          {formatMoney(amount, currency)}
        </Text>
      ),
    },
    {
      key: 'credit',
      title: 'Credit',
      dataIndex: 'credit',
      render: (amount, { id }) => (
        <Text
          size="xs"
          color={getTextColor(amount, id === 'total')}
          weight={id === 'total' ? 'bold' : 'semi'}
        >
          {formatMoney(amount, currency)}
        </Text>
      ),
    },
  ]

  const hasData = journals?.journals && journals.journals.data.length > 0
  const loading = fetching || loadingAccounts || loadingEntryTypes

  if (loading) {
    return <PageLoader />
  }

  return (
    <>
      <PageHeader
        title="Journals"
        extra={
          <Flex gutterX="2">
            <Dropdown
              menu={[
                {
                  label: 'CSV',
                  value: 'csv',
                  render: (label) => (
                    <DropdownItem
                      align="center"
                      gutterX="2"
                      role="button"
                      onClick={downloadJournal}
                    >
                      <HiDocumentAdd color="#ABB3B9" />
                      <Text variant="body2" size="xs">
                        {label}
                      </Text>
                    </DropdownItem>
                  ),
                },
              ]}
            >
              <Button
                isLoading={downloading}
                appearance="secondary"
                append={<HiChevronDown color="#8EB0DF" />}
              >
                Download
              </Button>
            </Dropdown>
            <Button
              prepend={<HiArrowCircleUp />}
              onClick={toggleShowJournalDrawer}
            >
              Add Journal
            </Button>
          </Flex>
        }
      />
      <StyledLayoutContent>
        {/* <PageBanner
          title="View and manage your journals"
          description="
            Journals are used to record transactions that are not directly related to sales, purchases, receipts, and payments. 
          "
          actionText="Watch demo"
          bgSvg={background}
        /> */}
        <Flex align="end" justify="start" css={{ columnGap: 30, mb: 20 }}>
          <DateInput
            label="From"
            defaultValue={values.startDate}
            prepend={<HiCalendar color="#ABB3B9" />}
            placeholder="From date"
            onChange={(e) => {
              setShowClearFilter(true)
              setInputValue('startDate', new Date(e.target.value))
            }}
          />
          <DateInput
            label="To"
            defaultValue={values.endDate}
            prepend={<HiCalendar color="#ABB3B9" />}
            placeholder="To date"
            onChange={(e) => {
              setShowClearFilter(true)
              setInputValue('endDate', new Date(e.target.value))
            }}
          />
          <TypeAheadSelect
            label="Entry Type"
            css={{ width: 180 }}
            placeholder="Select entry type"
            value={values.journalType}
            onChange={(value) => {
              setShowClearFilter(true)
              setInputValue('journalType', value as string)
            }}
            options={entryTypes ?? []}
          />
          <Flex align="center" css={{ columnGap: 15 }}>
            <Button
              appearance="primary"
              onClick={() => refreshJournals({ requestPolicy: 'network-only' })}
            >
              Filter
            </Button>
            {showClearFilter && (
              <Button appearance="ghost" onClick={resetFilters}>
                <FlexWithHover align="center" gutterX="1">
                  <HiX />
                  <TextWithHover size="xs" color={'$primary'} weight={'semi'}>
                    Clear Filter
                  </TextWithHover>
                </FlexWithHover>
              </Button>
            )}
          </Flex>
        </Flex>
        {!hasData && (
          <TableEmptyNotice
            type="cta"
            title="No data available for date range"
            subtitle="If this is your first time here you can start by creating a manual entry"
            action={
              <Button
                appearance="secondary"
                onClick={toggleShowJournalDrawer}
                prepend={<HiOutlinePlus />}
                size="xl"
              >
                Create manual entry
              </Button>
            }
          />
        )}
        {hasData && (
          <Box>
            {journals?.journals?.data.map((journal) => (
              <TableContainer direction="column" key={journal.id}>
                <TableTop justify="between" stretchx>
                  <Stack spacing={5}>
                    <Text color="#1C1C1C" size="xs">
                      <Span css={{ color: '#848C96' }}>
                        {format(
                          new Date(journal.effectiveDate),
                          'dd MMM, yyyy'
                        )}{' '}
                        -
                      </Span>{' '}
                      ID {journal.id} - {journal.entryType}
                    </Text>
                    <Stack>
                      <Text color="#848C96" size="xxs">
                        {journal.description}
                      </Text>
                    </Stack>
                    <Flex gutterX="1">
                      <Text color="#848C96" size="xxs">
                        Entry by
                      </Text>
                      <Button
                        appearance="ghost"
                        onClick={() => {
                          setCurrentUser(journal.createdBy as any)
                          toggleshowUserJournalDrawer()
                        }}
                      >
                        <TextWithHover color="#848C96" size="xxs">
                          {journal.createdBy.firstName}{' '}
                          {journal.createdBy.lastName}
                        </TextWithHover>
                      </Button>
                    </Flex>
                  </Stack>
                  <Stack>
                    <Button
                      prepend={<MdModeEdit color="#ABB3B9" size="1.5rem" />}
                      appearance="secondary"
                      onClick={() => onJournalEdit(journal as Journal)}
                    >
                      Edit
                    </Button>
                  </Stack>
                </TableTop>
                <Table
                  onRowClick={(data) => {
                    onJournalDetail(data.glAccount)
                  }}
                  columns={columns}
                  dataSource={[
                    ...journal.journalEntries,
                    getTotal(journal.journalEntries as any) as any,
                  ]}
                />
              </TableContainer>
            ))}
          </Box>
        )}
      </StyledLayoutContent>
      <NewJournalDrawer
        visible={showJournalDrawer}
        onClose={onNewJournalClose}
        onSuccess={onJournalAddSuccess}
        journal={currentJournal}
      />
      {currentGlAccount && (
        <JournalDetail
          onUser={onUser}
          visible={showJournalDetailDrawer}
          onClose={toggleShowJournalDetailDrawer}
          glAcccount={currentGlAccount}
        />
      )}

      <UserJournal
        visible={showUserJournalDrawer}
        onClose={toggleshowUserJournalDrawer}
        user={currentUser}
      />
    </>
  )
}

export default Journals
