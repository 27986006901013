import Avatar from 'components/avatar'
import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import { Box, Flex } from 'components/layout'
import { Span } from 'components/text/span'
import Text from 'components/text/text'
import { BankAccount, useCurrenciesQuery } from 'generated/__generated_graphql'
import React from 'react'
import {
  HiArrowCircleUp,
  HiCash,
  HiDocumentAdd,
  HiDotsVertical,
  HiPencil,
} from 'react-icons/hi'
import { MdAccountBalance, MdAccountBalanceWallet } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { formatMoney } from 'utils/helpers'
import AddTransactionsDrawer from './add-transactions-drawer'
import { EditAccountDrawer } from './edit-account-drawer'
import { ImportTransactionsDrawer } from './import-transactions-drawer'

type FinancialAccountProps = {
  selectedFinancialAccount: Omit<BankAccount, 'bankAccountable'>
  financialAccounts: Omit<BankAccount, 'bankAccountable'>[]
  refetchFinancialAccounts: () => void
  refreshAllAccounts?: () => void
}

const FinancialAccount: React.FC<FinancialAccountProps> = (props) => {
  const {
    selectedFinancialAccount,
    financialAccounts,
    refetchFinancialAccounts,
  } = props

  const navigate = useNavigate()
  const [showImportTransactionsDrawer, setShowImportTransactionsDrawer] =
    React.useState(false)
  const [showEditDrawer, setShowEditDrawer] = React.useState(false)
  const [showAddTransactionsDrawer, setShowAddTransactionsDrawer] =
    React.useState(false)
  const [transactionType, setTransactionType] = React.useState<
    'Expense' | 'Income'
  >('Expense')

  const [{ data: currenciesData }] = useCurrenciesQuery()

  const currencySymbol = currenciesData?.currencies.find(
    (currency) => currency.id === selectedFinancialAccount?.currencyId
  )?.symbol

  return (
    <>
      <Box css={{ background: '$white', borderRadius: '1rem' }}>
        <Flex
          align="center"
          justify="between"
          css={{
            p: '$3',
            pb: '$2',
            // borderBottom: '.1rem solid $border'
          }}
        >
          <Flex gutter="3" align="center">
            <Avatar
              title={
                selectedFinancialAccount?.title ||
                selectedFinancialAccount?.name
              }
              css={{ color: '#8EB0DF', borderRadius: '1rem' }}
              background="#F3F8FF"
              // size="xtraLarge"
              shape="square"
            />
            <Flex direction="column" gutter="1">
              <Text size="md">
                {selectedFinancialAccount?.title} &nbsp;
                <Span css={{ color: '$gray' }}>
                  (
                  {selectedFinancialAccount?.name ||
                    selectedFinancialAccount?.routingNumber}
                  )
                </Span>
                {/* <Text size="xs" color="$secondary">
                {selectedFinancialAccount?.accountNumber}
              </Text>  */}
              </Text>
              {/* <Text size="xs" color="$secondary">
                {selectedFinancialAccount?.accountNumber}
              </Text> */}
            </Flex>
          </Flex>

          <Flex gutter="2" align="center">
            <Button
              prepend={<HiCash color="#ABB3B9" size="1.5rem" />}
              appearance="secondary"
              onClick={() =>
                navigate(
                  `/dashboard/financial/transaction/${selectedFinancialAccount.id}`
                )
              }
            >
              View Transactions
            </Button>
            <Dropdown
              placement="bottomRight"
              menu={[
                {
                  label: (
                    <Flex
                      align="center"
                      gutter="2"
                      role="button"
                      onClick={() => setShowImportTransactionsDrawer(true)}
                    >
                      <HiArrowCircleUp color="#ABB3B9" size="1.75rem" />
                      <Text size="xs">New Upload</Text>
                    </Flex>
                  ),
                },
                {
                  label: (
                    <Flex
                      align="center"
                      gutter="2"
                      role="button"
                      onClick={() => setShowEditDrawer(true)}
                    >
                      <HiPencil color="#ABB3B9" size="1.75rem" />
                      <Text size="xs">Edit</Text>
                    </Flex>
                  ),
                },
                {
                  label: (
                    <Flex
                      align="center"
                      gutter="2"
                      role="button"
                      onClick={() => {
                        setShowAddTransactionsDrawer(true)
                        setTransactionType('Income')
                      }}
                    >
                      <HiDocumentAdd color="#ABB3B9" size="1.75rem" />
                      <Text size="xs">New Income</Text>
                    </Flex>
                  ),
                },
                {
                  label: (
                    <Flex
                      align="center"
                      gutter="2"
                      role="button"
                      onClick={() => {
                        setShowAddTransactionsDrawer(true)
                        setTransactionType('Expense')
                      }}
                    >
                      <HiDocumentAdd color="#ABB3B9" size="1.75rem" />
                      <Text size="xs">New Expense</Text>
                    </Flex>
                  ),
                },
                // {
                //   label: (
                //     <Flex
                //       align="center"
                //       gutter="2"
                //       role="button"
                //       onClick={() => {
                //         navigate(
                //           '/dashboard/settings/product/bank-rules?drawer=open'
                //         )
                //       }}
                //     >
                //       <HiDocumentAdd color="#ABB3B9" size="1.75rem" />
                //       <Text size="xs">New Bank Rule</Text>
                //     </Flex>
                //   ),
                // },
              ]}
            >
              <Button appearance="secondary">
                <HiDotsVertical color="#ABB3B9" size="1.5rem" />
              </Button>
            </Dropdown>
          </Flex>
        </Flex>
        <Flex
          align="center"
          gutter="9"
          css={{
            p: '$3',
            // pt: '$1',
          }}
        >
          <Flex direction="column" gutterY={1}>
            <Flex gutterX={2} align="center">
              <MdAccountBalance color="#ABB3B9" />
              <Text color="$secondary" size="xxs">
                Statement Balance
              </Text>
            </Flex>
            <Text
              size="lg"
              color="$black"
              css={{
                fontFamily: '$space',
              }}
            >
              {formatMoney(
                selectedFinancialAccount?.statementBalance as number,
                currencySymbol
              )}
            </Text>
          </Flex>

          <Flex direction="column" gutterY={1}>
            <Flex gutterX={2} align="center">
              <MdAccountBalanceWallet color="#ABB3B9" />
              <Text color="$secondary" size="xxs">
                GL Account Balance
              </Text>
            </Flex>
            <Text
              size="lg"
              color="$black"
              css={{
                fontFamily: '$space',
              }}
            >
              {formatMoney(
                selectedFinancialAccount?.glAccountBalance as number,
                currencySymbol
              )}
            </Text>
          </Flex>

          <Flex css={{ ml: 'auto' }} direction="column" align="end" gutter="2">
            <Text size="xxs" color="$secondary">
              Reconcilation Status
            </Text>
            {selectedFinancialAccount?.issues === 0 &&
            selectedFinancialAccount?.statementBalance ? (
              <Text size="md" color="$success">
                Completed
              </Text>
            ) : (
              <Text size="md" color="$warning">
                {selectedFinancialAccount?.issues} Issues
              </Text>
            )}
          </Flex>
        </Flex>
      </Box>

      <ImportTransactionsDrawer
        visible={showImportTransactionsDrawer}
        id={selectedFinancialAccount.id}
        onClose={() => {
          setShowImportTransactionsDrawer(false)
        }}
        refetchFinancialAccounts={refetchFinancialAccounts}
      />
      {selectedFinancialAccount && (
        <EditAccountDrawer
          visible={showEditDrawer}
          financialAccount={selectedFinancialAccount}
          onClose={() => setShowEditDrawer(false)}
        />
      )}

      <AddTransactionsDrawer
        visible={showAddTransactionsDrawer}
        transactionType={transactionType}
        financialAccounts={financialAccounts}
        selectedFinancialAccount={selectedFinancialAccount}
        onClose={() => setShowAddTransactionsDrawer(false)}
        refetchFinancialAccounts={refetchFinancialAccounts}
      />
    </>
  )
}

export default FinancialAccount
