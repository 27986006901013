import { Avatar } from 'components/avatar/avatar'
import Button from 'components/button/button'
import DateInput from 'components/date-input/date-input'
import { CollapsibleSection } from 'components/drawer/collapsible-section'
import { Drawer } from 'components/drawer/drawer'
import { Dropdown } from 'components/dropdown/dropdown'
import { InputLabel } from 'components/input'
import Input from 'components/input/input'
import { Flex } from 'components/layout'
import Portal from 'components/portal/portal'
import { SelectMultiValue } from 'components/select/select.types'
import { Text } from 'components/text/text'
import Textarea from 'components/textarea/textarea'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select'
import { addDays } from 'date-fns'
import {
  BankAccount,
  Customer,
  InvoiceEnum,
  InvoiceItemInput,
  useCreateInvoiceMutation,
  useCustomersQuery,
  useFinancialAccountsQuery,
} from 'generated/__generated_graphql'
import useDisclosure from 'hooks/useDisclosure'
import { AddContactsDrawer } from 'pages/contacts/components/add-contacts-drawer'
import { AddAccountDrawer } from 'pages/financial/components/add-account-drawer'
import {
  BorderedSection,
  CustomerBox,
  StyledItemsDropdownHeaderAction,
  StyledItemsWrapper,
} from 'pages/sales/sales.styles'
import { useAppProvider } from 'providers/app-provider'
import React, { useState } from 'react'
import {
  HiCalendar,
  HiChevronDown,
  HiDocumentAdd,
  HiOutlineSearch,
  HiPlusCircle,
  HiUserAdd,
  HiX,
} from 'react-icons/hi'
import {
  extractGraphqlErrors,
  formatLocaleDate,
  isValidEmail,
} from 'utils/helpers'
import { omit } from 'utils/object'
import {
  INVOICE_ACTIONS,
  InvoiceState,
  useInvoiceContext,
} from '../provider/invoice-provider'
import { NewInvoiceDrawerItems } from './new-invoice-drawer-items'

interface IProps {
  visible: boolean
  onClose: () => void
  onSuccess?: (values?: any) => void
  type?: 'invoice' | 'offer'
}

export const NewInvoiceDrawer: React.FC<IProps> = (props) => {
  const notify = useToast()
  const { visible, onClose, onSuccess, type } = props

  const isInvoice = type === 'invoice'
  const [{ data: customers }, refetchCustomers] = useCustomersQuery({
    variables: { pagination: {} },
  })

  const [{ data: accounts }, refreshAccount] = useFinancialAccountsQuery({
    variables: { pagination: {} },
  })

  const [customer, setCustomer] = useState<Customer>()

  const { isOpen: showCustomersModal, toggle: toggleCustomersModal } =
    useDisclosure()
  const { isOpen: showAccount, toggle: toggleShowAccount } = useDisclosure()

  const { organisation } = useAppProvider()

  const {
    dispatch,
    state: { invoiceState },
  } = useInvoiceContext()

  const [{ fetching: creatingInvoice }, createInvoice] =
    useCreateInvoiceMutation()

  async function onSave(isDraft: boolean) {
    if (!invoiceState) return

    const payload = {
      ...(omit(invoiceState, [
        'discountRate',
        'taxRate',
        'dueDate',
        'issueDate',
      ]) as InvoiceState),
      templateId: null,
      isDraft,
      invoiceType: type === 'invoice' ? InvoiceEnum.Invoice : InvoiceEnum.Offer,
      invoiceItems: invoiceState.invoiceItems.map(
        (item) => omit(item, ['id']) as InvoiceItemInput
      ),
      dueDate: formatLocaleDate(invoiceState.dueDate),
      issueDate: formatLocaleDate(invoiceState.issueDate),
    }

    console.log(payload)

    try {
      const response = await createInvoice({ input: payload })
      const error = extractGraphqlErrors(response, 'createInvoice')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content:
          type === 'invoice'
            ? 'Invoice saved successfully'
            : 'Offer saved successfully',
        position: 'top',
        status: 'success',
      })
      onSuccess?.()
      onDismiss()
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  function onDismiss() {
    setCustomer(undefined)
    dispatch?.({ type: INVOICE_ACTIONS.INITIALIZE_INVOICE })
    onClose()
  }

  const currency = organisation?.currency.symbol

  const emails =
    customer?.customerContacts?.map((contact) => ({
      label: contact.email,
      value: contact.email,
    })) ?? []

  const canSave =
    invoiceState?.customerId !== '' &&
    invoiceState?.issueDate &&
    invoiceState?.dueDate &&
    invoiceState?.invoiceItems.length > 0

  const financialAccounts = accounts?.financialAccounts?.data.filter(
    (account) => account.id !== '1'
  )

  return (
    <>
      <Portal>
        <AddContactsDrawer
          visible={showCustomersModal}
          onClose={toggleCustomersModal}
          onSuccess={(customer) => {
            setCustomer(customer as Customer)
            dispatch?.({
              type: INVOICE_ACTIONS.SELECT_CUSTOMER,
              payload: {
                customerId: customer?.id,
              },
            })
          }}
          refetchCustomers={() =>
            refetchCustomers({ requestPolicy: 'network-only' })
          }
        />
      </Portal>
      <Drawer
        size="large"
        title={isInvoice ? 'New Invoice' : 'New Offer'}
        titleIcon={<HiDocumentAdd size="2rem" color="#ABB3B9" />}
        visible={visible}
        onClose={onDismiss}
        footer={
          <Flex justify="between" stretchx gutterX="2">
            <Button appearance="secondary" onClick={onDismiss}>
              Cancel
            </Button>
            <Button
              appearance="secondary"
              css={{ ml: 'auto' }}
              disabled={creatingInvoice || !canSave}
              onClick={() => onSave(true)}
            >
              Save Draft
            </Button>
            <Button
              onClick={() => onSave(false)}
              disabled={creatingInvoice || !canSave}
              isLoading={creatingInvoice}
            >
              Save & Approve
            </Button>
          </Flex>
        }
      >
        <Flex>
          <BorderedSection sideBorder direction="column" gutterY="5">
            <Flex direction="column" gutterY="5">
              <Flex
                align="center"
                gutter={2}
                justify="between"
                css={{
                  '& > div': {
                    width: '50%',
                  },
                }}
              >
                {customer ? (
                  <Flex direction="column">
                    <InputLabel required> Client </InputLabel>
                    <CustomerBox justify="between" align="center">
                      <Flex align="center" gutterX="2">
                        <Avatar size="medium" title={customer.name} />
                        <Flex direction="column">
                          <Text size="xs" color="$primary">
                            {customer.name}
                          </Text>
                        </Flex>
                      </Flex>
                      <Button
                        appearance="ghost"
                        onClick={() => setCustomer(undefined)}
                      >
                        <HiX role="button" color="#ABB3B9" />
                      </Button>
                    </CustomerBox>
                  </Flex>
                ) : (
                  <Dropdown
                    fullWidth
                    menu={customers?.customers?.data?.map((customer) => {
                      return {
                        label: (
                          <Text size="xs" color="$primary">
                            {customer.name}
                          </Text>
                        ),
                        onClick: () => {
                          setCustomer(customer as Customer)
                          dispatch?.({
                            type: INVOICE_ACTIONS.SELECT_CUSTOMER,
                            payload: {
                              customerId: customer.id,
                            },
                          })
                        },
                      }
                    })}
                    header={
                      <Button appearance="ghost" onClick={toggleCustomersModal}>
                        <StyledItemsDropdownHeaderAction
                          align="center"
                          gutterX="2"
                        >
                          <HiUserAdd color="#ABB3B9" />
                          <Text size="xs" color="$secondary">
                            Add new customer
                          </Text>
                        </StyledItemsDropdownHeaderAction>
                      </Button>
                    }
                  >
                    <Input
                      required
                      prepend={<HiOutlineSearch color="#ABB3B9" />}
                      label="Client"
                      placeholder="Find or add a customer"
                    />
                  </Dropdown>
                )}

                <TypeAheadSelect
                  size="sm"
                  isMulti
                  isCreatable={true}
                  label="Recipient Email(s)"
                  placeholder={`doe@gmail.com, jane@example.com`}
                  options={emails}
                  valueKey="email"
                  labelKey="email"
                  noOptionsMessage={() => (
                    <Text size={'xxs'}>
                      Type in a valid email address then press ENTER / TAB to
                      add
                    </Text>
                  )}
                  onChange={(emails: SelectMultiValue) => {
                    dispatch?.({
                      type: INVOICE_ACTIONS.UPDATE_INVOICE,
                      payload: {
                        field: 'emails',
                        value: emails,
                      },
                    })
                  }}
                  isValidNewOption={(val) => isValidEmail(val)}
                />
              </Flex>

              <Flex
                align="center"
                gutter={2}
                justify="between"
                css={{
                  '& > div': {
                    width: '50%',
                  },
                }}
              >
                <DateInput
                  required
                  prepend={<HiCalendar color="#ABB3B9" />}
                  append={<HiChevronDown size="1.9rem" color="#ABB3B9" />}
                  label="Issue Date"
                  placeholder="Select date"
                  maximumDate={addDays(new Date(), 0)}
                  dateFormat="MMM dd, yyyy"
                  onChange={(e) => {
                    dispatch?.({
                      type: INVOICE_ACTIONS.UPDATE_INVOICE,
                      payload: {
                        field: 'issueDate',
                        value: new Date(e.target.value),
                      },
                    })
                  }}
                />

                <DateInput
                  required
                  prepend={<HiCalendar color="#ABB3B9" />}
                  append={<HiChevronDown size="1.9rem" color="#ABB3B9" />}
                  label="Due date"
                  placeholder="Select date"
                  minimumDate={addDays(new Date(), -1)}
                  dateFormat="MMM dd, yyyy"
                  onChange={(e) => {
                    dispatch?.({
                      type: INVOICE_ACTIONS.UPDATE_INVOICE,
                      payload: {
                        field: 'dueDate',
                        value: new Date(e.target.value),
                      },
                    })
                  }}
                />
              </Flex>

              <Flex
                gutterY="2"
                justify="between"
                css={{
                  '& > div': {
                    width: '50%',
                  },
                }}
              >
                <TypeAheadSelect
                  required
                  label="Payment option"
                  placeholder="Select financial account"
                  options={financialAccounts}
                  valueKey="id"
                  labelKey="name"
                  renderOption={(value: BankAccount) => (
                    <Flex align="center" gutterX="2" css={{ py: '1.2rem' }}>
                      <Text size="xs">
                        {value.title}({value.name ?? value.routingNumber})
                      </Text>
                    </Flex>
                  )}
                  renderValue={(value: BankAccount) => (
                    <Flex align="center" gutterX="2" css={{ py: '1.2rem' }}>
                      <Text size="xs">
                        {value.title}({value.name ?? value.routingNumber})
                      </Text>
                    </Flex>
                  )}
                  noOptionsMessage={() => (
                    <Flex direction="column">
                      <Button appearance="ghost" onClick={toggleShowAccount}>
                        <HiPlusCircle color="#398AFA" />
                        <Text color="$blue">Link an account</Text>
                      </Button>
                    </Flex>
                  )}
                  onChange={(value) => {
                    dispatch?.({
                      type: INVOICE_ACTIONS.UPDATE_INVOICE,
                      payload: {
                        field: 'bankAccountId',
                        value: value,
                      },
                    })
                  }}
                />
              </Flex>
            </Flex>
          </BorderedSection>
        </Flex>

        <Flex
          direction={'column'}
          gutter={'2'}
          justify={'start'}
          align={'start'}
          css={{
            width: '100%',
            padding: '3.2rem 2.4rem',
            borderBottom: '0.1rem solid $border',
            [`& ${StyledItemsWrapper}`]: {
              width: '100%',
            },
          }}
        >
          <InputLabel required>Items</InputLabel>
          <NewInvoiceDrawerItems currency={currency} />
        </Flex>

        <CollapsibleSection title="Additional Options" borders={false} collapse>
          <Flex gutterY="5" direction="column">
            {/* <FileUpload
              onChange={(val) =>
                dispatch?.({
                  type: INVOICE_ACTIONS.UPDATE_INVOICE,
                  payload: {
                    field: 'uploadId',
                    value: val[0].id,
                  },
                })
              }
            /> */}
            <Textarea
              label={isInvoice ? 'Message on Invoice' : 'Message on Offer'}
              placeholder="Type something here ..."
              onChange={(e) => {
                dispatch?.({
                  type: INVOICE_ACTIONS.UPDATE_INVOICE,
                  payload: {
                    field: 'message',
                    value: e.target.value,
                  },
                })
              }}
            />
          </Flex>
        </CollapsibleSection>
        <AddAccountDrawer
          visible={showAccount}
          onClose={toggleShowAccount}
          onAccountAddSuccess={() =>
            refreshAccount({ requestPolicy: 'network-only' })
          }
        />
      </Drawer>
    </>
  )
}
