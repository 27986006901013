import { zodResolver } from '@hookform/resolvers/zod'
import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import { StyledDrawerSection } from 'components/drawer/drawer.styles'
import { InputError } from 'components/input'
import Flex from 'components/layout/flex'
import Text from 'components/text/text'
import Textarea from 'components/textarea'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select/type-ahead-select'
import {
  useAccountTypeAccountsQuery,
  useBankRulesQuery,
  useFinancialAccountsQuery,
  useUpdateBankRuleMutation,
} from 'generated/__generated_graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { HiLightningBolt } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { z } from 'zod'
import { Action } from './add-bank-rules-drawer'
import BankRuleCard from './bank-rule-card'
import { BankRulesTableData } from './bank-rules-table'

const formSchema = z.object({
  bankAccountId: z.string().refine((id) => !isNaN(Number.parseInt(id)), {
    message: 'Please select one of your connected bank accounts',
  }),
  bankRule: z.object({
    lookupSectionId: z.string().refine((id) => !isNaN(Number.parseInt(id))),
    ruleId: z.string().refine((id) => !isNaN(Number.parseInt(id))),
    fact: z.string(),
  }),
  generalLedgerAccountId: z
    .string()
    .refine((id) => !isNaN(Number.parseInt(id)), {
      message:
        'Please select an account in the general ledger to associate these rules with',
    }),
  description: z
    .string()
    .min(3, 'Please describe these bank rules (will be applied to each rule)'),
})

type FormFields = z.infer<typeof formSchema>

type EditBankRuleDrawerProps = {
  visible: boolean
  onClose: () => void
  bankRule: BankRulesTableData['bankRule']
}

export const EditBankRuleDrawer: React.FC<EditBankRuleDrawerProps> = (
  props
) => {
  const { visible, onClose, bankRule } = props

  const [actionInProgress, setActionInProgress] = useState<Action>('IDLE')

  const notify = useToast()

  const [, updateBankRule] = useUpdateBankRuleMutation()
  const [, queryBankRules] = useBankRulesQuery()

  const [{ data: financialAccountsQuery }] = useFinancialAccountsQuery()
  const { data: financialAccounts } =
    financialAccountsQuery?.financialAccounts ?? {}

  const [{ data: accountTypeAccountsQueryData }] = useAccountTypeAccountsQuery()
  const { accountTypeAccounts } = accountTypeAccountsQueryData ?? {}

  const financialAccountsSelectOptions =
    financialAccounts?.map((finAcc) => ({
      label: finAcc.title ?? finAcc.name,
      value: finAcc.id,
    })) ?? []

  const glaSelectOptions =
    accountTypeAccounts
      ?.filter((accountTypeAccount) => accountTypeAccount.name !== 'Bank')
      .map((accountTypeAccount, index) => ({
        label: accountTypeAccount.name,
        value: index,
        options: accountTypeAccount?.accounts?.map((account) => ({
          label: account.name,
          value: account.id,
        })),
      })) ?? []

  const {
    control,
    register,
    reset,
    formState: { errors, isSubmitting, isValid },
    getValues,
    watch,
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    reset({
      bankAccountId: bankRule.bankAccount.id,
      bankRule: {
        fact: bankRule.fact,
        lookupSectionId: bankRule.lookUpTypeId,
        ruleId: bankRule.ruleId,
      },
      description: bankRule.description,
      generalLedgerAccountId: bankRule.glAccountId,
    })
  }, [])

  async function onSubmit() {
    try {
      const {
        bankAccountId,
        bankRule: formBankRule,
        description,
        generalLedgerAccountId,
      } = getValues()

      const { lookupSectionId, ruleId, fact } = formBankRule

      const response = await updateBankRule({
        input: {
          id: bankRule.id,
          bankAccountId,
          description,
          glAccountId: generalLedgerAccountId,
          lookUpTypeId: lookupSectionId,
          ruleId,
          fact,
        },
      })

      const error = extractGraphqlErrors(response, 'updateBankRule')

      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }

      queryBankRules({ requestPolicy: 'network-only' })

      notify({
        content: 'Bank rule has been updated successfully',
        status: 'success',
      })

      onReset()
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  function onReset() {
    setActionInProgress('IDLE')
    reset()
    onClose()
  }

  return (
    <Drawer
      title="Edit Bank Rule"
      titleIcon={<HiLightningBolt size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onReset}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        onReset={onReset}
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <StyledDrawerSection direction="column" gutterY="3">
          <Controller
            control={control}
            name="bankAccountId"
            render={({ field: { onChange, value } }) => {
              return (
                <TypeAheadSelect
                  isDisabled={isSubmitting}
                  required
                  label="Bank Account"
                  placeholder="Select one of your connected bank accounts"
                  options={financialAccountsSelectOptions}
                  value={value}
                  onChange={onChange}
                  error={errors.bankAccountId?.message}
                />
              )
            }}
          />
        </StyledDrawerSection>

        <Flex
          direction={'column'}
          gutterY="6"
          css={{ padding: '3.2rem 2.4rem' }}
        >
          <Text variant="h4" size="sm" weight="bold" color="$primary">
            Rules
          </Text>
          <Flex direction="column" gutterY="3">
            <Controller
              control={control}
              name="bankRule"
              render={({ field: { onChange, value: bankRule } }) => {
                return (
                  <Flex
                    direction={'column'}
                    css={{ width: '100%', height: '100%' }}
                  >
                    <BankRuleCard
                      setActionInProgress={setActionInProgress}
                      actionInProgress={actionInProgress}
                      editBankRule={(rule) => {
                        onChange(rule)
                        setActionInProgress('IDLE')
                      }}
                      savedBankRuleIndex={1}
                      bankRule={bankRule}
                    />

                    <InputError css={{ marginTop: 6 }}>
                      {errors.bankRule?.message}
                    </InputError>
                  </Flex>
                )
              }}
            />
          </Flex>
        </Flex>

        <Flex
          direction={'column'}
          gutterY="6"
          css={{ padding: '3.2rem 2.4rem' }}
        >
          <Text variant="h4" size="sm" weight="bold" color="$primary">
            Additional Options
          </Text>
          <Flex direction="column" gutterY="5">
            <Controller
              control={control}
              name="generalLedgerAccountId"
              render={({ field: { onChange, value } }) => {
                return (
                  <TypeAheadSelect
                    isDisabled={isSubmitting}
                    required
                    label="General Ledger Account"
                    placeholder="Select an account"
                    options={glaSelectOptions}
                    onChange={onChange}
                    value={value}
                    error={errors.generalLedgerAccountId?.message}
                  />
                )
              }}
            />

            <Textarea
              {...(register('description') as any)}
              required
              disabled={isSubmitting}
              label="Description"
              placeholder="Add description"
              value={watch('description')}
              error={errors.description?.message}
            />
          </Flex>
        </Flex>

        <Flex
          gutterX="2"
          align={'end'}
          css={{ alignSelf: 'end', flexGrow: 1, padding: '3.2rem 2.4rem' }}
        >
          <Button
            type="reset"
            size="md"
            appearance="secondary"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="md"
            isLoading={isSubmitting}
            disabled={!isValid}
          >
            Save
          </Button>
        </Flex>
      </form>
    </Drawer>
  )
}

export default EditBankRuleDrawer
