import { YearInReview } from 'generated/__generated_graphql'
import BarChart from './bar-chart'

export default function HoverCardChart({
  currency,
  subtitle,
  yearInReview,
}: {
  currency: string
  subtitle: string
  yearInReview: YearInReview[]
}) {
  const labels = yearInReview?.map((item) => item.month)
  const data = yearInReview?.map((item) => item.amount)
  return (
    <BarChart
      isPreview
      currency={currency}
      labels={labels}
      dataA={data}
      label={subtitle}
    />
  )
}
