import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import { styled } from 'stitches/stitches.config'

export const CalendarWrapper = styled(Box, {
  background: '$white',
  px: 12,
  py: 12,
  boxShadow: '$normal',
  borderRadius: '$4',
  display: 'inline-block',
  transition: 'all .3s',
  outline: 'none',
  position: 'relative',
})

export const CalendarHeader = styled(Flex, {
  width: '100%',
  marginBottom: '$3',
})

export const StyledNavButton = styled('button', {
  appearance: 'none',
  background: '$white',
  border: 'none',
  width: '2.8rem',
  height: '2.8rem',
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all .3s ease',
  padding: 0,

  '.icon': { fontSize: 20, color: '$primary' },

  '&:hover': {
    background: '$highlightBg',
    cursor: 'pointer',
  },

  '&:focus': { boxShadow: '$subtle' },
})

export const WeekHeader = styled(Flex, { marginBottom: '$3', gap: 4 })
export const WeekDay = styled(Flex, {
  width: 36,
  fontSize: 14,
  color: '$primary',
  fontFamily: '$medium',
})
export const Dates = styled('div', {
  display: 'grid',
  gap: 4,
  gridTemplateColumns: 'repeat(7,3.6rem)',
})

export const RangeInputs = styled(Flex, {
  width: 7 * 36 + 24,
  marginBottom: 24,
})

export const Day = styled('button', {
  height: 36,
  borderRadius: 8,
  fontSize: 14,
  fontFamily: '$bold',
  color: '$primary',
  cursor: 'pointer',
  userSelect: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  backgroundColor: 'transparent',

  '&:focus': {
    outline: 'none',
  },

  variants: {
    variant: {
      default: {
        background: '$white',

        '&:hover': { background: '$border' },
      },

      selected: {
        color: '$blue',
        background: '$highlightBg',
      },

      inRange: {
        color: '$primary',
        background: '$highlightBg',
      },

      offset: {
        pointerEvents: 'none',
        opacity: 0.2,

        '&:hover': { background: '$white' },
      },

      disabled: {
        pointerEvents: 'none',
        opacity: 0.4,
        '&:hover': { background: '$white' },
      },
    },
  },
})

export const StyledTag = styled('div', {})
