import MonoConnect from '@mono.co/connect.js'
import React from 'react'
import { getMonoKey } from 'utils/helpers'
import { Fn } from 'utils/types'
import useCallbackRef from './useCallbackRef'

interface IMonoConfig {
  onClose?: Fn
  onSuccess?: Fn
  onLoad?: Fn
}

export default function useMono(config?: IMonoConfig) {
  const onClose = useCallbackRef(config?.onClose)
  const onLoad = useCallbackRef(config?.onLoad)
  const onSuccess = useCallbackRef(config?.onSuccess)

  const monoConnect = React.useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose,
      onLoad,
      onSuccess,
      key: getMonoKey(),
    })

    monoInstance.setup()

    return monoInstance
  }, [onClose, onLoad, onSuccess])

  return monoConnect
}
