import Button from 'components/button/button'
import Input from 'components/input'
import { Box, Flex } from 'components/layout'
import Text from 'components/text/text'
import { motion } from 'framer-motion'
import React from 'react'
import { FiSearch } from 'react-icons/fi'
import {
  HiOutlineCash,
  HiOutlineChartPie,
  HiOutlineClipboardCheck,
} from 'react-icons/hi'
import { RiSearchLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { styled } from 'stitches/stitches.config'
import MenuItem from './components/menu-item'

const StyledQuickFind = styled('div', {
  background: '$white',
  boxShadow: '$deep',
  borderRadius: '$3',
  // p: '$3',
  width: 'inherit',
  height: 'inherit',
  pb: '$8',

  overflowY: 'auto',

  '.search': {
    all: 'unset',
    font: '$primary',
    color: '$secondary',
    fontSize: '$2',
    width: '100%',
    height: '100%',
    padding: 0,
    ml: '$1',

    '&::placeholder': {
      color: '$secondary',
    },
  },
})

function QuickFind() {
  const [isFocused, setIsFocused] = React.useState(false)
  const quickFindRef = React.useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  function handleAction(link: string) {
    navigate(link)
    setIsFocused(false)
  }

  const quickActions = [
    {
      title: 'Add financial account',
      icon: <HiOutlineCash color="#848C96" size="1.75rem" />,
      action: () =>
        handleAction('/dashboard/financial/accounts?openDrawer=true'),
      character: 'A',
    },
    {
      title: 'Create an Invoice',
      icon: <HiOutlineChartPie color="#848C96" size="1.75rem" />,
      action: () => handleAction('/dashboard/sales/invoices?openDrawer=true'),
      character: 'I',
    },
    {
      title: 'Create Smart Report',
      icon: <HiOutlineClipboardCheck color="#848C96" size="1.75rem" />,
      action: () =>
        handleAction('/dashboard/reports/custom-reports?openDrawer=true'),
    },
  ]

  React.useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.metaKey && event.key === 'k') {
        setIsFocused(true)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        quickFindRef.current &&
        !quickFindRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  React.useEffect(() => {
    function handleDragOver(e: WindowEventMap['dragover']) {
      e.preventDefault()
      return false
    }
    function handleDrop(e: WindowEventMap['drop']) {
      const offset = e?.dataTransfer!.getData('text/plain').split(',')
      quickFindRef.current!.style.left =
        e.clientX + parseInt(offset[0], 10) + 'px'
      quickFindRef.current!.style.top =
        e.clientY + parseInt(offset[1], 10) + 'px'
      e.preventDefault()
      return false
    }
    document.body.addEventListener('dragover', handleDragOver, false)
    document.body.addEventListener('drop', handleDrop, false)
  }, [])

  function handleDragStart(e: React.DragEvent<HTMLDivElement>) {
    const style = window.getComputedStyle(e.target as Element, null)
    e.dataTransfer.setData(
      'text/plain',
      parseInt(style.getPropertyValue('left'), 10) -
        e.clientX +
        ',' +
        (parseInt(style.getPropertyValue('top'), 10) - e.clientY)
    )
  }

  return (
    <>
      <Input
        size="sm"
        css={{ height: 'auto', mt: '4rem', mb: '-1rem' }}
        prepend={<FiSearch size="1.75rem" color="#8A979D" />}
        onFocus={() => setIsFocused(true)}
        append={
          <Flex
            align="center"
            gutter={1}
            css={{
              background: '$white',
              p: '$1 $2',
              borderRadius: '$1',
              border: '1px solid $border',
              boxShadow: '$subtle',
            }}
          >
            <Text color="$secondary">⌘</Text>
            <Text color="$secondary">K</Text>
          </Flex>
        }
        placeholder="Search"
      />

      <Box
        ref={quickFindRef}
        draggable
        onDragStart={handleDragStart}
        css={{
          zIndex: '1',
          width: '55rem',
          height: '35rem',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          pointerEvents: isFocused ? 'all' : 'none',
        }}
      >
        <motion.div
          initial={{
            opacity: 0,
            scale: 1.2,
          }}
          animate={{
            opacity: isFocused ? 1 : 0,
            scale: isFocused ? 1 : 1.2,
            transition: {
              duration: 0.5,
              ease: 'easeInOut',
            },
          }}
          exit={{ opacity: 0, scale: 1.2 }}
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <StyledQuickFind>
            <Flex
              stretchx
              align="center"
              css={{
                p: '$3',
                pb: '$2',
                borderBottom: '1px solid $border',
              }}
            >
              <RiSearchLine size="1.75rem" color="#8A979D" />
              <input type="text" placeholder="Search" className="search" />
            </Flex>
            <Flex direction="column" gutter="3" css={{ p: '$3' }}>
              <Text size="xxs" color="$secondary">
                Quick Actions
              </Text>
              {quickActions.map((item, index) => (
                <MenuItem key={index} item={item} />
              ))}
            </Flex>
            {/* <Flex
              direction="column"
              gutter="3"
              css={{
                mt: '$1',
                borderTop: '1px solid $border',
                p: '$3',
              }}
            >
              {menu.map((item, index) => (
                <MenuItem key={index} item={item} />
              ))}
            </Flex> */}
            <Flex
              align="center"
              justify="between"
              css={{
                position: 'absolute',
                width: '100%',
                bottom: '0',
                backgroundColor: '$background',
                p: '$3 $4',
                borderTop: '9px solid $white',
                borderBottomLeftRadius: '$1',
                borderBottomRightRadius: '$1',
              }}
            >
              <Text size="xxs" weight="semi" color="$secondary">
                Take your business to the next level with our premium features
              </Text>
              <Button
                onClick={() =>
                  navigate('/dashboard/settings/organisation/plans')
                }
                size="sm"
                appearance="black"
              >
                Upgrade
              </Button>
            </Flex>
          </StyledQuickFind>
        </motion.div>
      </Box>
    </>
  )
}

export default QuickFind
