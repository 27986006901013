import Button from 'components/button/button'
import CheckBox from 'components/checkbox/checkbox'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import Loader from 'components/loader/loader'
import { Text } from 'components/text/text'
import React, { useEffect, useState } from 'react'
import { HiArrowCircleUp, HiFilter, HiOutlineSearch } from 'react-icons/hi'
import { ColumnFilter } from './components/column-filter'
import { TableEmptyNotice } from './components/table-empty-notice'
import {
  StyledBody,
  StyledFilterHeader,
  StyledInputWrap,
  StyledLoader,
  StyledPagination,
  StyledTable,
  StyledTableActions,
  StyledTableContainer,
  StyledTd,
  StyledTh,
  StyledTr,
} from './table.styles'
import {
  IFilterOperator,
  ITableFilterOptions,
  ITableFilterTypes,
  TableProps,
} from './table.types'

export const Table = React.forwardRef<HTMLTableElement, TableProps>(
  (
    {
      columns,
      dataSource,
      hideHead,
      pagination,
      tdPadding,
      showExport,
      showGeneralFilter,
      showDownload,
      showSearch,
      searchProps,
      secondaryFilter,
      actions,
      emptyProps,
      selectable,
      loading,
      onRowClick,
      onRowSelect,
      ...tableContainerProps
    },
    ref
  ) => {
    // state for keeping newly filtered data
    const [selected, setSelected] = useState<(typeof dataSource)[0]['id'][]>([])
    const [filteredData, setFilteredData] =
      useState<typeof dataSource>(dataSource)

    const canClickRow = !!onRowClick

    // keeping tract of applied filters incase of duplicate filter types
    const [columnFilters, setColumnFilters] = useState<{
      [key: string]: {
        key: string
        type: ITableFilterTypes
        value: ITableFilterOptions['value'][]
        operator?: IFilterOperator
      }
    }>()

    // do general filter here
    // general filter resets colum filters
    const handleSearchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
      const filteredData = dataSource.filter((item) => {
        const values = Object.values(item)
        return values.find((value) => {
          if (typeof value === 'string') {
            return value.toLowerCase().includes(e.target.value.toLowerCase())
          }
        })
      })

      setFilteredData(filteredData)
    }

    const getFilterTitleSuffix = (type: ITableFilterTypes) => {
      const map: any = {
        [ITableFilterTypes.CHECKBOX]: 'Options',
        [ITableFilterTypes.SELECT]: 'Option',
      }

      const title = map[type] ?? 'Filter'
      return title
    }

    useEffect(() => {
      if (!columnFilters) {
        setFilteredData(dataSource)
        return
      }

      // let filtered = dataSource

      const filtered = dataSource.filter((row) => {
        let meetsConditions = false

        const allConditions = Object.values(columnFilters)?.filter(
          (item) => !!item.value.length
        )

        if (!allConditions.length) {
          return true
        }

        allConditions.forEach((condition) => {
          condition.value.includes(row[condition.key])
            ? (meetsConditions = true)
            : (meetsConditions = false)
        })

        return meetsConditions
      })

      setFilteredData(filtered)
    }, [columnFilters, dataSource])

    useEffect(() => {
      onRowSelect?.(selected)
    }, [selected])

    return (
      <StyledTableContainer ref={ref} {...tableContainerProps}>
        {(showExport || showSearch || secondaryFilter) && (
          <StyledTableActions align="stretch" gutterX={2}>
            {showSearch && (
              <StyledInputWrap>
                <Input
                  prepend={<HiOutlineSearch color="#ABB3B9" />}
                  placeholder="Search Data..."
                  onChange={handleSearchFilter}
                  {...searchProps}
                />
              </StyledInputWrap>
            )}

            {secondaryFilter && (
              <div style={{ marginLeft: showSearch ? 'auto' : 'initial' }}>
                {secondaryFilter}
              </div>
            )}
            {showGeneralFilter && (
              <div style={{ marginLeft: showSearch ? 'auto' : 'initial' }}>
                <Button
                  size="xl"
                  appearance="secondary"
                  prepend={<HiFilter size="1.3rem" color="#ABB3B9" />}
                >
                  Filter
                </Button>
              </div>
            )}
            {(showDownload || showExport) && (
              <div
                style={{
                  display: 'flex',
                  gap: '0.8rem',
                  alignItems: 'center',
                  marginLeft: 'auto',
                }}
              >
                {showDownload && (
                  <div>
                    <Button
                      size="xl"
                      appearance="secondary"
                      append={<HiArrowCircleUp size="1.3rem" color="#ABB3B9" />}
                    >
                      Download
                    </Button>
                  </div>
                )}
                {showExport && (
                  <div>
                    <Button
                      size="xl"
                      appearance="secondary"
                      append={<HiArrowCircleUp size="1.3rem" color="#ABB3B9" />}
                    >
                      Export
                    </Button>
                  </div>
                )}
              </div>
            )}
            <div>{actions}</div>
          </StyledTableActions>
        )}
        <StyledTable>
          {loading && (
            <StyledLoader>
              <Loader size="md" color="$blue" />
            </StyledLoader>
          )}
          {!hideHead && !!dataSource.length && (
            <thead>
              <tr>
                {columns.map((col, index) => (
                  <StyledTh key={index} align="left">
                    <Flex stretchx justify="between" align="center">
                      <Flex align="center" gutterX="2">
                        {selectable && index === 0 && (
                          <CheckBox
                            checked={selected.length === filteredData.length}
                            onChange={() => {
                              const allSelected =
                                selected.length === filteredData.length

                              if (allSelected) {
                                setSelected([])
                              } else {
                                setSelected(filteredData.map((item) => item.id))
                              }
                            }}
                          />
                        )}
                        <Text
                          variant="h4"
                          size="xxs"
                          weight="bold"
                          color="$secondary"
                        >
                          {col.title.toUpperCase()}
                        </Text>
                      </Flex>

                      {col.filter && (
                        <Flex gutterX="2" justify="end" align="center">
                          <ColumnFilter
                            {...col.filter}
                            data={filteredData}
                            colKey={col.dataIndex as string}
                            onFilter={(key, type, value) => {
                              setColumnFilters({
                                ...columnFilters,
                                [key]: {
                                  key,
                                  type,
                                  value,
                                },
                              })
                            }}
                            header={
                              col.filter.header ?? (
                                <StyledFilterHeader>
                                  <Text
                                    size="xs"
                                    color="$primary"
                                    weight="bold"
                                  >
                                    {col.title}{' '}
                                    {getFilterTitleSuffix(col.filter.type)}
                                  </Text>
                                </StyledFilterHeader>
                              )
                            }
                          />
                        </Flex>
                      )}
                    </Flex>
                  </StyledTh>
                ))}
              </tr>
            </thead>
          )}
          <StyledBody loading={loading}>
            {filteredData && (
              <>
                {filteredData.map((data, index) => (
                  <StyledTr
                    onClick={() => onRowClick?.(data)}
                    css={{
                      ...(canClickRow && {
                        transition: 'background-color 0.3s ease-in-out',
                        '&:hover': {
                          backgroundColor: '#F7F8FA',
                          cursor: 'pointer',
                        },
                      }),
                    }}
                    key={index}
                  >
                    {columns.map((col, i) => (
                      <StyledTd
                        css={{
                          padding: tdPadding,
                        }}
                        key={i}
                        border={index + 1 !== filteredData.length}
                      >
                        <Flex align="center" gutterX="2">
                          {selectable && i === 0 && (
                            <CheckBox
                              checked={selected.includes(data.id)}
                              onChange={() => {
                                const isSelected = selected.includes(data.id)

                                if (isSelected) {
                                  setSelected(
                                    selected.filter((item) => item !== data.id)
                                  )
                                } else {
                                  setSelected([...selected, data.id])
                                }
                              }}
                            />
                          )}
                          {!col.shouldNotRender && (
                            <>
                              {col.render ? (
                                col.render(data[col.dataIndex as string], data)
                              ) : (
                                <Text color="$primary" size="xs">
                                  {data[col.dataIndex as string]}
                                </Text>
                              )}
                            </>
                          )}
                        </Flex>
                      </StyledTd>
                    ))}
                  </StyledTr>
                ))}
              </>
            )}

            {filteredData.length === 0 && (
              <tr>
                <td colSpan={columns.length}>
                  <TableEmptyNotice
                    loading={loading}
                    {...emptyProps}
                    title={
                      dataSource.length === 0 ? emptyProps?.title : undefined
                    }
                    subtitle={
                      dataSource.length === 0 ? emptyProps?.subtitle : undefined
                    }
                    action={
                      dataSource.length === 0 ? emptyProps?.action : undefined
                    }
                    icon={
                      dataSource.length === 0 ? emptyProps?.icon : undefined
                    }
                  />
                </td>
              </tr>
            )}
          </StyledBody>
        </StyledTable>

        {!!pagination && !!filteredData.length && (
          <StyledPagination loading={loading} align="center" justify="between">
            <Flex align="center" gutterX={1}>
              <Text size="xs" weight="bold" color="$primary">
                {pagination.totalCount}
              </Text>
              <Text size="xs" weight="medium" color="$secondary">
                Entries / Page {pagination?.currentPage} of{' '}
                {pagination?.totalPages ?? 1}
              </Text>
            </Flex>
            {(pagination?.totalPages as number) > 1 && (
              <Flex align="center" gutterX={1}>
                <Button
                  size="md"
                  appearance="secondary"
                  onClick={() => pagination?.onPaginationClick?.('previous')}
                >
                  Previous
                </Button>
                <Button
                  size="md"
                  appearance="secondary"
                  onClick={() => pagination?.onPaginationClick?.('next')}
                >
                  Next
                </Button>
              </Flex>
            )}
          </StyledPagination>
        )}
      </StyledTableContainer>
    )
  }
)

Table.displayName = 'Table'
