import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { Span } from 'components/text/span'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import { useCancelSubscriptionMutation } from 'generated/__generated_graphql'
import { useAppProvider } from 'providers/app-provider'
import React from 'react'
import { HiTrash } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { StyledText } from '../../organisation.styles'

interface CancelSubscriptionDrawerProps {
  visible: boolean
  onClose: () => void
}

export const CancelSubscriptionDrawer: React.FC<
  CancelSubscriptionDrawerProps
> = (props) => {
  const { visible, onClose } = props
  const notify = useToast()

  const { organisation, refreshApp } = useAppProvider()
  const { name: orgName } = organisation ?? {}
  const { name: planName } = organisation?.plan ?? {}

  const [{ fetching: cancelling }, cancelSubscription] =
    useCancelSubscriptionMutation()

  async function onSubmit() {
    try {
      const response = await cancelSubscription({
        input: {
          cancel: true,
        },
      })

      const error = extractGraphqlErrors(response, 'cancelSubscription')

      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }

      notify({
        content: 'Your subscription to ' + planName + ' has been cancelled',
        status: 'success',
      })
      refreshApp()

      onClose()
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  return (
    <Drawer
      title="Cancel subscription"
      titleIcon={<HiTrash size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      closable={!cancelling}
      maskClosable={!cancelling}
      fullHeight={false}
    >
      <Flex css={{ p: '$5', height: '100%' }} direction="column" gutter="3">
        <Flex direction="column" gutter="3">
          <Text size="xs" color="$primary">
            Are you sure you want to cancel your subscription to{' '}
            <Span css={{ color: '$blue' }}>{planName} Plan</Span>?
          </Text>
          <Flex>
            <StyledText size="xs">
              The entire <Span css={{ color: 'red' }}>{orgName}</Span> team will
              immediately lose access to all the upgrades associated with the
              plan.
            </StyledText>
          </Flex>
        </Flex>

        <Flex
          gutterX="2"
          css={{ alignItems: 'end', alignSelf: 'end', flexGrow: 1 }}
        >
          <Button
            size="md"
            appearance="secondary"
            type="reset"
            disabled={cancelling}
          >
            Cancel
          </Button>
          <Button
            appearance="danger"
            size="md"
            type="submit"
            disabled={cancelling}
            isLoading={cancelling}
            onClick={onSubmit}
          >
            Cancel subscription
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}
