import Button from 'components/button/button'
import DateInput from 'components/date-input/date-input'
import Drawer from 'components/drawer/drawer'
import Input from 'components/input/input'
import { Box, Flex } from 'components/layout'
import Text from 'components/text/text'
import Textarea from 'components/textarea/textarea'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select'
import {
  BankAccount,
  Purchase,
  useCreatePurchasePaymentMutation,
  useFinancialAccountsQuery,
} from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import { useEffect } from 'react'
import { HiCalendar, HiChevronDown, HiDocumentAdd } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'

interface PaymentDrawerProps {
  visible: boolean
  onClose: () => void
  onSuccess?: (values?: any) => void
  purchase: Purchase
}

const RecordExpensePayment: React.FC<PaymentDrawerProps> = ({
  visible,
  onClose,
  onSuccess,
  purchase,
}) => {
  const notify = useToast()
  const { register, values, setInputValue, errors, bulkUpdate } = useForm({
    fields: { amount: 0, bankAccountId: '', comment: '', date: new Date() },
  })
  const [{ data: accounts }] = useFinancialAccountsQuery({
    variables: { pagination: {} },
  })

  const [{ fetching: creatingPayment }, createPurchasePaymentMutation] =
    useCreatePurchasePaymentMutation()

  async function createPurchasePayment() {
    try {
      const response = await createPurchasePaymentMutation({
        input: {
          ...values,
          amount: Number(values.amount),
          purchaseId: purchase.id,
        },
      })
      const error = extractGraphqlErrors(response, 'createPurchasePayment')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: 'Payment recorded successfully',
        status: 'success',
      })
      onClose()
      onSuccess?.()
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  const canSave = values.amount !== 0 && values.bankAccountId !== ''

  useEffect(() => {
    bulkUpdate({
      amount: purchase.amountPending,
      bankAccountId: purchase.bankAccount?.id,
    })
  }, [purchase.id])
  return (
    <Drawer
      title="Record Payment"
      titleIcon={<HiDocumentAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="md"
            disabled={!canSave || creatingPayment}
            onClick={createPurchasePayment}
          >
            Add Payment
          </Button>
        </Flex>
      }
    >
      <Box css={{ padding: '28px 24px' }}>
        <Text css={{ mt: 28 }}>Payment Details</Text>
        <Flex direction="column" gutterY="5" css={{ mt: 32 }}>
          <Input
            label="Amount"
            size="lg"
            type="number"
            required
            {...register('amount')}
          />
          <TypeAheadSelect
            required
            label="Bank"
            placeholder="Select bank"
            options={accounts?.financialAccounts?.data ?? []}
            valueKey="id"
            labelKey="name"
            defaultValue={purchase.bankAccount?.id}
            renderOption={(value: BankAccount) => (
              <Flex align="center" gutterX="2" css={{ py: '1.2rem' }}>
                <Text size="xs">
                  {value.title}({value.name ?? value.routingNumber})
                </Text>
              </Flex>
            )}
            renderValue={(value: BankAccount) => (
              <Flex align="center" gutterX="2" css={{ py: '1.2rem' }}>
                <Text size="xs">
                  {value.title}({value.name ?? value.routingNumber})
                </Text>
              </Flex>
            )}
            onChange={(value) =>
              setInputValue('bankAccountId', value as string)
            }
          />

          <DateInput
            required
            prepend={<HiCalendar color="#ABB3B9" />}
            append={<HiChevronDown size="1.9rem" color="#ABB3B9" />}
            label="Date"
            defaultValue={new Date()}
            placeholder="Select date"
            dateFormat="MMM dd, yyyy"
            onChange={(e) => {
              setInputValue('date', new Date(e.target.value))
            }}
          />

          <Textarea
            label="Comment"
            placeholder="Add a comment ..."
            {...register('comment')}
            error={errors.comment}
          />
        </Flex>
      </Box>
    </Drawer>
  )
}

export default RecordExpensePayment
