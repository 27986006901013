import { Route, Routes } from 'react-router-dom'
import { globalStyles } from 'stitches/stitches.config'

import ForgotPassword from 'pages/auth/forgot-password/forgot-password'
import Login from 'pages/auth/login/login'
import Register from 'pages/auth/register/register'
import ResetPassword from 'pages/auth/reset-password/reset-password'
import VerifyEmail from 'pages/auth/verify-email/verify-email'
import Dashboard from 'pages/dashboard/dashboard'
import Onboarding from 'pages/onboarding/onboarding'
import ReportsPreview from 'pages/reports/screens/reports-preview'

function App() {
  globalStyles()

  return (
    <>
      <Routes>
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/reports/:id" element={<ReportsPreview />} />
        <Route path="/verify-email/:id" element={<VerifyEmail />} />
        <Route path="/register" element={<Register />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </>
  )
}

export default App
