import Button from 'components/button/button'
import DateInput from 'components/date-input/date-input'
import Drawer from 'components/drawer/drawer'
import { Flex, Stack } from 'components/layout'
import { Select } from 'components/select/select'
import { Table } from 'components/table/table'
import { StyledEmptyIcon } from 'components/table/table.styles'
import { ITableColumn } from 'components/table/table.types'
import { Text } from 'components/text/text'
import { endOfMonth, format } from 'date-fns'
import {
  GlAccount,
  JournalEntry,
  NameAmountBreakdown,
  User,
  useJournalEntriesQuery,
  useJournalEntryTypesQuery,
} from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import { useAppProvider } from 'providers/app-provider'
import { HiCalendar } from 'react-icons/hi'
import { formatMoney, snakeCaseToWord } from 'utils/helpers'
import { TableContainer, TextWithHover } from './journals.styles'
import TypeAheadSelect from 'components/type-ahead-select/type-ahead-select'

interface JournalDetailProps {
  visible: boolean
  onClose: () => void
  onSuccess?: (values?: any) => void
  onUser?: (user: User) => void
  glAcccount: GlAccount | NameAmountBreakdown | any
}

function getTextColor(amount: number, isTotal?: boolean) {
  if (isTotal) {
    return '#171717'
  }
  return amount > 0 ? '$primary' : '$gray'
}

// function lastYearDate() {
//   const now = new Date()
//   now.setFullYear(now.getFullYear() - 1)
//   return now
// }

function getTotal(entries: JournalEntry[]) {
  const totalCredit = entries.reduce((acc, entry) => acc + entry.credit, 0)
  const totalDebit = entries.reduce((acc, entry) => acc + entry.debit, 0)
  return {
    glAccount: {
      name: 'Total',
    },
    credit: totalCredit,
    debit: totalDebit,
    id: 'total',
  }
}

function getDataSource(data: any[]) {
  if (data.length > 0) {
    return [...data, getTotal(data as any) as any]
  }
  return data
}

const JournalDetail: React.FC<JournalDetailProps> = ({
  visible,
  onClose,
  glAcccount,
  onUser,
}) => {
  const { values, setInputValue } = useForm({
    fields: {
      startDate: new Date(),
      endDate: endOfMonth(new Date()),
    },
  })
  const { organisation } = useAppProvider()
  const currency = organisation?.currency.symbol

  const glAccountId =
    typeof glAcccount?.glAccountId === 'undefined'
      ? glAcccount.id
      : glAcccount.glAccountId

  const [{ data: journals, fetching }] = useJournalEntriesQuery({
    requestPolicy: 'network-only',
    variables: {
      pagination: {
        per: 10,
        page: 1,
      },
      glAccountId: glAccountId,
      startDate: values.startDate,
      endDate: values.endDate,
    },
    pause: !glAccountId,
  })

  const [{ data: journalEntryTypes }] = useJournalEntryTypesQuery({})

  const entryTypes = journalEntryTypes?.journalEntryTypes?.map((entryType) => {
    return { label: snakeCaseToWord(entryType), value: entryType }
  })

  const data = journals?.journalEntries?.data ?? []

  const columns: ITableColumn<JournalEntry>[] = [
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'id',
      render: (value, entry) => {
        if (value === 'total') {
          return (
            <Text size="xs" color="#171717" weight={'bold'}>
              {entry.glAccount?.name}
            </Text>
          )
        }
        return (
          <Text size="xs" color="$primary">
            {format(new Date(entry.journal?.effectiveDate), 'dd MMM, yyyy')}
          </Text>
        )
      },
    },
    {
      key: 'account',
      title: 'Account',
      dataIndex: 'id',
      render: (id, entry) => {
        if (id !== 'total') {
          return (
            <Stack spacing={5}>
              <Text size="xs" color="#171717" weight="bold">
                ID {entry.id} - {entry.journal?.description}
              </Text>
              <TextWithHover
                size="xs"
                color="#848C96"
                css={{ cursor: 'pointer' }}
                onClick={() => onUser?.(entry.journal.createdBy)}
              >
                Entry by {entry.journal?.createdBy.firstName}{' '}
                {entry.journal?.createdBy.lastName}
              </TextWithHover>
            </Stack>
          )
        }
      },
    },
    {
      key: 'debit',
      title: 'Debit',
      dataIndex: 'debit',
      render: (amount, { id }) => (
        <Text
          size="xs"
          color={getTextColor(amount, id === 'total')}
          weight={id === 'total' ? 'bold' : 'semi'}
        >
          {id === 'total' ? formatMoney(amount, currency) : formatMoney(amount)}
        </Text>
      ),
    },
    {
      key: 'credit',
      title: 'Credit',
      dataIndex: 'credit',
      render: (amount, { id }) => (
        <Text
          size="xs"
          color={getTextColor(amount, id === 'total')}
          weight={id === 'total' ? 'bold' : 'semi'}
        >
          {id === 'total' ? formatMoney(amount, currency) : formatMoney(amount)}
        </Text>
      ),
    },
  ]

  return (
    <Drawer
      title={
        glAcccount.name ?? glAcccount?.accountName ?? glAcccount?.description
      }
      visible={visible}
      onClose={onClose}
      size="large"
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onClose}>
            Close
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="5" css={{ p: '$5' }}>
        <Flex gutter="4">
          <DateInput
            defaultValue={values.startDate}
            css={{ flexBasis: '30%' }}
            label="From"
            append={<HiCalendar color="#ABB3B9" />}
            placeholder="DD/MM/YYYY"
            onChange={(e) => {
              setInputValue('startDate', new Date(e.target.value))
            }}
          />
          <DateInput
            defaultValue={values.endDate}
            css={{ flexBasis: '30%' }}
            label="To"
            append={<HiCalendar color="#ABB3B9" />}
            placeholder="DD/MM/YYYY"
            onChange={(e) => {
              setInputValue('endDate', new Date(e.target.value))
            }}
          />
          <TypeAheadSelect
            css={{ flexBasis: '40%' }}
            label="Journal Entry Type"
            placeholder="Choose type"
            options={entryTypes ?? []}
          />
        </Flex>
        <Stack css={{ mt: 20, mb: 20 }}>
          <Text size="xs" weight="bold">
            Summary {format(new Date(values.startDate), 'dd MMM, yyyy')} to{' '}
            {format(new Date(values.endDate), 'dd MMM, yyyy')}
          </Text>
          <TableContainer>
            <Table
              columns={columns}
              dataSource={getDataSource(data)}
              loading={fetching}
              emptyProps={{
                title: 'No entry data found for the date range',
                subtitle:
                  'Try a valid date range to view all entries for selected account',
                icon: <StyledEmptyIcon width="5.6rem" height="5.6rem" />,
              }}
              pagination={{
                totalCount: data.length,
                currentPage: 1,
                perPage: 5,
                onPageChange: () => null,
                onPaginationClick(type) {
                  console.log(type)
                },
              }}
            />
          </TableContainer>
        </Stack>
      </Flex>
    </Drawer>
  )
}

export default JournalDetail
