import Flex from 'components/layout/flex'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import Text from 'components/text/text'
import { NameAmountBreakdown } from 'generated/__generated_graphql'
import JournalDetail from 'pages/accounting/journals/journal-detail'
import LogsAccordion from 'pages/settings/screens/organisation/components/logs/logs-accordion'
import React from 'react'
import { formatMoney } from 'utils/helpers'

export interface AccordionTableData {
  id: string
  name: string
  total: string
  accountCategoryBreakdown: {
    accountType: string
    // data: {
    //   id: string
    //   name: string
    //   amount: string
    // }[]
    data: NameAmountBreakdown[]
  }[]
}

export const AccordionTable = ({
  data,
  currency,
}: {
  data: AccordionTableData[]
  currency: string
}) => {
  const tableData: AccordionTableData[] = data
  const [glAcccount, setGlAcccount] =
    React.useState<NameAmountBreakdown | null>(null)
  const [visible, setVisible] = React.useState(false)

  const columns: ITableColumn<AccordionTableData>[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      render: (value, data) => (
        <Flex css={{ width: '100%' }}>
          <LogsAccordion data={data} currency={currency}>
            <Flex direction="column" css={{ mr: '3.8rem' }}>
              {data.accountCategoryBreakdown.map((item) => (
                <>
                  <Flex
                    key={item.accountType}
                    justify="between"
                    css={{
                      backgroundColor: '$background',
                      p: '$2',
                      mt: '$5',
                    }}
                  >
                    <Text weight="bold" size="xs">
                      {item.accountType}
                    </Text>
                  </Flex>

                  <Flex
                    stretchx
                    direction="column"
                    css={{
                      p: '$2',
                    }}
                  >
                    {item.data.map((breakdown) => (
                      <Flex
                        key={breakdown.glAccountId}
                        onClick={() => {
                          setVisible(true)
                          setGlAcccount(breakdown)
                        }}
                        justify="between"
                        css={{
                          py: '$2',
                          cursor: 'pointer',
                          borderBottom: '1px solid $border',
                          transition: 'background .3s ease-in',
                          '&:hover': {
                            backgroundColor: '$highlightBg',
                          },
                        }}
                      >
                        <Text color="$secondary" size="xs">
                          {breakdown.name}
                        </Text>
                        <Text size="xs">
                          {formatMoney(breakdown.amount, currency)}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                </>
              ))}
            </Flex>
          </LogsAccordion>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Table hideHead columns={columns} dataSource={tableData} />
      {glAcccount?.glAccountId && (
        <JournalDetail
          visible={visible}
          onClose={() => setVisible(false)}
          glAcccount={glAcccount}
        />
      )}
    </>
  )
}
