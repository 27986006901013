import { Flex } from 'components/layout'
import Loader from 'components/loader/loader'

function PageLoader({ type }: { type?: string }) {
  return (
    <Flex
      align="center"
      justify="center"
      css={{
        height: '100vh',
      }}
    >
      <Loader type={type} />
    </Flex>
  )
}

export default PageLoader
