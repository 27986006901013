import Avatar from 'components/avatar/avatar'
import Button from 'components/button/button'
import { CollapsibleSection } from 'components/drawer/collapsible-section'
import { Drawer } from 'components/drawer/drawer'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import PhoneNumberInput from 'components/phone-number-input'
import Text from 'components/text/text'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select/type-ahead-select'
import { motion } from 'framer-motion'
import {
  CustomerContact,
  UpdateCustomerInput,
  useBanksQuery,
  useCountriesQuery,
  useCurrenciesQuery,
  useUpdateCustomerMutation,
} from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import React, { useState } from 'react'
import { HiPlus, HiUserAdd } from 'react-icons/hi'
import { countries } from 'utils/countries'
import { extractGraphqlErrors, parsePhoneNumber } from 'utils/helpers'
import { CleanCustomer } from './contacts-table'

interface UpdateContactsDrawerProps {
  visible: boolean
  onClose: () => void
  customer: CleanCustomer
}

type CleanCustomerContact = Exclude<
  CleanCustomer['customerContacts'],
  null | undefined
>[number]

export const UpdateContactsDrawer: React.FC<UpdateContactsDrawerProps> = (
  props
) => {
  const { visible, onClose, customer } = props
  const {
    address,
    bankAccount,
    city,
    country,
    customerContacts: contacts,
    id,
    name,
    tin,
    zipCode,
  } = customer

  const [submitButtonPressed, setSubmitButtonPressed] = useState(false)

  const cleanContacts = contacts?.map((contact) => contact) ?? []
  const [customerContacts, setCustomerContacts] =
    useState<CleanCustomerContact[]>(cleanContacts)

  const [selectedCountry, setSelectedCountry] = useState<string>(country ?? '')
  const toast = useToast()

  const { register, values, setInputValue, errors, formIsComplete } = useForm({
    fields: {
      name: name,
      tin: tin,
      country: country,
      address: address,
      city: city,
      zipCode: zipCode,
      customerName: customer.name,
      customerEmail: cleanContacts[0]?.email,
      phone: cleanContacts[0]?.phone,
      accountNumber: bankAccount?.accountNumber,
      routingNumber: bankAccount?.routingNumber,
      bankName: bankAccount?.name,
      currencyId: bankAccount?.currencyId,
    },
    optional: [
      'bankName',
      'accountNumber',
      'routingNumber',
      'currencyId',
      'customerName',
      'customerEmail',
      'phone',
      'tin',
    ],
  })
  const {
    accountNumber,
    bankName,
    currencyId,
    customerName,
    customerEmail,
    phone,
  } = values

  const [payload, setPayload] = useState<UpdateCustomerInput>({
    id: id,
    name: values.name ? values.name : null,
    tin: values.tin ? values.tin : null,
    country: values.country ? values.country : null,
    address: values.address ? values.address : null,
    city: values.city ? values.city : null,
    zipCode: values.zipCode ? values.zipCode : null,
    bankAccount: {
      id: bankAccount?.id ? bankAccount?.id : null,
      accountNumber: accountNumber ? accountNumber : null,
      name: bankName ? bankName : null,
      currencyId: currencyId ? currencyId : null,
    },
    customerContacts: [
      {
        id: cleanContacts[0]?.id ? cleanContacts[0]?.id : null,
        name: customerName ? customerName : null,
        email: customerEmail ? customerEmail : null,
        phone: phone ? phone : null,
      },
    ],
  })

  const [{ data: countriesData }] = useCountriesQuery({
    variables: {
      all: true,
    },
  })
  const countriesMap =
    countriesData?.countries.map((country) => {
      return {
        label: `${country.emojiFlag} ${country.name}`,
        value: country.code,
      }
    }) ?? []

  function notify(error?: string, status?: 'success' | 'error') {
    toast({
      content: error ?? 'something went wrong',
      status: status,
    })
  }

  const [{ fetching }, submit] = useUpdateCustomerMutation()

  function handleAddCustomerContacts() {
    return setCustomerContacts([
      ...customerContacts,
      {
        id: '',
        name: '',
        email: '',
        phone: '',
      },
    ])
  }

  async function handlePayload() {
    const payload = {
      id: id,
      name: values.name,
      tin: values.tin ? values.tin : null,
      country: values.country,
      address: values.address,
      city: values.city,
      zipCode: values.zipCode,
      ...(values.accountNumber && {
        bankAccount: {
          id: bankAccount?.id,
          accountNumber: values.accountNumber,
          routingNumber: values.routingNumber,
          name: values.bankName,
          currencyId: values.currencyId,
        },
      }),
      ...(customerContacts.length && {
        customerContacts: [
          {
            id: cleanContacts[0]?.id,
            name: values.customerName,
            email: values.customerEmail,
            phone: values.phone,
          },
        ],
      }),
    }

    setPayload(payload)
    return payload
  }

  async function handleUpdateContact() {
    try {
      setSubmitButtonPressed(true)
      const payload = await handlePayload()
      // remove empty customerContacts
      payload.customerContacts = payload?.customerContacts?.filter(
        (contact: CustomerContact | any) =>
          contact.name || contact.email || contact.phone
      )

      const response = await submit({ input: payload as UpdateCustomerInput })
      const error = extractGraphqlErrors(response, 'updateCustomer')

      if (error) {
        notify(error, 'error')
        return
      }
      notify('Contact updated successfully', 'success')
      onClose()
    } catch (error) {
      notify(error as string, 'error')
    }
  }

  const [{ data: banksData }] = useBanksQuery({
    variables: {
      countryCode: selectedCountry,
    },
    pause: !selectedCountry,
  })
  const banksMap =
    banksData?.banks.map((bank) => {
      return {
        label: bank.name,
        value: bank.name,
      }
    }) ?? []

  const [{ data: currenciesData }] = useCurrenciesQuery()

  const currenciesMap =
    currenciesData?.currencies.map((currency) => {
      return {
        label: currency.name,
        value: currency.id,
        symbol: currency.symbol,
      }
    }) ?? []

  return (
    <Drawer
      title="Update Contact"
      titleIcon={<HiUserAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="md"
            isLoading={fetching}
            disabled={!formIsComplete}
            onClick={handleUpdateContact}
          >
            Update Customer
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="3">
        <CollapsibleSection borders title="Organisation Information">
          <Flex direction="column" gutter="4">
            <Input
              required
              label="Organisation name"
              placeholder="Enter your organisation's name"
              {...register('name')}
              error={errors.name}
            />
            <Input
              label="TIN/EIN"
              placeholder="Enter your organisation's TIN/EIN"
              {...register('tin')}
              error={errors.tin}
            />
            {/* <Select
              search
              label="Location"
              placeholder="Choose a country"
              options={countriesMap}
              name="country"
              onChange={(value) => {
                setInputValue('country', value as string)
                setSelectedCountry(value as string)
              }}
              error={errors.country as string}
              defaultValue={values.country}
            /> */}
            <TypeAheadSelect
              required
              label="Country"
              placeholder="Search for a country"
              options={countriesMap}
              value={values.country}
              onChange={(value) => {
                setInputValue('country', value as string)
                setSelectedCountry(value as string)
              }}
              error={errors.country}
            />
            <Input
              required
              label="Address"
              placeholder="Enter address"
              {...register('address')}
              error={errors.address}
            />
            <Input
              required
              label="City"
              placeholder="Enter city"
              {...register('city')}
              error={errors.city}
            />
            <Input
              required
              label="Zip code"
              placeholder="Enter zip code"
              {...register('zipCode')}
              error={errors.zipCode}
            />
          </Flex>
        </CollapsibleSection>

        <CollapsibleSection borders title="Banking Details" collapse>
          <Flex direction="column" gutter="4">
            {!banksMap.length ? (
              <Input
                label="Routing Number"
                placeholder="AB1099GHQET"
                {...register('routingNumber')}
                error={errors.routingNumber}
                defaultValue={values.routingNumber as string}
              />
            ) : (
              // <Select
              //   search
              //   label="Bank"
              //   placeholder="Select Bank"
              //   options={banksMap}
              //   onChange={(value) => setInputValue('bankName', value as string)}
              //   error={errors.bankName as string}
              //   defaultValue={values.bankName}
              // />
              <TypeAheadSelect
                label="Bank"
                placeholder="Select bank"
                options={banksMap}
                value={values.bankName}
                onChange={(value) => setInputValue('bankName', value as string)}
                error={errors.bankName}
              />
            )}
            <Input
              label="Account Number"
              placeholder="AB1099GHQET"
              {...register('accountNumber')}
              error={errors.accountNumber}
              defaultValue={values.accountNumber}
            />
            {/* <Select
              label="Currency"
              placeholder="Select Currency"
              options={currenciesMap}
              renderValue={(label: string, option) => (
                <Flex align="center" gutter="2">
                  <Avatar size="medium" title={option.symbol} />
                  <Text size="xs">{label}</Text>
                </Flex>
              )}
              onChange={(value) => setInputValue('currencyId', value as string)}
              error={errors.currencyId as string}
              defaultValue={values.currencyId}
            /> */}
            <TypeAheadSelect
              label="Currency"
              placeholder="Select currency"
              options={currenciesMap}
              value={values.currencyId}
              onChange={(value) => setInputValue('currencyId', value as string)}
              error={errors.currencyId}
              renderValue={(value: (typeof currenciesMap)[number]) => (
                <Flex align="center" gutter="2">
                  <Avatar size="medium" title={value.symbol} />
                  <Text size="xs">{value.label}</Text>
                </Flex>
              )}
            />
          </Flex>
        </CollapsibleSection>

        <CollapsibleSection title="User Contacts" collapse>
          {customerContacts.map((contact, index) => (
            <CustomerContacts
              key={index}
              payload={payload}
              submitButtonPressed={submitButtonPressed}
              setSubmitButtonPressed={setSubmitButtonPressed}
              contactData={contact}
            />
          ))}
          <Button
            disabled={!formIsComplete}
            onClick={handleAddCustomerContacts}
          >
            <HiPlus /> Add another Contact
          </Button>
        </CollapsibleSection>
      </Flex>
    </Drawer>
  )
}

type CustomerContactsProps = {
  payload: UpdateCustomerInput
  submitButtonPressed: boolean
  setSubmitButtonPressed: (value: boolean) => void
  contactData?: CleanCustomerContact
}

function CustomerContacts({
  payload,
  submitButtonPressed,
  setSubmitButtonPressed,
  contactData,
}: CustomerContactsProps) {
  const { register, values, setInputValue, errors } = useForm({
    fields: {
      name: contactData?.name,
      email: contactData?.email,
      phone: contactData?.phone,
    },
  })

  React.useEffect(() => {
    if (submitButtonPressed) {
      handleUpdateContact()
    }
  }, [submitButtonPressed])

  function handleUpdateContact() {
    const emailExists = payload?.customerContacts?.find(
      (contact) => contact.email === values.email
    )

    if (!emailExists) {
      const newContact = {
        id: contactData?.id,
        name: values.name,
        email: values.email,
        phone: values.phone,
      }
      payload?.customerContacts?.push(newContact as CustomerContact)
    } else {
      const index = payload?.customerContacts?.findIndex(
        (contact) => contact.email === values.email
      )
      if (payload?.customerContacts) {
        payload.customerContacts[index as number] = {
          id: contactData?.id,
          name: values.name,
          email: values.email,
          phone: values.phone,
        } as CustomerContact
      }
    }
    setSubmitButtonPressed(false)
  }

  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 20,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
    >
      <Flex direction="column" gutter="4">
        <Input
          label="Name"
          placeholder="Jane Doe"
          {...register('name')}
          error={errors.name}
        />
        <Input
          label="Email"
          placeholder="someone@email.com"
          {...register('email')}
          error={errors.email}
        />
        <PhoneNumberInput
          label="Phone Number"
          required
          name="phone"
          onChange={(phoneNumberData) => {
            setInputValue(
              'phone',
              `${phoneNumberData.dialingCode} ${phoneNumberData.phoneNumber}`
            )
          }}
          error={errors.phone}
          countries={countries}
          defaultValue={parsePhoneNumber(values?.phone as string)}
        />
      </Flex>
    </motion.div>
  )
}
