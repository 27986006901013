import {
  addMonths,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  set,
  setYear,
  subMonths,
} from 'date-fns'
import { useState } from 'react'
import { VariantProps } from 'stitches/stitches.config'
import { isArray } from 'utils/assertions'
import { Day } from './calendar.styles'
import { DateSelectionValue, DateTuple, ICalendarProps } from './calendar.types'
import { months } from './calendar.utils'

function useCalendar({
  selectedDate,
  onSelect,
  minimumDate,
  maximumDate,
  defaultSelectedDate = new Date(),
  mode = 'single',
  onDateRangeSelect,
}: ICalendarProps) {
  // selection state - update with selection
  const [, setValue] = useState<DateSelectionValue>(defaultSelectedDate)
  // view state - update for nav etc
  const [_date, setDate] = useState<Date>(
    isArray(defaultSelectedDate)
      ? (defaultSelectedDate as DateTuple)[0]
      : (defaultSelectedDate as Date)
  )
  // range indicator state
  const [activeSelection, setActiveSelection] = useState<'from' | 'to'>('from')

  // const _selectedDate = selectedDate ?? _value
  const [_selectedDate, setSelectedDate] = useState<DateSelectionValue>([
    new Date(),
    new Date(),
  ])

  const month = getMonth(_date)
  const year = getYear(_date)
  const [newDateRange, setNewDateRange] = useState<[Date?, Date?]>([])

  function onDateSelect(date: Date) {
    if (mode === 'range' && Array.isArray(_selectedDate)) {
      const [start, end] = _selectedDate as DateTuple

      if (activeSelection === 'from') {
        const newRange = [start, end] as DateTuple
        newRange[0] = date
        const first = newRange[0]
        setNewDateRange([first])
        setSelectedDate([first, end!])
        setActiveSelection('to')
        return
      }

      if (activeSelection === 'to') {
        const newRange = [start, end] as DateTuple
        newRange[1] = date
        const second = newRange[1]
        if (isBefore(newDateRange[0] as Date, second)) {
          setNewDateRange([...(newDateRange as []), second])
          setSelectedDate([...(newDateRange as []), second])
        } else {
          setNewDateRange([second])
        }
        // console.log([...newDateRange, second])
        onDateRangeSelect?.([...(newDateRange as []), second])

        // onSelect ? onSelect(second) : setValue(second)
        setActiveSelection('from')
        return
      }

      return
    }

    if (selectedDate && onSelect) {
      onSelect(date)
      setDate(date)
      return
    }
    setDate(date)
    setValue(date)
  }

  function goToPreviousMonth() {
    setDate(subMonths(_date, 1))
  }

  function goToNextMonth() {
    setDate(addMonths(_date, 1))
  }

  function getDayVariant(date: Date): VariantProps<typeof Day>['variant'] {
    if (mode === 'range' && isArray(newDateRange)) {
      const [start, end] = newDateRange as DateTuple
      if (isSameDay(date, start) || isSameDay(date, end!)) {
        return 'selected'
      }

      if (start && end && isWithinInterval(date, { start, end })) {
        return 'inRange'
      }

      return
    }

    if (
      (minimumDate && isBefore(date, minimumDate)) ||
      (maximumDate && isAfter(date, maximumDate)) ||
      !isSameMonth(date, new Date(year, month))
    ) {
      return 'offset'
    }

    if (isSameDay(date, selectedDate as Date)) {
      return 'selected'
    }

    return 'default'
  }

  function goToMonth(month: string) {
    const index = months.findIndex((m) => m === month)
    const newDate = set(_date, { month: index })
    setDate(newDate)
    // onDateSelect(newDate);
  }

  function goToYear(year: number) {
    const newDate = setYear(_date, year)
    setDate(newDate)
    // onDateSelect(newDate);
  }

  return {
    month,
    year,
    goToNextMonth,
    goToPreviousMonth,
    onDateSelect,
    getDayVariant,
    goToMonth,
    goToYear,
    _selectedDate,
    activeSelection,
    setActiveSelection,
  }
}

export default useCalendar
