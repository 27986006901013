import DashboardImg from 'assets/images/dashboard-bg.png'
import { Flex } from 'components/layout'
import Text from 'components/text/text'
import { FaStar } from 'react-icons/fa'
import { StyledBackground } from '../auth.styles'

export default function AuthBackground() {
  return (
    <StyledBackground span={8}>
      <Flex className="bg" direction="column">
        <Text
          css={{
            fontFamily: '$space',
            fontSize: '2rem',
            color: '$black',
            pr: '2rem',
            mb: '3rem',
          }}
        >
          “Accounteer is flexible and powerful, it worked wonders to help us
          manage our finances and grow our business. we gain so much clarity and
          time, everyday!”
        </Text>
        <Flex align="center" gutter={1} css={{ mb: '6rem' }}>
          <FaStar color="#DCAA5C" size="1.5rem" />
          <FaStar color="#DCAA5C" size="1.5rem" />
          <FaStar color="#DCAA5C" size="1.5rem" />
          <FaStar color="#DCAA5C" size="1.5rem" />
          <FaStar color="#DCAA5C" size="1.5rem" />
          <Text size="xs" weight="bold">
            {' '}
            - Adeola Adeoti,{' '}
            <Text size="xxs" weight="regular">
              {' '}
              Founder @Kashin.money
            </Text>
          </Text>
        </Flex>
        <img src={DashboardImg} alt="" />
      </Flex>
    </StyledBackground>
  )
}
