import Button from 'components/button/button'
import Drawer from 'components/drawer/drawer'
import { Flex, Stack } from 'components/layout'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import { Text } from 'components/text/text'
import {
  JournalEntry,
  useJournalsByUserQuery,
} from 'generated/__generated_graphql'
import { formatMoney } from 'utils/helpers'
import { TableContainer } from './journals.styles'

interface UserJournalProps {
  visible: boolean
  onClose: () => void
  onSuccess?: (values?: any) => void
  user?: any
}

function getTextColor(amount: number, isTotal?: boolean) {
  if (isTotal) {
    return '#171717'
  }
  return amount > 0 ? '$primary' : '$gray'
}

function getTotal(entries: JournalEntry[]) {
  const totalCredit = entries.reduce((acc, entry) => acc + entry.credit, 0)
  const totalDebit = entries.reduce((acc, entry) => acc + entry.debit, 0)
  return {
    glAccount: {
      name: 'Total',
    },
    credit: totalCredit,
    debit: totalDebit,
    id: 'total',
  }
}

const UserJournal: React.FC<UserJournalProps> = ({
  visible,
  onClose,
  user,
}) => {
  const [{ data: journals, fetching }] = useJournalsByUserQuery({
    requestPolicy: 'network-only',
    variables: {
      pagination: {},
      userId: user?.id,
    },
  })

  const data: any[] = []
  journals?.journalsByUser?.data.forEach((journal) =>
    data.push(...journal.journalEntries)
  )

  const columns: ITableColumn<JournalEntry>[] = [
    {
      key: 'account',
      title: 'Account',
      dataIndex: 'glAccount',
      render: (account, { id }) => {
        if (id === 'total') {
          return (
            <Text size="xs" color={'#171717'} weight={'bold'}>
              {account?.name}
            </Text>
          )
        }
        return (
          <Text size="xs" color={'$primary'} weight={'semi'}>
            {account?.name}
          </Text>
        )
      },
    },
    {
      key: 'credit',
      title: 'Credit',
      dataIndex: 'credit',
      render: (amount, { id }) => (
        <Text
          size="xs"
          color={getTextColor(amount, id === 'total')}
          weight={id === 'total' ? 'bold' : 'semi'}
        >
          {formatMoney(amount)}
        </Text>
      ),
    },
    {
      key: 'debit',
      title: 'Debit',
      dataIndex: 'debit',
      render: (amount, { id }) => (
        <Text
          size="xs"
          color={getTextColor(amount, id === 'total')}
          weight={id === 'total' ? 'bold' : 'semi'}
        >
          {formatMoney(amount)}
        </Text>
      ),
    },
  ]

  return (
    <Drawer
      title={`Entry by ${user?.firstName} ${user?.lastName}`}
      visible={visible}
      onClose={onClose}
      size="large"
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onClose}>
            Close
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="5" css={{ p: '$5' }}>
        <Stack css={{ mt: 20, mb: 20 }}>
          <Text size="xs" weight="bold">
            Entry by {user?.firstName} {user?.lastName}
          </Text>
          <TableContainer>
            <Table
              columns={columns}
              dataSource={[...data, getTotal(data as any) as any]}
              loading={fetching}
            />
          </TableContainer>
        </Stack>
      </Flex>
    </Drawer>
  )
}

export default UserJournal
