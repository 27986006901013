import Flex from 'components/layout/flex'
import Loader from 'components/loader/loader'
import PageHeader from 'components/page-header/page-header'
import { Tabs } from 'components/tabs/tabs'
import { useTaxesQuery } from 'generated/__generated_graphql'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import { StyledTableContainer } from 'pages/settings/settings.styles'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import AddTaxRateDrawer from './components/add-tax-rate-drawer'
import { TaxRatesTable } from './components/tax-rates-table'

export const TaxRatesPage = () => {
  const [{ data: taxesQueryQueryData }] = useTaxesQuery()
  const shouldOpenDrawer = useLocation().search.includes('openDrawer=true')

  const [showTaxRatesDrawer, setShowTaxRatesDrawer] =
    useState<boolean>(shouldOpenDrawer)
  const { data: taxes } = taxesQueryQueryData?.taxes ?? {}

  if (!taxes)
    return (
      <Flex
        direction={'column'}
        justify="center"
        align={'center'}
        css={{ width: '100%', height: '100vh' }}
      >
        <Loader size={'lg'} />
      </Flex>
    )

  return (
    <>
      <PageHeader title="Tax Rates" />
      <StyledLayoutContent>
        {/* <PageBanner
          title="View and manage your tax rates"
          description="Tax rates are the percentage of tax you charge on your sales."
          actionText="Learn more"
          bgSvg={background}
          onClick={() =>
            window.location.assign(
              `https://drive.google.com/file/d/1Pv5bzpjU1Tr8z_fq2TrXjYOLO1ljS8Z4/view?pli=1`
            )
          }
        /> */}
        <Flex direction="column" gutter="5">
          <StyledTableContainer fullWidth>
            <Tabs
              activeKey="1"
              tabs={[
                {
                  key: '1',
                  title: 'Tax rates',
                  content: (
                    <TaxRatesTable
                      setShowTaxRatesDrawer={setShowTaxRatesDrawer}
                      taxes={taxes}
                    />
                  ),
                },
                // { key: '2', title: 'Tax Allocation', content: <Allocation /> },
              ]}
            />
          </StyledTableContainer>
        </Flex>
      </StyledLayoutContent>

      <AddTaxRateDrawer
        visible={showTaxRatesDrawer}
        onClose={() => setShowTaxRatesDrawer(false)}
      />
    </>
  )
}
