// TODO: add support for multiple files and drag and drop
import type * as Stitches from '@stitches/react'
import Button from 'components/button/button'
import { InputError } from 'components/input'
import { Box } from 'components/layout'
import Flex from 'components/layout/flex'
import Loader from 'components/loader/loader'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import { useUploadFileMutation } from 'generated/__generated_graphql'
import React from 'react'
import { HiOutlinePlus } from 'react-icons/hi'
import { MdImage } from 'react-icons/md'
import { styled } from 'stitches/stitches.config'
import { extractGraphqlErrors } from 'utils/helpers'
import {
  StyledButton,
  StyledEmptyIcon,
  StyledFileInput,
  StyledFileInputLabel,
  StyledFileUpload,
  StyledFileUploadContainer,
} from './file-upload.styles'

type FileUploadProps = Stitches.VariantProps<typeof StyledFileUpload> & {
  error?: string
  onChange?: (uploadedFiles: UploadedFile[]) => void
  defaultValue?: any[]
  renderUploadedFile?: (
    uploadedFile: UploadedFile,
    uploadInputOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  ) => React.ReactNode
  children?: (params: {
    uploadedFiles: UploadedFile[]
    uploadInputOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    actionStatus: ActionStatus
    reset: React.Dispatch<React.SetStateAction<UploadedFile[]>>
  }) => React.ReactNode
  accept?: string
}

export type UploadedFile = {
  id: string | undefined
  name: string | null | undefined
  extension: string | undefined
  url: string | null | undefined
}

export type ActionStatus = 'idle' | 'loading' | 'success' | 'fail'

export const FileUpload = React.forwardRef<HTMLDivElement, FileUploadProps>(
  (props, ref) => {
    const {
      children,
      error,
      onChange,
      defaultValue,
      renderUploadedFile,
      accept,
      ...fileUploadProps
    } = props

    const toast = useToast()
    const [actionStatus, setActionStatus] = React.useState<ActionStatus>(
      defaultValue ? 'success' : 'idle'
    )
    const [uploadedFiles, setUploadedFiles] = React.useState<UploadedFile[]>(
      defaultValue ?? []
    )
    const [uploadError, setUploadError] = React.useState<string | undefined>(
      error
    )
    const [, upload] = useUploadFileMutation()

    function notify(error?: string, status?: 'success' | 'error') {
      toast({
        content: error ?? 'something went wrong',
        status: status,
      })
    }

    async function handleUpload(file: File) {
      setActionStatus('loading')
      setUploadError('')

      const reader = new FileReader()
      reader.onload = async function () {
        try {
          const response = await upload({
            input: {
              data: String(reader.result),
              extension: file.type.split('/')[1],
              name: file.name,
            },
          })
          const error = extractGraphqlErrors(response, 'uploadFile')

          if (error) {
            setActionStatus('success')
            setUploadError(error)
            return
          }

          const result = [
            ...uploadedFiles,
            {
              id: response.data?.uploadFile?.upload?.id,
              name: response.data?.uploadFile?.upload?.name,
              extension: response.data?.uploadFile?.upload?.extension,
              url: response.data?.uploadFile?.upload?.url,
            },
          ]
          setUploadedFiles(result)
          onChange?.(result)
          setActionStatus('success')
        } catch (error) {
          setActionStatus('fail')
          notify(
            'An error occurred while picking file from your computer',
            'error'
          )
        }
      }

      reader.readAsDataURL(file)
    }

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
      const files = e.target.files && Array.from(e.target.files)
      if (!files) return

      files.forEach((file) => handleUpload(file))
    }

    return (
      <StyledFileUpload
        ref={ref}
        {...fileUploadProps}
        border={
          uploadedFiles.length > 0 && renderUploadedFile
            ? 'none'
            : fileUploadProps.border
        }
      >
        {children ? (
          children({
            uploadedFiles,
            uploadInputOnChange: handleOnChange,
            actionStatus,
            reset: setUploadedFiles,
          })
        ) : (
          <>
            {!(uploadedFiles.length > 0 && !!renderUploadedFile) && (
              <StyledFileInput
                name="upload-file-input"
                id="upload-file-input"
                onChange={handleOnChange}
                type="file"
                accept={accept}
              />
            )}

            <StyledFileUploadContainer
              gutterY="6"
              padded={!(uploadedFiles.length > 0 && !!renderUploadedFile)}
            >
              {actionStatus === 'idle' && (
                <StyledFileInputLabel htmlFor="upload-file-input">
                  <UploadFilePlaceholder />
                </StyledFileInputLabel>
              )}

              {actionStatus === 'loading' && (
                <Flex
                  direction="column"
                  align="center"
                  gutter="3"
                  css={{ p: '$6' }}
                >
                  <Loader size="md" />
                  <Text
                    color="$secondary"
                    variant="h3"
                    size="xs"
                    align="center"
                  >
                    Uploading file(s) ...
                  </Text>
                </Flex>
              )}

              {actionStatus === 'success' &&
                (renderUploadedFile ? (
                  uploadedFiles.map((uploadedFile: UploadedFile) => {
                    return renderUploadedFile(uploadedFile, handleOnChange)
                  })
                ) : (
                  <Flex stretchx direction="column" gutter={8}>
                    <Flex align="center" justify="between">
                      <Flex direction="column" gutter={1}>
                        <Text color="$primary" size="sm">
                          Uploads
                        </Text>
                        <Text color="$secondary" size="xxs">
                          {uploadedFiles.length} images
                        </Text>
                      </Flex>
                      <Button
                        as={'label'}
                        // @ts-ignore
                        htmlFor="upload-file-input"
                        // onClick={() => {
                        //   inputRef.current?.click()
                        // }}
                        prepend={<HiOutlinePlus color="#fff" />}
                        size="md"
                      >
                        Add file(s)
                      </Button>
                    </Flex>
                    <Flex
                      css={{
                        overflowX: 'scroll',
                        width: '100%',
                      }}
                    >
                      <Flex align="center" gutter="3">
                        {uploadedFiles.map((file: UploadedFile) => {
                          return <UploadPreview key={file.id} file={file} />
                        })}
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
            </StyledFileUploadContainer>

            {uploadError && (
              <InputError css={{ marginTop: 8 }}>{uploadError}</InputError>
            )}
          </>
        )}
      </StyledFileUpload>
    )
  }
)

FileUpload.displayName = 'FileUpload'

function UploadPreview({ file }: { file: any }) {
  const StyledBox = styled(Box, {
    zIndex: 1,
    width: 120,
    height: 150,
    boxShadow: '$subtle',
    backgroundColor: '$background',
    borderRadius: '$3',
    overflow: 'hidden',
  })

  const StyledImage = styled(Flex, {
    width: '100%',
    height: '65%',
    backgroundColor: '$background',
    backgroundSize: 'cover',
  })

  const StyledDetails = styled(Flex, {
    width: '100%',
    height: '35%',
    backgroundColor: '$white',
    p: '$2',
  })

  return (
    <StyledBox onClick={() => console.log('asa')}>
      <StyledImage align="center" justify="center">
        <MdImage color="#ABB3B9" size={24} />
      </StyledImage>
      <StyledDetails direction="column">
        <Text color="$primary" size="xxs" css={{ fontSize: '$1' }}>
          {file.name.slice(0, 10)}...
        </Text>
        <Text color="$secondary" size="xxs" css={{ fontSize: '.9rem' }}>
          {file.extension}
        </Text>
      </StyledDetails>
    </StyledBox>
  )
}

export const UploadFilePlaceholder: React.FC = () => {
  return (
    <>
      <Flex direction="column" align="center" justify="center" gutterY="5">
        <StyledEmptyIcon />
        <Flex direction="column" align="center" justify="center" gutterY="3">
          <Text
            color="$primary"
            variant="h3"
            size="sm"
            align="center"
            weight="bold"
          >
            Click here to upload file
          </Text>
          <Text color="$secondary" size="xxs" align="center">
            PDF, XLS, CSV, PNG, JPEG, DOC, DOCX (smaller than 10MB)
          </Text>
        </Flex>
      </Flex>

      <StyledButton>
        <HiOutlinePlus color="#fff" />
        Choose File
      </StyledButton>
    </>
  )
}
