import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Box } from 'components/layout'
import { Bar } from 'react-chartjs-2'
import { formatMoney } from 'utils/helpers'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

function BarChart({
  labels,
  dataA,
  dataB,
  dataC,
  currency,
  isPreview,
  label,
}: {
  labels: string[]
  dataA: number[]
  dataB?: number[]
  dataC?: number[]
  currency: string
  isPreview?: boolean
  label?: string
}) {
  const getOrCreateTooltip = (chart: any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div')

    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.style.background = '#fff'
      tooltipEl.style.borderRadius = '3px'
      tooltipEl.style.border = '1px solid #EFF0F7'
      tooltipEl.style.boxShadow = '0px 1px 2px rgba(0, 0, 0, 0.04)'
      tooltipEl.style.color = '#848C96'
      tooltipEl.style.opacity = 1
      tooltipEl.style.pointerEvents = 'none'
      tooltipEl.style.position = 'absolute'
      tooltipEl.style.transform = 'translate(-50%, 0)'
      tooltipEl.style.transition = 'all .1s ease'

      const table = document.createElement('table')
      table.style.margin = '0px'

      tooltipEl.appendChild(table)
      chart.canvas.parentNode.appendChild(tooltipEl)
    }

    return tooltipEl
  }

  const externalTooltipHandler = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context
    const tooltipEl = getOrCreateTooltip(chart)

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0
      return
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || []
      const bodyLines = tooltip.body.map((b: any) => b.lines)

      const tableHead = document.createElement('thead')

      titleLines.forEach((title: any) => {
        const tr = document.createElement('tr')
        tr.style.borderWidth = '0'
        tr.style.textAlign = 'left'
        tr.style.color = '#000'

        const th = document.createElement('th')
        th.style.borderWidth = '0'
        th.style.paddingBottom = '8px'
        const text = document.createTextNode(title)

        th.appendChild(text)
        tr.appendChild(th)
        tableHead.appendChild(tr)
      })

      const tableBody = document.createElement('tbody')
      bodyLines.forEach((body: any, i: any) => {
        const colors = tooltip.labelColors[i]

        const span = document.createElement('span')
        span.style.background = colors.backgroundColor
        span.style.borderColor = colors.borderColor
        span.style.borderWidth = '2px'
        span.style.borderRadius = '50%'
        span.style.marginRight = '10px'
        span.style.height = '10px'
        span.style.width = '10px'
        span.style.display = 'inline-block'

        const tr = document.createElement('tr')
        tr.style.backgroundColor = 'inherit'
        tr.style.borderWidth = '0'

        const td = document.createElement('td')
        td.style.borderWidth = '0'

        const label = body[0].split(':')[0]
        const amount = formatMoney(
          body[0].split(':')[1].replaceAll(',', ''),
          currency
        )

        const word = document.createTextNode(`${label}: ${amount}`)

        td.appendChild(span)
        td.appendChild(word)
        tr.appendChild(td)
        tableBody.appendChild(tr)
      })

      const tableRoot = tooltipEl.querySelector('table')

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove()
      }

      // Add new children
      tableRoot.appendChild(tableHead)
      tableRoot.appendChild(tableBody)
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1
    tooltipEl.style.left = positionX + tooltip.caretX + 90 + 'px'
    tooltipEl.style.top = positionY + tooltip.caretY + 'px'
    tooltipEl.style.font = tooltip.options.bodyFont.string
    tooltipEl.style.fontSize = tooltip.options.bodyFont.size + 'px'
    tooltipEl.style.padding =
      tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
  }

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: 'nearest' as const,
        external: externalTooltipHandler,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: '#848C96',
          font: {
            family: 'Accounteer Semi',
            size: 12,
          },
        },
      },
      y: {
        stacked: true,
        border: {
          display: false,
          dash: [4, 4],
        },
        grid: {
          tickLength: 0,
        },

        ticks: {
          color: '#848C96',
          maxTicksLimit: 7,

          callback: function (value: any) {
            if (value === 0) return '0'
            return formatMoney(value, currency)
          },
          font: {
            family: 'Accounteer Semi',
            size: 12,
          },
        },
      },
    },
  }

  const previewData = {
    labels,
    datasets: [
      {
        label,
        data: dataA,
        backgroundColor: '#3C85C4',
        barPercentage: 0.5,
      },
    ],
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Revenue',
        data: dataA,
        backgroundColor: '#3C85C4',
        barPercentage: 0.5,
      },
      {
        label: 'Expenses',
        data: dataB,
        backgroundColor: '#DBECFD',
        barPercentage: 0.5,
      },
      {
        label: 'Net Profit',
        data: dataC,
        backgroundColor: '#262626',
        barPercentage: 0.5,
      },
    ],
  }
  return (
    <Box css={{ width: '100%', height: '100%' }}>
      <Bar options={options} data={isPreview ? previewData : data} />
    </Box>
  )
}

export default BarChart
