import { Meta, StoryFn } from '@storybook/react'
import Calendar from 'components/calendar/calendar'

export default {
  title: 'Calendar',
  component: Calendar,
  argTypes: {},
} as Meta<typeof Calendar>

const Template: StoryFn<typeof Calendar> = (args) => <Calendar {...args} />
export const Default = Template.bind({})
