import { ReactComponent as EmptyContactIcon } from 'assets/svg/empty-contact.svg'
import { Avatar } from 'components/avatar/avatar'
import Button from 'components/button/button'
import Flex from 'components/layout/flex'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import {
  CustomersQuery,
  useCustomerExportMutation,
  useCustomersQuery,
} from 'generated/__generated_graphql'
import React from 'react'
import { HiOutlinePlus } from 'react-icons/hi'
import { useLocation } from 'react-router-dom'
import { extractGraphqlErrors, formatMoney } from 'utils/helpers'
import { AddContactsDrawer } from './add-contacts-drawer'
import ContactsTableAction from './contacts-table-action'
import { ImportCsvDrawer } from './import-csv-drawer'

type CustomerPagination = Exclude<CustomersQuery, undefined | null>['customers']
export type CleanCustomer = Exclude<
  CustomerPagination,
  undefined | null
>['data'][number]

export type ContactsTableData = {
  customer: CleanCustomer
  id: string
  name: string
  address: string
  amountPaid: string
  outstandingAmount: string
  totalIssued: string
  currency: string
}

const columns: ITableColumn<ContactsTableData>[] = [
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
    render: (value: ContactsTableData['name']) => (
      <Flex align="center" gutterX="2">
        <Avatar title={value} size="medium" />
        <Text size="xs">{value}</Text>
      </Flex>
    ),
  },
  {
    key: 'address',
    title: 'ADDRESS',
    dataIndex: 'address',
    render: (value, data) => (
      <Text size="xs">
        {value}, {data?.customer?.city}, {data?.customer?.country}
      </Text>
    ),
  },

  {
    key: 'toBePaid',
    title: 'total issued',
    dataIndex: 'totalIssued',
    render: (value, data) => (
      <Text size="xs">{formatMoney(value, data?.currency)}</Text>
    ),
  },
  {
    key: 'toBeReceived',
    title: 'Outstanding amount',
    dataIndex: 'outstandingAmount',
    render: (value, data) => (
      <Text size="xs">{formatMoney(value, data.currency)}</Text>
    ),
  },
  {
    key: 'action',
    title: '',
    dataIndex: 'id',
    render: (id, { customer }) => <ContactsTableAction customer={customer} />,
  },
]

export const ContactsTable = () => {
  const shouldOpenDrawer = useLocation().search.includes('openDrawer=true')
  const [showDrawer, setShowDrawer] = React.useState<boolean>(false)
  const [page, setPage] = React.useState(1)

  const toast = useToast()

  const [{ fetching: downloading }, download] = useCustomerExportMutation()
  const [showImportCsvDrawer, setShowImportCsvDrawer] =
    React.useState(shouldOpenDrawer)

  const [{ fetching, data: customersQueryData }, refetchCustomers] =
    useCustomersQuery({
      variables: {
        pagination: {
          page,
          per: 10,
        },
      },
    })
  const { data: customers } = customersQueryData?.customers ?? {}
  const pagination = customersQueryData?.customers?.pagination

  const customersMap =
    customers?.map<ContactsTableData>((customer) => ({
      customer,
      id: customer?.id,
      name: customer?.name,
      address: customer?.address,
      amountPaid: String(customer?.amountPaid),
      outstandingAmount: String(customer?.outstandingAmount),
      totalIssued: String(customer?.totalIssued),
      currency: customer?.currency?.symbol,
    })) ?? []

  function notify(error?: string, status?: 'success' | 'error') {
    toast({
      content: error ?? 'something went wrong',
      status: status,
    })
  }

  async function handleDownload() {
    try {
      const response = await download({
        input: {
          export: true,
        },
      })
      const error = extractGraphqlErrors(response, 'customerExport')

      if (error) {
        notify(error, 'error')
        return
      }
      const url = response?.data?.customerExport?.url
      const a = document.createElement('a')
      a.href = url as string
      a.click()
      URL.revokeObjectURL(a.href)

      notify('Downloaded successfully', 'success')
    } catch (error) {
      notify('Something went wrong. Please try again', 'error')
    }
  }

  return (
    <>
      <Table
        showSearch
        columns={columns}
        dataSource={customersMap}
        loading={fetching}
        actions={
          <Flex gutterX="2">
            {/* <Button
              size="xl"
              appearance="secondary"
              append={<HiArrowCircleDown size="1.3rem" color="#ABB3B9" />}
              onClick={handleDownload}
              isLoading={downloading}
            >
              Download
            </Button>
            <Button
              size="xl"
              appearance="secondary"
              append={<HiArrowCircleUp size="1.3rem" color="#ABB3B9" />}
              onClick={() => setShowImportCsvDrawer(true)}
            >
              Import CSV
            </Button> */}
            <Button
              size="xl"
              prepend={<HiOutlinePlus color="#fff" />}
              onClick={() => setShowDrawer(true)}
            >
              Add Contact
            </Button>
          </Flex>
        }
        emptyProps={{
          title: 'Add or Import your contacts',
          icon: <EmptyContactIcon />,
          subtitle: `To use Accounteer more efficiently, upload a CSV list of your contacts or manually add them.`,
          action: (
            <Flex align="center" gutter="4">
              {/* <Button
                appearance="secondary"
                append={<HiArrowCircleUp size="1.3rem" color="#ABB3B9" />}
                size="xl"
                onClick={() => setShowImportCsvDrawer(true)}
              >
                Import CSV
              </Button> */}
              <Button
                onClick={() => setShowDrawer(true)}
                prepend={<HiOutlinePlus color="#fff" />}
                size="xl"
              >
                Add Contact
              </Button>
            </Flex>
          ),
        }}
        pagination={{
          totalCount: pagination?.totalCount as number,
          currentPage: pagination?.currentPage as number,
          perPage: 10,
          totalPages: pagination?.totalPages as number,
          onPaginationClick: (type) => {
            if (type === 'next') {
              if (page === pagination?.totalPages) return
              setPage(page + 1)
            } else {
              if (page === 1) return
              setPage(page - 1)
            }
          },
        }}
      />

      <AddContactsDrawer
        visible={showDrawer}
        onClose={() => setShowDrawer(false)}
        refetchCustomers={() =>
          refetchCustomers({ requestPolicy: 'network-only' })
        }
      />

      <ImportCsvDrawer
        visible={showImportCsvDrawer}
        onClose={() => setShowImportCsvDrawer(false)}
        refetchCustomers={() =>
          refetchCustomers({ requestPolicy: 'network-only' })
        }
      />

      {/* {customerDetails?.id && (
        <ContactsDetailsDrawer
          handleEditContact={handleEditContact}
          visible={overviewDrawer}
          data={customerDetails}
          onClose={() => {
            setOverviewDrawer(false)
            setCustomerDetails(undefined)
          }}
        />
      )}
      {customerDetails?.id && (
        <UpdateContactsDrawer
          visible={showUpdateDrawer}
          data={customerDetails}
          onClose={() => {
            setShowUpdateDrawer(false)
            setCustomerDetails(undefined)
          }}
        />
      )} */}
    </>
  )
}
