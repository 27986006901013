import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import React from 'react'
import { HiCheck } from 'react-icons/hi'
import { CSS, VariantProps } from 'stitches/stitches.config'
import { CheckBoxWrapper } from './checkbox.styles'

interface ICheckBox {
  css?: CSS
  label?: string | (() => React.ReactNode)
}

type CheckBoxProps = ICheckBox &
  VariantProps<typeof CheckBoxWrapper> &
  React.InputHTMLAttributes<HTMLInputElement>

export const CheckBox = ({
  css,
  id,
  label,
  shape,
  direction,
  ...props
}: CheckBoxProps) => {
  return (
    <CheckBoxWrapper direction={direction} css={css} htmlFor={id} shape={shape}>
      <>
        <input type="checkbox" {...props} />

        <Flex align="center" justify="center" className="custom-checkbox">
          <HiCheck size="1.5rem" strokeWidth={0} className="checkmark" />
        </Flex>

        {typeof label === 'string' ? (
          <Text css={{ ml: '$2', color: '$secondary' }} size="xs">
            {label}
          </Text>
        ) : (
          <>{label?.()}</>
        )}
      </>
    </CheckBoxWrapper>
  )
}

CheckBox.displayName = 'CheckBox'

export default CheckBox
