import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { Span } from 'components/text/span'
import Text from 'components/text/text'
import { useToast } from 'components/toast'
import { useDeleteTransactionMutation } from 'generated/__generated_graphql'

import React from 'react'
import { HiTrash } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'

import { CleanTransaction } from '../screens/transactions'

type DeleteTransactionDrawerProps = {
  visible: boolean
  onClose: () => void
  transaction: CleanTransaction | string[]
  refetchTransactions: () => void
  refetchFinancialAccounts: () => void
}

const DeleteTransactionDrawer: React.FC<DeleteTransactionDrawerProps> = (
  props
) => {
  const {
    visible,
    onClose,
    transaction,
    refetchFinancialAccounts,
    refetchTransactions,
  } = props
  const notify = useToast()

  // const [, queryTransactions] = useTransactionsQuery({
  //   variables: {
  //     bankAccountId: String(transaction.bankAccountId),
  //     pagination: {
  //       page: 1,
  //       per: 10,
  //     },
  //   },
  // })

  const [{ fetching: isBeingDeleted }, deleteTransaction] =
    useDeleteTransactionMutation()
  const isTransactionArray = Array.isArray(transaction)

  async function onSubmit() {
    try {
      const response = await deleteTransaction({
        input: {
          ids: isTransactionArray ? transaction : [transaction.id],
        },
      })

      const error = extractGraphqlErrors(response, 'deleteTransaction')

      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }

      notify({
        content: !isTransactionArray
          ? transaction?.description + ' deleted successfully'
          : 'Transactions deleted successfully',
        status: 'success',
        duration: 2500,
      })

      refetchTransactions()
      refetchFinancialAccounts()
      onClose()
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  return (
    <Drawer
      title={isTransactionArray ? 'Delete Transactions' : 'Delete Transaction'}
      titleIcon={<HiTrash size="2rem" color="#ABB3B9" />}
      visible={visible}
      closable={!isBeingDeleted}
      maskClosable={!isBeingDeleted}
      onClose={onClose}
      fullHeight={false}
    >
      <Flex
        direction={'column'}
        css={{
          width: '100%',
          height: '100%',
          padding: '3.2rem 2.4rem',
        }}
      >
        {!isTransactionArray ? (
          <Text size={'xs'} css={{ mb: '3.2rem' }}>
            Are you sure you want to delete{' '}
            <Span css={{ color: '$error' }}>{transaction?.description}</Span>
          </Text>
        ) : (
          <Text size={'xs'} css={{ mb: '3.2rem' }}>
            Are you sure you want to delete these transactions?
          </Text>
        )}

        <Flex gutterX="2" align="end" css={{ alignSelf: 'end', flexGrow: 1 }}>
          <Button
            type="reset"
            size="md"
            appearance="secondary"
            disabled={isBeingDeleted}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="md"
            appearance={'danger'}
            isLoading={isBeingDeleted}
            disabled={isBeingDeleted}
            onClick={onSubmit}
          >
            {isTransactionArray ? 'Delete Transactions' : 'Delete Transaction'}
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default DeleteTransactionDrawer
