import Box from 'components/layout/box'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import Tag from 'components/tag/tag'
import { Text } from 'components/text/text'
import { format } from 'date-fns'
import React from 'react'
import { formatMoney } from 'utils/helpers'
import { CleanCustomer } from './contacts-table'

interface IInvoiceTableData {
  invoiceRef: string
  amount: any
  status: 'draft'
  dueDate: string
  issueDate: string
}

type CleanCustomerInvoice = Exclude<
  CleanCustomer['invoices'],
  null | undefined
>[number]

type InvoiceTableProps = {
  customerInvoices: CleanCustomerInvoice[]
}

const InvoiceTable: React.FC<InvoiceTableProps> = (props) => {
  const { customerInvoices } = props

  const invoiceMap: any[] =
    customerInvoices?.map((invoice) => ({
      id: invoice.id,
      invoiceRef: invoice.reference,
      amount: {
        amount: invoice.amount,
        currency: invoice.currency,
      },
      status: invoice.status,
      dueDate: invoice.dueDate,
      issueDate: invoice.issueDate,
    })) ?? []

  const columns: ITableColumn<IInvoiceTableData>[] = [
    {
      key: 'amount',
      title: 'amount',
      dataIndex: 'amount',
      render: (amount: IInvoiceTableData['amount']) => (
        <Text size="xs">
          {formatMoney(amount.amount, amount.currency.symbol)}
        </Text>
      ),
    },
    {
      key: 'invoiceRef',
      title: 'invoice Number',
      dataIndex: 'invoiceRef',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (text) => (
        <Tag type="default">
          <Text size="xxs" color="$primary">
            {text}
          </Text>
        </Tag>
      ),
    },
    {
      key: 'dueDate',
      title: 'Due Date',
      dataIndex: 'dueDate',
      render: (date) => (
        <Text size="xs" color="$primary">
          {format(new Date(date), 'dd MMM, yyyy')}
        </Text>
      ),
    },
    {
      key: 'issueDate',
      title: 'Created',
      dataIndex: 'issueDate',
      render: (date) => (
        <Text size="xs" color="$primary">
          {format(new Date(date), 'dd MMM, yyyy')}
        </Text>
      ),
    },
  ]

  return (
    <Box
      css={{
        border: '1px solid $border',
        boxShadow: '$subtle',
        borderRadius: '$3',
      }}
    >
      <Table
        columns={columns}
        dataSource={invoiceMap}
        pagination={{
          totalCount: invoiceMap?.length,
          currentPage: 1,
          perPage: 10,
          onPageChange: () => null,
          onPaginationClick(type) {
            console.log(type)
          },
        }}
      />
    </Box>
  )
}

export default InvoiceTable
