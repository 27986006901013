import Button from 'components/button/button'
import Flex from 'components/layout/flex'
import PageHeader from 'components/page-header/page-header'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import { HiArrowCircleRight } from 'react-icons/hi'
import { styled } from 'stitches/stitches.config'
import { BankRulesTable } from './components/bank-rules-table'

export const StyledArrowRight = styled(HiArrowCircleRight, {
  position: 'absolute',
  right: '-1rem',
  color: '$secondary',
})

export const BankRuleSection = styled(Flex, {
  border: '0.1rem solid $border',
  borderRadius: 4,

  variants: {
    shadow: {
      true: {
        boxShadow: '$normal',
      },
    },
  },
})

export const BankRuleContent = styled(Flex, {
  padding: '1.6rem 1.2rem',

  variants: {
    bodered: {
      true: {
        padding: '1.6rem 2.4rem 3.2rem',
        borderTop: '0.1rem solid $border',
        borderBottom: '0.1rem solid $border',
      },
    },
  },
})

export const BankRuleHeader = styled(Flex, {
  padding: '1.6rem 1.4rem',
})

export const BankRuleFooter = styled(Flex, {
  padding: '1.6rem 2.4rem',
})

export const BankRuleActionOption = styled(Button, {
  padding: '0 !important',
  height: 'unset !important',
  boxShadow: 'none !important',
  border: 'none !important',
})

export const BankRuleActionButton = styled(Button, {
  flexGrow: '1 !important',
})

export const BankRuleDetail = styled(Flex, {
  border: '0.1rem solid $border',
  borderRadius: 4,
  padding: '1rem 1.6rem',
})

export type BankRuleState = {
  mode: 'add' | 'edit'
  inProgress: 'addition' | 'editing'
}

export const BankRulesPage = () => {
  return (
    <>
      <PageHeader title="Bank Rules" />
      <StyledLayoutContent>
        {/* <PageBanner
          title="Automatically categorize your transactions"
          description="Create rules to automatically categorize your transactions based on the description, amount, or other criteria."
          actionText="Watch demo"
          bgSvg={background}
          appearance="yellow"
        /> */}
        <Flex direction="column" gutter="5">
          <BankRulesTable />
        </Flex>
      </StyledLayoutContent>
    </>
  )
}
