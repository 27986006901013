import { Avatar } from 'components/avatar/avatar'
import Button from 'components/button/button'
import DrawerAccordion from 'components/drawer-accordion/drawer-accordion'
import { Drawer } from 'components/drawer/drawer'
import { Box } from 'components/layout'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import { format } from 'date-fns'
import { Product } from 'generated/__generated_graphql'
import React from 'react'
import { HiArchive, HiCheck, HiX } from 'react-icons/hi'
import { formatMoney } from 'utils/helpers'
import {
  StyleFlex,
  StyledProductDetails,
  StyledProductItem,
} from '../inventory.styles'

interface IProps {
  visible: boolean
  onClose: () => void
  onEdit?: () => void
  onRemove?: () => void
  product: Product
}

export const ProductOverviewDrawer: React.FC<IProps> = ({
  visible,
  onClose,
  onEdit,
  product,
}) => {
  return (
    <Drawer
      title={
        <Flex stretchx align="center" justify="between">
          <Flex align="center" gutter="2">
            <Box css={{ cursor: 'pointer' }}>
              <HiX size="1.8rem" color="#ABB3B9" onClick={onClose} />
            </Box>
            <StyleFlex align="center" gutter="2">
              <HiArchive size="1.8rem" color="#ABB3B9" />
              <Text weight="bold" color="$secondary">
                {product.name}
              </Text>
            </StyleFlex>
          </Flex>
          <Flex align="center" gutter="2">
            <Button size="sm" onClick={onEdit}>
              Edit
            </Button>
          </Flex>
        </Flex>
      }
      closable={false}
      visible={visible}
      onClose={onClose}
    >
      <Flex direction="column" gutter="5" css={{ p: '$5' }}>
        <StyledProductItem align="center" justify="start" gutter="3">
          <Avatar
            shape="square"
            background="$highlightBg"
            icon={<HiArchive color="#8EB0DF" />}
          />
          <Flex gutter="1" direction="column">
            <Text weight="bold" size="xs">
              {product.name}
            </Text>
            <Text size="xxs">{formatMoney(product.salePrice!)}</Text>
          </Flex>
        </StyledProductItem>

        <StyledProductDetails direction="column" gutterY="2">
          <DrawerAccordion border label="Details" css={{ p: '$3' }}>
            <Flex direction="column" gutter="2">
              <Flex justify="between">
                <Text size="xs" color="$secondary">
                  Title
                </Text>
                <Text size="xs">{product.name}</Text>
              </Flex>
              <Flex justify="between">
                <Text size="xs" color="$secondary">
                  Created
                </Text>
                <Text size="xs">
                  {format(new Date(product.createdAt), 'dd MMM, yyyy')}
                </Text>
              </Flex>
              <Flex justify="between">
                <Text size="xs" color="$secondary">
                  Item Category
                </Text>
                <Text size="xs">{product.productCategory.name}</Text>
              </Flex>
              <Flex justify="between">
                <Text size="xs" color="$secondary">
                  Description
                </Text>
                <Text size="xs">{product.description}</Text>
              </Flex>
            </Flex>
          </DrawerAccordion>
          <DrawerAccordion border label="Pricing" css={{ p: '$3 $4' }}>
            <Flex direction="column" gutter="2">
              <Flex justify="between">
                <Text size="xs" color="$secondary">
                  Sales Price
                </Text>
                <Text size="xs">{formatMoney(product.salePrice!)}</Text>
              </Flex>
              <Flex justify="between">
                <Text size="xs" color="$secondary">
                  Purchase Price
                </Text>
                <Text size="xs">{formatMoney(product.purchasePrice!)}</Text>
              </Flex>
            </Flex>
          </DrawerAccordion>
          <DrawerAccordion border label="Meta" css={{ p: '$3 $4' }}>
            <Flex direction="column" gutter="2">
              <Flex justify="between">
                <Text size="xs" color="$secondary">
                  SKU
                </Text>
                <Text size="xs">{product.sku}</Text>
              </Flex>
              <Flex justify="between">
                <Text size="xs" color="$secondary">
                  Show on Invoice
                </Text>
                {product.showOnInvoices ? (
                  <HiCheck size="1.4rem" color="#398AFA" />
                ) : (
                  <Text size="xs">-</Text>
                )}
              </Flex>
              <Flex justify="between">
                <Text size="xs" color="$secondary">
                  Show on Purchase
                </Text>
                {product.showOnPurchases ? (
                  <HiCheck size="1.4rem" color="#398AFA" />
                ) : (
                  <Text size="xs">-</Text>
                )}
              </Flex>
              <Flex justify="between">
                <Text size="xs" color="$secondary">
                  Tracked
                </Text>
                {product.tracked ? (
                  <HiCheck size="1.4rem" color="#398AFA" />
                ) : (
                  <Text size="xs">-</Text>
                )}
              </Flex>
            </Flex>
          </DrawerAccordion>
        </StyledProductDetails>
      </Flex>
    </Drawer>
  )
}
