import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import { useUnreconcileTransactionMutation } from 'generated/__generated_graphql'
import React from 'react'
import { HiTrash } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { CleanTransaction } from '../screens/transactions'

export type UnreconcileTransactionDrawerProps = {
  visible: boolean
  onClose: () => void
  transaction: CleanTransaction
  refetchTransactions: () => void
  refetchFinancialAccounts: () => void
}

const UnreconcileTransactionDrawer: React.FC<
  UnreconcileTransactionDrawerProps
> = (props) => {
  const {
    visible,
    transaction,
    onClose,
    refetchTransactions,
    refetchFinancialAccounts,
  } = props

  // const [, queryTransactions] = useTransactionsQuery({
  //   variables: {
  //     bankAccountId: String(transaction.bankAccountId),
  //     pagination: {
  //       page: 1,
  //       per: 10,
  //     },
  //   },
  // })

  // const [, queryFinancialAccounts] = useFinancialAccountsQuery({
  //   variables: {
  //     pagination: {
  //       page: 1,
  //       per: 10,
  //     },
  //   },
  // })

  const toast = useToast()
  function notify(error?: string, status?: 'success' | 'error') {
    toast({
      content: error ?? 'something went wrong',
      status: status,
    })
  }

  const [{ fetching: unreconciling }, unreconcile] =
    useUnreconcileTransactionMutation()
  async function handleUnreconcile(transactionId: string) {
    try {
      const response = await unreconcile({
        input: {
          transactionId,
        },
      })
      const error = extractGraphqlErrors(response, 'unreconcileTransaction')

      if (error) {
        notify(error, 'error')
        return
      }

      refetchTransactions()
      refetchFinancialAccounts()
      notify('Transaction unreconciled successfully', 'success')
      onClose()
    } catch (error) {
      notify(error as string, 'error')
    }
  }

  return (
    <Drawer
      fullHeight={false}
      visible={visible}
      onClose={onClose}
      footerAlignment="end"
      title="Unreconcile Transaction"
      titleIcon={<HiTrash size="1.6rem" color="#ABB3B9" />}
      footer={
        <Flex align="center" gutterX="2">
          <Button appearance="secondary">Cancel</Button>
          <Button
            isLoading={unreconciling}
            onClick={() => handleUnreconcile(transaction.id)}
            appearance="danger"
          >
            Unreconcile
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutterY="3" css={{ padding: '2.8rem 2.4rem' }}>
        <Text size="xs" color="$secondary">
          Are you sure you want to unreconcile this transaction?
        </Text>
      </Flex>
    </Drawer>
  )
}

export default UnreconcileTransactionDrawer
