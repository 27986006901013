import Flex from 'components/layout/flex'
import { styled } from 'stitches/stitches.config'

export const StyleFlex = styled(Flex, {
  borderLeft: '2px solid $border',
  pl: '$2',
})

export const StyledProductItem = styled(Flex, {
  p: '$2',
  border: '1px solid $border',
  boxShadow: '$subtle',
  borderRadius: '$2',
})

export const StyledProductDetails = styled(Flex, {
  //   p: '$4',
  border: '1px solid $border',
  boxShadow: '$subtle',
  borderRadius: '$3',
})
