import Button from 'components/button/button'
import { useToast } from 'components/toast'
import {
  Exact,
  LoginInput,
  LoginMutation,
  LoginStrategyEnum,
  useLoginMutation,
  useRegisterMutation,
} from 'generated/__generated_graphql'
import React, { ComponentProps, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { OperationResult } from 'urql'
import { setToken } from 'utils/auth'
import { extractGraphqlErrors } from 'utils/helpers'
import { wayFinder } from 'utils/wayfinder'

type CredentialResponse = {
  clientId: string
  client_id: string
  credential: string
  select_by: string
}

// googleColor = #1a73e8

function useGoogleAuthentication() {
  const [, loginUser] = useLoginMutation()
  const [, registerUser] = useRegisterMutation()
  const notify = useToast()
  const navigate = useNavigate()

  async function onLoginResponseGotten(
    response: OperationResult<
      LoginMutation,
      Exact<{
        input: LoginInput
      }>
    >
  ) {
    const { login } = response.data ?? {}
    const { id } = login?.user?.organisation ?? {}
    const { plan } = login?.user?.organisation ?? {}

    const error = extractGraphqlErrors(response, 'login')

    if (error) {
      notify({ content: error, status: 'error' })
      return
    }

    const token = login?.token

    if (token) {
      setToken(token)
      if (localStorage.getItem('lastPath')) {
        navigate(localStorage.getItem('lastPath') || '/dashboard')
        localStorage.removeItem('lastPath')
        return
      }
      navigate(wayFinder({ id, plan }, '/dashboard'))
    }
  }

  async function googleSignInCallback(credentialResponse: CredentialResponse) {
    try {
      const response = await loginUser({
        input: {
          email: '',
          password: '',
          strategy: LoginStrategyEnum.Google,
          strategyData: {
            token: credentialResponse['credential'],
          },
        },
      })

      onLoginResponseGotten(response)
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  async function googleSignUpCallback(
    credentialResponse: CredentialResponse,
    setSuccessfullyRegistered: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    try {
      const response = await registerUser({
        input: {
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          phone: '',
          strategy: LoginStrategyEnum.Google,
          strategyData: {
            token: credentialResponse['credential'],
          },
        },
      })

      const error = extractGraphqlErrors(response, 'register')

      if (error) {
        notify({ content: error, status: 'error' })
        return
      }

      setSuccessfullyRegistered(true)
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  function googleSignIn() {
    // @ts-expect-error
    if (window.google) {
      // @ts-expect-error
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: googleSignInCallback,
      })

      // @ts-expect-error
      google.accounts.id.renderButton(
        document.getElementById('google-auth-button'),
        {
          text: 'signin_with',
          type: 'standard',
          theme: 'outline',
          shape: 'square',
          width: 400,
          logo_alignment: 'center',
        }
      )
    }
  }

  function googleSignUp(
    setSuccessfullyRegistered: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    // @ts-expect-error
    if (window.google) {
      // @ts-expect-error
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: (credRes: CredentialResponse) =>
          googleSignUpCallback(credRes, setSuccessfullyRegistered),
      })

      // @ts-expect-error
      google.accounts.id.renderButton(
        document.getElementById('google-auth-button'),
        {
          text: 'continue_with',
          type: 'standard',
          theme: 'outline',
          shape: 'square',
          width: 400,
          logo_alignment: 'center',
        }
      )
    }
  }

  return {
    googleSignIn,
    googleSignUp,
  }
}

type GoogleAuthenticationButtonProps = ComponentProps<typeof Button> & {
  mode: 'sign-in' | 'sign-up'
  setSuccessfullyRegistered?: React.Dispatch<React.SetStateAction<boolean>>
}

export const GoogleAuthenticationButton: React.FC<
  GoogleAuthenticationButtonProps
> = (props) => {
  const { mode, setSuccessfullyRegistered } = props
  const { googleSignIn, googleSignUp } = useGoogleAuthentication()

  useEffect(() => {
    if (mode === 'sign-in') {
      googleSignIn()
    } else {
      googleSignUp(setSuccessfullyRegistered!)
    }
  }, [])

  return (
    // <Button
    //   appearance="secondary"
    //   as={'div'}
    //   css={{ all: 'unset', width: '100%', p: '0' }}
    //   id="google-auth-button"
    // />
    <button id="google-auth-button" style={{ all: 'unset' }}></button>
  )

  // return (
  //   <Button
  //     {...props}
  //     type="button"
  //     css={{
  //       pt: 1,
  //       pb: 1,
  //       pl: 1,
  //       backgroundColor: '#1a73e8',
  //       minWidth: '4rem',
  //     }}
  //   >
  //     <Flex
  //       justify={'center'}
  //       align={'center'}
  //       css={{
  //         backgroundColor: '$white',
  //         color: '#1a73e8',
  //         height: '100%',
  //         width: '3.5rem',
  //         borderTopLeftRadius: 2,
  //         borderBottomLeftRadius: 2,
  //       }}
  //     >
  //       <GoogleLogo style={{ width: '2rem' }} />
  //     </Flex>
  //     <Text>{children}</Text>{' '}
  //   </Button>
  // )
}

export default useGoogleAuthentication
