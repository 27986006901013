import * as RadixAccordion from '@radix-ui/react-accordion'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import { styled } from 'stitches/stitches.config'

export const StyledContent = styled(Flex, {
  pt: '$4',
  background: '$white',
  borderRadius: '$3',
  maxWidth: '70rem',
  boxShadow: '$tiny',

  variants: {
    fullWidth: {
      true: {
        maxWidth: '100%',
      },
    },
    maxContent: {
      true: {
        maxWidth: 'max-content',
      },
    },
    paddingFull: {
      true: {
        p: '$4',
      },
    },
  },
})

export const StyledText = styled(Text, {
  color: '$secondary',
  fontFamily: '$primary',
  mb: '$5',

  a: {
    color: '$highlight',
    textDecoration: 'underline',
  },
})

export const StyledFlex = styled(Flex, {
  gap: '$2',
  a: {
    color: '$highlight',
  },

  '.chevron': {
    ml: 'auto',
  },
})

export const StyleTab = styled(Flex, {
  gap: '$2',
})

export const StyledLink = styled('a', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '$2',
  background: '$white',
  border: '.1rem solid $border',
  boxShadow: '$subtle',
  borderRadius: '$1',
  padding: '$3 $4',
  color: '$primary',
})

export const Root = styled(RadixAccordion.Root, {
  width: '100%',
})

export const Item = styled(RadixAccordion.Item, {})

export const Trigger = styled(RadixAccordion.Trigger, {
  width: '100%',
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'flex-start',
  gap: '$2',
  padding: '$2',
  backgroundColor: '$white',
  border: 'none',
  textAlign: 'left',
  cursor: 'pointer',

  '.icon': { size: '$6', color: '$gray' },
  '&[data-state=closed]': { '.active-icon': { display: 'none' } },
  '&[data-state=open]': {
    '.inactive-icon': { display: 'none' },
    '.active-color': { color: '$highlight' },
  },
})

export const Content = styled(RadixAccordion.Content, {
  width: '100%',
  ml: '3rem',
  mb: '$4',
})
