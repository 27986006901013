import { InputValidator } from 'components/input/input.types'
import { defaultValidators } from 'components/input/input.utils'
import React from 'react'
import { createCleanObject, objectKeys, omit } from 'utils/object'

interface IUseFormConfig<T> {
  fields: T
  validators?: Partial<Record<keyof T, InputValidator<T>>>
  optional?: Array<keyof T>
  validateOnChange?: boolean
}

type ValidateFieldFn = (e: any) => void

// typeof of use Form
export type UseFormReturnType<T> = {
  values: T
  errors: Partial<Record<keyof T, string>>
  onChange: <E>(e: React.ChangeEvent<E>) => void
  onSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    submitFn: (...args: any) => void
  ) => void
  hasErrors: boolean
  formIsComplete: boolean
  validateField: ValidateFieldFn
  getFieldProps: (fieldName: keyof T) => {
    value: string | number
    error: string
    name: keyof T
    onBlur: ValidateFieldFn
    onChange: <E>(e: React.ChangeEvent<E>) => void
  }
  register: (
    fieldName: keyof T
  ) => ReturnType<UseFormReturnType<T>['getFieldProps']>
  setInputValue: (fieldName: keyof T, value: T[keyof T] | null) => void
  setError: (fieldName: keyof T, value: string) => void
  bulkUpdate: (values: Partial<T>) => void
  clearForm: () => void
  handleOptionalFields: (arr: any) => void
  // handleOptionalFields: React.Dispatch<React.SetStateAction<keyof T[]>>
}

export default function useForm<T = any>(
  config: IUseFormConfig<T>
): UseFormReturnType<T> {
  const [values, setValues] = React.useState<
    Record<keyof typeof config.fields, any>
  >(config.fields)
  const [errors, setErrors] = React.useState<
    Record<keyof typeof config.fields, any>
  >(createCleanObject(config.fields) as any)

  // const optional = config.optional ?? []
  const [optional, handleOptionalFields] = React.useState<any>(
    config.optional ?? []
  )

  const hasErrors = Object.values(errors).filter(Boolean).length > 0

  function getFormCompleteness() {
    const requiredFieldsOnly = omit(values, optional as (keyof T)[])

    const fieldsWithNoValues = objectKeys(requiredFieldsOnly).filter((key) => {
      return values[key] === '' || values[key] === undefined
    })

    const formIsComplete = fieldsWithNoValues.length === 0
    return formIsComplete && !hasErrors
  }

  function onChange(e: any) {
    const { name, value } = e.target
    validateField(e)
    setValues({ ...values, [name]: value })
  }

  function setInputValue(name: keyof T, value: any) {
    setValues({
      ...values,
      [name]: value,
    })
  }

  function setError(name: keyof T, value: string) {
    setErrors({
      ...errors,
      [name]: value,
    })
  }

  // TODO Refactor this to be less dependent on the dom event
  function validateField(e: any): void {
    const validators: typeof defaultValidators & typeof config.validators = {
      ...defaultValidators,
      ...config.validators,
    }

    const { name, value } = e.target
    if (optional.includes(name)) return

    // eslint-disable-next-line no-prototype-builtins
    const hasValidator = validators.hasOwnProperty(name)
    const error = hasValidator
      ? validators[name as keyof T]?.(value, values)
      : validators['any']?.(value, values)

    setErrors({ ...errors, [name]: error })
  }

  function validateAllFields() {
    objectKeys(values).forEach((key) => {
      validateField({ target: { name: key, value: values[key] } })
    })
  }

  function onSubmit(e: any, submitFn: (...args: any) => void) {
    e.preventDefault()

    validateAllFields()

    if (hasErrors) {
      return
    }

    submitFn()
  }

  function getFieldProps(field: keyof T) {
    return {
      onChange,
      value: values[field as keyof T] as string | number,
      error: errors[field as keyof T],
      name: field,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onBlur: optional.includes(field as keyof T) ? () => {} : validateField,
    }
  }

  function register(field: keyof T) {
    return getFieldProps(field)
  }

  function bulkUpdate(newValues: Partial<T>) {
    setValues({
      ...values,
      ...newValues,
    })
  }

  const formIsComplete = getFormCompleteness()

  function clearForm() {
    setValues(createCleanObject(config.fields) as any)
    setErrors(createCleanObject(config.fields) as any)
  }

  return {
    values,
    onChange,
    errors,
    validateField,
    hasErrors,
    onSubmit,
    formIsComplete,
    getFieldProps,
    setInputValue,
    register,
    setError,
    bulkUpdate,
    clearForm,
    handleOptionalFields,
  }
}
