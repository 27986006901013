import Button from 'components/button/button'
import { Box, Stack } from 'components/layout'
import { Text } from 'components/text/text'
import useDisclosure from 'hooks/useDisclosure'
import { AddAccountDrawer } from 'pages/financial/components/add-account-drawer'
import { useNavigate } from 'react-router'
import { styled } from 'stitches/stitches.config'
import fuseLogo from './fuse.svg'

const Main = styled(Box, {
  width: 435,
  margin: '0 auto',
  marginTop: 100,
})
const ConnectCard = styled(Box, {
  background: 'White',
  border: '1px solid #F5F5F5',
  boxShadow: '0px 8px 11px rgba(17, 17, 17, 0.03)',
  borderRadius: 4,
  padding: '$4 $3',
  paddingBottom: 20,
})

const Logo = styled('img', {
  width: 40,
  marginBottom: 12,
})

interface ConnectAccountProps {
  onProceed?: () => void
}

const paymentOptions = [
  {
    id: '1',
    title: 'Connect Business Bank Account with Fuse',
    description:
      'Connect your banks globally for automated account screenings and faster reconciliations.',
    logo: fuseLogo,
  },
]

const ConnectAccount: React.FC<ConnectAccountProps> = ({ onProceed }) => {
  const navigate = useNavigate()
  const { isOpen: showAddAccountDrawer, toggle: toggleShowAddAccountDrawer } =
    useDisclosure()

  return (
    <Main>
      <Box>
        <Stack css={{ mb: 50 }} spacing={4}>
          <Text color="#848C96" size="xs">
            STEP 2 OF 4
          </Text>
          <Text weight="bold" variant="h3" size="md">
            Connect Business Bank Account
          </Text>
          <Text color="#848C96" size="xxs">
            Tell us a bit about yourself to get started ...
          </Text>
        </Stack>
        <Stack css={{ mb: 40 }}>
          {paymentOptions.map((paymentOption) => (
            <ConnectCard css={{ mb: 25 }} key={paymentOption.id}>
              <Logo src={paymentOption.logo} alt="mono logo" />
              <Stack spacing={6}>
                <Text weight="bold" size="sm">
                  {paymentOption.title}
                </Text>
                <Text color="#848C96" size="xxs">
                  {paymentOption.description}
                </Text>
              </Stack>
            </ConnectCard>
          ))}
        </Stack>
        <Button fullWidth onClick={toggleShowAddAccountDrawer}>
          Connect Account
        </Button>
        <Button appearance="ghost" fullWidth css={{ mt: 20, mb: 40 }}>
          <Text size="xs" color="$secondary" onClick={onProceed}>
            Do this later
          </Text>
        </Button>
      </Box>
      <AddAccountDrawer
        visible={showAddAccountDrawer}
        onClose={toggleShowAddAccountDrawer}
        onAccountAddSuccess={() => navigate(`/dashboard`)}
        // onAccountAddSuccess={() => navigate(`/onboarding?status=plans`)}
      />
    </Main>
  )
}

export default ConnectAccount
