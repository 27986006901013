import { ReactComponent as EmptyContactIcon } from 'assets/svg/empty-contact.svg'
import { Avatar } from 'components/avatar/avatar'
import Flex from 'components/layout/flex'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import Tag from 'components/tag/tag'
import { Text } from 'components/text/text'
import { TopOperatingExpenses } from 'generated/__generated_graphql'
import JournalDetail from 'pages/accounting/journals/journal-detail'
import React from 'react'
import { formatMoney } from 'utils/helpers'

export type TopExpenseTableData = {
  id: string
  description: string
  amount: string
  percentage: string
}

export const TopExpenseTable = ({
  currency,
  data,
}: {
  currency: string
  data: TopOperatingExpenses[]
}) => {
  const [glAcccount, setGlAcccount] = React.useState<any>(null)
  const [visible, setVisible] = React.useState(false)

  const columns: ITableColumn<TopExpenseTableData>[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'description',
      render: (value: TopExpenseTableData['description']) => (
        <Flex align="center" gutterX="2">
          <Avatar title={value} size="medium" />
          <Text size="xs">{value}</Text>
        </Flex>
      ),
    },
    {
      key: 'amount',
      title: 'Amount',
      dataIndex: 'amount',
      render: (value) => <Text size="xs">{formatMoney(value, currency)}</Text>,
    },
    {
      key: 'percentage',
      title: 'Percentage',
      dataIndex: 'percentage',
      render: (value) => (
        <Tag type="blue" size="xs">
          {value}%
        </Tag>
      ),
    },
  ]

  const topExpenseDataMap = data?.map((item) => ({
    id: item?.glAccountId?.toString(),
    description: item?.description,
    amount: item?.amount,
    percentage: item?.percentage,
  }))

  return (
    <>
      <Table
        onRowClick={(data) => {
          setGlAcccount(data)
          setVisible(true)
        }}
        columns={columns}
        dataSource={topExpenseDataMap ?? []}
        emptyProps={{
          title: 'No expenses yet',
          icon: <EmptyContactIcon />,
          subtitle: `You have not added any expenses yet.`,
        }}
      />
      {glAcccount?.id && (
        <JournalDetail
          visible={visible}
          onClose={() => setVisible(false)}
          glAcccount={glAcccount}
        />
      )}
    </>
  )
}
