import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import React, { ComponentProps, ReactNode } from 'react'
import { StyledHeader } from './page-header.styles'

export interface PageHeaderProps
  extends Omit<ComponentProps<typeof StyledHeader>, 'title'> {
  title?: string | JSX.Element
  subtitle?: string
  extra?: ReactNode
  type?: 'box' | 'plain'
}

const PageHeader: React.FC<PageHeaderProps> = ({
  type = 'box',
  title,
  subtitle,
  extra,
  ...props
}) => {
  // const navigate = useNavigate()

  // function handleNavigationClick(direction: 'left' | 'right') {
  //   if (direction === 'left') return navigate(-1)
  //   navigate(1)
  // }

  return (
    <StyledHeader {...props} type={type}>
      <Flex gutter="3" align="center">
        {/* {type === 'box' && (
          <>
            <StyledNavigation onClick={() => handleNavigationClick('left')}>
              <HiOutlineChevronLeft color="#ABB3B9" />
            </StyledNavigation>
            <StyledNavigation onClick={() => handleNavigationClick('right')}>
              <HiOutlineChevronRight color="#ABB3B9" />
            </StyledNavigation>
          </>
        )} */}

        <Flex
          direction={type === 'box' ? 'row' : 'column'}
          gutter={type === 'box' ? 3 : 1}
          align={type === 'box' ? 'center' : 'start'}
        >
          {title && (
            <Text
              color="$primary"
              weight="bold"
              size={type === 'box' ? 'md' : 'lg'}
            >
              {title}
            </Text>
          )}

          {subtitle && (
            <Text color="$secondary" weight="medium" size="xs">
              {subtitle}
            </Text>
          )}
        </Flex>
      </Flex>

      {extra}
    </StyledHeader>
  )
}

export default PageHeader
