import Flex from 'components/layout/flex'
import { styled } from 'stitches/stitches.config'

export const StyledMenuBar = styled(Flex, {})
export const StyledMenuItem = styled(Flex, {
  cursor: 'pointer',
  width: '100%',
  padding: '$2 ',
  transition: 'all 0.2s ease-in-out',
  '.icon': {
    display: 'flex',
    alignItems: 'center',
    // position: 'relative',
    '& > div': {
      position: 'absolute',
      inset: '0',
    },

    svg: {
      color: '$secondary',
      // color: '$gray',
      fontSize: '$4',
    },
  },
  '.chevron': { color: '$secondary', fontSize: '$4' },

  variants: {
    active: {
      true: {
        // backgroundColor: '$highlightBg',
        // borderRadius: '$2',
        // '.icon': {
        //   svg: {
        //     color: '$highlight',
        //   },
        // },
      },
    },

    type: {
      dashboard: {
        '&:hover': {
          backgroundColor: '$highlightBg',
          borderRadius: '$2',

          '.icon': {
            svg: {
              color: '$highlight',
            },
          },
        },
      },

      settings: {
        cursor: 'auto',

        '&:hover': {
          backgroundColor: 'none',
          borderRadius: '$2',

          '.icon': {
            svg: {
              color: 'none',
            },
          },
        },
      },
    },
  },
})
export const StyledSubMenu = styled(Flex, {
  height: '0',
  overflow: 'hidden',
  cursor: 'pointer',
  transition: 'background-color 0.2s ease-in-out',
  pl: '3.4rem !important',

  variants: {
    isSubMenuActive: {
      true: {
        height: '100%',
        overflow: 'auto',
        backgroundColor: '$highlightBg',
        borderRadius: '$2',
        padding: '$2',
      },
    },

    active: {
      true: {
        height: '100%',
        overflow: 'auto',
        padding: '$2',
      },
    },
  },

  '&:hover': {
    backgroundColor: '$highlightBg',
    borderRadius: '$2',
  },
})
