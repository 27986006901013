import React from 'react'
import ReactDOM from 'react-dom'

interface IProps {
  children: React.ReactNode
  id?: string
}

const portalRootEl = document.getElementById('portal-root')
class Portal extends React.Component {
  portal: HTMLDivElement | null
  props: IProps

  constructor(props: IProps) {
    super(props)
    this.props = props
    this.portal = document.createElement('div')

    this.props.id && this.portal.setAttribute('id', this.props.id)
    portalRootEl?.style.setProperty('z-index', '100')
  }

  componentDidMount() {
    this.portal && portalRootEl?.appendChild(this.portal)
  }

  componentWillUnmount() {
    this.portal && portalRootEl?.removeChild(this.portal)
  }

  render() {
    if (!this.portal) return null
    return ReactDOM.createPortal(this.props?.children, this.portal)
  }
}

export default Portal
