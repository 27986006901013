import { useRect } from '@reach/rect'
import { format, isValid } from 'date-fns'
import useDisclosure from 'hooks/useDisclosure'
import useOutsideClick from 'hooks/useOutsideClick'
import React from 'react'
import { extractMonthAndYear } from 'utils/helpers'
import { IProps } from './date-input.types'

function useDateInput({
  defaultValue,
  onChange,
  onDateRangeChange,
  name,
  defaultDateText,
  isMonthPicker,
  dateFormat = 'yyyy/MM/d',
  showDefaultDate = true,
}: IProps) {
  const { isOpen, show, hide } = useDisclosure()

  const calendarRef = React.useRef<HTMLDivElement>(null)
  const inputRef = React.useRef<HTMLDivElement>(null)
  const inputRect = useRect(inputRef, { observe: true })
  const [date, setDate] = React.useState<Date>(defaultValue ?? new Date())
  const [dateText, setDateText] = React.useState<string>(
    defaultValue
      ? format(defaultValue as Date, dateFormat)
      : isMonthPicker
      ? (defaultDateText as string) ?? handleDefaultDateText()
      : ''
  )

  useOutsideClick({
    isOpen,
    ref: inputRef,
    callback: hide,
    portal: '#root',
    // portal: '#portal-root',
  })

  function handleDefaultDateText() {
    // return the current month and year
    return showDefaultDate
      ? `${new Date().toLocaleString('default', {
          month: 'long',
        })} ${new Date().getFullYear()}`
      : ''
  }

  function onDateRangeSelect(date: Date[]) {
    const [start, end] = date
    setDateText(`${format(start, dateFormat)} - ${format(end, dateFormat)}`)
    onDateRangeChange?.({
      target: {
        startDate: start,
        endDate: end,
      },
    })
    // hide()
  }

  function onTextChange(e: any) {
    const { value } = e.target
    setDateText(value)
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.code === 'Enter') {
      setDateFromText()
      hide()
    }

    if (e.code === 'ArrowDown') {
      if (!isOpen) {
        show()
      }
    }
  }

  function setDateFromText() {
    const dateFromText = new Date(dateText)

    if (!isValid(dateFromText)) {
      setDateText(format(date, dateFormat))
      return
    }

    setDate(dateFromText)
    onChange &&
      onChange({
        target: {
          name: name as string,
          value: dateFromText,
        },
      })
  }

  function onDateSelect(date: Date) {
    setDate(date)
    if (isMonthPicker) {
      setDateText(
        `${extractMonthAndYear(date).month} ${extractMonthAndYear(date).year}`
      )
    } else {
      setDateText(format(date, dateFormat))
    }
    const dateFromText = new Date(format(date, dateFormat))
    onChange &&
      onChange({
        target: {
          name: name as string,
          value: dateFromText,
        },
      })
    hide()
  }

  function onInputFocus() {
    show()
  }

  return {
    isOpen,
    hide,
    calendarRef,
    inputRef,
    inputRect,
    date,
    dateText,
    onDateSelect,
    onTextChange,
    handleKeyUp,
    setDateFromText,
    onInputFocus,
    onDateRangeSelect,
  }
}

export default useDateInput
