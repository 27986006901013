import { zodResolver } from '@hookform/resolvers/zod'
import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select/type-ahead-select'
import {
  useOrganisationQuery,
  useRolesQuery,
  useUpdateTeamMemberRoleMutation,
} from 'generated/__generated_graphql'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { HiUserAdd } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { z } from 'zod'
import { Member } from '../../../pages/members'

// Describe the correctness of data's form.
const formSchema = z.object({
  emailAddress: z
    .string()
    .email({ message: 'Please enter a valid email address' }),
  role: z
    .string()
    .min(1, 'Please select a role to be assigned to the team member(s)'),
})

// Infer the TS type according to the zod schema.
type FormFields = z.infer<typeof formSchema>

interface EditMemberDrawerProps {
  visible: boolean
  onClose: () => void
  member: Member
}

export const EditMemberDrawer: React.FC<EditMemberDrawerProps> = (props) => {
  const { visible, onClose, member } = props

  const {
    control,
    register,
    reset,
    formState: { errors, isSubmitting, isValid },
    getValues,
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    reset({
      emailAddress: member.email,
      role: member.role?.id,
    })
  }, [])

  const notify = useToast()

  const [{ data: rolesQueryData }] = useRolesQuery()
  const [, queryOrganisation] = useOrganisationQuery()
  const [, updateTeamMemberRole] = useUpdateTeamMemberRoleMutation()

  const rolesOptions =
    rolesQueryData?.roles.map((role) => ({
      label: role.name,
      value: role.id,
    })) ?? []

  async function onSubmit() {
    try {
      const { role } = getValues()
      const response = await updateTeamMemberRole({
        input: {
          teamMemberId: member.id,
          roleId: role,
        },
      })
      const error = extractGraphqlErrors(response, 'updateTeamMemberRole')
      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }
      queryOrganisation({ requestPolicy: 'network-only' })

      notify({
        status: 'success',
        content:
          member.firstName +
          `'s ` +
          'role has been successfully updated to ' +
          rolesOptions.find((roleOption) => roleOption.value === role)?.label,
      })

      onReset()
    } catch (error) {
      notify({
        status: 'error',
        content: 'Something went wrong. Please try again',
      })
    }
  }

  function onReset() {
    reset()
    onClose()
  }

  return (
    <Drawer
      title="Change Member Role"
      titleIcon={<HiUserAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      closable={!isSubmitting}
      maskClosable={!isSubmitting}
      onClose={onReset}
      destroyOnClose
    >
      <form
        style={{ height: '100%', width: '100%' }}
        onSubmit={handleSubmit(onSubmit)}
        onReset={onReset}
      >
        <Flex
          css={{
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
            height: 'inherit',
            width: 'inherit',
            p: '$5',
          }}
        >
          <Flex direction="column" gutter="3" css={{ width: 'inherit' }}>
            <Input
              disabled
              {...(register('emailAddress') as any)}
              required
              label={`Email address`}
              error={errors.emailAddress?.message}
            />

            <Controller
              control={control}
              name="role"
              render={({ field: { onChange, value } }) => {
                return (
                  <TypeAheadSelect
                    required
                    label="Role"
                    placeholder="Select role"
                    options={rolesOptions}
                    value={value}
                    onChange={onChange}
                    error={errors.role?.message}
                  />
                )
              }}
            />
          </Flex>

          <Flex gutterX="2" css={{ alignSelf: 'end' }}>
            <Button
              type="reset"
              size="md"
              appearance="secondary"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              size="md"
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting || !isValid}
            >
              Update Role
            </Button>
          </Flex>
        </Flex>
      </form>
    </Drawer>
  )
}
