import Flex from 'components/layout/flex'
import PageHeader from 'components/page-header/page-header'
import { LogsTable } from '../components/logs/logs-table'
import { StyledContent } from '../organisation.styles'

const Logs = () => {
  return (
    <Flex direction="column" gutter="3">
      <PageHeader
        title="Logs"
        subtitle="Track members’ login details and activities on the app."
        type="plain"
      />

      <StyledContent fullWidth direction="column" gutter="3">
        <LogsTable />
      </StyledContent>
    </Flex>
  )
}

export default Logs
