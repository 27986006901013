import Flex from 'components/layout/flex'
import { styled } from 'stitches/stitches.config'

export const StyledContent = styled(Flex, {
  pt: '$4',
  background: '$white',
  borderRadius: '$3',
  maxWidth: '70rem',
  boxShadow: '$tiny',
})
