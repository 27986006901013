import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import Flex from 'components/layout/flex'
import { useToast } from 'components/toast'
import {
  useArchiveAccountMutation,
  useChartOfAccountsQuery,
  useUnarchiveAccountMutation,
} from 'generated/__generated_graphql'
import React, { useState } from 'react'
import { HiDotsHorizontal } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { ChartOfAccountsTableData } from './chart-of-accounts-table'
import DeleteAccountDrawer from './delete-account-drawer'
import MutateAccountDrawer from './mutate-account-drawer'

const ChartOfAccountsTableAction: React.FC<{
  account: ChartOfAccountsTableData['account']
}> = ({ account }) => {
  const [showEditAccountDrawer, setShowEditAccountDrawer] = useState(false)
  const [showDeleteAccountDrawer, setShowDeleteAccountDrawer] = useState(false)

  const [, archiveAccount] = useArchiveAccountMutation()
  const [, unarchiveAccount] = useUnarchiveAccountMutation()
  const [, queryAccounts] = useChartOfAccountsQuery()
  const notify = useToast()

  async function onArchiveAccount() {
    try {
      const response = await archiveAccount({
        input: { accountId: account.id },
      })

      const error = extractGraphqlErrors(response, 'archiveAccount')

      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: `${account.name} has been archived`,
        status: 'success',
        duration: 2500,
      })

      queryAccounts({
        requestPolicy: 'network-only',
      })
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  async function onUnarchiveAccount() {
    try {
      const response = await unarchiveAccount({
        input: { accountId: account.id },
      })

      const error = extractGraphqlErrors(response, 'unarchiveAccount')

      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: `${account.name} has been un-archived`,
        status: 'success',
        duration: 2500,
      })

      queryAccounts({
        requestPolicy: 'network-only',
      })
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  return (
    <>
      <Flex stretchx justify="end">
        <Dropdown
          placement="bottomRight"
          menu={[
            {
              label: 'Edit',
              onClick: () => setShowEditAccountDrawer(true),
            },
            {
              label: account.isArchived ? 'Unarchive' : 'Archive',
              onClick: account.isArchived
                ? onUnarchiveAccount
                : onArchiveAccount,
            },
            {
              label: 'Delete',
              onClick: () => setShowDeleteAccountDrawer(true),
            },
          ]}
        >
          {/* <p>ok</p> */}
          {/* <StyledOptionsContainer stretchx align="center" justify="end"> */}
          {/* <StyledOptions appearance="ghost"> */}
          <Button
            appearance="ghost"
            css={{
              position: 'unset',
            }}
          >
            <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
          </Button>
          {/* </StyledOptions> */}
          {/* </StyledOptionsContainer> */}
        </Dropdown>
      </Flex>

      <MutateAccountDrawer
        visible={showEditAccountDrawer}
        onClose={() => setShowEditAccountDrawer(false)}
        account={account}
      />

      <DeleteAccountDrawer
        visible={showDeleteAccountDrawer}
        onClose={() => setShowDeleteAccountDrawer(false)}
        account={account}
      />
    </>
  )
}

export default ChartOfAccountsTableAction
