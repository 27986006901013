import React, { useState } from 'react'
import { HiFilter } from 'react-icons/hi'
import { StyledFilterButton } from '../table.styles'
import { IFilterComponent, ITableFilterTypes } from '../table.types'
import { CheckboxFilter } from './checkbox-filter'

export const ColumnFilter: React.FC<IFilterComponent> = ({
  type,
  operator,
  options,
  header,
  data,
  colKey,
  onFilter,
}) => {
  const [showFilter, setShowFilter] = useState(false)

  const filterButton = (
    <StyledFilterButton
      active={showFilter}
      onClick={() => setShowFilter(!showFilter)}
    >
      <HiFilter size="0.9rem" color="#ABB3B9" />
    </StyledFilterButton>
  )

  if (type === ITableFilterTypes.CHECKBOX) {
    return (
      <CheckboxFilter
        type={type}
        header={header}
        operator={operator}
        options={options}
        trigger={filterButton}
        data={data}
        colKey={colKey}
        onFilter={onFilter}
        onVisibleChange={(visible) => setShowFilter(visible)}
      />
    )
  }

  return null
}
