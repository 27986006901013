import { useRect } from '@reach/rect'
import type * as Stitches from '@stitches/react'
import { Box } from 'components/layout'
import { Text } from 'components/text/text'
import useOutsideClick from 'hooks/useOutsideClick'
import React, { ReactNode, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { mergeRefs } from 'utils/dom'
import {
  DropdownViewport,
  StyledDropdown,
  StyledDropdownMenu,
  StyledDropdownMenuItem,
  StyledDropdownTrigger,
  StyledDropdownWrapper,
} from './dropdown.styles'

export interface IDropdownProps
  extends Stitches.VariantProps<typeof StyledDropdownTrigger> {
  children: JSX.Element
  disabled?: boolean
  visible?: boolean
  fullWidth?: boolean
  overlay?: ReactNode
  header?: ReactNode
  footer?: ReactNode
  overlayStyle?: Stitches.CSS
  wrapperStyle?: Stitches.CSS
  menuItemStyle?: Stitches.CSS
  placement?: 'bottomLeft' | 'bottomRight'
  menu?: {
    label: ReactNode
    value?: any
    link?: {
      url: string
      external: boolean
    }
    disabled?: boolean
    onClick?: (value: any) => void
    render?: (label: ReactNode) => React.ReactNode
  }[]
  closeOnMenuItemClick?: boolean
  onVisibleChange?: (visible: boolean) => void
}

export const Dropdown = React.forwardRef<HTMLDivElement, IDropdownProps>(
  (
    {
      children,
      overlay,
      menu,
      header,
      footer,
      visible,
      disabled,
      fullWidth,
      wrapperStyle,
      overlayStyle,
      menuItemStyle,
      onVisibleChange,
      closeOnMenuItemClick = true,
      placement = 'bottomLeft',
    },
    ref
  ) => {
    const _componentIsTouched = React.useRef(false)

    const contentRef = React.useRef<HTMLDivElement>(null)
    const wrapperRef = React.useRef<HTMLDivElement>(null)
    const reachRef = React.useRef<HTMLDivElement>(null)
    const triggerRef = React.useRef<HTMLDivElement>(null)

    const rect = useRect(reachRef, { observe: true })

    const [open, setOpen] = useState<boolean>(false)

    useOutsideClick({
      ref: wrapperRef,
      isOpen: open,
      callback: () => {
        setOpen(false)
      },
      portal: '#dropdown',
    })

    function toggleOpen(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      e.stopPropagation()
      setOpen((open) => !open)
      _componentIsTouched.current = true
    }

    useEffect(() => {
      setOpen(!!visible)
    }, [visible])

    useEffect(() => {
      onVisibleChange?.(open)
    }, [open])

    return (
      <StyledDropdownWrapper
        css={{ ...wrapperStyle, width: fullWidth ? '100%' : undefined }}
        ref={mergeRefs(ref, wrapperRef, reachRef)}
      >
        <StyledDropdownTrigger
          tabIndex={0}
          onClick={toggleOpen}
          ref={triggerRef}
          // css={{
          //   py: triggerRect?.height && triggerRect?.height > 60 ? 8 : 0,
          // }}
        >
          {children}
        </StyledDropdownTrigger>

        {open && !disabled && (
          // <Portal id="dropdown">
          <StyledDropdown
            ref={contentRef}
            css={{
              top: (rect?.y ?? 0) + (rect?.height ?? 0) + 4,
              left: placement === 'bottomLeft' ? rect?.x : undefined,
              right:
                placement === 'bottomRight' && rect
                  ? window.innerWidth - rect?.right
                  : undefined,
              minWidth: rect?.width,
              ...overlayStyle,
            }}
          >
            {overlay ?? (
              <StyledDropdownMenu>
                <DropdownViewport>
                  {header}
                  {menu?.map((item, index) => (
                    <StyledDropdownMenuItem
                      disabled={item.disabled}
                      key={index}
                      css={menuItemStyle}
                      onClick={() => {
                        closeOnMenuItemClick && setOpen(false)
                        item.onClick?.(item.value)
                      }}
                    >
                      {item.render ? (
                        item.render(item.label)
                      ) : (
                        <Box
                          css={{
                            px: '1.6rem',
                            py: '0.8rem',
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer',
                            '&:hover': {
                              color: '$highlight',
                              backgroundColor: '$highlightBg',

                              '& *': {
                                color: '$highlight',
                              },
                            },
                          }}
                        >
                          {item.link ? (
                            item.link.external ? (
                              <a href={item.link.url}>
                                <Text size="xs" weight="semi" color="inherit">
                                  {item.label}
                                </Text>
                              </a>
                            ) : (
                              <Link to={item.link.url}>
                                <Text size="xs" weight="semi" color="inherit">
                                  {item.label}
                                </Text>
                              </Link>
                            )
                          ) : (
                            <Text size="xs" weight="semi" color="inherit">
                              {item.label}
                            </Text>
                          )}
                        </Box>
                      )}
                    </StyledDropdownMenuItem>
                  ))}
                  {footer}
                </DropdownViewport>
              </StyledDropdownMenu>
            )}
          </StyledDropdown>
          // </Portal>
        )}
      </StyledDropdownWrapper>
    )
  }
)

Dropdown.displayName = 'Dropdown'
