import Button from 'components/button/button'
import { Table } from 'components/table/table'
import { StyledInputWrap } from 'components/table/table.styles'
import { ITableColumn } from 'components/table/table.types'
import { Text } from 'components/text/text'
import { ChartOfAccountsQuery } from 'generated/__generated_graphql'
import JournalDetail from 'pages/accounting/journals/journal-detail'
import { StyledBadge } from 'pages/settings/settings.styles'
import React, { ComponentProps, useState } from 'react'
import { HiOutlinePlus } from 'react-icons/hi'
import ChartOfAccountsTableAction from './chart-of-accounts-table-action'
import MutateAccountDrawer from './mutate-account-drawer'

type CleanAccount = Exclude<
  ChartOfAccountsQuery['chartOfAccounts'],
  null | undefined
>['data'][number]

export type ChartOfAccountsTableData = {
  id: string
  accountName: string
  accountType: string
  accountNumber: string
  account: CleanAccount
}

type ChartOfAccountsTableProps = {
  accounts: CleanAccount[]
} & Partial<ComponentProps<typeof Table>>

export type SelectedAccount = {
  name: string
  number: string
  accountTypeId: string
  allowPayments: boolean
  showOnInvoices: boolean
  showOnPurchases: boolean
}

const ChartOfAccountsTable: React.FC<ChartOfAccountsTableProps> = (props) => {
  const { accounts, pagination, ...remainderProps } = props
  const [showDrawer, setShowDrawer] = useState(false)

  const [glAcccount, setGlAcccount] = React.useState<any>(null)
  const [visible, setVisible] = React.useState(false)

  const columns: ITableColumn<ChartOfAccountsTableData>[] = [
    {
      key: 'accountNumber',
      dataIndex: 'accountNumber',
      title: 'GL Account Number',
      render: (text: string) => (
        <Text size="xs" color="inherit" weight="semi">
          {text}
        </Text>
      ),
    },
    {
      key: 'accountName',
      title: 'Account Name',
      dataIndex: 'accountName',
      render: (text: string) => (
        <Text size="xs" color="inherit" weight="semi">
          {text}
        </Text>
      ),
    },
    {
      key: 'accountType',
      title: 'Account Type',
      dataIndex: 'accountType',
      render: (text: string, { account }) => (
        <StyledBadge status={account.isArchived ? 'inactive' : 'active'}>
          <Text size="xs" color="inherit" weight="semi">
            {text}
          </Text>
        </StyledBadge>
      ),
    },
    {
      key: 'action',
      title: '',
      dataIndex: 'id',
      render: (_, { account }) => {
        // if (account.accountType?.name === 'Bank') return <></>

        return <ChartOfAccountsTableAction account={account} />
      },
    },
  ]

  const data =
    accounts?.map<ChartOfAccountsTableData>((account) => ({
      id: account.id,
      accountName: account.name ?? '',
      accountType: account?.accountType?.name ?? '',
      accountNumber: account.glCode ?? '',
      account,
    })) ?? []

  return (
    <>
      <Table
        {...remainderProps}
        onRowClick={(data) => {
          setGlAcccount(data)
        }}
        showSearch
        css={{
          [`& ${StyledInputWrap}`]: {
            mr: '3.5rem',
          },
        }}
        columns={columns}
        dataSource={data}
        actions={
          <Button
            size="xl"
            prepend={<HiOutlinePlus color="#fff" />}
            onClick={() => setShowDrawer(true)}
          >
            Add New Account
          </Button>
        }
        pagination={pagination}
      />

      <MutateAccountDrawer
        visible={showDrawer}
        onClose={() => setShowDrawer(false)}
      />

      {glAcccount?.id && (
        <JournalDetail
          visible={visible}
          onClose={() => setVisible(false)}
          glAcccount={glAcccount}
        />
      )}
    </>
  )
}

export default ChartOfAccountsTable
