import { z } from 'zod'

// assertions
const assertStringIsValidNumber = (message: string) =>
  z.string().refine((value) => !isNaN(Number.parseInt(value)), {
    message,
  })

const assertStringIsInvalidNumber = (message: string) =>
  z.string().refine((value) => isNaN(Number.parseInt(value)), {
    message,
  })

// schemas
export const email = z
  .string({ required_error: 'Please enter your email address' })
  .email({ message: 'Please enter a valid email address' })
  .transform((email) => email.toLowerCase())

export const password = z
  .string({ required_error: 'Please enter your correct password' })
  .min(8, { message: 'Your password should be a minimum of 8 characters' })
  .regex(new RegExp(/[0-9]/), {
    message: 'Your password should contain at least one number',
  })
  .regex(new RegExp(/[A-Z]/), {
    message: 'Your password should contain at least one uppercase letter',
  })
  .regex(new RegExp(/[^a-zA-Z\d\s:]/), {
    message:
      'Your password should contain at least one non-alphanumeric character',
  })

export const firstName = z
  .string()
  .min(3, 'Please enter your first name (3 letters minimum)')
  .and(
    assertStringIsInvalidNumber(
      'Please enter your first name (3 letters minimum)'
    )
  )

export const lastName = z
  .string()
  .min(3, 'Please enter your last name (3 letters minimum)')
  .and(
    assertStringIsInvalidNumber(
      'Please enter your last name (3 letters minimum)'
    )
  )

export const username = z
  .union([
    z.string().length(0, 'Please enter a username (3 characters minimum)'),
    z.string().min(3, 'Please enter a username (3 characters minimum)'),
  ])
  .optional()
  .transform((e) => (e === '' ? undefined : e))

export const phone = z
  .string()
  .min(1)
  .and(assertStringIsValidNumber('Please enter your correct phone number'))

export const website = z.string().regex(
  new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/
  ),
  {
    message: `Please enter your organisation's website URL (eg: www.float.co)`,
  }
)
