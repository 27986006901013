import Box from 'components/layout/box'
import Stack from 'components/layout/stack'
import { styled } from 'stitches/stitches.config'

export const AlertWrapper = styled(Box, {
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '$2',
  width: '100%',
  boxShadow: '$min',

  borderRadius: 8,
  color: '$white',
  variants: {
    variant: {
      dark: { backgroundColor: '$black' },
      success: { backgroundColor: '$blue' },
      error: { backgroundColor: '$error' },
      warning: { backgroundColor: '$warning' },
      default: {
        backgroundColor: '$white',
        color: '$gray',
        boxShadow: '$subtle',
        '.close-icon': { color: '$gray' },
      },
    },
    size: {
      md: { py: 12, px: 16 },
      sm: { py: 6, pl: 12, pr: 8 },
    },
  },

  defaultVariants: {
    variant: 'default',
    size: 'sm',
  },
})

export const AlertContent = styled(Stack, {
  textAlign: 'left',
  fontSize: 15,
  color: 'inherit',
  whiteSpace: 'nowrap',
  marginRight: 16,
})

export const AlertButton = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '$4',
  height: '$4',
  borderRadius: '50%',
  cursor: 'pointer',
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: 0,

  '&:hover': {
    background: '$backdropBg',
    outline: 'none',
  },
  '&:active': { outline: 'none' },
  transition: 'all 0.3s',
})
