import { Meta, StoryFn } from '@storybook/react'

import Tooltip from './tooltip'
// import { tabs } from "./data";

// More on default export: https://storybook.js.org/docs/react/writing-stories/introduction#default-export
export default {
  title: 'Tooltip',
  component: Tooltip,
  // More on argTypes: https://storybook.js.org/docs/react/api/argtypes
  argTypes: {},
} as Meta<typeof Tooltip>

// More on component templates: https://storybook.js.org/docs/react/writing-stories/introduction#using-args
const Template: StoryFn<typeof Tooltip> = (args) => <Tooltip {...args} />

const IconBtn = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4413_24154)">
        <path
          opacity="0.4"
          d="M6.29999 0.196869C2.92851 0.196869 0.196869 2.93048 0.196869 6.29999C0.196869 9.66951 2.92851 12.4031 6.29999 12.4031C9.67148 12.4031 12.4031 9.67148 12.4031 6.29999C12.4031 2.92851 9.67148 0.196869 6.29999 0.196869ZM6.29999 2.9039C6.50442 2.9039 6.70425 2.96452 6.87423 3.07809C7.0442 3.19166 7.17668 3.35309 7.25491 3.54195C7.33314 3.73082 7.35361 3.93864 7.31373 4.13914C7.27385 4.33963 7.17541 4.5238 7.03085 4.66835C6.8863 4.81291 6.70213 4.91135 6.50164 4.95123C6.30114 4.99111 6.09332 4.97064 5.90445 4.89241C5.71559 4.81418 5.55416 4.6817 5.44059 4.51173C5.32702 4.34175 5.2664 4.14192 5.2664 3.93749C5.2664 3.66337 5.3753 3.40047 5.56913 3.20663C5.76297 3.0128 6.02587 2.9039 6.29999 2.9039ZM7.67812 9.15468C7.67812 9.233 7.64701 9.30812 7.59162 9.3635C7.53624 9.41888 7.46113 9.44999 7.38281 9.44999H5.21718C5.13886 9.44999 5.06375 9.41888 5.00836 9.3635C4.95298 9.30812 4.92187 9.233 4.92187 9.15468V8.56406C4.92187 8.48573 4.95298 8.41062 5.00836 8.35524C5.06375 8.29986 5.13886 8.26874 5.21718 8.26874H5.51249V6.69374H5.21718C5.13886 6.69374 5.06375 6.66263 5.00836 6.60725C4.95298 6.55187 4.92187 6.47675 4.92187 6.39843V5.80781C4.92187 5.72948 4.95298 5.65437 5.00836 5.59899C5.06375 5.54361 5.13886 5.51249 5.21718 5.51249H6.79218C6.8705 5.51249 6.94562 5.54361 7.001 5.59899C7.05638 5.65437 7.08749 5.72948 7.08749 5.80781V8.26874H7.38281C7.46113 8.26874 7.53624 8.29986 7.59162 8.35524C7.64701 8.41062 7.67812 8.48573 7.67812 8.56406V9.15468Z"
          fill="#C7EBE5"
        />
        <path
          d="M6.3 4.97107C6.50443 4.97107 6.70426 4.91045 6.87423 4.79688C7.04421 4.68331 7.17669 4.52188 7.25492 4.33302C7.33315 4.14415 7.35361 3.93633 7.31373 3.73584C7.27385 3.53534 7.17541 3.35117 7.03086 3.20662C6.88631 3.06207 6.70214 2.96363 6.50164 2.92375C6.30115 2.88386 6.09333 2.90433 5.90446 2.98256C5.7156 3.06079 5.55417 3.19327 5.4406 3.36325C5.32703 3.53322 5.26641 3.73305 5.26641 3.93748C5.26641 4.21161 5.3753 4.4745 5.56914 4.66834C5.76298 4.86218 6.02587 4.97107 6.3 4.97107ZM7.38281 8.26873H7.0875V5.80779C7.0875 5.72947 7.05639 5.65436 7.001 5.59897C6.94562 5.54359 6.87051 5.51248 6.79219 5.51248H5.21719C5.13887 5.51248 5.06375 5.54359 5.00837 5.59897C4.95299 5.65436 4.92188 5.72947 4.92188 5.80779V6.39842C4.92187 6.47674 4.95299 6.55185 5.00837 6.60723C5.06375 6.66262 5.13887 6.69373 5.21719 6.69373H5.5125V8.26873H5.21719C5.13887 8.26873 5.06375 8.29984 5.00837 8.35522C4.95299 8.4106 4.92188 8.48572 4.92188 8.56404V9.15467C4.92187 9.23299 4.95299 9.3081 5.00837 9.36348C5.06375 9.41887 5.13887 9.44998 5.21719 9.44998H7.38281C7.46113 9.44998 7.53625 9.41887 7.59163 9.36348C7.64701 9.3081 7.67812 9.23299 7.67812 9.15467V8.56404C7.67812 8.48572 7.64701 8.4106 7.59163 8.35522C7.53625 8.29984 7.46113 8.26873 7.38281 8.26873Z"
          fill="#067A6F"
        />
      </g>
      <defs>
        <clipPath id="clip0_4413_24154">
          <rect width="12.6" height="12.6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Sample = Template.bind({})
Sample.args = {
  children: <IconBtn />,
  description: 'Tooltip description',
  placement: 'right',
}
