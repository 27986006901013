import { Box } from 'components/layout'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import React from 'react'
import { styled } from 'stitches/stitches.config'
import { StyledEmptyIcon, StyledNotice, StyledTd } from '../table.styles'
import { ITableEmptyNotice } from '../table.types'

const StyledBox = styled(Box, {
  width: '100%',
  height: '100%',
  backgroundColor: '$white',
  variants: {
    loading: {
      true: {
        opacity: 0.1,
      },
    },
  },
})

export const TableEmptyNotice: React.FC<ITableEmptyNotice> = ({
  title,
  subtitle,
  action,
  icon,
  loading,
  type = 'cta',
}) => {
  return (
    <StyledBox loading={loading}>
      {type === 'notice' ? (
        <StyledTd>
          <Text size="xs" color="$secondary">
            {title}
          </Text>
        </StyledTd>
      ) : (
        <StyledNotice
          direction="column"
          align="center"
          justify="center"
          gutterY="7"
        >
          <>
            <Flex
              direction="column"
              align="center"
              justify="center"
              gutterY="5"
            >
              {icon ?? <StyledEmptyIcon />}
              <Flex
                direction="column"
                align="center"
                justify="center"
                gutterY="3"
              >
                {title && (
                  <Text
                    color="$primary"
                    variant="h3"
                    size="md"
                    align="center"
                    weight="bold"
                  >
                    {title}
                  </Text>
                )}
                {subtitle && (
                  <Text
                    color="$secondary"
                    size="xs"
                    align="center"
                    css={{ width: '80%', textAlign: 'center' }}
                  >
                    {subtitle}
                  </Text>
                )}
              </Flex>
            </Flex>

            {action}
          </>
        </StyledNotice>
      )}
    </StyledBox>
  )
}
