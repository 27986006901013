import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { keyframes, styled } from 'stitches/stitches.config'

const slideUpAndFade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateY(10px)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateY(0)',
  },
})

const slideRightAndFade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(-10px)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(0)',
  },
})

const slideDownAndFade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateY(-10px)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateY(0)',
  },
})

const slideLeftAndFade = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(10px)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(0)',
  },
})

export const StyledTrigger = styled(TooltipPrimitive.Trigger, {
  border: 'none',
  background: 'transparent',
  padding: 0,
  cursor: 'pointer',
  outline: 'none',
})

export const StyledContent = styled(TooltipPrimitive.Content, {
  borderRadius: '$3',
  padding: '$2',
  fontSize: 10,
  lineHeight: 1,
  color: '$white',
  maxWidth: '30rem',
  backgroundColor: '#171717',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '600ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
})

export const StyledArrow = styled(TooltipPrimitive.Arrow, {
  fill: '#171717',
})

// Exports
export const Provider = TooltipPrimitive.Provider
export const TooltipRoot = TooltipPrimitive.Root
