import Button from 'components/button/button'
import DateInput from 'components/date-input/date-input'
import { Drawer } from 'components/drawer/drawer'
import Input from 'components/input/input'
import { Box, Flex } from 'components/layout'
import Separator from 'components/separator/separator'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select'
import {
  Journal,
  JournalEntryInput,
  useCreateJournalMutation,
  useGeneralLedgerAccountsQuery,
  useUpdateJournalMutation,
} from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import { useAppProvider } from 'providers/app-provider'
import React, { useEffect, useState } from 'react'
import {
  HiCalendar,
  HiDocumentAdd,
  HiOutlineExclamationCircle,
  HiPlus,
  HiTrash,
} from 'react-icons/hi'
import { styled } from 'stitches/stitches.config'
import { isUndefinedOrNull } from 'utils/assertions'
import { extractGraphqlErrors, formatMoney } from 'utils/helpers'
import { omit } from 'utils/object'

const StyledBox = styled(Box, {
  gap: 4,
  py: 8,
  px: 12,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '$errorBg',

  '.icon': { flexShrink: 0 },
})

type StickerProps = React.ComponentProps<typeof StyledBox>

const Sticker = React.forwardRef<HTMLDivElement, StickerProps>(
  ({ children, ...props }, ref) => {
    return (
      <StyledBox {...props} ref={ref}>
        {children}
      </StyledBox>
    )
  }
)

Sticker.displayName = 'Sticker'

interface NewJournalDrawerProps {
  visible: boolean
  onClose: () => void
  onSuccess?: (values?: any) => void
  journal?: Journal
}

type ID = string | number

type JournalEntry = {
  id: ID
  glAccountId: string
  transactionType: string
  amount: any
}

const defaultEntry = {
  id: 1,
  glAccountId: '',
  transactionType: '',
  amount: '',
}

function getEntries(journal: Journal) {
  return journal?.journalEntries.map((entry) => {
    return {
      id: entry.id,
      glAccountId: entry.glAccount.id,
      transactionType: entry.transactionType,
      amount: entry.transactionType === 'credit' ? entry.credit : entry.debit,
    }
  })
}

const NewJournalDrawer: React.FC<NewJournalDrawerProps> = ({
  visible,
  onClose,
  onSuccess,
  journal,
}) => {
  const notify = useToast()
  const {
    register,
    values,
    setInputValue,
    formIsComplete,
    errors,
    bulkUpdate,
  } = useForm({
    fields: {
      description: '',
      effectiveDate: new Date(),
    },
  })

  const isCreate = isUndefinedOrNull(journal?.id)

  const [entries, setEntries] = useState<JournalEntry[]>([
    defaultEntry,
    { ...defaultEntry, id: 2 },
  ])

  const { organisation } = useAppProvider()
  const currency = organisation?.currency.symbol

  const [{ data: accounts }] = useGeneralLedgerAccountsQuery()

  const [{ fetching: creatingJournal }, createJournalMutation] =
    useCreateJournalMutation()

  const [{ fetching: updatingJournal }, updateJournalMutation] =
    useUpdateJournalMutation()

  useEffect(() => {
    if (!journal) {
      return
    }
    bulkUpdate({
      description: journal?.description,
      effectiveDate: new Date(journal?.effectiveDate),
    })
    const entries = getEntries(journal)
    setEntries(entries)
  }, [journal?.id])

  function addJournalEntry() {
    setEntries((entries) => [
      ...entries,
      { ...defaultEntry, id: entries.length + 1 },
    ])
  }

  function removeEntry(id: ID) {
    setEntries((entries) => entries.filter((entry) => entry.id !== id))
  }

  function updateEntry(id: ID, name: string, value: any) {
    setEntries((entries) => {
      return entries.map((entry) => {
        if (entry.id === id) {
          return { ...entry, [name]: value }
        }
        return entry
      })
    })
  }

  function onExit() {
    bulkUpdate({ description: '', effectiveDate: new Date() })
    setEntries([defaultEntry, { ...defaultEntry, id: 2 }])
    onClose()
  }

  function getFilteredAccounts(entryId: ID) {
    const entry = entries.find((entry) => entry.id === entryId)
    const glAccounts = accounts?.generalLedgerAccounts ?? []
    if (entry?.transactionType == 'credit') {
      return glAccounts.filter((account) => account.isCredit)
    } else if (entry?.transactionType == 'debit') {
      return glAccounts.filter((account) => !account.isCredit)
    }
    return glAccounts
  }

  async function saveJournal() {
    try {
      const payload = {
        ...values,
        journalEntries: entries.map((entry) =>
          omit(entry, ['id'])
        ) as JournalEntryInput[],
      }
      const response = await createJournalMutation({
        input: payload,
      })
      const error = extractGraphqlErrors(response, 'createJournal')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({ content: 'Journal saved successfully', status: 'success' })
      onExit()
      onSuccess?.()
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  async function updateJournal() {
    if (!journal) {
      return
    }
    try {
      const payload = {
        ...values,
        id: journal?.id,
        journalEntries: entries as JournalEntryInput[],
      }
      const response = await updateJournalMutation({
        input: payload,
      })
      const error = extractGraphqlErrors(response, 'updateJournal')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({ content: 'Journal updated successfully', status: 'success' })
      onExit()
      onSuccess?.()
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  const totalCredit = entries.reduce(
    (acc, entry) =>
      acc + (entry.transactionType === 'credit' ? entry.amount : 0),
    0
  )
  const totalDebit = entries.reduce(
    (acc, entry) =>
      acc + (entry.transactionType === 'debit' ? entry.amount : 0),
    0
  )
  const isEntriesValid = totalCredit === totalDebit

  const hasEmpty = entries.some((entry) =>
    Object.values(entry).some((value) => !value)
  )

  const canSave = formIsComplete && !hasEmpty && isEntriesValid

  return (
    <Drawer
      title={isCreate ? 'New manual journal' : 'Update manual journal'}
      titleIcon={<HiDocumentAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onExit}
      css={{ width: '50vw' }}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onExit}>
            Cancel
          </Button>
          <Button
            size="md"
            disabled={creatingJournal || updatingJournal || !canSave}
            isLoading={creatingJournal || updatingJournal}
            onClick={isCreate ? saveJournal : updateJournal}
          >
            {isCreate ? 'Save Journal' : 'Update Journal'}
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="5" css={{ p: '$5' }}>
        <Flex align="center" justify="between" gutter="5">
          <DateInput
            css={{
              flexBasis: '50%',
            }}
            defaultValue={values.effectiveDate}
            required
            append={<HiCalendar color="#ABB3B9" />}
            label="Date"
            placeholder="Select date"
            dateFormat="MMM dd, yyyy"
            value={values.effectiveDate}
            onChange={(e) => {
              setInputValue('effectiveDate', new Date(e.target.value))
            }}
          />

          <Input
            css={{
              flexBasis: '50%',
            }}
            {...register('description')}
            label="Description"
            required
            placeholder="Enter description"
            error={errors.description}
          />
        </Flex>

        {entries.map((entry) => (
          <Box key={entry.id}>
            <Flex gutter="5" align="start">
              <TypeAheadSelect
                css={{ flexBasis: '40%' }}
                required
                label="General Ledger Account"
                placeholder="Select account"
                options={getFilteredAccounts(entry.id)}
                valueKey="id"
                labelKey="name"
                value={entry.glAccountId}
                renderValue={(value: any) => (
                  <Flex align="center" gutterX="2" css={{ py: '1.2rem' }}>
                    <Text size="xs">{value.name}</Text>
                  </Flex>
                )}
                onChange={(value) =>
                  updateEntry(entry.id, 'glAccountId', value as string)
                }
              />
              <TypeAheadSelect
                css={{ flexBasis: '30%' }}
                required
                label="Type"
                placeholder="Choose type"
                value={entry.transactionType}
                options={[
                  {
                    label: 'Debit',
                    value: 'debit',
                  },
                  {
                    label: 'Credit',
                    value: 'credit',
                  },
                ]}
                onChange={(value) =>
                  updateEntry(entry.id, 'transactionType', value as string)
                }
              />
              <Input
                css={{ flexBasis: '30%' }}
                required
                label="Amount"
                defaultValue={entry.amount}
                placeholder="0.00"
                type="number"
                step="any"
                onChange={(e) =>
                  updateEntry(entry.id, 'amount', Number(e.target.value))
                }
              />
              {isCreate && (
                <Button
                  appearance="ghost"
                  onClick={() => removeEntry(entry.id)}
                  css={{ alignSelf: 'center' }}
                >
                  <HiTrash color="#F15656" />
                </Button>
              )}
            </Flex>
          </Box>
        ))}

        <Separator color="#EEF2F4" css={{ margin: 0, padding: 0 }} />

        <Flex gutter="5" align="start">
          <Text
            css={{ flexBasis: '40%' }}
            color="#171717"
            size="xs"
            weight="bold"
          >
            Total
          </Text>
          <Text
            css={{ flexBasis: '30%' }}
            color="#171717"
            size="xs"
            weight="bold"
          >
            Debit({formatMoney(totalDebit, currency)})
          </Text>
          <Text
            css={{ flexBasis: '30%' }}
            color="#171717"
            size="xs"
            weight="bold"
          >
            Credit {formatMoney(totalCredit, currency)}
          </Text>
        </Flex>

        {!isEntriesValid && (
          <Sticker>
            <Box color="Tomato">
              <HiOutlineExclamationCircle
                className="icon"
                size={14}
                color="Tomato"
              ></HiOutlineExclamationCircle>
            </Box>

            <Text size="xs" color="Tomato">
              Your entries are not balanced. It is off by{' '}
              <Text size="xs" color="Tomato" weight="bold">
                {formatMoney(Math.abs(totalDebit - totalCredit), currency)}
              </Text>
            </Text>
          </Sticker>
        )}

        {isCreate && (
          <Button
            appearance="primary"
            fullWidth
            size="lg"
            onClick={addJournalEntry}
          >
            <Flex align="center" color="White" css={{ columnGap: 5 }}>
              <HiPlus />
              <Text>Add new line</Text>
            </Flex>
          </Button>
        )}
      </Flex>
    </Drawer>
  )
}

export default NewJournalDrawer
