/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import { motion } from 'framer-motion'
import React from 'react'
import { HiChevronDown, HiChevronRight } from 'react-icons/hi'
import { useLocation, useNavigate } from 'react-router-dom'
import { StyledMenuBar, StyledMenuItem, StyledSubMenu } from './menu-bar.styles'
import { MenuBarProps } from './menu-bar.types'

const MenuBar = React.forwardRef<HTMLDivElement, MenuBarProps>(
  ({ data, type = 'dashboard', ...MenuBarProps }, ref) => {
    const [itemsData, setItemsData] = React.useState<any>(data)
    const navigate = useNavigate()
    const location = useLocation()
    const pathName = location.pathname
    // const labelColor = '$secondary'

    function handleActive(key: string, path: string, subMenu: any) {
      const hasSubMenu = subMenu && subMenu.length > 0
      const isActive = pathName === path

      if (hasSubMenu) {
        const initialTo = subMenu[0].path
        navigate(initialTo as string)
        setItemsData(
          itemsData.map((item: any) => {
            if (item.key === key) {
              item.active = isActive || !item.active
            } else {
              item.active = false
            }
            return item
          })
        )
        return
      }

      navigate(path)
      setItemsData(
        itemsData.map((item: any) => {
          if (item.key === key) {
            item.active = isActive || !item.active
          } else {
            item.active = false
          }
          return item
        })
      )
    }

    function handleSubMenuActive(key: string, path: string) {
      const isActive = pathName === path
      if (isActive) return
      navigate(path)
      setItemsData(
        itemsData.map((item: any) => {
          if (item.subMenu) {
            item.subMenu.map((child: any) => {
              if (child.key === key) {
                child.isSubMenuActive = isActive || !child.isSubMenuActive
              } else {
                child.isSubMenuActive = false
              }
            })
          }
          return item
        })
      )
    }

    React.useEffect(() => {
      setItemsData(
        itemsData.map((item: any) => {
          if (item.path === pathName) {
            item.active = true
          } else {
            if (type === 'dashboard') {
              item.active = false
            } else {
              item.active = true
            }
          }
          return item
        })
      )
      setItemsData(
        itemsData.map((item: any) => {
          if (item.subMenu) {
            item.subMenu.map((child: any) => {
              if (child.path === pathName) {
                child.isSubMenuActive = true
                item.active = true
              } else {
                child.isSubMenuActive = false
              }
            })
          }
          return item
        })
      )
    }, [pathName])

    return (
      <StyledMenuBar direction="column" {...MenuBarProps} ref={ref}>
        {itemsData.map(
          ({ key, path, icon, iconActive, active, label, subMenu }: any) => (
            <Flex direction="column" key={key}>
              <StyledMenuItem
                align="center"
                justify="between"
                active={active}
                // type={type}
                onClick={
                  type === 'dashboard'
                    ? () => handleActive(key, path, subMenu)
                    : () => undefined
                }
                css={{
                  backgroundColor: active
                    ? subMenu
                      ? 'transparent'
                      : '$highlightBg'
                    : 'transparent',
                  borderRadius: active ? '$2' : '0',

                  '.icon': {
                    svg: {
                      color:
                        active && type === 'dashboard'
                          ? '$highlight'
                          : '$secondary',
                    },
                  },
                }}
              >
                <Flex
                  align="center"
                  gutterX="5"
                  css={{
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <Box css={{ width: '25%' }} className="icon">
                    <motion.div
                      initial={{ y: active ? 20 : 0, opacity: 0 }}
                      animate={{ y: active ? 0 : 20, opacity: 1 }}
                      transition={{
                        delay: 0.1,
                        type: 'spring',
                        stiffness: 100,
                        damping: 10,
                      }}
                    >
                      {iconActive || icon}
                    </motion.div>
                    <motion.div
                      initial={{ y: active ? -20 : 0, opacity: 0 }}
                      animate={{ y: 0, opacity: active ? 0 : 1 }}
                      transition={{ duration: 0 }}
                    >
                      {icon}
                    </motion.div>
                  </Box>

                  <Text
                    weight="semi"
                    size="xs"
                    css={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                      color: (subMenu ? false : active)
                        ? '$highlight'
                        : '$secondary',
                    }}
                  >
                    {label}
                  </Text>
                </Flex>
                {type === 'dashboard' &&
                  subMenu &&
                  (active ? (
                    <HiChevronDown className="chevron" />
                  ) : (
                    <HiChevronRight className="chevron" />
                  ))}
              </StyledMenuItem>

              {subMenu &&
                subMenu.map(({ key, isSubMenuActive, label, path }: any) => (
                  <StyledSubMenu
                    active={active}
                    isSubMenuActive={active && isSubMenuActive}
                    key={key}
                    onClick={() => handleSubMenuActive(key, path)}
                  >
                    <Text
                      color={isSubMenuActive ? '$highlight' : '$gray'}
                      weight="semi"
                      size="xs"
                    >
                      {label}
                    </Text>
                  </StyledSubMenu>
                ))}
            </Flex>
          )
        )}
      </StyledMenuBar>
    )
  }
)

MenuBar.displayName = 'MenuBar'

export default MenuBar
