import { styled } from 'stitches/stitches.config'

export const StyledAvatar = styled('span', {
  display: 'grid',
  placeItems: 'center',
  font: 'inherit',
  fontFamily: '$primary',
  overflow: 'hidden',
  color: '$white',
  textTransform: 'uppercase',

  defaultVariants: {
    size: 'large',
    shape: 'circle',
  },

  variants: {
    size: {
      small: {
        minWidth: '1.25rem !important',
        maxWidth: '1.25rem !important',
        minHeight: '1.25rem !important',
        maxHeight: '1.25rem !important',
        fontSize: '0.45rem',

        '& svg': {
          minWidth: '0.6rem !important',
          maxWidth: '0.6rem !important',
          minHeight: '0.6rem !important',
          maxHeight: '0.6rem !important',
        },
      },

      default: {
        minWidth: '1.5rem !important',
        maxWidth: '1.5rem !important',
        minHeight: '1.5rem !important',
        maxHeight: '1.5rem !important',
        fontSize: '0.6rem',

        '& svg': {
          minWidth: '0.8rem !important',
          maxWidth: '0.8rem !important',
          minHeight: '0.8rem !important',
          maxHeight: '0.8rem !important',
        },
      },

      medium: {
        minWidth: '2.4rem !important',
        maxWidth: '2.4rem !important',
        minHeight: '2.4rem !important',
        maxHeight: '2.4rem !important',
        fontSize: '1.1rem',

        '& svg': {
          minWidth: '1rem !important',
          maxWidth: '1rem !important',
          minHeight: '1rem !important',
          maxHeight: '1rem !important',
        },
      },

      large: {
        minWidth: '3.5rem !important',
        maxWidth: '3.5rem !important',
        minHeight: '3.5rem !important',
        maxHeight: '3.5rem !important',
        fontSize: '1.5rem',

        '& svg': {
          minWidth: '1.5rem !important',
          maxWidth: '1.5rem !important',
          minHeight: '1.5rem !important',
          maxHeight: '1.5rem !important',
        },
      },

      xtraLarge: {
        minWidth: '6.5rem !important',
        maxWidth: '6.5rem !important',
        minHeight: '6.5rem !important',
        maxHeight: '6.5rem !important',
        fontSize: '2rem',

        '& svg': {
          minWidth: '2.5rem !important',
          maxWidth: '2.5rem !important',
          minHeight: '2.5rem !important',
          maxHeight: '2.5rem !important',
        },
      },
    },

    shape: {
      circle: {
        borderRadius: '100%',
      },

      square: {
        borderRadius: '$1',
      },
    },

    appearance: {
      outline: {
        border: '.1rem solid $border',
        boxShadow: '$tiny',
        background: '$white !important',
        color: '$secondary !important',
      },
    },
  },
})

export const StyledImg = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
})
