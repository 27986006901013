import { Flex } from 'components/layout'
import { Text } from 'components/text/text'
import { styled } from 'stitches/stitches.config'

export const TableContainer = styled(Flex, {
  backgroundColor: '$white',
  borderRadius: '1rem',
  boxShadow: '$tiny',
  width: '100%',
  marginBottom: 20,
})

export const TableTop = styled(Flex, {
  padding: '2rem',
  borderBottom: '0.1rem solid $border',
})

export const TextWithHover = styled(Text, {
  '&:hover': {
    color: '$highlight',
    transition: 'all 0.2s',
  },
})
