import Calendar from 'components/calendar'
import Input from 'components/input/input'
import Box from 'components/layout/box'
import React from 'react'
import { CalendarContainer, DateInputContainer } from './date-input.styles'
import { IProps } from './date-input.types'
import useDateInput from './use-date-input'

export const DateInput = React.forwardRef<HTMLDivElement, IProps>(
  (
    {
      isMonthPicker,
      showDefaultDate,
      defaultValue,
      onChange,
      onDateRangeChange,
      name,
      dateFormat = 'yyyy/MM/d',
      minimumDate,
      maximumDate,
      css,
      mode,
      defaultDateText,
      dates,
      ...props
    },
    ref
  ) => {
    const {
      isOpen,
      hide,
      calendarRef,
      inputRef,
      inputRect,
      date,
      dateText,
      onDateSelect,
      onTextChange,
      handleKeyUp,
      setDateFromText,
      onInputFocus,
      onDateRangeSelect,
    } = useDateInput({
      dates,
      showDefaultDate,
      defaultValue,
      onChange,
      onDateRangeChange,
      name,
      isMonthPicker,
      dateFormat,
      defaultDateText,
      css,
      ...props,
    })

    React.useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (
          inputRef.current &&
          !inputRef.current.contains(event.target as Node)
        ) {
          hide()
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [hide])

    return (
      <Box css={{ height: '100%', ...css }} ref={inputRef}>
        <DateInputContainer ref={ref}>
          <Input
            {...props}
            size="sm"
            appearance="secondary"
            onBlur={setDateFromText}
            onKeyUp={handleKeyUp}
            onChange={onTextChange}
            onFocus={onInputFocus}
            value={dateText}
            ref={inputRef}
            css={{ height: '100%' }}
          ></Input>
          {isOpen && (
            <CalendarContainer
              ref={calendarRef}
              tabIndex={0}
              css={{ top: (inputRect?.height as number) + 6 }}
            >
              <Calendar
                onClose={hide}
                onSelect={onDateSelect}
                selectedDate={dates || date}
                minimumDate={minimumDate}
                maximumDate={maximumDate}
                mode={mode}
                isMonthPicker={isMonthPicker}
                onDateRangeSelect={onDateRangeSelect}
              ></Calendar>
            </CalendarContainer>
          )}
        </DateInputContainer>
      </Box>
    )
  }
)

DateInput.displayName = 'DateInput'

export default DateInput
