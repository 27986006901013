import magicBolt from 'assets/svg/magic-bolt.svg'
import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Input from 'components/input/input'
import { Box } from 'components/layout'
import Flex from 'components/layout/flex'
import Loader from 'components/loader/loader'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select/type-ahead-select'
import {
  BankAccountLinker,
  useBanksQuery,
  useCreateFinancialAccountMutation,
  useGetPlaidLinkTokenQuery,
  useLinkBankAccountToMonoMutation,
  useLinkFinancialAccountMutation,
} from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import useMono from 'hooks/useMono'
import { useAppProvider } from 'providers/app-provider'
import React from 'react'
import { BiSolidBank } from 'react-icons/bi'
import { PiBankBold } from 'react-icons/pi'
import { PlaidLinkOnSuccess, usePlaidLink } from 'react-plaid-link'
import { styled } from 'stitches/stitches.config'
import { extractGraphqlErrors } from 'utils/helpers'

interface IProps {
  visible: boolean
  onClose: () => void
  onAccountAddSuccess?: () => void
  refetchFinancialAccounts?: () => void
}

export const AddAccountDrawer: React.FC<IProps> = ({
  visible,
  onClose,
  onAccountAddSuccess,
  refetchFinancialAccounts,
}) => {
  const { organisation } = useAppProvider()
  const toast = useToast()
  const countryCode =
    (organisation?.countryCode as string) ??
    localStorage.getItem('countryCode') ??
    ''
  const country = React.useMemo(() => countryCode, [])
  const countriesWithBankSupport = ['NG', 'GH', 'KE', 'ZA']
  const countriesWithLinkSupport = [
    'US',
    'CA',
    'GB',
    'DE',
    'FR',
    'NL',
    'IE',
    'ES',
    'SE',
    'DK',
    'PT',
  ]

  const {
    register,
    values,
    setInputValue,
    errors,
    formIsComplete,
    onChange,
    clearForm,
  } = useForm({
    fields: {
      title: '',
      bankName: '',
      accountNumber: '',
      routingNumber: '',
      currencyId: '',
      country: '',
    },
    optional: ['bankName', 'routingNumber', 'country', 'currencyId'],
  })

  const [canSubmit, setCanSubmit] = React.useState(false)

  const [{ data: banksData }] = useBanksQuery({
    variables: {
      countryCode: country,
    },
    pause: !country,
  })

  const banksMap =
    banksData?.banks?.map((bank) => {
      return {
        label: bank.name,
        value: bank.name,
      }
    }) ?? []

  function notify(error?: string, status?: 'success' | 'error') {
    toast({
      content: error ?? 'something went wrong',
      status: status,
    })
  }

  const [{ fetching }, addFinancialAccount] =
    useCreateFinancialAccountMutation()

  function handleAccountSuccess() {
    // notify('Financial account succesfully added', 'success')
    refetchFinancialAccounts?.()
    onClose()
    clearForm()
    localStorage.removeItem('countryCode')
    localStorage.removeItem('countryId')
    onAccountAddSuccess?.()
  }

  async function handleAddFinancialAccount() {
    try {
      const response = await addFinancialAccount({
        input: {
          title: values.title,
          bankName: values.bankName,
          accountNumber: values.accountNumber,
          routingNumber: values.routingNumber,
          currencyId:
            (organisation?.currency?.id as string) ||
            (localStorage.getItem('countryId') as string),
          countryCode: country,
        },
      })

      const error = extractGraphqlErrors(response, 'createFinancialAccount')

      if (error) {
        notify(error, 'error')
        return
      }
      handleAccountSuccess()
    } catch (error) {
      notify(error as string, 'error')
    }
  }

  function resetAndClose() {
    onClose()
  }

  const [{ fetching: isGettingtingPlaidLink, data: plaidData }] =
    useGetPlaidLinkTokenQuery()

  const [, linkPlaidAccount] = useLinkFinancialAccountMutation()

  const onSuccess = React.useCallback<PlaidLinkOnSuccess>(
    async (publicToken) => {
      // get the stringified account data from localStorage and parse it
      const accountData = JSON.parse(
        localStorage.getItem('accountData') as string
      )

      const payload = {
        enrollmentToken: publicToken,
        provider: BankAccountLinker.Plaid,
        title: accountData.title,
        countryCode: accountData.countryCode,
      }

      onClose()
      try {
        const response = await linkPlaidAccount({
          input: payload,
        })

        const error = extractGraphqlErrors(response, 'linkFinancialAccount')

        if (error) {
          notify(error, 'error')
          return
        }
        handleAccountSuccess()
      } catch (error) {
        notify(error as string, 'error')
      } finally {
        clearForm()
        // setCountry('')
      }
    },
    []
  )

  const { open, ready } = usePlaidLink({
    token: plaidData?.getPlaidLinkToken?.linkToken as string,
    onSuccess,
  })

  const monoConnect = useMono({
    onSuccess: (code) => linkAccount(code.code),
  })
  const [, linkFinancialAccount] = useLinkBankAccountToMonoMutation()

  async function linkAccount(code: string) {
    try {
      const response = await linkFinancialAccount({
        input: {
          title: values.title,
          countryCode: country,
          code,
        },
      })

      const error = extractGraphqlErrors(response, 'linkBankAccountToMono')

      if (error) {
        notify(error, 'error')
        return
      }
      handleAccountSuccess()
    } catch (error) {
      notify(error as string, 'error')
    } finally {
      clearForm()
      // setCountry('')
    }
  }

  return (
    <Drawer
      title="New Financial Account"
      titleIcon={<BiSolidBank size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={resetAndClose}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={resetAndClose}>
            Cancel
          </Button>
          <Button
            isLoading={fetching}
            disabled={!formIsComplete || !canSubmit}
            onClick={handleAddFinancialAccount}
            size="md"
          >
            Add Account
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="5" css={{ p: '$5' }}>
        {countriesWithLinkSupport.includes(country) &&
          isGettingtingPlaidLink && <Loader size="sm" color="$black" />}
        {countriesWithLinkSupport.includes(country) && (
          <>
            <StyledBanner justify="between" type="europe">
              <Flex direction="column" gutter={2} className="text">
                <Text
                  css={{
                    opacity: 0.6,
                    whiteSpace: 'nowrap',
                  }}
                  color="white"
                  size="xxs"
                  weight={'semi'}
                >
                  LINK EXTERNAL BANK ACCOUNT WITH FUSE
                </Text>
                <Button
                  css={{ width: 'min-content', py: '$3' }}
                  size="xs"
                  appearance="secondary"
                  disabled={!ready}
                  onClick={() => {
                    localStorage.setItem(
                      'accountData',
                      JSON.stringify({
                        title: values.title,
                        countryCode: country,
                      })
                    )
                    open()
                  }}
                  prepend={<PiBankBold size="1.4rem" color="#848C96" />}
                >
                  <Text size={'xxs'} color="$secondary">
                    Connect bank account
                  </Text>
                </Button>
              </Flex>
              <img width={36} src={magicBolt} alt="magic bolt" />
            </StyledBanner>
            <Flex align="center" gutter="4">
              <Box
                css={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '$disabled',
                }}
              ></Box>
              <Text
                size="xxs"
                weight="bold"
                color="gray"
                css={{
                  whiteSpace: 'nowrap',
                }}
              >
                or add manually
              </Text>
              <Box
                css={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '$disabled',
                }}
              ></Box>
            </Flex>
          </>
        )}

        {country === 'NG' && (
          <>
            <StyledBanner justify="between">
              <Flex direction="column" gutter={2} className="text">
                <Text
                  css={{
                    opacity: 0.6,
                    whiteSpace: 'nowrap',
                  }}
                  color="white"
                  size="xxs"
                  weight={'semi'}
                >
                  LINK EXTERNAL BANK ACCOUNT WITH MONO
                </Text>
                <Button
                  css={{ width: 'min-content', py: '$3' }}
                  size="xs"
                  appearance="secondary"
                  onClick={() => monoConnect.open()}
                >
                  <Text size={'xxs'} color="$secondary">
                    Connect bank account
                  </Text>
                </Button>
              </Flex>
              <img width={26} src={magicBolt} alt="magic bolt" />
            </StyledBanner>
            {/* <Accordion
              css={{
                p: '0',
                mt: '0',
              }}
              tabs={[
                {
                  title: 'Why should i link my bank account?',
                  content:
                    'Linking your bank account with Mono allows us to pull your last 2 years bank transactions automatically, so you dont have to upload them manually.',
                },
              ]}
            /> */}

            <Flex align="center" gutter="4">
              <Box
                css={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '$disabled',
                }}
              ></Box>
              <Text
                size="xxs"
                weight="bold"
                color="gray"
                css={{
                  whiteSpace: 'nowrap',
                }}
              >
                or add manually
              </Text>
              <Box
                css={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '$disabled',
                }}
              ></Box>
            </Flex>
          </>
        )}
        <Input
          label="Title"
          required
          placeholder="Financial Account name"
          {...register('title')}
          error={errors.title}
        />
        {!countriesWithBankSupport.includes(country) && (
          <Input
            required
            label="Routing Number"
            placeholder="Add routing number"
            {...register('routingNumber')}
            onChange={(val) => {
              onChange(val)
              setCanSubmit(true)
            }}
            error={errors.routingNumber}
          />
        )}
        {country && (
          <>
            {countriesWithBankSupport.includes(country) && (
              // <>
              <TypeAheadSelect
                required
                label="Bank"
                placeholder="Search for a bank"
                options={banksMap}
                onChange={(value) => {
                  setInputValue('bankName', value as string)
                  setCanSubmit(true)
                }}
                value={values.bankName}
                error={errors.bankName}
              />
              // </>
            )}

            <Input
              required
              label="Account Number"
              placeholder="Add account number"
              {...register('accountNumber')}
              error={errors.accountNumber}
            />
          </>
        )}
        {/* {country === 'NG' && (
          <>
            <StyledBanner justify="between">
              <Flex direction="column" gutter={2} className="text">
                <Text
                  css={{
                    opacity: 0.6,
                    whiteSpace: 'nowrap',
                  }}
                  color="white"
                  size="xxs"
                  weight={'semi'}
                >
                  LINK EXTERNAL BANK ACCOUNT WITH MONO
                </Text>
                <Button
                  css={{ width: 'min-content', py: '$3' }}
                  size="xs"
                  appearance="secondary"
                  onClick={() => monoConnect.open()}
                >
                  <Text size={'xxs'} color="$secondary">
                    Connect bank account
                  </Text>
                </Button>
              </Flex>
              <img width={26} src={magicBolt} alt="magic bolt" />
            </StyledBanner>

            <Flex align="center" gutter="4">
              <Box
                css={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '$disabled',
                }}
              ></Box>
              <Text
                size="xxs"
                weight="bold"
                color="gray"
                css={{
                  whiteSpace: 'nowrap',
                }}
              >
                or add manually
              </Text>
              <Box
                css={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '$disabled',
                }}
              ></Box>
            </Flex>
          </>
        )} */}
      </Flex>
    </Drawer>
  )
}

const StyledBanner = styled(Flex, {
  px: 16,
  py: 22,
  borderRadius: 10,
  background: 'linear-gradient(270deg, #398AFA 0%, #73AEFF 100%), #FFFFFF',
  variants: {
    type: {
      europe: {
        background:
          'linear-gradient(270deg, #333333 0%, #000000 100%), #FFFFFF',
      },
    },
  },
  '.text': { width: '70%' },
})
