import Flex from 'components/layout/flex'
import { styled } from 'stitches/stitches.config'

export const TableContainer = styled(Flex, {
  backgroundColor: '$white',
  borderRadius: '1rem',
  boxShadow: '$tiny',
  width: '100%',
})

export const TableTop = styled(Flex, {
  padding: '2rem',
  borderBottom: '0.1rem solid $border',
})
