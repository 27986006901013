import Button from 'components/button/button'
import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import { styled } from 'stitches/stitches.config'

export const StyleContent = styled(Box, {
  background: '$white',
  borderRadius: '$3',
  border: '1px solid $border',
  boxShadow: '$subtle',
})

export const StyleFlex = styled(Flex, {
  borderLeft: '2px solid $border',
  pl: '$2',
})

export const StyledProductItem = styled(Flex, {
  p: '$2',
  border: '1px solid $border',
  boxShadow: '$subtle',
  borderRadius: '$2',
})

export const StyledProductDetails = styled(Flex, {
  //   p: '$4',
  border: '1px solid $border',
  boxShadow: '$subtle',
  borderRadius: '$3',
})

export const StyledButton = styled(Button, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mx: '$5',
  mb: '$9',
})

export const StyledDetails = styled(Flex, {
  p: '$3 $5',
  border: '1px solid $border',
  boxShadow: '$subtle',
  borderRadius: '$3',
})
