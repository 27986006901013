import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import Input from 'components/input/input'
import { Flex } from 'components/layout'
import { Text } from 'components/text/text'
import { Discount } from 'generated/__generated_graphql'
import {
  HiDocumentAdd,
  HiOutlineExternalLink,
  HiOutlinePlus,
  HiX,
} from 'react-icons/hi'
import { styled } from 'stitches/stitches.config'
import { formatMoney } from 'utils/helpers'

type IItem = Discount | any
type ItemType = 'tax' | 'discount'
type ItemTextValue = {
  titleText: string
  newText: string
  addText: string
  placeholder: string
}

interface SummaryItemProps {
  items: IItem[]
  type: ItemType
  item?: IItem
  currency?: string
  total: number
  onItem?: (item?: IItem) => void
  onNewItem?: () => void
}

const Item = styled(Flex, {
  padding: '0.8rem 1.2rem',
  borderRadius: 4,
  boxShadow: '$subtle',
  border: '0.1rem solid $border',
  width: '19rem',
})

const NewItemWrapper = styled(Flex, {
  backgroundColor: '$highlightBg',
  height: '4rem',
  px: '1.4rem',
})

const ItemSearch = styled(Input, {
  '&  .input-wrapper': {
    borderRadius: '0 !important',
    border: 'none !important',
    borderBottom: '0.1rem solid $border !important',
    backgroundColor: '$white !important',
    boxShadow: 'none !important',
  },
})

const ItemMap: Record<ItemType, ItemTextValue> = {
  tax: {
    titleText: 'Tax',
    newText: 'Create new tax',
    addText: 'Add tax',
    placeholder: 'Search tax',
  },
  discount: {
    titleText: 'Discount',
    newText: 'Create new discount',
    addText: 'Add discount',
    placeholder: 'Search discount',
  },
}

const SummaryItem: React.FC<SummaryItemProps> = ({
  items,
  type,
  item,
  total,
  currency,
  onItem,
  onNewItem,
}) => {
  const itemText = ItemMap[type]

  if (items?.[0]?.__typename === 'Tax') {
    items = items.filter((item) => item.isActive)
  }

  return (
    <Flex align="center" justify="between" stretchx>
      <Text size="xs" weight="semi" color="$primary">
        {itemText.titleText}
      </Text>
      {item ? (
        <Item justify="between" css={{ margin: '0 1.6rem 0 auto' }}>
          <Text size="xxs" weight="semi" color="$primary">
            {`${item.name} (${item.rate}%)`}
          </Text>
          <HiX
            role="button"
            color="#ABB3B9"
            onClick={() => {
              onItem?.(undefined)
              console.log(item)
            }}
          />
        </Item>
      ) : (
        <Dropdown
          closeOnMenuItemClick
          menu={items.map((item) => {
            return {
              label: `${item.name} (${item.rate}%)`,
              onClick: () => onItem?.(item),
            }
          })}
          header={
            <div>
              <ItemSearch placeholder={itemText.placeholder} />
              <Button fullWidth appearance="ghost" onClick={onNewItem}>
                <NewItemWrapper stretchx justify="between" align="center">
                  <Flex align="center" gutterX="2">
                    <HiDocumentAdd color="#398AFA" />
                    <Text size="xs" color="$blue">
                      {itemText.newText}
                    </Text>
                  </Flex>
                  <HiOutlineExternalLink color="#398AFA" />
                </NewItemWrapper>
              </Button>
            </div>
          }
        >
          <Button
            appearance="ghost"
            prepend={<HiOutlinePlus color="#398AFA" />}
          >
            <Text size="xs" weight="semi" color="$blue">
              {itemText.addText}
            </Text>
          </Button>
        </Dropdown>
      )}
      <Flex css={{ minWidth: '6rem' }}>
        <Text size="xs" weight="semi" color="$secondary">
          {formatMoney(total, currency)}
        </Text>
      </Flex>
    </Flex>
  )
}

export default SummaryItem
