import * as Dialog from '@radix-ui/react-dialog'
import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import { Flex } from 'components/layout'
import Chat from 'pages/reports/components/chat'
import {
  StyledOptions,
  StyledOptionsContainer,
} from 'pages/settings/settings.styles'
import React, { useState } from 'react'
import { HiDotsHorizontal } from 'react-icons/hi'
import { getApiUrl } from 'utils/helpers'
import { CleanServiceReport } from '../service-report'
import {
  DialogContent,
  DialogOverlay,
} from './table-action-unprocessed-service-reports'

type ProcessedReportsTableDataActionProps = {
  report: CleanServiceReport
}

const ProcessedReportsTableDataAction: React.FC<
  ProcessedReportsTableDataActionProps
> = ({ report }) => {
  const [showReportChatDrawer, setShowReportChatDrawer] = useState(false)

  function handleProcessedReportDownload() {
    const url = `${getApiUrl()}/smart-reports/${report.id}.pdf`
    const a = document.createElement('a')
    a.href = url as string
    a.target = '_blank'
    a.click()
    URL.revokeObjectURL(a.href)
  }

  return (
    <>
      <Dialog.Root
        open={showReportChatDrawer}
        onOpenChange={() => setShowReportChatDrawer(true)}
      >
        <Flex stretchx justify="end">
          <Dropdown
            placement="bottomRight"
            menu={[
              {
                label: (
                  <Dialog.Trigger asChild>
                    <Button
                      css={{
                        width: '100%',
                        textAlign: 'left',
                        justifyContent: 'start',
                      }}
                      appearance={'ghost'}
                    >
                      Open chat
                    </Button>
                  </Dialog.Trigger>
                ),
              },
              {
                label: (
                  <Button
                    appearance={'ghost'}
                    css={{ width: '100%', justifyContent: 'start' }}
                  >
                    Download
                  </Button>
                ),
                onClick: handleProcessedReportDownload,
              },
            ]}
          >
            <StyledOptionsContainer stretchx align="center" justify="end">
              <StyledOptions appearance="ghost">
                <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
              </StyledOptions>
            </StyledOptionsContainer>
          </Dropdown>
        </Flex>

        <Dialog.Portal>
          <DialogOverlay onClick={() => setShowReportChatDrawer(false)} />

          <DialogContent>
            <Chat id={report.chat.id} />
          </DialogContent>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  )
}

export default ProcessedReportsTableDataAction
