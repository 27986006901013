import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { Span } from 'components/text/span'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import {
  useDeleteTaxMutation,
  useTaxesQuery,
} from 'generated/__generated_graphql'
import React from 'react'
import { HiTrash } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { TaxRatesTableData } from './tax-rates-table'

type DeleteTaxRateDrawerProps = {
  visible: boolean
  onClose: () => void
  tax: TaxRatesTableData['tax']
}

const DeleteTaxRateDrawer: React.FC<DeleteTaxRateDrawerProps> = (props) => {
  const { visible, onClose, tax } = props

  const notify = useToast()
  const [{ fetching: deletingTax }, deleteTax] = useDeleteTaxMutation()
  const [, queryTaxes] = useTaxesQuery()

  async function submit() {
    try {
      const response = await deleteTax({
        input: {
          taxId: tax.id,
        },
      })

      const error = extractGraphqlErrors(response, 'deleteTax')

      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }

      notify({
        content: tax.name + ' has been deleted',
        status: 'success',
      })

      queryTaxes({
        requestPolicy: 'network-only',
      })

      onClose()
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  return (
    <Drawer
      title="Delete Tax"
      titleIcon={<HiTrash size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      closable={!deletingTax}
      maskClosable={!deletingTax}
      fullHeight={false}
    >
      <Flex css={{ p: '$5', height: '100%' }} direction="column" gutter="3">
        <Text size="xs" color="$primary">
          Are you sure you want to delete{' '}
          <Span css={{ color: '$error' }}>{tax.name}</Span>?
        </Text>

        <Flex
          gutterX="2"
          css={{ alignSelf: 'end', alignItems: 'end', flexGrow: 1 }}
        >
          <Button
            size="md"
            appearance="secondary"
            disabled={deletingTax}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            appearance="danger"
            size="md"
            disabled={deletingTax}
            isLoading={deletingTax}
            onClick={submit}
          >
            Delete Tax
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default DeleteTaxRateDrawer
