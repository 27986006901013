import { Route, Routes } from 'react-router-dom'
import General from './pages/general'
import Logs from './pages/logs'
import Members from './pages/members'
import Plans from './pages/plans'
import Subscriptions from './pages/subscriptions'

const Organisation = () => {
  return (
    <Routes>
      <Route path="/general" element={<General />}></Route>
      <Route path="/subscriptions" element={<Subscriptions />}></Route>
      <Route path="/plans" element={<Plans />}></Route>
      <Route path="/members" element={<Members />}></Route>
      {/* <Route path="/import-export" element={<ImportExport />}></Route> */}
      <Route path="/logs" element={<Logs />}></Route>
    </Routes>
  )
}

export default Organisation
