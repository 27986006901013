/* eslint-disable react/prop-types */
import { Box } from 'components/layout'
import Text from 'components/text/text'
import React from 'react'
import {
  Provider,
  StyledArrow,
  StyledContent,
  StyledTrigger,
  TooltipRoot,
} from './tooltip.styles'
import { TooltipProps } from './tooltip.types'

const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
  ({ children, description, placement, align, css, defaultOpen }, ref) => {
    return (
      <Box css={css} ref={ref}>
        <Provider>
          <TooltipRoot delayDuration={50} defaultOpen={defaultOpen}>
            <StyledTrigger>{children}</StyledTrigger>
            <StyledContent align={align} side={placement} sideOffset={5}>
              <Text size="xxs" color="$white">
                {description}
              </Text>
              <StyledArrow />
            </StyledContent>
          </TooltipRoot>
        </Provider>
      </Box>
    )
  }
)

Tooltip.displayName = 'Tooltip'

export default Tooltip
