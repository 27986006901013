import { styled } from 'stitches/stitches.config'

export const DateInputContainer = styled('div', {
  position: 'relative',
  height: '100%',
})

export const CalendarContainer = styled('div', {
  position: 'absolute',
  zIndex: 20000000,
})
