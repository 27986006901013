import { Text } from 'components/text/text'
import { useCurrenciesQuery } from 'generated/__generated_graphql'
import React from 'react'
import { formatMoney } from 'utils/helpers'
import { CleanTransaction } from '../screens/transactions'

export type TransactionsTableAmountProps = {
  transaction: CleanTransaction
  currencyId: string
}

const TransactionsTableAmount: React.FC<TransactionsTableAmountProps> = (
  props
) => {
  const { transaction, currencyId } = props

  const [{ data: currenciesQueryData }] = useCurrenciesQuery()

  const { currencies } = currenciesQueryData ?? {}

  const currencySymbol = currencies?.find(
    (currency) => currency.id === currencyId
  )?.symbol

  // const isDebit = data?.status?.[0] === 'debit'
  return (
    <Text size="xs">
      {formatMoney(transaction.amount ?? 0, currencySymbol)}
      {/* {formatMoney(`${isDebit ? '-' : ''}${value}`, currencySymbol)} */}
    </Text>
  )
}

export default TransactionsTableAmount
