import React from 'react'
export default function useDisclosure(defaultOpen = false) {
  const [isOpen, setIsOpen] = React.useState<boolean>(defaultOpen)

  function toggle() {
    if (isOpen) {
      setIsOpen(false)
      return
    }

    setIsOpen(true)
  }

  function show() {
    setIsOpen(true)
  }

  function hide() {
    setIsOpen(false)
  }

  return {
    isOpen,
    setIsOpen,
    toggle,
    show,
    hide,
  }
}
