import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import Flex from 'components/layout/flex'
import PageHeader from 'components/page-header/page-header'
import { Span } from 'components/text/span'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import {
  BankAccount,
  BankTransactionPagination,
  useSyncTransactionsMutation,
  useTransactionsQuery,
} from 'generated/__generated_graphql'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import React from 'react'
import { FaSync } from 'react-icons/fa'
import { HiChevronDown, HiDocumentAdd } from 'react-icons/hi'
import { MdModeEdit } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { extractGraphqlErrors } from 'utils/helpers'
import AddTransactionsDrawer from '../components/add-transactions-drawer'
import { EditAccountDrawer } from '../components/edit-account-drawer'
import { ImportTransactionsDrawer } from '../components/import-transactions-drawer'
import { TransactionsTable } from '../components/transactions-table'
import { CleanFinancialAccount } from '../financial'
import { TableContainer, TableTop } from '../financial.styles'

export type CleanTransaction = Exclude<
  BankTransactionPagination,
  null | undefined
>['data'][number]

export type FinancialTransactionsProps = {
  financialAccounts: Omit<BankAccount, 'bankAccountable'>[]
  refreshFinancialAccounts: () => void
}

export const FinancialTransactions: React.FC<FinancialTransactionsProps> = (
  props
) => {
  const { financialAccounts, refreshFinancialAccounts } = props

  const toast = useToast()
  const { id } = useParams()

  const [showImportTransactionsDrawer, setShowImportTransactionsDrawer] =
    React.useState(false)

  const [showEditDrawer, setShowEditDrawer] = React.useState(false)

  const [showAddTransactionsDrawer, setShowAddTransactionsDrawer] =
    React.useState(false)

  const [transactionType, setTransactionType] = React.useState<
    'Expense' | 'Income'
  >('Expense')

  const [page, setPage] = React.useState(1)

  const [{ fetching, data }, refetchTransactions] = useTransactionsQuery({
    variables: {
      bankAccountId: String(id),
      pagination: {
        page,
        per: 10,
      },
    },
  })

  const isLinked = data?.transactions?.bankAccount?.linked
  const { bankTransactions, bankAccount } = data?.transactions ?? {}
  const { data: transactions, pagination: transactionsPagination } =
    bankTransactions ?? {}

  React.useEffect(() => {
    refetchTransactions({
      requestPolicy: 'network-only',
    })
  }, [id])

  function notify(error?: string, status?: 'success' | 'error') {
    toast({
      content: error ?? 'something went wrong',
      status: status,
    })
  }

  const [{ fetching: syncingTransactions }, syncTransactions] =
    useSyncTransactionsMutation()

  async function handleTransactionSync() {
    try {
      const response = await syncTransactions({
        input: {
          bankAccountId: String(id),
        },
      })
      const error = extractGraphqlErrors(response, 'syncTransactions')

      if (error) {
        notify(error, 'error')
        return
      }
      notify('Transactions synced successfully', 'success')
      refetchTransactions()
    } catch (error) {
      notify(error as string, 'error')
    }
  }

  // if (fetching) return <PageLoader />
  return (
    <>
      <PageHeader
        title="Transactions"
        extra={
          <Flex gutterX="2">
            <Dropdown
              placement="bottomRight"
              menu={[
                {
                  label: 'New Income',
                  value: 'income',
                  render: (label) => (
                    <Flex
                      css={{
                        px: '1.6rem',
                        py: '0.8rem',
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                        '&:hover': {
                          color: '$highlight',
                          backgroundColor: '$highlightBg',

                          '& *': {
                            color: '$highlight',
                          },
                        },
                      }}
                      align="center"
                      gutterX="2"
                      role="button"
                      onClick={() => {
                        setShowAddTransactionsDrawer(true)
                        setTransactionType('Income')
                      }}
                    >
                      <HiDocumentAdd color="#ABB3B9" />
                      <Text variant="body2" size="xs">
                        {label}
                      </Text>
                    </Flex>
                  ),
                },
                {
                  label: 'New Expense',
                  value: 'expense',
                  render: (label) => (
                    <Flex
                      css={{
                        px: '1.6rem',
                        py: '0.8rem',
                        width: '100%',
                        height: '100%',
                        cursor: 'pointer',
                        '&:hover': {
                          color: '$highlight',
                          backgroundColor: '$highlightBg',

                          '& *': {
                            color: '$highlight',
                          },
                        },
                      }}
                      align="center"
                      gutterX="2"
                      role="button"
                      onClick={() => {
                        setShowAddTransactionsDrawer(true)
                        setTransactionType('Expense')
                      }}
                    >
                      <HiDocumentAdd color="#ABB3B9" />
                      <Text variant="body2" size="xs">
                        {label}
                      </Text>
                    </Flex>
                  ),
                },
              ]}
            >
              <Button
                appearance="secondary"
                append={<HiChevronDown color="#8EB0DF" />}
              >
                New
              </Button>
            </Dropdown>

            {/* <Button
              onClick={() => setShowImportTransactionsDrawer(true)}
              prepend={<HiArrowCircleUp />}
            >
              New Upload
            </Button> */}
          </Flex>
        }
      />

      <StyledLayoutContent>
        <TableContainer direction="column">
          <TableTop justify="between" stretchx>
            <Flex gutterX="2">
              <Flex direction="column">
                <Text variant="h3" size="md" color="$primary">
                  {bankAccount?.title} &nbsp;
                  <Span css={{ color: '$gray' }}>
                    ({bankAccount?.name || bankAccount?.routingNumber})
                  </Span>
                </Text>
                <Text variant="body2" size="xs" color="$secondary">
                  {bankAccount?.accountNumber}
                </Text>
              </Flex>
            </Flex>

            <Flex gutter="2" align="center">
              <Button
                prepend={<MdModeEdit color="#ABB3B9" size="1.5rem" />}
                appearance="secondary"
                onClick={() => setShowEditDrawer(true)}
              >
                Edit Account
              </Button>
              {bankAccount?.linked && (
                <Button
                  prepend={<FaSync color="#ABB3B9" size="1.25rem" />}
                  appearance="secondary"
                  isLoading={syncingTransactions}
                  onClick={handleTransactionSync}
                >
                  Sync Transactions
                </Button>
              )}
            </Flex>
          </TableTop>
          <TransactionsTable
            transactions={transactions ?? []}
            transactionsPagination={transactionsPagination}
            loading={fetching}
            refreshFinancialAccounts={refreshFinancialAccounts}
            refetchTransactions={() =>
              refetchTransactions({
                requestPolicy: 'network-only',
              })
            }
            currencyId={bankAccount?.currencyId}
            page={page}
            setPage={setPage}
            isLinked={isLinked}
          />
        </TableContainer>
      </StyledLayoutContent>

      <ImportTransactionsDrawer
        visible={showImportTransactionsDrawer}
        id={id as string}
        onClose={() => {
          setShowImportTransactionsDrawer(false)
        }}
        refetchFinancialAccounts={refreshFinancialAccounts}
        refetchTransactions={() =>
          refetchTransactions({
            requestPolicy: 'network-only',
          })
        }
      />

      <EditAccountDrawer
        visible={showEditDrawer}
        financialAccount={(bankAccount as CleanFinancialAccount) ?? {}}
        onClose={() => setShowEditDrawer(false)}
      />

      <AddTransactionsDrawer
        selectedFinancialAccount={
          bankAccount as Omit<BankAccount, 'bankAccountable'>
        }
        refetchFinancialAccounts={refreshFinancialAccounts}
        refetchTransactions={() =>
          refetchTransactions({
            requestPolicy: 'network-only',
          })
        }
        visible={showAddTransactionsDrawer}
        transactionType={transactionType}
        financialAccounts={financialAccounts}
        onClose={() => setShowAddTransactionsDrawer(false)}
      />
    </>
  )
}
