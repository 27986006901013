import { styled } from 'stitches/stitches.config'

export const StyledHeader = styled('header', {
  width: '100%',
  minHeight: '6.4rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '$white',

  defaultVariants: {
    type: 'box',
  },

  variants: {
    type: {
      box: {
        position: 'sticky',
        top: '0',
        padding: '$2 $5',
        boxShadow: '$shadow',
        background: '$white',
        border: '.1rem solid $border',

        zIndex: 1,
      },

      plain: {
        pb: '$3',
        backgroundColor: 'transparent',
        borderBottom: '0.1rem solid $border',
      },
    },
  },
})

export const StyledNavigation = styled('header', {
  cursor: 'pointer',
})
