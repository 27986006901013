import type * as Stitches from '@stitches/react'
import React, { useCallback, useState } from 'react'
import { colors } from './avatar.data'
import { StyledAvatar, StyledImg } from './avatar.styles'

interface IAvatarprops extends Stitches.VariantProps<typeof StyledAvatar> {
  src?: string
  srcSet?: string
  title?: string
  background?: Stitches.CSS['backgroundColor']
  backgroundImage?: Stitches.CSS['backgroundImage']
  icon?: React.ReactNode
  renderTitle?: React.ReactNode
  alt?: string
  css?: Stitches.CSS
  singleInitial?: boolean
  onError?: () => void
  children?: React.ReactNode
}

export const Avatar = React.forwardRef<HTMLSpanElement, IAvatarprops>(
  (
    { icon, src, srcSet, alt, onError, renderTitle, children, css, ...props },
    ref
  ) => {
    const [useFallback, setUsefallback] = useState(false)

    const defaultColor = React.useMemo(() => {
      if (props.title) {
        return colors[props.title?.length % colors.length]
      }
    }, [props.title])

    const getInitials = useCallback(() => {
      if (renderTitle) return renderTitle
      const array = props.title?.split(' ')
      if (!array) return

      const initials = !array[1]
        ? array[0].slice(0, 1)
        : `${array[0][0]}${array[1][0]}`

      return initials
    }, [props.title])

    return (
      <StyledAvatar
        {...props}
        ref={ref}
        css={{
          backgroundColor: props.background ?? (defaultColor as any),
          backgroundImage: props.backgroundImage,
          ...css,
        }}
      >
        {children ? (
          children
        ) : src && !useFallback ? (
          <StyledImg
            src={src}
            srcSet={srcSet}
            alt={alt}
            onError={onError ?? (() => setUsefallback(true))}
          ></StyledImg>
        ) : icon ? (
          icon
        ) : (
          getInitials()
        )}
      </StyledAvatar>
    )
  }
)

export default Avatar

Avatar.displayName = 'Avatar'
