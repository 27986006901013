import { styled } from 'stitches/stitches.config'

export const StyledTableContainer = styled('div', {
  backgroundColor: '$white',
  borderRadius: '1rem',
  boxShadow: '$tiny',
  width: '92rem',

  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },
})
