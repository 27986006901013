import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import { styled } from 'stitches/stitches.config'

export const StyledRow = styled(Flex, {
  width: '100%',
  height: '100vh',
})

export const StyledCol = styled(Box, {
  overflow: 'auto',
  height: '100vh',
})

export const StyledContent = styled(Box, {
  background: '$background',
  padding: '$3',
  height: '100vh',
  overflowX: 'hidden',
})

export const StyledBox = styled(Box, {
  background: '$white',
  boxShadow: '$tiny',
  borderRadius: '1rem',
  padding: '$5',
  width: '100%',
  height: '100%',

  '.animated-number': {
    fontSize: '2.4rem',
    fontFamily: '$space',
    lineHeight: '$4',
    mt: '$2',
    color: '$secondary',
  },
})

export const StyledMarker = styled(Box, {
  background: '$blue',
  borderRadius: '.5rem',
  width: '.5rem',
  height: '100%',
})
export const StyledLabel = styled(Flex, {
  padding: '$2 $4',
  borderRadius: '$1',
  border: '.1rem solid $border',

  div: {
    background: '$blue',
    borderRadius: '$round',
    width: '.5rem',
    height: '.5rem',
  },
})

export const StyledFlexItem = styled('div', {
  flexShrink: 0,
})

export const StyledTableSection = styled(Flex, {
  height: '100%',
})

export const StyledTableHead = styled(Flex, {
  borderBottom: '0.1rem solid $border',
  margin: '0 -$5',
  px: '$5',
  paddingBottom: '$5',
  position: 'relative',
})

export const StyledTableContainer = styled('div', {
  paddingTop: '1.8rem',
  flexGrow: 1,
})

export const StyledTableFooter = styled(Flex, {
  borderTop: '0.1rem solid $border',
  padding: '$5 $5 0',
  margin: '0 -$5',
})

export const StyledTableTab = styled(Flex, {
  cursor: 'pointer',
  width: '50%',
})

export const StyledTableTabIndicator = styled('span', {
  position: 'absolute',
  width: '40%',
  height: '0.2rem',
  backgroundColor: '$blue',
  bottom: 0,
  transition: 'all 0.2s ease-out',

  variants: {
    position: {
      1: {
        marginRight: '60%',
      },

      2: {
        marginLeft: 'calc(60% - $5 - $5 - $5)',
      },
    },
  },
})

export const StyledDisplay = styled(Flex, {
  backgroundColor: '#fff',
  width: '100%',
  borderRadius: '3rem',
  overflow: 'hidden',

  '.left': {
    width: '45%',
    background: '#fff',
    p: '$5 $6',
    position: 'relative',
    // isolation: 'isolate',

    '.avatar': {
      width: '7rem',
      ml: '-1rem',
    },
  },
  '.right': {
    width: '55%',
    // p: '$5 $6',

    '.img': {
      width: '100%',
    },

    '.btn': {
      width: '50%',
      borderRadius: 'unset',
      py: '$2 !important',
      transition: 'all 0.3s ease-in-out',
      font: '$space',
      '&:first-child': {
        borderRight: '0.1rem solid #494949',
      },
      '&:hover': {
        color: '#F4D364',
      },
    },
  },
})
