import Accordion from 'components/accordion/accordion'
import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import PageHeader from 'components/page-header/page-header'
import { Tabs } from 'components/tabs/tabs'
import { StyledRadixTabTriggerList } from 'components/tabs/tabs.styles'
import { Text } from 'components/text/text'
import { BillingCycle, PlanTypeEnum } from 'generated/__generated_graphql'
import { useAppProvider } from 'providers/app-provider'
import { useState } from 'react'
import { styled } from 'stitches/stitches.config'
import PlanBody from '../components/plans/plan-body'

const accordionData = [
  {
    title: `Can I use Accounteer for free`,
    content: (
      <Text weight="medium" size={'xs'}>
        Yes, the trial plan is free but only provides restricted access to all
        of Accounteer’s features.
      </Text>
    ),
  },
  {
    title: `Can I change my plan after a subscription?`,
    content: (
      <Text weight="medium" size={'xs'}>
        {' '}
        Yes, you can upgrade to a higher plan at any time.
      </Text>
    ),
  },
  {
    title: `Are there monthly and yearly billing options?`,
    content: (
      <Text weight="medium" size={'xs'}>
        {' '}
        Yes, there are monthly and yearly billing options available and a you
        can decide to subscribe to any of the plans at any time.
      </Text>
    ),
  },
  {
    title: `How does adding and removing members work?`,
    content: (
      <Text weight="medium" size={'xs'}>
        {' '}
        As an “Owner” or an “Admin” on Accounteer, you can add a new team member
        or remove existing members under “Team” in “Organization Settings”.
      </Text>
    ),
  },
  {
    title: `What happens if I go above my plan limits?`,
    content: (
      <Text weight="medium" size={'xs'}>
        {' '}
        Every monthly subscription expires after its cycle, so you will no
        longer have access to your account unless a renewal is made.
      </Text>
    ),
  },
]

const PlanTabs = styled(Tabs, {
  [`& ${StyledRadixTabTriggerList}`]: {
    p: '1.25rem .75rem',
    pb: '1.55rem',
    backgroundColor: '$inputBg',
    borderRadius: '$3',
    columnGap: '$1',
    m: '0 auto',
    // mb: '$6',
    width: 'fit-content',
  },
  width: '100%',
  mb: '3.2rem',
})

const PlanTabsButton = styled(Text, {
  gap: '$2',
  color: '$secondary',
  padding: '$2 $4',
  borderRadius: '$1',
  border: '1px solid transparent',
  fontFamily: '$bold',
  boxShadow: '0 .2rem .4rem transparent',
  transition: 'all .3s ease-in-out',

  variants: {
    active: {
      true: {
        color: '$blue',
        background: '$white',
        border: '1px solid $border',
        boxShadow: '$subtle',
        borderRadius: '$1',
      },
    },
  },
})

const Plans = () => {
  const { organisation } = useAppProvider()
  const { plan } = organisation ?? {}
  const planType = plan?.planType ?? PlanTypeEnum.Product

  const [billingCycle, setBillingCycle] = useState<BillingCycle>(
    BillingCycle.Monthly
  )

  return (
    <Flex direction="column" gutter="3">
      <PageHeader
        title="Plans"
        subtitle="Select a plan that matches your organisation's needs"
        type="plain"
      />

      <Flex direction="column" justify="center" gutter="3">
        <Flex
          direction="column"
          justify={'center'}
          align={'center'}
          css={{
            px: '$6',
            pt: '$4',
            background: '$white',
            borderRadius: '$3',
            minWidth: '70rem',
            boxShadow: '$tiny',
          }}
        >
          {/* <PlanTabs
            activeKey={planType}
            fullWidth={false}
            hideDefaultIndicator
            hideBottomBorder
            onChange={(id) => {
              setBillingCycle(BillingCycle.Monthly)
              setPlanType(id as PlanTypeEnum)
            }}
            tabs={[
              {
                key: PlanTypeEnum.Product,
                title: (
                  <PlanTabsButton
                    active={planType === PlanTypeEnum.Product}
                    size="xs"
                    role="button"
                  >
                    Product
                  </PlanTabsButton>
                ),
              },
              {
                key: PlanTypeEnum.Service,
                title: (
                  <PlanTabsButton
                    active={planType === PlanTypeEnum.Service}
                    size="xs"
                    role="button"
                  >
                    Service
                  </PlanTabsButton>
                ),
              },
            ]}
          /> */}

          {planType === PlanTypeEnum.Product && (
            <PlanTabs
              activeKey={billingCycle}
              fullWidth={false}
              hideDefaultIndicator
              hideBottomBorder
              onChange={(id) => setBillingCycle(id as BillingCycle)}
              tabs={[
                {
                  key: BillingCycle.Monthly,
                  title: (
                    <PlanTabsButton
                      active={billingCycle === BillingCycle.Monthly}
                      size="xs"
                      role="button"
                    >
                      Billed Monthly
                    </PlanTabsButton>
                  ),
                },
                {
                  key: BillingCycle.Quarterly,
                  title: (
                    <PlanTabsButton
                      active={billingCycle === BillingCycle.Quarterly}
                      size="xs"
                      role="button"
                    >
                      Billed Quarterly
                    </PlanTabsButton>
                  ),
                },
                {
                  key: BillingCycle.Yearly,
                  title: (
                    <PlanTabsButton
                      active={billingCycle === BillingCycle.Yearly}
                      size="xs"
                      role="button"
                    >
                      Billed Yearly
                    </PlanTabsButton>
                  ),
                },
              ]}
            />
          )}

          <PlanBody billingCycle={billingCycle} planType={planType} />
        </Flex>

        <Flex
          direction="column"
          gutter="3"
          css={{
            px: '$6',
            // width: '105.6rem',
            py: '$4',
            background: '$white',
            borderRadius: '$3',
            minWidth: '70rem',
            boxShadow: '$tiny',
          }}
        >
          <Text
            css={{ mb: '$5', px: '$4', fontFamily: '$bold' }}
            size="md"
            color="$primary"
          >
            FAQ
          </Text>

          <Box>
            <Accordion tabs={accordionData} />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Plans
