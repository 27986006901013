import Box from 'components/layout/box'
import PageHeader from 'components/page-header/page-header'
import { Tabs } from 'components/tabs/tabs'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import React, { useState } from 'react'
import { ItemsCategoryTable } from '../components/items-category-table'
import { ItemsSubCategoryTable } from '../components/items-sub-category-table'

interface ItemsCategoriesProps {}

const ItemsCategories: React.FC<ItemsCategoriesProps> = () => {
  const [activeKey, setActiveKey] = useState('1')
  return (
    <>
      <PageHeader title="Item Categories" />
      <StyledLayoutContent>
        {/* <PageBanner
          title="View and manage your inventory items"
          description="Items are the products and services you sell. You can add items categories to your account and manage them here."
          actionText="Learn more"
          bgSvg={background}
          appearance="blue"
        /> */}
        <Box css={{ background: '$white', borderRadius: '$3' }}>
          <Tabs
            onChange={(id) => setActiveKey(id)}
            activeKey={activeKey}
            tabs={[
              {
                key: '1',
                title: 'Categories',
                content: <ItemsCategoryTable />,
              },
              {
                key: '2',
                title: 'Sub categories',
                content: <ItemsSubCategoryTable />,
              },
            ]}
          />
        </Box>
      </StyledLayoutContent>
    </>
  )
}

export default ItemsCategories
