import { zodResolver } from '@hookform/resolvers/zod'
import Button from 'components/button/button'
import { CheckBox } from 'components/checkbox/checkbox'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select'
import {
  useAccountTypeCategoriesQuery,
  useChartOfAccountsQuery,
  useCreateAccountMutation,
  useUpdateAccountMutation,
} from 'generated/__generated_graphql'

import { StyledDrawerInput } from 'pages/settings/settings.styles'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { HiDocumentAdd } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { z } from 'zod'
import { ChartOfAccountsTableData } from './chart-of-accounts-table'

type MutateAccountDrawerProps = {
  visible: boolean
  onClose: () => void
  account?: ChartOfAccountsTableData['account']
}

const formSchema = z.object({
  accountName: z
    .string()
    .min(3, 'Please enter an account name (at least 3 characters)'),
  accountTypeId: z.string({
    required_error: 'Please select an account type',
  }),
  allowPayments: z.boolean(),
  showOnInvoices: z.boolean(),
  showOnPurchases: z.boolean(),
})

type FormFields = z.infer<typeof formSchema>

const MutateAccountDrawer: React.FC<MutateAccountDrawerProps> = (params) => {
  const notify = useToast()

  const { visible, onClose, account } = params
  const [, queryAccounts] = useChartOfAccountsQuery()
  const [, createAccount] = useCreateAccountMutation()
  const [, updateAccount] = useUpdateAccountMutation()

  const [{ data: accountTypeCategoriesQueryData }] =
    useAccountTypeCategoriesQuery()
  const { accountTypeCategories } = accountTypeCategoriesQueryData ?? {}

  const accountTypeOptions =
    accountTypeCategories?.map((category) => ({
      label: category.name,
      value: category.id,
      options:
        category?.accountTypes
          ?.filter((accType) => accType.name !== 'Bank')
          ?.map((accType) => ({
            label: accType.name,
            value: accType.id,
          })) ?? [],
    })) ?? []

  const {
    control,
    reset,
    register,
    formState: { errors, isSubmitting, isValid },
    getValues,
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      allowPayments: false,
      showOnInvoices: false,
      showOnPurchases: false,
    },
  })

  useEffect(() => {
    if (account)
      reset({
        accountName: account.name ?? '',
        accountTypeId: account.accountType?.id,
        allowPayments: account.allowPayments,
        showOnInvoices: account.showOnInvoices,
        showOnPurchases: account.showOnPurchases,
      })
  }, [])

  async function onSubmit() {
    try {
      if (!account) {
        const {
          accountTypeId,
          accountName,
          allowPayments,
          showOnInvoices,
          showOnPurchases,
        } = getValues()

        const response = await createAccount({
          input: {
            accountTypeId: accountTypeId,
            name: accountName,
            allowPayments: allowPayments,
            showOnInvoices: showOnInvoices,
            showOnPurchases: showOnPurchases,
          },
        })

        const error = extractGraphqlErrors(response, 'createChartOfAccount')

        if (error) {
          notify({
            content: error,
            status: 'error',
          })
          return
        }
      }

      if (account) {
        const {
          accountTypeId,
          accountName,
          allowPayments,
          showOnInvoices,
          showOnPurchases,
        } = getValues()

        const response = await updateAccount({
          input: {
            id: account.id,
            accountTypeId: accountTypeId,
            name: accountName,
            allowPayments: allowPayments,
            showOnInvoices: showOnInvoices,
            showOnPurchases: showOnPurchases,
          },
        })

        const error = extractGraphqlErrors(response, 'createChartOfAccount')

        if (error) {
          notify({
            content: error,
            status: 'error',
          })
          return
        }
      }

      queryAccounts({
        requestPolicy: 'network-only',
      })
      const { accountName } = getValues()

      notify({
        content:
          accountName +
          ' was successfully' +
          (account ? ' updated' : ' added to your chart of accounts'),
        status: 'success',
      })
      onReset()
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  function onReset() {
    reset()
    onClose()
  }

  return (
    <Drawer
      title={!account ? 'New Account' : 'Edit Account'}
      titleIcon={<HiDocumentAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      closable={!isSubmitting}
      maskClosable={!isSubmitting}
      onClose={onReset}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        onReset={onReset}
        style={{ width: '100%', height: '100%' }}
      >
        <Flex direction={'column'} css={{ width: '100%', height: '100%' }}>
          <Flex direction={'column'} css={{ padding: '3.2rem 2.4rem' }}>
            <Flex direction="column" gutterY="5">
              <StyledDrawerInput
                {...(register('accountName') as any)}
                size="lg"
                label="Account Name"
                placeholder="Enter name"
                error={errors.accountName?.message}
                required
              />

              <Controller
                control={control}
                name="accountTypeId"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TypeAheadSelect
                      label="Account Type"
                      placeholder="Select a type of account"
                      options={accountTypeOptions}
                      value={value}
                      onChange={(value: string) => onChange(value)}
                      required
                    />
                  )
                }}
              />
            </Flex>

            <Flex direction="column" gutterY="4" css={{ mt: '5rem' }}>
              <Flex gutterX="3">
                <Controller
                  control={control}
                  name="allowPayments"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CheckBox
                        checked={value}
                        onChange={() => onChange(!value)}
                      />
                    )
                  }}
                />

                <Text color="$secondary" size="xs">
                  Allow payments to this account
                </Text>
              </Flex>
              <Flex gutterX="3">
                <Controller
                  control={control}
                  name="showOnInvoices"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CheckBox
                        checked={value}
                        onChange={() => onChange(!value)}
                      />
                    )
                  }}
                />

                <Text color="$secondary" size="xs">
                  Show account on sales invoices
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            gutterX="2"
            align="end"
            css={{ alignSelf: 'end', flexGrow: 1, padding: '3.2rem 2.4rem' }}
          >
            <Button
              type="reset"
              size="md"
              appearance="secondary"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="md"
              isLoading={isSubmitting}
              disabled={isSubmitting || !isValid}
            >
              {account ? 'Edit Account' : 'Add Account'}
            </Button>
          </Flex>
        </Flex>
      </form>
    </Drawer>
  )
}

export default MutateAccountDrawer
