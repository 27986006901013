import { ReactComponent as EmptyIcon } from 'assets/svg/empty-transactions.svg'
import Button from 'components/button/button'
import Flex from 'components/layout/flex'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import Tag from 'components/tag/tag'
import { Text } from 'components/text/text'
import { format } from 'date-fns'
import { Pagination } from 'generated/__generated_graphql'
import React, { useState } from 'react'
import { HiTrash } from 'react-icons/hi'
import { CleanTransaction } from '../screens/transactions'
import TransactionsTableAction from './transactions-table-action'
import TransactionsTableAmount from './transactions-table-amount'
import TransactionsTableOption from './transactions-table-option'
import DeleteTransactionDrawer from './delete-transaction-drawer'

interface TransactionTableData {
  id: string
  description: string
  amount: string
  status: string[]
  date: string
  action: {
    id: string
    status: string[]
  }
  transaction: CleanTransaction
  currencyId: string
}

export type TransactionsTableProps = {
  transactions: CleanTransaction[]
  transactionsPagination?: Pagination
  currencyId: string | undefined
  refetchTransactions: (variables?: any) => void
  refreshFinancialAccounts: () => void
  page: number
  setPage: (page: number) => void
  loading?: boolean
  isLinked?: boolean
}

export const TransactionsTable: React.FC<TransactionsTableProps> = (props) => {
  const {
    transactions,
    transactionsPagination,
    currencyId,
    refetchTransactions,
    refreshFinancialAccounts,
    page,
    setPage,
    loading,
    isLinked,
  } = props

  const [selected, setSelected] = useState<string[]>([])
  const [showDeleteTransactionDrawer, setShowDeleteTransactionDrawer] =
    useState(false)

  const transactionMap = transactions.map<TransactionTableData>((tx) => {
    const status = [
      tx.status ?? '',
      tx.reconciled ? 'Reconciled' : 'Unreconciled',
    ]

    return {
      id: tx.id,
      description: tx.description ?? '',
      amount: tx.amount ? String(tx.amount) : '',
      status: status,
      date: tx.transactionDate,
      action: {
        id: tx.id,
        status: status,
      },
      transaction: tx,
      currencyId: currencyId ?? '',
    }
  })

  const columns: ITableColumn<TransactionTableData>[] = [
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
    },
    {
      key: 'amount',
      title: 'Amount',
      dataIndex: 'amount',
      render: (_, { currencyId, transaction }) => (
        <TransactionsTableAmount
          transaction={transaction}
          currencyId={currencyId}
        />
      ),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (items) => (
        <Flex gutterX="2">
          {items.map((tag: string, i: number) => {
            const isReconciled = tag === 'Reconciled' && 'blue'
            const isUnreconciled = tag === 'Unreconciled' && 'danger'

            const type = isReconciled || isUnreconciled || 'default'
            return (
              <Tag key={i} type={type} size="xs">
                {tag}
              </Tag>
            )
          })}
        </Flex>
      ),
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      render: (date) => (
        <Text size="xs" color="$primary">
          {format(new Date(date), 'dd MMM, yyyy')}
        </Text>
      ),
    },
    {
      key: 'action',
      title: 'Action',
      dataIndex: 'action',
      render: (_, { transaction, status }) => (
        <TransactionsTableAction
          refetchFinancialAccounts={refreshFinancialAccounts}
          refetchTransactions={refetchTransactions}
          transaction={transaction}
          statuses={status}
        />
      ),
    },
    {
      key: 'options',
      title: '',
      dataIndex: 'id',
      shouldNotRender: isLinked as boolean,
      render: (_, { transaction }) => (
        <TransactionsTableOption
          refetchFinancialAccounts={refreshFinancialAccounts}
          refetchTransactions={refetchTransactions}
          transaction={transaction}
        />
      ),
    },
  ]

  return (
    <>
      <Table
        columns={columns}
        dataSource={transactionMap || []}
        loading={loading}
        showSearch
        selectable={isLinked ? false : true}
        pagination={{
          totalCount: transactionsPagination?.totalCount as number,
          currentPage: transactionsPagination?.currentPage as number,
          perPage: 10,
          totalPages: transactionsPagination?.totalPages as number,
          onPaginationClick: (type) => {
            if (type === 'next') {
              if (page === transactionsPagination?.totalPages) return
              setPage(page + 1)
            } else {
              if (page === 1) return
              setPage(page - 1)
            }
          },
        }}
        onRowSelect={(ids) => setSelected(ids)}
        actions={
          <>
            {selected?.length > 0 && (
              <Button
                css={{ height: '100%' }}
                prepend={<HiTrash color="#FFFFFF" />}
                appearance="danger"
                onClick={() => setShowDeleteTransactionDrawer(true)}
              >
                Delete
              </Button>
            )}
          </>
        }
        emptyProps={{
          title: 'Add or Import Transactions',
          subtitle:
            'Import existing transaction data or populate new transactions.',
          icon: <EmptyIcon width="5.6rem" height="5.6rem" />,
          // action: (
          //   <Flex gutterX="2">
          //     <Button appearance="secondary" append={<HiArrowCircleDown />}>
          //       Import File(s)
          //     </Button>
          //     <Button append={<HiChevronDown />}>New</Button>
          //   </Flex>
          // ),
        }}
      />

      <DeleteTransactionDrawer
        refetchFinancialAccounts={refreshFinancialAccounts}
        refetchTransactions={refetchTransactions}
        visible={showDeleteTransactionDrawer}
        onClose={() => setShowDeleteTransactionDrawer(false)}
        transaction={selected}
      />
    </>
  )
}
