import { styled } from 'stitches/stitches.config'
import * as RadixAccordion from '@radix-ui/react-accordion'

export const Root = styled(RadixAccordion.Root, {
  variants: {
    border: {
      true: {
        borderBottom: '.1rem solid $border',
      },
    },
  },
})

export const Header = styled(RadixAccordion.Header, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})
export const Item = styled(RadixAccordion.Item, {})

export const Trigger = styled(RadixAccordion.Trigger, {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '$2',
  backgroundColor: '$white',
  border: 'none',
  textAlign: 'left',
  cursor: 'pointer',

  '&[data-state=closed]': { '.active-icon': { display: 'none' } },
  '&[data-state=open]': {
    '.inactive-icon': { display: 'none' },
    '.active-color': { color: '$secondary' },
  },
})

export const Content = styled(RadixAccordion.Content, {
  mt: '$3',
})
