import Flex from 'components/layout/flex'
import Loader from 'components/loader/loader'
import PageHeader from 'components/page-header/page-header'
import { Tabs } from 'components/tabs/tabs'
import { format } from 'date-fns'
import { AccountStatusEnum } from 'generated/__generated_graphql'
import { CleanOrganisation, useAppProvider } from 'providers/app-provider'
import { useState } from 'react'

import { InviteTeamMemberDrawer } from '../components/members/components/invite-member-drawer'
import MembersTable, {
  type MembersTableData,
} from '../components/members/members-table'
import { StyledContent } from '../organisation.styles'

export type Member = Exclude<
  CleanOrganisation['users'],
  null | undefined
>[number]

const Members = () => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false)

  const { organisation } = useAppProvider()

  const { users: teamMembers } = organisation ?? {}

  function returnTeamMember(user: Member) {
    return {
      id: user.id,
      name:
        user.firstName && user.lastName
          ? user.firstName + ' ' + user.lastName
          : '',
      emailAddress: user.email,
      roles: user.role?.name ?? '',
      lastLogin:
        user.accountStatus === AccountStatusEnum.Active
          ? format(Date.parse(user.updatedAt), 'MMM dd, yyyy')
          : '',
      member: user,
    }
  }

  const allTeamMembers =
    teamMembers
      ?.filter((member) => member.accountStatus === AccountStatusEnum.Active)
      .map<MembersTableData>((tMember) => returnTeamMember(tMember)) ?? []

  const pendingInviteTeamMembers =
    teamMembers
      ?.filter(
        (tMember) =>
          tMember.accountStatus === AccountStatusEnum.PendingInviteAccept
      )
      ?.map<MembersTableData>((tMember) => returnTeamMember(tMember)) ?? []

  if (!teamMembers)
    return (
      <Flex
        align={'center'}
        justify="center"
        css={{ width: '100%', height: '100vh' }}
      >
        <Loader size={'md'} />
      </Flex>
    )

  return (
    <>
      <Flex direction="column" gutter="3">
        <PageHeader
          title="Members"
          subtitle="Manage user access to this workspace"
          type="plain"
        />

        <StyledContent fullWidth direction="column" gutter="3">
          <Tabs
            defaultValue="1"
            tabs={[
              {
                key: '1',
                title: 'All',
                content: (
                  <MembersTable
                    tableData={allTeamMembers}
                    setShowDrawer={setShowDrawer}
                  />
                ),
              },
              {
                key: '2',
                title: 'Pending Invites',
                content: (
                  <MembersTable
                    tableData={pendingInviteTeamMembers}
                    setShowDrawer={setShowDrawer}
                  />
                ),
              },
            ]}
          />
        </StyledContent>
      </Flex>

      <InviteTeamMemberDrawer
        visible={showDrawer}
        onClose={() => setShowDrawer(false)}
      />
    </>
  )
}

export default Members
