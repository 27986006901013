import * as Tabs from '@radix-ui/react-tabs'
import { styled } from 'stitches/stitches.config'

export const StyledRadixTabRoot = styled(Tabs.Root, {
  all: 'unset',
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',

  defaultVariants: {
    bottomBorder: true,
  },

  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },

    bottomBorder: {
      true: {},
    },
  },
})

export const StyledTabIndicator = styled('div', {
  width: '100%',
  height: '0.2rem',
  borderRadius: '0.44rem 0.4rem 0 0',
  backgroundColor: '$blue',
  transition: 'left .2s cubic-bezier(0.23, 0.82, 0.16, 1.46)',
})

export const StyledRadixTabTrigger = styled(Tabs.Trigger, {
  all: 'unset',
  // position: 'relative',
  height: '3rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  '&[data-state="active"]': {
    span: {
      color: '$primary',
    },
    [`& ${StyledTabIndicator}`]: {
      display: 'block',
      transform: 'translateY(.8rem)',
      // pb: '0.4rem',
      // position: 'absolute',
      // bottom: 0,
    },
  },
  '&[data-state="inactive"]': {
    span: {
      color: '$secondary',
    },
    [`& ${StyledTabIndicator}`]: {
      display: 'none',
    },
  },
})

export const StyledRadixTabTriggerList = styled(Tabs.TabsList, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '1.6rem',
  cursor: 'pointer',
  px: '2rem',
  pt: '1.6rem',

  borderBottom: '0.1rem solid $border',

  variants: {
    hideBottomBorder: {
      true: {
        borderBottom: 'none',
      },
    },
  },
})
