type Dict<T = any> = Record<string, T>

export function range(start = 0, end: number, step = 1): Array<number> {
  const result: Array<number> = []

  for (let index = start; index <= end; index += step) {
    result.push(index)
  }

  return result
}

export function findObjectInArray<O extends Dict>(
  array: Array<O>,
  key: keyof O,
  value: any
): O | undefined {
  return array.find((item) => {
    return item[key] === value
  })
}

type AnyObject = Record<string, any>

export const isArrayObjects = (arr: (AnyObject | string)[]): boolean => {
  return arr.some(
    (item) => typeof item === 'object' && item !== null && !Array.isArray(item)
  )
}
