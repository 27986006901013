import * as Stitches from '@stitches/react'
import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import TypeAheadSelect from 'components/type-ahead-select'
import {
  Product,
  useGeneralLedgerAccountsQuery,
} from 'generated/__generated_graphql'
import useDisclosure from 'hooks/useDisclosure'
import useForm from 'hooks/useForm'
import { AddNewProductDrawer } from 'pages/inventory/components/add-new-product-drawer'
import {
  NewItemContent,
  NewItemFooter,
  NewItemFormContainer,
  NewItemFormGrid,
  NewItemFormHeader,
  StyledItemSearch,
} from 'pages/sales/sales.styles'
import React, { useState } from 'react'
import { HiDocumentAdd, HiOutlineSearch, HiPlusCircle } from 'react-icons/hi'
import { InvoiceItem } from '../provider/invoice-provider'

const invoiceAccounts = ['Assets', 'Revenue', 'Inventory', 'Equity']

interface IProps {
  type: 'New' | 'Edit'
  css?: Stitches.CSS
  offerItem?: InvoiceItem | any
  inventoryItems?: InvoiceItem[]
  onCancel: () => void
  onSaveItem: (item: Partial<InvoiceItem>) => void
  hasInvoiceItems: boolean
  currentItemId?: string
}

export const NewInvoiceItemForm = React.forwardRef<HTMLDivElement, IProps>(
  (
    {
      css,
      offerItem,
      onSaveItem,
      onCancel,
      type,
      inventoryItems,
      hasInvoiceItems,
      currentItemId,
    },
    ref
  ) => {
    const { isOpen: showInventory, toggle: toggleInventory } = useDisclosure()
    const { register, setInputValue, values, bulkUpdate, setError } = useForm({
      fields: {
        name: offerItem?.name ?? '',
        description: offerItem?.description ?? '',
        quantity: offerItem?.quantity ?? undefined,
        unitPrice: offerItem?.unitPrice ?? undefined,
        glAccountId: offerItem?.glAccount?.id,
        // glAccountId: offerItem?.glAccountId,
      },
    })

    // console.log(offerItem)

    const [inventoryItemsData, setInventoryItemsData] = useState(inventoryItems)
    const [fromInventory, setFromInventory] = useState(false)

    const [{ data: accounts }] = useGeneralLedgerAccountsQuery()

    function onInventoryAddSuccess(product: Product) {
      setValues({
        name: product.name,
        description: product.description,
        quantity: undefined,
        unitPrice: product.salePrice,
        glAccountId: product.glAccount?.id,
      } as any)
    }

    function setValues(item: InvoiceItem) {
      bulkUpdate({
        name: item.name,
        description: item.description,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        glAccountId: item.glAccountId,
      })
      setFromInventory(true)
      setError('name', '')
    }

    const accountCategories = accounts?.generalLedgerAccounts
      .filter((account) => {
        const category = account.accountType.accountTypeCategory.name
        return invoiceAccounts.includes(category)
      })
      .filter((account) => account.accountType.name !== 'Bank')

    const hasEmptyField = Object.values(values).some((value) => !value)

    return (
      <NewItemFormContainer direction="column" ref={ref} css={css}>
        <NewItemFormHeader gutterX="2">
          <HiDocumentAdd size="2rem" color="#ABB3B9" />
          <Text size="sm" color="$primary">
            {type} Item
          </Text>
        </NewItemFormHeader>
        <NewItemContent direction="column" gutterY="6">
          {type === 'New' && (
            <NewItemFormGrid columns={'two'}>
              <Dropdown
                fullWidth
                menuItemStyle={{
                  height: '4rem',
                }}
                menu={
                  inventoryItemsData?.length === 0
                    ? inventoryItems?.map((item) => ({
                        label: item.name,
                        onClick: () => {
                          setValues(item)
                        },
                      }))
                    : inventoryItemsData?.map((item) => ({
                        label: item.name,
                        onClick: () => {
                          setValues(item)
                        },
                      }))
                }
                header={
                  <Flex direction="column">
                    <Button appearance="ghost" onClick={toggleInventory}>
                      <HiPlusCircle color="#398AFA" />
                      <Text color="$blue">Create an inventory item</Text>
                    </Button>
                    <StyledItemSearch
                      onChange={(e) => {
                        const filtered = inventoryItems?.filter((item) =>
                          item.name
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                        )
                        setInventoryItemsData(filtered)
                      }}
                      placeholder="Search for Item"
                      prepend={<HiOutlineSearch color="#ABB3B9" />}
                    />
                  </Flex>
                }
              >
                <Input
                  required
                  label="Item"
                  placeholder="Add Item"
                  value={values.name}
                  onChange={(e) => {
                    setFromInventory(false)
                    setInputValue('name', e.target.value)
                  }}
                />
              </Dropdown>

              <Input
                required
                label="Description"
                placeholder="Add Item Description"
                {...register('description')}
                wrapperStyle={{ flexGrow: 'unset' }}
              />
            </NewItemFormGrid>
          )}
          <NewItemFormGrid columns="two">
            {type === 'New' && (
              <>
                <TypeAheadSelect
                  required
                  isDisabled={fromInventory}
                  label="Category"
                  placeholder="Select category"
                  options={accountCategories ?? []}
                  valueKey="id"
                  labelKey="name"
                  renderValue={(value: any) => (
                    <Flex align="center" gutterX="2" css={{ py: '1.2rem' }}>
                      <Text size="xs">{value.name}</Text>
                    </Flex>
                  )}
                  value={values.glAccountId}
                  onChange={(value) =>
                    setInputValue('glAccountId', value as string)
                  }
                />
                <NewItemFormGrid columns="two">
                  <Input
                    required
                    label="Quantity"
                    name="quantity"
                    placeholder="0"
                    type="number"
                    value={values.quantity}
                    onChange={(e) =>
                      setInputValue('quantity', Number(e.target.value))
                    }
                    wrapperStyle={{ flexGrow: 'unset' }}
                  />
                  <Input
                    required
                    disabled={fromInventory}
                    label="Unit Price"
                    name="unitPrice"
                    placeholder="500"
                    type="number"
                    value={values.unitPrice}
                    onChange={(e) =>
                      setInputValue('unitPrice', Number(e.target.value))
                    }
                    wrapperStyle={{ flexGrow: 'unset' }}
                  />
                </NewItemFormGrid>
              </>
            )}
            {type === 'Edit' && (
              <>
                <Input
                  required
                  label="Quantity"
                  name="quantity"
                  placeholder="0"
                  type="number"
                  value={values.quantity}
                  onChange={(e) =>
                    setInputValue('quantity', Number(e.target.value))
                  }
                  wrapperStyle={{ flexGrow: 'unset' }}
                />
                <Input
                  required
                  disabled
                  label="Unit Price"
                  name="unitPrice"
                  placeholder="500"
                  type="number"
                  value={values.unitPrice}
                  onChange={(e) =>
                    setInputValue('unitPrice', Number(e.target.value))
                  }
                  wrapperStyle={{ flexGrow: 'unset' }}
                />
              </>
            )}
          </NewItemFormGrid>
        </NewItemContent>
        <NewItemFooter align="center" justify="end" gutterX="2">
          {hasInvoiceItems ? (
            <Button appearance="secondary" onClick={onCancel}>
              Cancel
            </Button>
          ) : (
            ''
          )}
          <Button
            disabled={hasEmptyField && type === 'New'}
            onClick={() => onSaveItem({ ...values, id: currentItemId })}
          >
            {type === 'New' ? 'Add' : 'Update'}
          </Button>
        </NewItemFooter>
        <AddNewProductDrawer
          visible={showInventory}
          onClose={toggleInventory}
          onSuccess={onInventoryAddSuccess}
        />
      </NewItemFormContainer>
    )
  }
)

NewInvoiceItemForm.displayName = 'NewOfferItemForm'
