import Button from 'components/button/button'
import Drawer from 'components/drawer/drawer'
import { Box, Flex } from 'components/layout'
import Separator from 'components/separator/separator'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select'
import { format } from 'date-fns'
import {
  Purchase,
  useSendPurchaseOrderMutation,
} from 'generated/__generated_graphql'
import { useAppProvider } from 'providers/app-provider'
import { useState } from 'react'
import { HiDocumentAdd } from 'react-icons/hi'
import { extractGraphqlErrors, formatMoney } from 'utils/helpers'

interface SendPurchaseProps {
  visible: boolean
  onClose: () => void
  onSuccess?: (values?: any) => void
  purchase: Purchase
}

type Recipient = {
  email: string
}

const SendPurchase: React.FC<SendPurchaseProps> = ({
  visible,
  onClose,
  purchase,
}) => {
  const notify = useToast()
  const { organisation } = useAppProvider()
  const [purchaseRecipients, setPurchaseRecipients] = useState<Recipient[]>([])

  const [{ fetching: sendingPurchase }, sendPurchaseMutation] =
    useSendPurchaseOrderMutation()

  async function sendPurchase() {
    try {
      const response = await sendPurchaseMutation({
        input: {
          purchaseOrderId: purchase.id,
          emails: purchaseRecipients.map((recipient) => recipient.email),
        },
      })
      const error = extractGraphqlErrors(response, 'sendPurchase')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: 'Purchase Order sent successfully',
        status: 'success',
      })
      onClose()
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  const currency = organisation?.currency.symbol

  return (
    <Drawer
      title="Send Purchase Order"
      // fullHeight={false}
      titleIcon={<HiDocumentAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="md"
            disabled={purchaseRecipients.length === 0 || sendingPurchase}
            onClick={sendPurchase}
            isLoading={sendingPurchase}
          >
            Send Purchase Order
          </Button>
        </Flex>
      }
    >
      <Box css={{ padding: '28px 24px' }}>
        <Flex justify="start" direction="column" css={{ rowGap: 5 }}>
          <Text size="xxs">
            Date:{' '}
            <Text color="#171717" size="xxs">
              {format(new Date(purchase.issueDate), 'dd MMM, yyyy')}
            </Text>
          </Text>
          <Text size="xxs">
            Due on:{' '}
            <Text color="#171717" size="xxs">
              {format(new Date(purchase.dueDate), 'dd MMM, yyyy')}
            </Text>
          </Text>
          <Text size="xxs">
            Purchase Order Number:{' '}
            <Text color="#171717" size="xxs">
              {purchase.reference}
            </Text>
          </Text>
        </Flex>
        <Separator color="#EEF2F4" />

        <Flex justify="start" direction="column" css={{ rowGap: 5 }}>
          <Text size="xxs">
            Amount:{' '}
            <Text size="xs" weight="bold">
              {formatMoney(purchase.amount, currency)}
            </Text>
          </Text>
        </Flex>
        <Separator color="#EEF2F4" />

        <Flex justify="between" css={{ mb: '$6' }}>
          <Flex justify="start" direction="column" css={{ rowGap: 10 }}>
            <Text size="xxs" color="$secondary">
              From
            </Text>
            <Text size="xxs">{organisation?.name}</Text>
            <Text size="xxs">{organisation?.countryCode}</Text>
          </Flex>
          <Flex justify="start" direction="column" css={{ rowGap: 10 }}>
            <Text size="xxs">Prepared for</Text>
            <Text size="xxs">{purchase?.supplier?.name}</Text>
            <Text size="xxs">{purchase?.supplier?.country}</Text>
            {/* <Text size="xxs">VAT: {invoice?.customer?.tin}</Text> */}
          </Flex>
        </Flex>

        <Flex>
          <TypeAheadSelect
            required
            size="sm"
            isMulti
            isCreatable={true}
            label="Recipients"
            placeholder="Press enter to add emails"
            valueKey="email"
            labelKey="email"
            value={purchaseRecipients?.map((r) => r.email)}
            options={purchaseRecipients}
            onChange={(recipients: string[]) => {
              setPurchaseRecipients(
                recipients.map((recipient) => ({ email: recipient }))
              )
            }}
            noOptionsMessage={() => (
              <Text size={'xxs'}>
                Type in a valid email address then press ENTER / TAB to add
              </Text>
            )}
          />
        </Flex>
      </Box>
    </Drawer>
  )
}

export default SendPurchase
