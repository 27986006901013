import { Avatar } from 'components/avatar/avatar'
import Button from 'components/button/button'
import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import PageHeader from 'components/page-header/page-header'
import PageLoader from 'components/page-loader/page-loader'
import { Tabs } from 'components/tabs/tabs'
import { Span } from 'components/text/span'
import { Text } from 'components/text/text'
import { useAppProvider } from 'providers/app-provider'
import { useState } from 'react'
import { HiChatAlt2, HiChevronRight, HiLightningBolt } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { CancelSubscriptionDrawer } from '../components/subscriptions/cancel-subscription-drawer'
import { Declined } from '../components/subscriptions/declined'
import { Paid } from '../components/subscriptions/paid'
import { SubscriptionHistory } from '../components/subscriptions/subscription-history'
import { StyledContent, StyledFlex } from '../organisation.styles'

const Subscription = () => {
  const navigate = useNavigate()
  const { organisation, profile } = useAppProvider()
  const [cancelSubscription, setCancelSubscription] = useState(false)

  if (!profile) {
    return <PageLoader />
  }
  const isBusinessInNigeria = organisation?.countryCode === 'NG'

  const { name: orgName } = organisation ?? {}
  const { name: planName } = organisation?.plan ?? {}
  const { billings } = organisation ?? {}

  return (
    <>
      <Flex direction="column" gutter="3">
        <PageHeader
          title="Subscriptions"
          subtitle="Manage your subscriptions"
          type="plain"
        />

        <StyledContent css={{ p: '$4' }} direction="column" gutter="3">
          <StyledFlex align="center">
            <HiChatAlt2 size="1.5rem" color="#ABB3B9" />
            <Text size="xxs">
              Got questions?{' '}
              <Span css={{ color: '$secondary' }}>
                Contact us on{' '}
                <a href="mailto:subscriptions@accounteer.com">
                  subscriptions@accounteer.com
                </a>
              </Span>
            </Text>
            <HiChevronRight size="2rem" color="#ABB3B9" className="chevron" />
          </StyledFlex>
        </StyledContent>
        <StyledContent direction="column" gutter="3">
          <Text
            css={{ mb: '$5', px: '$4', fontFamily: '$bold' }}
            size="md"
            color="$primary"
          >
            Current Plan
          </Text>
          <Flex css={{ px: '$4' }} align="center" gutter="3">
            <Avatar
              icon={<HiLightningBolt color="#8EB0DF" size="3rem" />}
              background="#F3F8FF"
              size="xtraLarge"
            />
            <Flex direction="column" gutter="2">
              <Text weight="bold" size="md">
                {planName}
              </Text>
              <Text size="xs" color="$secondary">
                {orgName} is subscribed to the{' '}
                <Span css={{ color: '$blue' }}>{planName} Plan</Span>
                {/* with {members}{' '}
              users */}
              </Text>
            </Flex>
          </Flex>

          <Flex
            justify="end"
            css={{
              background: '$settingsFooterbg',
              p: '$3',
              mt: '$4',
              gap: '1rem',
            }}
          >
            {!planName?.toLowerCase().includes('free') && (
              <Button
                appearance={'danger'}
                onClick={() => setCancelSubscription(true)}
              >
                Cancel subscription
              </Button>
            )}
            {!planName?.toLowerCase().includes('ent') && (
              <Button
                onClick={() =>
                  navigate('/dashboard/settings/organisation/plans')
                }
              >
                Upgrade plan
              </Button>
            )}
          </Flex>
        </StyledContent>

        <StyledContent css={{ pt: 0 }}>
          <Box css={{ width: '100%' }}>
            <Tabs
              defaultValue="1"
              tabs={[
                {
                  key: '1',
                  title: 'History',
                  content: <SubscriptionHistory isBusinessInNigeria={isBusinessInNigeria} billings={billings ?? []} />,
                },
                {
                  key: '2',
                  title: 'Paid',
                  content: <Paid isBusinessInNigeria={isBusinessInNigeria} billings={billings ?? []} />,
                },
                {
                  key: '3',
                  title: 'Declined',
                  content: <Declined isBusinessInNigeria={isBusinessInNigeria} billings={billings ?? []} />,
                },
              ]}
            />
          </Box>
        </StyledContent>
      </Flex>

      <CancelSubscriptionDrawer
        visible={cancelSubscription}
        onClose={() => setCancelSubscription(false)}
      />
    </>
  )
}

export default Subscription
