import { Flex } from 'components/layout'
import Loader from 'components/loader/loader'
import { useProfileQuery } from 'generated/__generated_graphql'
import { Route, Routes } from 'react-router-dom'
import Profile from './pages/profile'

const Account = () => {
  const [{ data: profileQueryData }] = useProfileQuery()

  if (!profileQueryData?.profile)
    return (
      <Flex
        direction={'column'}
        justify="center"
        align={'center'}
        css={{ height: '100vh', width: '100%' }}
      >
        <Loader size={'md'} />
      </Flex>
    )

  return (
    <Routes>
      <Route path="/profile" element={<Profile />}></Route>
      {/* <Route path="/preferences" element={<Preferences />}></Route>
      <Route path="/notifications" element={<Notifications />}></Route>
      <Route path="/connected-accounts" element={<ConnectedAccounts />}></Route> */}
    </Routes>
  )
}

export default Account
