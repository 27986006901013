import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select/type-ahead-select'
import {
  InvoiceEnum,
  ReconcileTransactionInput,
  ReconciliationTypeEnum,
  useAccountTypeAccountsQuery,
  useInvoicesQuery,
  useReconcileTransactionMutation,
  useTaxesQuery,
} from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import { useAppProvider } from 'providers/app-provider'
import React, { useState } from 'react'
import { HiTemplate } from 'react-icons/hi'
import { extractGraphqlErrors, formatMoney } from 'utils/helpers'
import { CleanTransaction } from '../screens/transactions'

type ReconcileTransactionDrawerProps = {
  visible: boolean
  onClose: () => void
  transaction: CleanTransaction
  refetchTransactions: () => void
  refetchFinancialAccounts: () => void
}

const ReconcileTransactionDrawer: React.FC<ReconcileTransactionDrawerProps> = (
  props
) => {
  const {
    visible,
    onClose,
    transaction,
    refetchTransactions,
    refetchFinancialAccounts,
  } = props

  const [{ fetching: reconciling }, submitReconciliation] =
    useReconcileTransactionMutation()

  // const [, queryTransactions] = useTransactionsQuery({
  //   variables: {
  //     bankAccountId: String(transaction.bankAccountId),
  //     pagination: {
  //       page: 1,
  //       per: 10,
  //     },
  //   },
  // })

  // const [, queryFinancialAccounts] = useFinancialAccountsQuery({
  //   variables: {
  //     pagination: {
  //       page: 1,
  //       per: 10,
  //     },
  //   },
  // })

  const toast = useToast()
  const { organisation } = useAppProvider()

  const [selectedReconcilitionCategory, setSelectedReconcilitionCategory] =
    useState('journal')

  const { values, setInputValue, errors } = useForm({
    fields: {
      category: selectedReconcilitionCategory,
      account: '',
      tax: '',
      document: '',
    },
  })

  function notify(error?: string, status?: 'success' | 'error') {
    toast({
      content: error ?? 'something went wrong',
      status: status,
    })
  }

  const [{ data: taxes }] = useTaxesQuery()

  const taxesMap = taxes?.taxes?.data?.map((tax) => {
    return {
      label: tax.name,
      value: tax.id,
    }
  })

  const categoriesMap = [
    {
      label: 'Journal',
      value: 'journal',
    },
    {
      label: 'Payment',
      value: 'payment',
    },
    {
      label: 'Transfer',
      value: 'transfer',
    },
  ]

  const [selectedAccount, setSelectedAccount] = useState<any>()
  const [{ data: accountTypeAccountsQueryData }] = useAccountTypeAccountsQuery()
  const { accountTypeAccounts } = accountTypeAccountsQueryData ?? {}

  const categoriesSelectOptions =
    accountTypeAccounts?.map((accountTypeAccount, index) => ({
      label: accountTypeAccount.name,
      value: index,
      options: accountTypeAccount?.accounts?.map((account) => ({
        label: account.name,
        value: account.id,
      })),
    })) ?? []

  const accounts =
    selectedReconcilitionCategory === 'transfer'
      ? categoriesSelectOptions?.find((item) => item.label === 'Bank')?.options
      : categoriesSelectOptions

  const [{ data: invoices }] = useInvoicesQuery({
    variables: {
      invoiceType: InvoiceEnum.Invoice,
      status: null,
      pagination: {
        per: 10,
        page: 1,
      },
    },
  })

  const invoiceMap = invoices?.invoices?.data?.map((invoice) => {
    return {
      label: `${invoice.reference} • ${invoice.customer.name} • ${formatMoney(
        invoice.amount,
        organisation?.currency.symbol
      )}`,
      value: invoice.id,
    }
  })

  async function handleReconciliation() {
    try {
      const payload: ReconcileTransactionInput = {
        bankAccountTransactionId: transaction.id,
        bankAccountId: String(transaction.bankAccountId),
        glAccountId: selectedAccount,
        ...(values.category === 'journal'
          ? {
              reconciliationType: ReconciliationTypeEnum.Journal,
              taxId: values.tax,
            }
          : values.category === 'payment'
          ? {
              reconciliationType: ReconciliationTypeEnum.Payment,
              invoiceId: values.document,
            }
          : {
              reconciliationType: ReconciliationTypeEnum.Transfer,
            }),
      }

      const response = await submitReconciliation({ input: payload })
      const error = extractGraphqlErrors(response, 'reconcileTransaction')

      if (error) {
        notify(error, 'error')
        return
      }

      refetchTransactions()
      refetchFinancialAccounts()
      onClose()
      notify('Transaction reconciled successfully', 'success')
    } catch (error) {
      notify(error as string, 'error')
    }
  }

  return (
    <Drawer
      title="Reconcile Transaction"
      titleIcon={<HiTemplate size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary">
            Cancel
          </Button>
          <Button
            isLoading={reconciling}
            size="md"
            onClick={handleReconciliation}
          >
            Reconcile
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="5" css={{ p: '$5' }}>
        <TypeAheadSelect
          required
          label="Choose Category"
          placeholder="Select option"
          options={categoriesMap}
          onChange={(value) => {
            setInputValue('category', value as string)
            setSelectedReconcilitionCategory(value as string)
          }}
          error={errors.category}
          defaultValue={selectedReconcilitionCategory}
        />
        {selectedReconcilitionCategory === 'journal' && (
          <>
            <TypeAheadSelect
              required
              label="Accounts"
              placeholder="Select an account"
              options={accounts}
              onChange={setSelectedAccount}
              value={selectedAccount}
            />
            <TypeAheadSelect
              label="Tax"
              placeholder="Select tax"
              options={
                taxesMap?.length
                  ? taxesMap
                  : [
                      {
                        label: 'No tax',
                        value: '',
                      },
                    ]
              }
              onChange={(value) => {
                setInputValue('tax', value as string)
              }}
              error={errors.tax}
            />
          </>
        )}
        {selectedReconcilitionCategory === 'payment' && (
          <>
            <TypeAheadSelect
              required
              label="Choose Document"
              placeholder="Select option"
              options={invoiceMap}
              onChange={(value) => {
                setInputValue('document', value as string)
              }}
              error={errors.category}
            />
          </>
        )}
        {selectedReconcilitionCategory === 'transfer' && (
          <>
            <TypeAheadSelect
              required
              label="Accounts"
              placeholder="Select an account"
              options={accounts}
              onChange={setSelectedAccount}
            />
          </>
        )}
      </Flex>
    </Drawer>
  )
}

export default ReconcileTransactionDrawer
