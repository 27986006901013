import Button from 'components/button/button'
import Stack from 'components/layout/stack'
import NativeSelect from 'components/native-select/native-select'
import { format, isSameMonth } from 'date-fns'
import React from 'react'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import {
  CalendarHeader,
  CalendarWrapper,
  Dates,
  Day,
  RangeInputs,
  StyledNavButton,
  WeekDay,
  WeekHeader,
} from './calendar.styles'
import { ICalendarProps } from './calendar.types'
import {
  daysOfTheWeek,
  getDates,
  getYears,
  months,
  monthsSelect,
} from './calendar.utils'
import RangeIndicator from './range-indicator'
import useCalendar from './use-calendar'

const Calendar = React.forwardRef<HTMLDivElement, ICalendarProps>(
  (
    {
      selectedDate,
      onSelect,
      onClose,
      minimumDate,
      maximumDate,
      mode,
      isMonthPicker,
      onDateRangeSelect,
    },
    ref
  ) => {
    const {
      month,
      year,
      _selectedDate,
      goToNextMonth,
      goToPreviousMonth,
      onDateSelect,
      getDayVariant,
      goToMonth,
      goToYear,
      activeSelection,
      setActiveSelection,
    } = useCalendar({
      selectedDate,
      onSelect,
      onClose,
      minimumDate,
      maximumDate,
      mode,
      onDateRangeSelect,
    })

    return (
      <CalendarWrapper className="calendar" ref={ref}>
        <div className="calendar-content">
          {mode === 'range' && (
            <RangeInputs justify={'between'} className="range-inputs">
              <RangeIndicator
                dates={_selectedDate}
                activeSelection={activeSelection}
                onSelectorChange={setActiveSelection}
              ></RangeIndicator>
            </RangeInputs>
          )}
          <CalendarHeader
            className="calendar-header"
            align="center"
            justify="between"
          >
            {!isMonthPicker && (
              <StyledNavButton tabIndex={-1} onClick={goToPreviousMonth}>
                <HiChevronLeft className="icon" />
              </StyledNavButton>
            )}
            <Stack isInline spacing={8} align="center">
              <NativeSelect
                size="sm"
                css={{ width: 120 }}
                aria-label="Month"
                defaultValue={
                  months[(selectedDate as Date).getMonth()] ?? months[month]
                }
                labelKey="key"
                valueKey="value"
                options={monthsSelect}
                onChange={(e) => goToMonth(e.target.value as string)}
              />

              <NativeSelect
                size="sm"
                css={{ width: 80 }}
                aria-label="Year"
                defaultValue={(selectedDate as Date).getFullYear() ?? year}
                labelKey="label"
                valueKey="value"
                options={getYears(minimumDate, maximumDate)}
                onChange={(e) => goToYear(Number(e.target.value))}
              />
            </Stack>
            {!isMonthPicker && (
              <StyledNavButton tabIndex={-1} onClick={goToNextMonth}>
                <HiChevronRight className="icon" />
              </StyledNavButton>
            )}
          </CalendarHeader>
          {isMonthPicker && (
            <Button
              fullWidth
              size="sm"
              appearance="secondary"
              onClick={() => onDateSelect(new Date(year, month))}
            >
              Select
            </Button>
          )}
          {!isMonthPicker && (
            <>
              <WeekHeader>
                {daysOfTheWeek.map((day) => {
                  return (
                    <WeekDay justify="center" key={day}>
                      {day.substring(0, 2)}
                    </WeekDay>
                  )
                })}
              </WeekHeader>
              <Dates>
                {getDates(month, year).map((date, index) => {
                  if (!isSameMonth(date, new Date(year, month))) {
                    return (
                      <Day variant="disabled" key={index}>
                        {format(date, 'd')}
                      </Day>
                    )
                  }

                  return (
                    <Day
                      tabIndex={-1}
                      role="button"
                      onClick={() => onDateSelect(date)}
                      key={index}
                      variant={getDayVariant(date)}
                    >
                      {format(date, 'd')}
                    </Day>
                  )
                })}
              </Dates>
            </>
          )}
        </div>
      </CalendarWrapper>
    )
  }
)

Calendar.displayName = 'Calendar'

export default React.memo(Calendar)
