import Box from 'components/layout/box'
import React from 'react'
import { SeparatorProps } from './separator.types'

const Separator = React.forwardRef<HTMLDivElement, SeparatorProps>(
  ({ spacing = 16, color = '$secondary', css }, ref) => {
    return (
      <Box
        ref={ref}
        css={{
          height: 1,
          width: '100%',
          background: color,
          my: spacing,
          ...css,
        }}
      ></Box>
    )
  }
)

Separator.displayName = 'Separator'

export default Separator
