import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Input from 'components/input/input'
import { Box, Flex } from 'components/layout'
import Text from 'components/text/text'
import { useToast } from 'components/toast'
import {
  CreateDiscountMutation,
  CreateTaxMutation,
  useCreateDiscountMutation,
  useCreateTaxMutation,
} from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import { HiDocumentAdd } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'

type ItemType = 'tax' | 'discount'

type ItemTextValue = {
  titleText: string
  nameText: string
  descriptionText: string
}

interface ItemDrawerProps {
  visible: boolean
  onClose: () => void
  onSuccess?: (values?: any) => void
  type: ItemType
}

const ItemTextMap: Record<ItemType, ItemTextValue> = {
  tax: {
    titleText: 'New Tax Rate',
    nameText: 'Income tax',
    descriptionText: 'This is an income tax',
  },
  discount: {
    titleText: 'New Discount Rate',
    nameText: 'Bonanza',
    descriptionText: 'Up to 10% of discount',
  },
}

const ItemDrawer: React.FC<ItemDrawerProps> = ({
  visible,
  type,
  onClose,
  onSuccess,
}) => {
  const itemText = ItemTextMap[type]
  const notify = useToast()
  const { register, values, errors } = useForm({
    fields: { name: '', description: '', rate: 0 },
  })

  const hasEmptyField = Object.values(values).some((value) => !value)

  const [{ fetching: creatingTax }, createTaxMutation] = useCreateTaxMutation()
  const [{ fetching: creatingDiscount }, createDiscountMutation] =
    useCreateDiscountMutation()

  async function createCustomItem() {
    const operation =
      type === 'tax' ? createTaxMutation : createDiscountMutation
    try {
      const response = await operation({
        input: { ...values, rate: Number(values.rate) },
      })
      const error = extractGraphqlErrors(response, 'createTax')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      const itemData =
        type === 'tax'
          ? (response.data as CreateTaxMutation)?.createTax?.tax
          : (response.data as CreateDiscountMutation)?.createDiscount?.discount
      onSuccess?.(itemData)
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  const creating = creatingTax || creatingDiscount
  return (
    <Drawer
      title={itemText.titleText}
      titleIcon={<HiDocumentAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="md"
            isLoading={creating}
            disabled={creating || hasEmptyField}
            onClick={createCustomItem}
          >
            Add Rate
          </Button>
        </Flex>
      }
    >
      <Box css={{ padding: '28px 24px' }}>
        <Text css={{ mt: 28 }}>Rate Details</Text>
        <Flex direction="column" gutterY="5" css={{ mt: 32 }}>
          <Input
            label="Name"
            size="lg"
            placeholder={itemText.nameText}
            {...register('name')}
            error={errors.name}
            required
          />
          <Input
            label="Description"
            size="lg"
            placeholder={itemText.descriptionText}
            {...register('description')}
            error={errors.description}
            required
          />
          <Input
            label="Rate"
            size="lg"
            placeholder="2%"
            type="number"
            step="any"
            {...register('rate')}
            error={errors.rate}
            required
          />
        </Flex>
      </Box>
    </Drawer>
  )
}

export default ItemDrawer
