import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Items from './screens/items'
import ItemsCategories from './screens/items-categories'

export const Inventory = () => {
  return (
    <Routes>
      <Route path="/items" element={<Items />} />
      <Route path="/item-categories" element={<ItemsCategories />} />
    </Routes>
  )
}
