import type * as Stitches from '@stitches/react'
import { DateSelectionValue, DateTuple } from 'components/calendar'
import { StyledInputWrapper } from 'components/input/input.styles'
import Flex from 'components/layout/flex'
import Stack from 'components/layout/stack'
import Tag from 'components/tag/tag'
import { format } from 'date-fns'
import React from 'react'
import { HiArrowRight, HiCalendar } from 'react-icons/hi'
import { styled } from 'stitches/stitches.config'

const Wrapper = styled(Flex, StyledInputWrapper, {
  px: 4,

  '.tag': { cursor: 'pointer' },
})

type RangeIndicatorProps = React.ComponentProps<typeof Wrapper> & {
  dates: DateSelectionValue
  css?: Stitches.CSS
  activeSelection: 'from' | 'to'
  onSelectorChange(indicator: 'to' | 'from'): void
}

const RangeIndicator = React.forwardRef<HTMLDivElement, RangeIndicatorProps>(
  ({ css, activeSelection, dates, onSelectorChange }, ref) => {
    const [start, end] = dates as DateTuple

    return (
      <Wrapper
        ref={ref}
        className="date-input"
        align={'center'}
        css={css}
        size={'md'}
      >
        <Flex stretchx align={'center'} justify="between" className="start">
          <Stack align={'center'} isInline className="start">
            <Tag
              role="button"
              className="tag"
              onClick={() => onSelectorChange('from')}
              prependIcon={<HiCalendar />}
              size="xs"
              shape={'pill'}
              color={activeSelection === 'from' ? 'teal' : 'gray'}
            >
              {format(start, 'MM/dd/yyyy')}
            </Tag>
          </Stack>

          <div className="arrow">
            <HiArrowRight></HiArrowRight>
          </div>

          <Stack align={'center'} isInline className="start">
            <Tag
              role="button"
              className="tag"
              onClick={() => onSelectorChange('to')}
              prependIcon={<HiCalendar />}
              size="xs"
              shape={'pill'}
              color={activeSelection === 'to' ? 'teal' : 'gray'}
            >
              {end ? format(end, 'MM/dd/yyyy') : 'MM/DD/YYYY'}
            </Tag>
          </Stack>
        </Flex>
      </Wrapper>
    )
  }
)

RangeIndicator.displayName = 'RangeIndicator'

export default RangeIndicator
