import { ReactComponent as EmptyIcon } from 'assets/svg/empty-transactions.svg'
import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import Flex from 'components/layout/flex'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import Tag from 'components/tag/tag'
import { Text } from 'components/text/text'
import { format } from 'date-fns'
import {
  CustomReportViewer,
  useCustomReportsQuery,
} from 'generated/__generated_graphql'
import React from 'react'
import { HiDotsHorizontal, HiPlus } from 'react-icons/hi'
import {
  MdOutlineAccountBalanceWallet,
  MdOutlineBusinessCenter,
} from 'react-icons/md'
import { TbReportMoney } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { ReportsPageProps } from '../reports'
import ShareReportDrawer from './share-report-drawer'

interface CustomReportsTableData {
  id: string
  name: string
  date: string
  type: string[]
  createdBy: string
  link: string
  reportViewers: CustomReportViewer[]
}

export const CustomReportsTable: React.FC<ReportsPageProps> = ({
  setShowDrawer,
}) => {
  const navigate = useNavigate()
  const [page, setPage] = React.useState(1)

  const [shareReportId, setShareReportId] = React.useState<string | null>(null)
  const [customReportId, setCustomReportId] = React.useState<string | null>(
    null
  )
  const [reportViewers, setReportViewers] = React.useState<
    CustomReportViewer[]
  >([])

  const [{ fetching, data }] = useCustomReportsQuery({
    variables: {
      pagination: {
        page,
        per: 10,
      },
    },
  })
  const customReports = data?.customReports?.data
  const pagination = data?.customReports?.pagination

  const dataMap = customReports?.map((report) => {
    return {
      id: report.id,
      name: report.title,
      date: report.reportDate,
      type: report.reportTypes,
      createdBy: report.createdBy,
      link: report.shareableLink,
      reportViewers: report.customReportViewers,
    }
  })

  const iconMap: Record<string, JSX.Element> = {
    'Profit & Loss': <TbReportMoney />,
    'Balance Sheet': <MdOutlineAccountBalanceWallet />,
    'Business Summary': <MdOutlineBusinessCenter />,
  }

  const columns: ITableColumn<CustomReportsTableData>[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      render: (date) => (
        <Text size="xs" color="$primary">
          {format(new Date(date), 'dd MMM, yyyy')}
        </Text>
      ),
    },
    {
      key: 'type',
      title: 'Type',
      dataIndex: 'type',
      render: (items) => (
        <Flex gutterX="2">
          {items.map((tag: string, i: number) => {
            return (
              <Tag prependIcon={iconMap[tag]} key={i} size="xs">
                {tag}
              </Tag>
            )
          })}
        </Flex>
      ),
    },
    {
      key: 'createdBy',
      title: 'Created By',
      dataIndex: 'createdBy',
    },
    {
      key: 'link',
      title: '',
      dataIndex: 'link',
      render: (link, data) => (
        <Flex stretchx justify="end">
          <Dropdown
            placement="bottomRight"
            menu={[
              {
                label: 'Preview',
                onClick: () => {
                  const link =
                    data.link.split('/')[data.link.split('/').length - 1]
                  navigate(`/reports/${link}`)
                },
              },
              {
                label: 'Share',
                onClick: () => {
                  setCustomReportId(data.id)
                  setShareReportId(link)
                  setReportViewers(data.reportViewers)
                },
              },
            ]}
          >
            <Flex stretchx align="center" justify="end">
              <Button appearance="ghost">
                <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
              </Button>
            </Flex>
          </Dropdown>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Table
        columns={columns}
        loading={fetching}
        dataSource={dataMap || []}
        showSearch
        emptyProps={{
          title: 'No custom reports yet',
          subtitle:
            'Create custom reports to view your business performance over a period of time.',
          icon: <EmptyIcon width="5.6rem" height="5.6rem" />,
          action: (
            <Flex gutterX="2">
              <Button
                prepend={<HiPlus />}
                onClick={() =>
                  setShowDrawer({
                    open: true,
                  })
                }
              >
                Create custom report
              </Button>
            </Flex>
          ),
        }}
        pagination={{
          totalCount: pagination?.totalCount as number,
          currentPage: pagination?.currentPage as number,
          perPage: 10,
          totalPages: pagination?.totalPages as number,
          onPaginationClick: (type) => {
            if (type === 'next') {
              if (page === pagination?.totalPages) return
              setPage(page + 1)
            } else {
              if (page === 1) return
              setPage(page - 1)
            }
          },
        }}
      />
      {shareReportId && (
        <ShareReportDrawer
          id={customReportId as string}
          reportViewers={reportViewers}
          link={shareReportId}
          visible={!!shareReportId}
          onClose={() => setShareReportId(null)}
        />
      )}
    </>
  )
}
