/* eslint-disable react/prop-types */
import InputError from 'components/input/components/input-error'
import InputLabel from 'components/input/components/input-label'
import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import { StyledCaret } from 'components/select/select.styles'
import React from 'react'
import {
  SelectWrapper,
  StyledChevron,
  StyledSelect,
} from './native-select.styles'
import { IProps } from './native-select.types'

const NativeSelect = React.forwardRef<HTMLDivElement, IProps>(
  (
    {
      label,
      error,
      required,
      css,
      options,
      id,
      defaultValue,
      labelKey = 'label',
      valueKey = 'value',
      disabled,
      size,
      ...selectProps
    },
    ref
  ) => {
    return (
      <Box css={css} ref={ref}>
        {label && (
          <InputLabel htmlFor={id} required={required}>
            {label}
          </InputLabel>
        )}
        <SelectWrapper size={size} isDisabled={disabled}>
          <Flex align="center" justify="between" stretchx>
            <StyledChevron>
              <StyledCaret size="1.9rem" />
            </StyledChevron>
            <StyledSelect
              disabled={disabled}
              id={id}
              defaultValue={defaultValue}
              {...selectProps}
              className="input-el"
            >
              {options?.map((option, index) => {
                return (
                  <option key={index} value={option[valueKey as string]}>
                    {option[labelKey as string]}
                  </option>
                )
              })}
            </StyledSelect>
          </Flex>
        </SelectWrapper>
        {error && <InputError css={{ marginTop: 8 }}>{error}</InputError>}
      </Box>
    )
  }
)

NativeSelect.displayName = 'NativeSelect'

export default NativeSelect
