import { zodResolver } from '@hookform/resolvers/zod'
import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import Text from 'components/text/text'
import { useToast } from 'components/toast'
import TypeAheadSelect, {
  CustomClearIndicator,
  CustomControl,
  CustomMenu,
  CustomMenuList,
  CustomMenuPortal,
  CustomMultiValue,
  CustomOption,
  CustomPlaceholder,
  CustomSingleValue,
} from 'components/type-ahead-select'
import {
  useInviteTeamMemberMutation,
  useProfileQuery,
  useRolesQuery,
} from 'generated/__generated_graphql'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { HiUserAdd } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { z } from 'zod'

// Describe the correctness of data's form.
const formSchema = z.object({
  emailAddresses: z
    .array(z.string().email({ message: 'Please enter a valid email address' }))
    .nonempty({ message: 'Please add at least one email address' }),
  inviteAs: z
    .string()
    .min(1, 'Please select a role to be assigned to the team member(s)'),
})

// Infer the TS type according to the zod schema.
type FormFields = z.infer<typeof formSchema>

const components = {
  DropdownIndicator: null,
  Control: CustomControl,
  ClearIndicator: CustomClearIndicator,
  Placeholder: CustomPlaceholder,
  Menu: CustomMenu,
  Option: CustomOption,
  SingleValue: CustomSingleValue,
  MultiValue: CustomMultiValue,
  MenuList: CustomMenuList,
  MenuPortal: CustomMenuPortal,
}

interface InviteTeamMemberDrawerProps {
  visible: boolean
  onClose: () => void
}

export const InviteTeamMemberDrawer: React.FC<InviteTeamMemberDrawerProps> = (
  props
) => {
  const { visible, onClose } = props

  const {
    control,
    reset,
    formState: { errors, isSubmitting, isValid },
    getValues,
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  const notify = useToast()
  const [{ data: rolesQueryData }] = useRolesQuery()
  const [, queryProfile] = useProfileQuery()
  const [, inviteTeamMember] = useInviteTeamMemberMutation()

  const rolesOptions =
    rolesQueryData?.roles.map((role) => ({
      label: role.name,
      value: role.id,
    })) ?? []

  async function onSubmit() {
    try {
      const { emailAddresses, inviteAs } = getValues()

      const response = await inviteTeamMember({
        input: {
          emails: emailAddresses,
          roleId: inviteAs,
        },
      })

      const error = extractGraphqlErrors(response, 'inviteTeamMember')

      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }

      queryProfile({ requestPolicy: 'network-only' })

      notify({
        status: 'success',
        content:
          'Invites have been sent to the emails to join your team on Accounteer',
      })

      onReset()
    } catch (error) {
      notify({
        status: 'error',
        content:
          'Something went wrong while inviting team members. Please try again',
      })
    }
  }

  function onReset() {
    reset()
    onClose()
  }

  return (
    <Drawer
      title="Invite Team Members"
      titleIcon={<HiUserAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      closable={!isSubmitting}
      maskClosable={!isSubmitting}
      onClose={onReset}
      destroyOnClose
    >
      <form
        style={{ height: '100%', width: '100%' }}
        onSubmit={handleSubmit(onSubmit)}
        onReset={onReset}
      >
        <Flex
          css={{
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
            height: 'inherit',
            width: 'inherit',
            p: '$5',
          }}
        >
          <Flex direction="column" gutter="3" css={{ width: 'inherit' }}>
            <Controller
              control={control}
              name="emailAddresses"
              render={({ field: { onChange } }) => {
                return (
                  <TypeAheadSelect
                    required
                    id={'email-addresses'}
                    label={`Enter team members email address`}
                    isClearable
                    isCreatable
                    isMulti
                    components={components}
                    placeholder={`ada@plaid.com, san@example.com, etc ...`}
                    noOptionsMessage={() => (
                      <Text size={'xxs'}>
                        Type in a valid email address then press ENTER / TAB to
                        add
                      </Text>
                    )}
                    onChange={onChange}
                    error={errors.emailAddresses?.message}
                  />
                )
              }}
            />

            <Controller
              control={control}
              name="inviteAs"
              render={({ field: { onChange } }) => {
                return (
                  <TypeAheadSelect
                    required
                    label="Invite as"
                    placeholder="Select role"
                    options={rolesOptions}
                    onChange={onChange}
                    error={errors.inviteAs?.message}
                  />
                )
              }}
            />
          </Flex>

          <Flex gutterX="2" css={{ alignSelf: 'end' }}>
            <Button
              type="reset"
              size="md"
              appearance="secondary"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              size="md"
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting || !isValid}
            >
              Send Invites
            </Button>
          </Flex>
        </Flex>
      </form>
    </Drawer>
  )
}
