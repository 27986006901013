import Avatar from 'components/avatar'
import Button from 'components/button/button'
import Drawer from 'components/drawer/drawer'
import Input from 'components/input/input'
import { Box, Flex } from 'components/layout'
import Tag from 'components/tag/tag'
import Text from 'components/text/text'
import Textarea from 'components/textarea/textarea'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select'
import {
  CustomReportViewer,
  useShareCustomReportMutation,
} from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import { FiCopy } from 'react-icons/fi'
import { HiPresentationChartLine } from 'react-icons/hi'
import { extractGraphqlErrors, isValidEmail } from 'utils/helpers'

interface CreaeCustomeReportDrawerProps {
  visible: boolean
  onClose: () => void
  id: string
  link: string
  reportViewers: CustomReportViewer[]
}

function ShareReportDrawer({
  visible,
  onClose,
  id,
  link,
  reportViewers,
}: CreaeCustomeReportDrawerProps) {
  const toast = useToast()

  const [{ fetching }, shareCustomReport] = useShareCustomReportMutation()

  const { register, values, setInputValue, errors, clearForm } = useForm({
    fields: {
      customReportId: id,
      emails: [''],
      comment: '',
    },
  })

  function verifyEmailsAreValid(emails: string[]) {
    const regex = /\S+@\S+\.\S+/
    return emails.every((email) => regex.test(email))
  }

  async function handleGenerateReport() {
    try {
      if (values.emails.length === 0) {
        toast({
          content: 'Please add at least one email',
          status: 'error',
        })
        return
      }
      if (!verifyEmailsAreValid(values.emails)) {
        toast({
          content: 'Please add a valid email',
          status: 'error',
        })
        return
      }
      const response = await shareCustomReport({
        input: values,
      })

      const error = extractGraphqlErrors(response, 'createFinancialAccount')

      if (error) {
        toast({
          content: error,
          status: 'error',
        })
        return
      }
      toast({
        content: 'Report shared successfully',
        status: 'success',
      })
      onClose()
      clearForm()
    } catch (error) {
      toast({
        content: (error as string) || 'Something went wrong',
        status: 'error',
      })
    }
  }

  return (
    <Drawer
      placement="right"
      title="Share report"
      titleIcon={<HiPresentationChartLine size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button isLoading={fetching} onClick={handleGenerateReport} size="md">
            Share report
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="5" css={{ p: '$5' }}>
        <TypeAheadSelect
          size="sm"
          isMulti
          required
          isCreatable={true}
          label="Recipient Email(s)"
          placeholder={`doe@gmail.com, jane@example.com`}
          valueKey="email"
          labelKey="email"
          noOptionsMessage={() => (
            <Text size={'xxs'}>
              Type in a valid email address then press ENTER / TAB to add
            </Text>
          )}
          onChange={(emails: string[]) => {
            setInputValue('emails', emails)
          }}
          error={errors.emails}
          isValidNewOption={(val) => isValidEmail(val)}
        />
        {/* <TypeAheadSelect
          label="Recipient Access"
          placeholder="Select Recipient Access"
          required
          options={[
            { id: '1', name: 'Viewer' },
            { id: '2', name: 'Commenter' },
            { id: '3', name: 'Full Access' },
          ]}
          labelKey="name"
          valueKey="id"
        /> */}
        <Textarea
          label="Add a comments"
          placeholder="
            Add a comment to your report
          "
          {...register('comment')}
          // onChange={(val) => {
          //   onChange(val)
          // }}
          error={errors.comment}
        />
        <Flex align="center" gutter="4">
          <Box
            css={{
              width: '100%',
              height: '1px',
              backgroundColor: '$disabled',
            }}
          ></Box>
          <Text
            size="xxs"
            weight="bold"
            color="gray"
            css={{
              whiteSpace: 'nowrap',
            }}
          >
            who can access this report
          </Text>
          <Box
            css={{
              width: '100%',
              height: '1px',
              backgroundColor: '$disabled',
            }}
          ></Box>
        </Flex>
        <Flex
          gutter="3"
          direction="column"
          css={{
            p: '$3',
            border: '.1rem solid $border',
            borderRadius: '$4',
            maxHeight: '15rem',
            overflowY: 'auto',
          }}
        >
          {reportViewers?.map((viewer) => (
            <Person key={viewer.id} email={viewer.email} access={viewer.role} />
          ))}
        </Flex>
        <Flex direction="column" gutter="2">
          <Text size="xs" weight="bold">
            Share Link
          </Text>
          <Input
            value={link}
            append={
              <Button
                appearance="ghost"
                onClick={() => {
                  navigator.clipboard.writeText(link)
                  toast({
                    content: 'Link copied to clipboard',
                    status: 'success',
                  })
                }}
              >
                <FiCopy />
              </Button>
            }
          />
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default ShareReportDrawer

type Tag = {
  [key: string]: 'success' | 'blue' | 'default'
}

function Person({
  // name,
  email,
  access,
}: {
  // name: string
  email: string
  access: string
}) {
  const tagColor: Tag = {
    'Full Access': 'success',
    Commenter: 'blue',
    Viewer: 'default',
  }
  return (
    <Flex align="center" justify="between" gutterX="2">
      <Flex align="center" gutterX="2">
        <Avatar title={email} size="medium" />
        <Flex direction="column" gutterX="2">
          {/* <Text size="xs">{email}</Text> */}
          <Text color="$secondary" size="xs">
            {email}
          </Text>
          {/* {email && (
            <Text color="$secondary" size="xxs">
              {email}
            </Text>
          )} */}
        </Flex>
      </Flex>
      <Tag type={tagColor[access]} size="xs">
        {access}
      </Tag>
    </Flex>
  )
}
