import * as Dialog from '@radix-ui/react-dialog'
import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import { Flex } from 'components/layout'
import { SmartReportStatus } from 'generated/__generated_graphql'
import Chat from 'pages/reports/components/chat'
import {
  StyledOptions,
  StyledOptionsContainer,
} from 'pages/settings/settings.styles'
import { useState } from 'react'
import { HiDotsHorizontal } from 'react-icons/hi'
import { keyframes, styled } from 'stitches/stitches.config'
import { getApiUrl } from 'utils/helpers'
import { CleanServiceReport } from '../service-report'
import DeleteReportDrawer from './delete-service-report-drawer'

type UnProcessedReportsTableActionProps = {
  report: CleanServiceReport
}

const UnProcessedReportsTableAction: React.FC<
  UnProcessedReportsTableActionProps
> = (props) => {
  const { report } = props

  const [showDeleteReportDrawer, setShowDeleteReportDrawer] = useState(false)
  const [showReportChatDrawer, setShowReportChatDrawer] = useState(false)

  return (
    <>
      <Dialog.Root
        open={showReportChatDrawer}
        onOpenChange={() => setShowReportChatDrawer(true)}
      >
        <Flex stretchx justify="end">
          <Dropdown
            placement="bottomRight"
            menu={[
              {
                label: (
                  <Dialog.Trigger asChild>
                    <Button
                      css={{
                        width: '100%',
                        textAlign: 'left',
                        justifyContent: 'start',
                      }}
                      appearance={'ghost'}
                    >
                      Open chat
                    </Button>
                  </Dialog.Trigger>
                ),
              },
              {
                label: (
                  <Button
                    appearance={'ghost'}
                    as={'a'}
                    // @ts-ignore
                    href={`${getApiUrl()}/smart-reports/${report.id}.pdf`}
                    target="_blank"
                    css={{
                      width: '100%',
                      justifyContent: 'start',
                    }}
                  >
                    Download
                  </Button>
                ),
              },
              ...(report.status !== SmartReportStatus.Uploaded
                ? []
                : [
                    {
                      label: (
                        <Button
                          appearance={'ghost'}
                          onClick={() => setShowDeleteReportDrawer(true)}
                          css={{ width: '100%', justifyContent: 'start' }}
                        >
                          Delete
                        </Button>
                      ),
                    },
                  ]),
            ]}
          >
            <StyledOptionsContainer stretchx align="center" justify="end">
              <StyledOptions appearance="ghost">
                <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
              </StyledOptions>
            </StyledOptionsContainer>
          </Dropdown>
        </Flex>

        <Dialog.Portal>
          <DialogOverlay onClick={() => setShowReportChatDrawer(false)} />

          <DialogContent>
            <Chat id={report.chat.id} />
          </DialogContent>
        </Dialog.Portal>
      </Dialog.Root>

      <DeleteReportDrawer
        report={report}
        visible={showDeleteReportDrawer}
        onClose={() => setShowDeleteReportDrawer(false)}
      />
    </>
  )
}

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'scale(.96)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
})

export const DialogOverlay = styled(Dialog.Overlay, {
  backgroundColor: '$backdropBg',
  position: 'fixed',
  inset: 0,
  animation: `${overlayShow} 300ms cubic-bezier(0.16, 1, 0.3, 1)`,
  cursor: 'pointer',
})

export const DialogContent = styled(Dialog.Content, {
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  bottom: '5%',
  right: '2%',
  width: '30rem',
  height: '45rem',
  padding: 0,
  animation: `${contentShow} 300ms cubic-bezier(0.16, 1, 0.3, 1)`,
  '&:focus': { outline: 'none' },
})

export default UnProcessedReportsTableAction
