import Avatar from 'components/avatar'
import Input, {
  InputError,
  InputLabel,
  type InputProps,
} from 'components/input'
import { Flex } from 'components/layout'
import Text from 'components/text'
import TypeAheadSelect, { CustomMenu } from 'components/type-ahead-select'

import React from 'react'
import { styled } from 'stitches/stitches.config'
import { Country } from 'utils/countries'

export const StyledInput = styled(Input, {
  width: '100%',
  height: '100%',
  '& input': {
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
})

interface PhoneNumberData {
  dialingCode: string
  phoneNumber: string
}

interface PhoneNumberInputProps
  extends Omit<InputProps, 'onChange' | 'defaultValue'> {
  onChange: (value: PhoneNumberData) => void
  countries: Country[]
  defaultValue?: PhoneNumberData
}

export const PhoneNumberInput = React.forwardRef<
  HTMLDivElement,
  PhoneNumberInputProps
>((props, ref) => {
  const {
    label,
    required,
    name,
    disabled,
    error,
    hideErrorMessage,
    onChange,
    size,
    countries,
    defaultValue,
  } = props

  const countriesArray = countries.map((country, index) => ({
    ...country,
    value: country.name,
    key: country.code,
    id: Object.keys(countries)[index],
  }))

  const [phoneNumberData, setPhoneNumberData] = React.useState<PhoneNumberData>(
    defaultValue || { dialingCode: '', phoneNumber: '' }
  )

  const selectedCountry = countriesArray.find(
    (c) => c.phone === phoneNumberData.dialingCode
  )

  const onSelectChange = (countryCode: string) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const selectedPhone = countriesArray.find((c) => c.id === countryCode)
      ?.phone!

    setPhoneNumberData({ ...phoneNumberData, dialingCode: selectedPhone })
    onChange({ ...phoneNumberData, dialingCode: selectedPhone })
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = e.target.value
    setPhoneNumberData({ ...phoneNumberData, phoneNumber: phoneNumber })
    onChange({ ...phoneNumberData, phoneNumber: phoneNumber })
  }

  return (
    <Flex direction={'column'}>
      {label && (
        <InputLabel required={required} htmlFor={name}>
          {label}
        </InputLabel>
      )}
      <Flex gutter="2" align={'center'} css={{ width: '100%' }}>
        <TypeAheadSelect
          className="country-type-ahead"
          css={{ width: 110 }}
          customMenu={(props) => (
            <CustomMenu
              {...{
                ...props,
                innerProps: {
                  ...props.innerProps,
                  style: {
                    width: 350,
                  },
                },
              }}
            />
          )}
          placeholder="+000"
          size={size}
          required={required}
          name={`${name}-select`}
          onChange={onSelectChange}
          options={countriesArray}
          renderOption={(option: Country) => (
            <Flex
              align="center"
              gutter="1"
              css={{ width: '100%', height: '$5' }}
            >
              <Avatar>{option.emoji}</Avatar>
              <Flex
                css={{
                  flexGrow: 1,
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Text size="xs">{option.name}</Text>
                <Text size="xs" color={'black'}>
                  {option.phone}
                </Text>
              </Flex>
            </Flex>
          )}
          labelKey="name"
          valueKey="id"
          renderValue={(value: Country) => (
            <Text size="xs" align={'center'}>
              {value.code}
            </Text>
          )}
          defaultInputValue={selectedCountry?.code}
        />

        <StyledInput
          disabled={disabled}
          className="phone-number-input-input-el"
          placeholder="12 345 6789"
          type={'number'}
          id={name}
          name={name}
          ref={ref}
          required={required}
          size={size}
          value={phoneNumberData.phoneNumber}
          onChange={onInputChange}
          prepend={<Text>{phoneNumberData.dialingCode}</Text>}
        />
      </Flex>
      {error && !hideErrorMessage && (
        <InputError css={{ marginTop: 4 }}>{error}</InputError>
      )}
    </Flex>
  )
})

PhoneNumberInput.displayName = 'PhoneNumberInput'

export default PhoneNumberInput
