import { Avatar } from 'components/avatar/avatar'
import Button from 'components/button/button'
import Flex from 'components/layout/flex'
import { Table } from 'components/table/table'
import { StyledInputWrap } from 'components/table/table.styles'
import { ITableColumn } from 'components/table/table.types'
import Tag from 'components/tag/tag'
import { Text } from 'components/text/text'
import { CleanOrganisation } from 'providers/app-provider'
import React, { PropsWithChildren } from 'react'
import { HiOutlinePlus } from 'react-icons/hi'
import MembersTableDataAction from './components/members-table-action'

export type MembersTableData = {
  id: string
  name: string
  emailAddress: string
  roles: string
  lastLogin: string
  member: Exclude<CleanOrganisation['users'], null | undefined>[number]
}

const columns: ITableColumn<MembersTableData>[] = [
  {
    key: 'name',
    title: 'name',
    dataIndex: 'name',
    render: (name: MembersTableData['name']) => (
      <Flex align="center" gutterX="2">
        <Avatar
          title={name}
          background={
            name.toLowerCase().includes('pending') ? '$error' : undefined
          }
          size="medium"
        />
        <Text
          size="xs"
          color={name.toLowerCase().includes('pending') ? '$error' : undefined}
        >
          {name}
        </Text>
      </Flex>
    ),
  },
  {
    key: 'emailAddress',
    title: 'email Address',
    dataIndex: 'emailAddress',
  },
  {
    key: 'roles',
    title: 'roles',
    dataIndex: 'roles',
  },
  {
    key: 'lastLogin',
    title: 'last Login',
    dataIndex: 'lastLogin',
    render: (value: MembersTableData['lastLogin']) => (
      <Flex align="center" gutterX="2">
        {value ? (
          <Text size="xs">{value}</Text>
        ) : (
          <Tag
            size="xs"
            css={{ color: '#6759A1', background: '#F6F3FF' }}
            content="Invitation Sent"
          />
        )}
      </Flex>
    ),
  },
  {
    key: 'action',
    title: '',
    dataIndex: 'id',
    render: (_, { member }) => {
      return <MembersTableDataAction member={member} />
    },
  },
]

export type MembersTableProps = {
  tableData: MembersTableData[]
  setShowDrawer: (show: boolean) => void
}

const MembersTable: React.FC<PropsWithChildren<MembersTableProps>> = (
  props
) => {
  const { tableData, setShowDrawer } = props

  return (
    <Table
      showSearch
      columns={columns}
      dataSource={tableData}
      css={{
        [`& ${StyledInputWrap}`]: {
          mr: '2%',
        },
      }}
      actions={
        <Button
          size="xl"
          prepend={<HiOutlinePlus color="#fff" />}
          onClick={() => setShowDrawer(true)}
        >
          Add New Member
        </Button>
      }
    />
  )
}

export default MembersTable
