import { zodResolver } from '@hookform/resolvers/zod'
import { ReactComponent as BlueClipIcon } from 'assets/svg/blue-clip-icon.svg'
import { ReactComponent as GrayClipIcon } from 'assets/svg/gray-clip-icon.svg'
import { ReactComponent as GrayTrashIcon } from 'assets/svg/gray-trash-icon.svg'
import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import { FileUpload } from 'components/file-upload/file-upload'
import { StyledFileInput } from 'components/file-upload/file-upload.styles'
import { InputLabel } from 'components/input'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import Loader from 'components/loader/loader'
import Text from 'components/text/text'
import Textarea from 'components/textarea/textarea'
import { useToast } from 'components/toast'
import {
  SmartReportStatus,
  useCreateSmartReportMutation,
  useSmartReportsQuery,
} from 'generated/__generated_graphql'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BsFillArrowUpCircleFill } from 'react-icons/bs'
import { OperationContext } from 'urql'
import { extractGraphqlErrors } from 'utils/helpers'
import { z } from 'zod'

const formSchema = z.object({
  reportName: z.string().min(3, 'Please enter a name for the report'),
  reportInstructions: z
    .string()
    .min(3, 'Please enter some instructions for the report'),
  uploadedDocumentIds: z.array(z.string()).nonempty({
    message: 'Please upload the report documents you want processed',
  }),
})

type FormFields = z.infer<typeof formSchema>

interface UploadReportDocumentsDrawerProps {
  visible: boolean
  onClose: () => void
  refetchReports: (opts?: Partial<OperationContext> | undefined) => void
}

export const UploadReportDocumentsDrawer: React.FC<
  UploadReportDocumentsDrawerProps
> = (props) => {
  const { visible, onClose } = props

  const notify = useToast()

  const {
    control,
    register,
    reset,

    formState: { errors, isSubmitting, isValid },
    getValues,
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  const [, createSmartReport] = useCreateSmartReportMutation()
  const [, queryAllReports] = useSmartReportsQuery({
    variables: {
      status: SmartReportStatus.All,
    },
  })

  async function onSubmit() {
    try {
      const { reportName, reportInstructions, uploadedDocumentIds } =
        getValues()

      const response = await createSmartReport({
        input: {
          name: reportName,
          instructions: reportInstructions,
          uploadIds: uploadedDocumentIds,
        },
      })

      const error = extractGraphqlErrors(response, 'register')

      if (error) {
        notify({ content: error, status: 'error' })
        return
      }

      notify({
        content: 'Your reports were uploaded successfully',
        status: 'success',
      })

      queryAllReports({
        requestPolicy: 'network-only',
      })

      onReset()
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  function onReset() {
    reset()
    onClose()
  }

  return (
    <Drawer
      title="Upload documents"
      titleIcon={<BsFillArrowUpCircleFill size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onReset}
    >
      <form
        style={{ height: '100%' }}
        onSubmit={handleSubmit(onSubmit)}
        onReset={onReset}
      >
        <Flex
          css={{
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
            height: '100%',
            width: '100%',
            p: '$5',
          }}
        >
          <Flex direction="column" gutter="3" css={{ width: '100%' }}>
            <Input
              {...(register('reportName') as any)}
              required
              label="Report name"
              placeholder="Enter report name"
              error={errors.reportName?.message}
            />

            <Textarea
              {...(register('reportInstructions') as any)}
              required
              label="Report instructions"
              placeholder="Enter report instructions"
              error={errors.reportInstructions?.message}
            />

            <InputLabel css={{ my: '1.6rem' }} required>
              Select your documents to upload
            </InputLabel>

            <Controller
              control={control}
              name="uploadedDocumentIds"
              render={({ field: { onChange } }) => (
                <FileUpload
                  onChange={(uploads) =>
                    onChange(uploads.map((upload) => upload.id))
                  }
                >
                  {({
                    uploadedFiles,
                    uploadInputOnChange,
                    reset,
                    actionStatus,
                  }) => (
                    <>
                      <label htmlFor="upload-documents-input">
                        <Flex
                          justify={'center'}
                          align={'center'}
                          css={{
                            width: '100%',
                            height: 140,
                            boxShadow: '$subtle',
                            padding: '4.4rem 8.6rem',
                            gap: '2.4rem',
                            border: '1px solid #EEF2F4',
                            borderRadius: '$3',
                            cursor: 'pointer',
                          }}
                        >
                          {actionStatus === 'loading' ? (
                            <Loader />
                          ) : (
                            <>
                              <Flex
                                justify={'center'}
                                align={'center'}
                                css={{
                                  width: '5.2rem',
                                  height: '5.2rem',
                                  boxShadow: '$subtle',
                                  border: '1px solid #EEF2F4',
                                  borderRadius: '$3',
                                }}
                              >
                                <BlueClipIcon />
                              </Flex>
                              <Flex direction={'column'}>
                                <Text size={'xs'}>
                                  Click here to upload file
                                </Text>
                                <Text size={'xxs'} color={'$secondary'}>
                                  PDF, XLS, CSV, PNG, JPEG, DOC, DOCX (smaller
                                  than 10MB)
                                </Text>
                              </Flex>
                            </>
                          )}
                        </Flex>
                      </label>

                      {actionStatus !== 'loading' && (
                        <StyledFileInput
                          type="file"
                          id="upload-documents-input"
                          multiple={false}
                          onChange={uploadInputOnChange}
                        />
                      )}

                      <Flex direction={'column'} css={{ gap: '$3', mt: '$3' }}>
                        {uploadedFiles.map((uploadedFile) => (
                          <Flex
                            key={uploadedFile.id}
                            css={{
                              padding: '1.5rem 1.3rem',
                              backgroundColor: '$white',
                              boxShadow: '$tiny',
                              border: '1px solid #EDEDED',
                              borderRadius: '$3',
                              alignItems: 'center',
                              gap: '1rem',
                              width: '100%',
                            }}
                          >
                            <GrayClipIcon />

                            <Text size="xs" color={'$secondary'}>
                              {uploadedFile.name}
                            </Text>
                            <Flex
                              css={{
                                flexGrow: 1,
                                alignSelf: 'end',
                                justifyContent: 'end',
                              }}
                            >
                              <Button
                                type="button"
                                appearance={'ghost'}
                                onClick={() =>
                                  reset(
                                    uploadedFiles.filter(
                                      (file) => file.id !== uploadedFile.id!
                                    )
                                  )
                                }
                              >
                                <GrayTrashIcon />
                              </Button>
                            </Flex>
                          </Flex>
                        ))}
                      </Flex>
                    </>
                  )}
                </FileUpload>
              )}
            />
          </Flex>

          <Flex gutterX="2" css={{ alignSelf: 'end' }}>
            <Button
              type="reset"
              size="md"
              appearance="secondary"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              size="md"
              isLoading={isSubmitting}
              type="submit"
              disabled={isSubmitting || !isValid}
            >
              Continue
            </Button>
          </Flex>
        </Flex>
      </form>
    </Drawer>
  )
}
