import { styled } from 'stitches/stitches.config'

export const CheckBoxWrapper = styled('label', {
  display: 'flex',
  position: 'relative',
  paddingLeft: '2rem',
  height: '2rem',
  cursor: 'pointer',
  fontSize: '$3',
  userSelect: 'none',

  defaultVariants: {
    shape: 'square',
    direction: 'left',
  },

  '.custom-checkbox': {
    position: 'absolute',
    left: 'unset',
    height: '2rem',
    width: '2rem',
    overflow: 'hidden',
    backgroundColor: '$white',
    border: '0.15rem solid $border',
    transition: 'all 0.5s',

    '.checkmark': {
      display: 'hidden',
      color: '$white',
      flexShrink: 0,
    },
  },

  variants: {
    shape: {
      circle: {
        '.custom-checkbox': {
          borderRadius: '100%',
        },
      },

      square: {
        '.custom-checkbox': {
          borderRadius: 4,
        },
      },
    },
    direction: {
      left: {
        '.custom-checkbox': {
          left: '0',
        },
      },
      right: {
        paddingLeft: '0rem',
        p: '$3',
        border: '0.1rem solid $border',
        boxShadow: '$tiny',
        height: 'max-content',
        borderRadius: '$2',
        '.custom-checkbox': {
          right: '1.5rem',
          transform: `translateY(40%)`,
        },
      },
    },
  },

  '.custom-checkbox:focus-within': {
    boxShadow: '$tiny',
  },

  '&:hover input ~ .custom-checkbox': {
    backgroundColor: '$background',
  },

  'input:focus ~ .custom-checkbox': {
    boxShadow: '$tiny',
  },

  'input[aria-checked="mixed"] ~ .checkmark': {
    backgroundColor: '$white',
    border: '0.1rem solid $blue',
  },

  'input:checked ~ .custom-checkbox': {
    backgroundColor: '$blue',
    border: '0.1rem solid $blue',

    '.checkmark': { display: 'block' },
  },

  input: {
    position: 'absolute',
    opacity: 0,
    left: 0,
    height: '2rem',
    width: '2rem',
    zIndex: 3,
    cursor: 'pointer',
  },
})

export const VerticalCheckBox = styled('label', {
  position: 'relative',
  height: '2rem',
  cursor: 'pointer',
  fontSize: '$3',
  userSelect: 'none',

  defaultVariants: {
    shape: 'square',
  },

  '.custom-checkbox': {
    position: 'absolute',
    left: 0,
    height: '2rem',
    width: '2rem',
    overflow: 'hidden',
    backgroundColor: '$white',
    border: '0.15rem solid $border',
    transition: 'all 0.5s',

    '.checkmark': {
      display: 'hidden',
      color: '$white',
      flexShrink: 0,
    },
  },

  variants: {
    shape: {
      circle: {
        '.custom-checkbox': {
          borderRadius: '100%',
        },
      },

      square: {
        '.custom-checkbox': {
          borderRadius: 4,
        },
      },
    },
  },

  '.custom-checkbox:focus-within': {
    boxShadow: '$tiny',
  },

  '&:hover input ~ .custom-checkbox': {
    backgroundColor: '$background',
  },

  'input:focus ~ .custom-checkbox': {
    boxShadow: '$tiny',
  },

  'input[aria-checked="mixed"] ~ .checkmark': {
    backgroundColor: '$white',
    border: '0.1rem solid $blue',
  },

  'input:checked ~ .custom-checkbox': {
    backgroundColor: '$blue',
    border: '0.1rem solid $blue',

    '.checkmark': { display: 'block' },
  },

  input: {
    position: 'absolute',
    opacity: 0,
    left: 0,
    height: '2rem',
    width: '2rem',
    zIndex: 3,
    cursor: 'pointer',
  },
})
