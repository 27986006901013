// import Input from 'components/input/input'
// import { Avatar } from 'components/avatar/avatar'
import discountImg from 'assets/png/discount.png'
// import { FiSearch } from 'react-icons/fi'
// import { Avatar } from 'components/avatar/avatar'
import { ReactComponent as AccounteerLogo } from 'assets/svg/accounteer-logo.svg'
import AccountSwitcher from 'components/account-switcher/account-switcher'
import Button from 'components/button/button'
import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import MenuBar from 'components/menu-bar/menu-bar'
import QuickFind from 'components/quick-find/quick-find'
import Tag from 'components/tag/tag'
import { Text } from 'components/text/text'
import { motion } from 'framer-motion'
import {
  HiCash,
  HiChartPie,
  HiClipboardCheck,
  HiGift,
  HiHome,
  HiOutlineCash,
  HiOutlineChartPie,
  HiOutlineClipboardCheck,
  HiOutlineGift,
  HiOutlineHome,
  HiOutlinePresentationChartLine,
  HiOutlineReceiptRefund,
  HiOutlineUsers,
  HiPresentationChartLine,
  HiReceiptRefund,
  HiUsers,
} from 'react-icons/hi'
import { MdAccountTree, MdOutlineAccountTree } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { DashboardLayoutProps } from './layout.types'
import { StyledCol, StyledDiscount, StyledRow } from './layouts.styles'

const defaultLayoutItems = [
  {
    key: 'dashboard',
    icon: <HiOutlineHome />,
    iconActive: <HiHome />,
    label: 'Dashboard',
    path: '/dashboard',
  },
  {
    key: 'sales',
    icon: <HiOutlineChartPie />,
    iconActive: <HiChartPie />,
    label: 'Sales',
    path: '/dashboard/sales/offer',
    subMenu: [
      {
        key: '2',
        label: 'Invoices',
        path: '/dashboard/sales/invoices',
      },
      // {
      //   key: '1',
      //   label: 'Offers',
      //   path: '/dashboard/sales/offers',
      // },
    ],
  },
  {
    key: 'purchases',
    icon: <HiOutlineGift />,
    iconActive: <HiGift />,
    label: 'Purchases',
    path: '/dashboard/purchases/expenses',
    subMenu: [
      {
        key: '1',
        label: 'Bills/Expenses',
        path: '/dashboard/purchases/expenses',
      },
      {
        key: '1',
        label: 'Purchase Order',
        path: '/dashboard/purchases/orders',
      },
    ],
  },
  {
    key: 'financial',
    icon: <HiOutlineCash />,
    iconActive: <HiCash />,
    label: 'Financial',
    path: '/dashboard/financial/accounts',
    subMenu: [
      {
        key: '1',
        label: 'Accounts',
        path: '/dashboard/financial/accounts',
      },
      {
        key: '2',
        label: 'Bank Rules',
        path: '/dashboard/financial/bank-rules',
      },
    ],
  },
  {
    key: 'accounting',
    icon: <MdOutlineAccountTree />,
    iconActive: <MdAccountTree />,
    label: 'Accounting',
    path: '/dashboard/accounting/charts-of-accounts',
    subMenu: [
      {
        key: '1',
        label: 'Charts of accounts',
        path: '/dashboard/accounting/charts-of-accounts',
      },
      {
        key: '2',
        label: 'Journals',
        path: '/dashboard/accounting/journals',
      },
    ],
  },
  {
    key: 'reports',
    icon: <HiOutlinePresentationChartLine />,
    iconActive: <HiPresentationChartLine />,
    label: 'Reports',
    path: '/dashboard/reports/overview',
    subMenu: [
      {
        key: '1',
        label: 'Overview',
        path: '/dashboard/reports/overview',
      },
      {
        key: '2',
        label: 'Balance Sheet',
        path: '/dashboard/reports/balance-sheet',
      },
      {
        key: '3',
        label: 'Profit & Loss',
        path: '/dashboard/reports/profit-loss',
      },
      {
        key: '4',
        label: 'Custom Reports',
        path: '/dashboard/reports/custom-reports',
      },
    ],
  },
  {
    key: 'contacts',
    icon: <HiOutlineUsers />,
    iconActive: <HiUsers />,
    label: 'Contacts',
    path: '/dashboard/contacts',
  },
  // {
  //   key: 'assets',
  //   icon: <HiDatabase />,
  //   label: 'Assets',
  //   path: '/dashboard/assets',
  // },
  {
    key: 'inventory',
    icon: <HiOutlineClipboardCheck />,
    iconActive: <HiClipboardCheck />,
    label: 'Inventory',
    path: '/dashboard/inventory/items',
    subMenu: [
      {
        key: '1',
        label: 'Items',
        path: '/dashboard/inventory/items',
      },
      {
        key: '2',
        label: 'Item Categories',
        path: '/dashboard/inventory/item-categories',
      },
    ],
  },
  {
    key: 'taxes',
    icon: <HiOutlineReceiptRefund />,
    iconActive: <HiReceiptRefund />,
    label: 'Taxes',
    path: '/dashboard/taxes/tax-rates',
    subMenu: [
      {
        key: '1',
        label: 'Tax rates',
        path: '/dashboard/taxes/tax-rates',
      },
    ],
  },
]

const sidebarWidth = 270

const DefaultLayout: React.FC<DashboardLayoutProps> = (props) => {
  const { children } = props
  const navigate = useNavigate()

  return (
    <motion.div
      initial={{ opacity: 0, scale: 1.2 }}
      animate={{
        opacity: 1,
        scale: 1,
        transition: {
          duration: 0.5,
          ease: 'easeInOut',
        },
      }}
      exit={{ opacity: 0, scale: 1.2 }}
    >
      <StyledRow>
        <StyledCol background="white" css={{ width: sidebarWidth }}>
          <Flex
            direction="column"
            justify="start"
            gutter="5"
            css={{ p: '$3', height: '100%' }}
          >
            <Box
              css={{
                position: 'absolute',
                top: '$3',
                left: '$3',
                width: sidebarWidth - 38,
              }}
            >
              <AccountSwitcher />
            </Box>
            <QuickFind />
            <MenuBar data={defaultLayoutItems} />
            <StyledDiscount direction="column" gutter="2">
              <img src={discountImg} alt="50% discount" className="img" />
              <Text className="text" size="sm" color="$black">
                Generate Custom AI Smart Reports
              </Text>
              <Text
                size="xxs"
                weight="regular"
                color="$secondary"
                align="center"
              >
                Upgrade to Growth Plan today to enjoy 50% off your subscription.
                Learn more.
              </Text>
              <Button
                appearance="black"
                size="sm"
                onClick={() =>
                  navigate('/dashboard/settings/organisation/plans')
                }
              >
                + Upgrade Now
              </Button>
            </StyledDiscount>
            <Flex
              align="center"
              justify="between"
              css={{
                width: sidebarWidth - 38,
                pt: '$3',
              }}
            >
              <AccounteerLogo
                style={{
                  width: '9rem',
                }}
              />
              <Tag>
                <Text
                  color="$secondary"
                  size="xxs"
                  css={{
                    textTransform: 'lowercase',
                  }}
                >
                  v 2.1.0
                </Text>
              </Tag>
            </Flex>
          </Flex>
        </StyledCol>
        <StyledCol
          css={{
            width: `calc(100vw - ${sidebarWidth}px)`,
            position: 'relative',
            // isolation: 'isolate',
            // zIndex: -1,
          }}
        >
          {children}
        </StyledCol>
      </StyledRow>
    </motion.div>
  )
}

export default DefaultLayout
