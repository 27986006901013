import React, { RefObject } from 'react'

export interface IProps {
  ref?: RefObject<HTMLElement>
  isOpen?: boolean
  callback?(): void
  portal?: string
}

export default function useOutsideClick(props: IProps) {
  const { ref, isOpen, callback, portal } = props

  const handleOutSideClick = React.useCallback(
    (e: MouseEvent) => {
      if (ref?.current?.contains(e.target as HTMLElement)) {
        return
      }

      if (portal) {
        const root = document.querySelector(portal)
        if (root?.contains(e.target as HTMLElement)) return
      }

      callback && callback()
    },
    [ref, callback]
  )

  React.useEffect(() => {
    if (!isOpen) {
      document.removeEventListener('mouseup', handleOutSideClick)
      return
    }

    document.addEventListener('mouseup', handleOutSideClick)

    return () => {
      document.removeEventListener('mouseup', handleOutSideClick)
    }
  }, [isOpen, handleOutSideClick])
}
