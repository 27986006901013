import { VariantProps } from '@stitches/react'
import { InputError, InputLabel } from 'components/input'
import Flex from 'components/layout/flex'
import React from 'react'
import { RiErrorWarningFill } from 'react-icons/ri'

import {
  StyledContainer,
  StyledInputWrapper,
  StyledTextArea,
} from 'components/input/input.styles'
import { CSS } from 'stitches/stitches.config'

export type TextareaProps = Omit<
  React.ComponentProps<typeof StyledTextArea>,
  'size'
> &
  VariantProps<typeof StyledInputWrapper> & {
    prepend?: React.ReactNode
    append?: React.ReactNode
    containerStyle?: CSS
    wrapperStyle?: CSS
    inputStyle?: CSS
    label?: string | React.ReactNode
    error?: string
    required?: boolean
    max?: number | Date
    min?: number | Date
    hideErrorMessage?: boolean
  }

export type InputValidator<T = Record<string, unknown>> = (
  value?: any,
  formValues?: T
) => string

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      appearance,
      append,
      prepend,
      size,
      label,
      error,
      css,
      id,
      required,
      disabled,
      wrapperStyle,
      hideErrorMessage,
      className,
    } = props

    return (
      <StyledContainer
        className={'input' + (className ? ` ${className}` : '')}
        css={css}
        direction="column"
      >
        {label && (
          <InputLabel required={required} htmlFor={id}>
            {label}
          </InputLabel>
        )}
        <Flex direction="column" gutterX="3" css={{ flexGrow: 1 }}>
          <StyledInputWrapper
            className="input-wrapper"
            appearance={appearance}
            size={size}
            state={error ? 'invalid' : 'none'}
            isDisabled={disabled}
            css={wrapperStyle}
          >
            {prepend && <>{prepend}</>}
            <StyledTextArea
              {...props}
              ref={ref}
              id={id}
              data-textarea
              disabled={disabled}
              className="input-el"
            />
            {append && <>{append}</>}
            {error && <RiErrorWarningFill color="#F15656" />}
          </StyledInputWrapper>
        </Flex>
        {error && !hideErrorMessage && (
          <InputError css={{ marginTop: 6 }}>{error}</InputError>
        )}
      </StyledContainer>
    )
  }
)

Textarea.displayName = 'Textarea'

export default Textarea
