import Button from 'components/button/button'
import Drawer from 'components/drawer/drawer'
import { Box, Flex, Stack } from 'components/layout'
import Separator from 'components/separator/separator'
import Text from 'components/text/text'
import { format } from 'date-fns'
import { Invoice } from 'generated/__generated_graphql'
import { useAppProvider } from 'providers/app-provider'
import { HiDocumentAdd } from 'react-icons/hi'
import { styled } from 'stitches/stitches.config'
import { formatMoney } from 'utils/helpers'
import InvoicePreviewer from './invoice-previewer'

interface InvoiceDetailProps {
  visible: boolean
  onClose: () => void
  onSend?: () => void
  onDownload?: () => void
  downloading?: boolean
  type?: 'invoice' | 'offer'
  invoice: Invoice
}

const Container = styled(Box, {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  height: '100%',
})

const InvoiceDetail: React.FC<InvoiceDetailProps> = ({
  visible,
  onClose,
  onSend,
  onDownload,
  downloading,
  type,
  invoice,
}) => {
  const { organisation } = useAppProvider()
  const subTotal =
    invoice?.invoiceItems?.reduce((acc, item) => {
      return acc + item.quantity * item.unitPrice
    }, 0) ?? 0

  const discount =
    invoice?.discounts && invoice?.discounts.length > 0
      ? invoice?.discounts[0].rate
      : 0
  const tax =
    invoice?.taxes && invoice?.taxes.length > 0 ? invoice?.taxes[0].rate : 0
  const discountValue = (discount / 100) * subTotal
  const taxValue = (tax / 100) * subTotal
  const gross = Math.max(0, subTotal! - discountValue!)
  const total = gross + taxValue!
  const symbol = organisation?.currency.symbol
  const hasPayments =
    invoice.invoicePayments && invoice.invoicePayments.length > 0

  return (
    <Drawer
      title={invoice.reference}
      titleIcon={<HiDocumentAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      size="large"
      onClose={onClose}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button size="md" onClick={onSend}>
            {type === 'invoice' ? 'Send Invoice' : 'Send offer'}
          </Button>
        </Flex>
      }
    >
      <Box css={{ padding: '28px 24px' }}>
        <Container>
          <Box css={{ borderRight: '1px solid #EEF2F4', px: 10 }}>
            <Stack spacing={5}>
              <Text size="sm">{formatMoney(invoice.amount, symbol)}</Text>
              <Text size="xs">
                Due on {format(new Date(invoice.dueDate), 'dd MMM, yyyy')}
              </Text>
            </Stack>
            <Stack css={{ mt: 20 }} spacing={8}>
              <Text color="$secondary" size="xxs" weight="bold">
                Created for
              </Text>
              <Text size="xs" weight="bold">
                {invoice?.customer?.name}
              </Text>
              <Text size="xs" weight="bold">
                {invoice?.customer?.country}
              </Text>
            </Stack>
            <Separator color="#EEF2F4" />
            <Stack css={{ mt: 20 }} spacing={8}>
              <Text color="$secondary" size="xxs" weight="bold">
                Items
              </Text>
              {invoice.invoiceItems?.map((item) => (
                <Box key={item.id}>
                  <Flex justify="between" align="end">
                    <Stack spacing={5}>
                      <Text size="xs" weight="bold">
                        {item.name}
                      </Text>
                      <Text size="xs" weight="bold">
                        {formatMoney(item.unitPrice, symbol)} x {item.quantity}
                      </Text>
                    </Stack>
                    <Text size="xs" weight="bold">
                      {formatMoney(item.unitPrice * item.quantity, symbol)}
                    </Text>
                  </Flex>
                  <Separator color="#EEF2F4" />
                </Box>
              ))}
            </Stack>
            <Stack spacing={5}>
              <Flex justify="between" align="center">
                <Text size="xs" weight="bold" color="$secondary">
                  Subtotal
                </Text>
                <Text size="xs" weight="bold" color="$secondary">
                  {formatMoney(subTotal, symbol)}
                </Text>
              </Flex>

              <Flex justify="between" align="center">
                <Text size="xs" weight="bold" color="$secondary">
                  Tax
                </Text>
                <Text size="xs" weight="bold" color="$secondary">
                  {formatMoney(taxValue, symbol)}
                </Text>
              </Flex>

              <Flex justify="between" align="center">
                <Text size="xs" weight="bold" color="$secondary">
                  Discount
                </Text>
                <Text size="xs" weight="bold" color="$secondary">
                  {formatMoney(discountValue, symbol)}
                </Text>
              </Flex>

              <Flex justify="between" align="center">
                <Text size="xs" weight="bold">
                  Total
                </Text>
                <Text size="xs" weight="bold">
                  {formatMoney(total, symbol)}
                </Text>
              </Flex>
            </Stack>
            <Separator color="#EEF2F4" />
            <Stack spacing={8}>
              <Text color="$secondary" size="xxs" weight="bold">
                Account
              </Text>
              <Stack spacing={5}>
                <Text size="sm" weight="bold">
                  {invoice.bankAccount?.title}
                </Text>
                <Text size="xs" weight="bold">
                  {invoice.bankAccount?.name ??
                    invoice.bankAccount?.routingNumber}
                </Text>
                <Text size="xs" weight="bold">
                  {invoice.bankAccount?.accountNumber}
                </Text>
              </Stack>
            </Stack>
            <Separator color="#EEF2F4" />
            {hasPayments && (
              <Stack spacing={8}>
                <Text color="$secondary" size="xxs" weight="bold">
                  Payments
                </Text>
                {invoice.invoicePayments?.map((payment) => (
                  <Box key={payment.id}>
                    <Stack spacing={5}>
                      <Text size="sm" weight="bold">
                        {formatMoney(payment.amount, symbol)}
                      </Text>
                      <Text size="xs" weight="bold">
                        Paid on {format(new Date(payment.date), 'dd MMM, yyyy')}
                      </Text>
                    </Stack>
                    <Separator color="#EEF2F4" />
                  </Box>
                ))}
              </Stack>
            )}

            <Stack spacing={8}>
              <Text color="$secondary" size="xxs" weight="bold">
                Outstanding
              </Text>
              <Text size="sm" weight="bold">
                {formatMoney(invoice.amountPending, symbol)}
              </Text>
            </Stack>
          </Box>

          <Box>
            <InvoicePreviewer
              downloading={downloading}
              onAction={onDownload}
              invoice={invoice}
              totals={{ subTotal, discountValue, taxValue, total }}
              showActions
            />
          </Box>
        </Container>
      </Box>
    </Drawer>
  )
}

export default InvoiceDetail
