/* eslint-disable react/prop-types */
import icon from 'assets/gif/accounteer-icon.gif'
import React from 'react'
import { StyledLoader } from './loader.styles'

type LoaderProps = React.ComponentProps<typeof StyledLoader> & {
  type?: string
}

const Loader = React.forwardRef<HTMLDivElement, LoaderProps>(
  ({ className, size = 'md', color = '#398AFA', css, type }, ref) => {
    return (
      <>
        {type === 'gif' ? (
          <img
            style={{
              width: '20rem',
            }}
            src={icon}
            alt="loading content..."
          />
        ) : (
          <StyledLoader
            ref={ref}
            size={size}
            css={{
              borderColor: `${color} ${color} $border $border`,
              ...css,
            }}
            className={'loader' + (className ? ` ${className}` : '')}
          />
        )}
      </>
    )
  }
)

Loader.displayName = 'Loader'

export default Loader
