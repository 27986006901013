import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import { styled } from 'stitches/stitches.config'

export const StyledLayoutContent = styled(Box, {
  background: '$background',
  padding: '$5',
  minHeight: '100vh',
  overflowX: 'hidden',
})

export const StyledRow = styled(Flex, {
  width: '100%',
  height: '100vh',
})

export const StyledCol = styled(Box, {
  overflow: 'auto',
  height: '100vh',
  backgroundColor: '$background',
  // isolation: 'isolate',

  variants: {
    background: {
      white: {
        backgroundColor: '$white',
      },
    },
  },
})

export const StyledDiscount = styled(Flex, {
  p: '$4 $5',
  borderRadius: '$4',
  border: '.1rem solid $border',
  mt: 'auto',

  '.img': {
    width: '50%',
    marginInline: 'auto',
  },

  '.text': {
    textAlign: 'center',
    fontFamily: '$space',
    fontWeight: '500',
  },
})
