import AnimatedNumber from 'animated-number-react'
import { Box, Flex } from 'components/layout'
import Text from 'components/text/text'
import { ReactElement } from 'react'
import { HiTrendingDown, HiTrendingUp } from 'react-icons/hi'
import { styled } from 'stitches/stitches.config'
import { formatMoney } from 'utils/helpers'

const StyledBox = styled(Box, {
  //   boxShadow: '$tiny',
  borderRadius: '2rem',
  padding: '$5',
  width: '100%',
  height: '100%',

  '.animated-number': {
    fontSize: '3rem',
    lineHeight: '$4',
    fontFamily: '$space',
    mt: '$4',
    color: '$black',
    letterSpacing: '-0.09rem',
  },

  defaultVariants: {
    background: 'blue',
  },

  variants: {
    background: {
      purple: {
        background: '#C6C5F8',
      },
      gold: {
        background: '#ECE8E3',
      },
      blue: {
        background: '#DBEBFD',
      },
    },
  },
})

interface SummaryCardProps {
  children?: React.ReactNode
  title: string
  subTitle?: ReactElement
  amount?: string
  //   tooltip: string
  icon?: ReactElement
  currency?: string
  percentage?: number
  background?: 'purple' | 'gold' | 'blue'
}

function SummaryCard({
  title,
  amount,
  subTitle,
  //   tooltip,
  currency,
  icon,
  background,
}: SummaryCardProps) {
  return (
    <StyledBox background={background}>
      <Flex direction="column" justify="between" gutter={1}>
        <Flex align="center" gutter={2}>
          {icon}
          <Text size="xs" weight="bold" color="$black">
            {title}
          </Text>

          <Text
            size="xxs"
            weight="semi"
            css={{ mt: '$1', ml: 'auto' }}
            color="$black"
          >
            {subTitle}
          </Text>
        </Flex>
        <AnimatedNumber
          easing="linear"
          value={amount}
          formatValue={(value: number) => formatMoney(value, currency)}
          duration={1000}
          className="animated-number"
        />
      </Flex>
    </StyledBox>
  )
}

function Indicator({ percentage }: { percentage: number }) {
  const trend = percentage > 0 ? 'up' : 'down'
  return (
    <Flex align="center">
      <Text size="xxs" color="$black ">
        {trend === 'up' ? (
          <>
            <Text weight="bold" color="$black">
              {percentage}%
            </Text>
          </>
        ) : (
          <>
            <Text weight="bold" color="$black">
              {percentage}%
            </Text>
          </>
        )}
      </Text>
      {trend === 'up' ? (
        <HiTrendingUp color="black" size="1.75rem" />
      ) : (
        <HiTrendingDown color="black" size="1.75rem" />
      )}
    </Flex>
  )
}

export { SummaryCard, Indicator }
