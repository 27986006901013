import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'

import React from 'react'

import { Span } from 'components/text/span'
import {
  SmartReportStatus,
  useDeleteSmartReportMutation,
  useSmartReportsQuery,
} from 'generated/__generated_graphql'
import { HiTrash } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { CleanServiceReport } from '../service-report'

interface DeleteReportDrawerProps {
  visible: boolean
  onClose: () => void
  report: CleanServiceReport
}

const DeleteReportDrawer: React.FC<DeleteReportDrawerProps> = (props) => {
  const { visible, onClose, report } = props

  const notify = useToast()

  const [{ fetching: deletingReport }, deleteReport] =
    useDeleteSmartReportMutation()

  const [, refetchReports] = useSmartReportsQuery({
    variables: { status: SmartReportStatus.All },
  })

  async function submit() {
    try {
      const response = await deleteReport({
        input: {
          id: report.id,
        },
      })
      const error = extractGraphqlErrors(response, 'deleteReport')
      if (error) {
        notify({
          content: error,
          status: 'error',
          duration: 1500,
        })
        return
      }
      notify({
        content: report.name + ' has been deleted successfully',
        status: 'success',
        duration: 3000,
      })
      refetchReports({ requestPolicy: 'network-only' })
      onClose()
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
        duration: 1500,
      })
    }
  }

  return (
    <Drawer
      title="Delete Report"
      titleIcon={<HiTrash size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      closable={!deletingReport}
      maskClosable={!deletingReport}
      fullHeight={false}
    >
      <Flex css={{ p: '$5', height: '100%' }} direction="column" gutter="3">
        <Text size={'xs'} css={{ mb: '3.2rem' }}>
          Are you sure you want to delete{' '}
          <Span css={{ color: '$error' }}>{report.name}</Span>?
        </Text>

        <Flex
          gutterX="2"
          css={{ alignSelf: 'end', alignItems: 'end', flexGrow: 1 }}
        >
          <Button
            size="md"
            appearance="secondary"
            disabled={deletingReport}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            appearance="danger"
            size="md"
            disabled={deletingReport}
            isLoading={deletingReport}
            onClick={submit}
          >
            Delete report
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default DeleteReportDrawer
