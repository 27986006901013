import Button from 'components/button/button'
import { StyledButton } from 'components/button/button.styles'
import { InputLabel } from 'components/input'
import Loader from 'components/loader/loader'
import useFileUpload from 'hooks/useFileUpload'
import { styled } from 'stitches/stitches.config'

const FileInput = styled('input', {
  display: 'none',
})

export const FileInputLabel = styled(InputLabel, StyledButton, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
        background: '$secondary',
        borderColor: '$secondary',
        color: '$primary',
      },
      false: {},
    },
  },
})

export type UploadFileInputParams = ReturnType<typeof useFileUpload>

const UploadFileInput = ({
  uploadStatus,
  fileInputOnChange,
  inputRef,
}: UploadFileInputParams) => {
  if (uploadStatus === 'loading') {
    return (
      <Button>
        <Loader size={'sm'} />
      </Button>
    )
  }
  return (
    <>
      <FileInputLabel htmlFor="upload-file-input">Upload</FileInputLabel>

      <FileInput
        ref={inputRef}
        type="file"
        name="upload-file-input"
        id="upload-file-input"
        multiple={false}
        onChange={fileInputOnChange}
      />
    </>
  )
}

export default UploadFileInput
