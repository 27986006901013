import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { Span } from 'components/text/span'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import {
  useCustomersQuery,
  useDeleteCustomerMutation,
} from 'generated/__generated_graphql'

import React from 'react'
import { HiTrash } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { CleanCustomer } from './contacts-table'

interface DeleteCustomerDrawerProps {
  visible: boolean
  onClose: () => void
  customer: CleanCustomer
}

const DeleteCustomerDrawer: React.FC<DeleteCustomerDrawerProps> = (props) => {
  const { visible, onClose, customer } = props
  const { id } = customer

  const notify = useToast()

  const [, queryCustomers] = useCustomersQuery({
    variables: {
      pagination: {
        per: 10,
        page: 1,
      },
    },
  })
  const [{ fetching: deletingCustomer }, deleteCustomer] =
    useDeleteCustomerMutation()

  async function submit() {
    try {
      const response = await deleteCustomer({
        input: {
          id,
        },
      })
      const error = extractGraphqlErrors(response, 'deleteCustomer')
      if (error) {
        notify({
          content: error,
          status: 'error',
          duration: 1500,
        })
        return
      }
      notify({
        content: customer.name + ' deleted successfully',
        status: 'success',
        duration: 3000,
      })
      queryCustomers({ requestPolicy: 'network-only' })
      onClose()
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
        duration: 1500,
      })
    }
  }

  return (
    <Drawer
      title="Delete Customer"
      titleIcon={<HiTrash size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      closable={!deletingCustomer}
      maskClosable={!deletingCustomer}
      fullHeight={false}
    >
      <Flex css={{ p: '$5', height: '100%' }} direction="column" gutter="3">
        <Text size="xs">
          Are you sure you want to remove{' '}
          <Span css={{ color: '$error' }}>{customer.name}</Span>
        </Text>

        <Flex
          gutterX="2"
          css={{ alignSelf: 'end', alignItems: 'end', flexGrow: 1 }}
        >
          <Button
            size="md"
            appearance="secondary"
            disabled={deletingCustomer}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            appearance="danger"
            size="md"
            disabled={deletingCustomer}
            isLoading={deletingCustomer}
            onClick={submit}
          >
            Remove Customer
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default DeleteCustomerDrawer
