import Button from 'components/button/button'
import Flex from 'components/layout/flex'

import { Table } from 'components/table/table'
import { StyledInputWrap } from 'components/table/table.styles'
import { ITableColumn } from 'components/table/table.types'
import { Text } from 'components/text/text'
import { format } from 'date-fns'
import { TaxesQuery } from 'generated/__generated_graphql'
import { StyledBadge } from 'pages/settings/settings.styles'
import { HiOutlinePlus } from 'react-icons/hi'
import TaxesTableAction from './taxes-table-action'

type CleanTax = Exclude<TaxesQuery['taxes'], null | undefined>['data'][number]

export type TaxRatesTableData = {
  id: string
  description: string
  name: string
  rate: number
  status: 'active' | 'inactive'
  created: string
  tax: CleanTax
}

const columns: ITableColumn<TaxRatesTableData>[] = [
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
    render: (text: string) => (
      <Text size="xs" color="inherit" weight="semi">
        {text}
      </Text>
    ),
  },
  {
    key: 'rate',
    title: 'Rate',
    dataIndex: 'rate',
    render: (rate: TaxRatesTableData['rate']) => (
      <Text color="$primary" size="xs">
        {rate}%
      </Text>
    ),
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (text: TaxRatesTableData['status']) => (
      <StyledBadge status={text}>
        <Text size="xs" color="inherit" weight="semi">
          {text}
        </Text>
      </StyledBadge>
    ),
  },
  {
    key: 'description',
    title: 'Description',
    dataIndex: 'description',
    render: (text: string) => (
      <Text size="xs" color="inherit" weight="semi">
        {text}
      </Text>
    ),
  },
  {
    key: 'created',
    title: 'Created',
    dataIndex: 'created',
    render: (text: string) => (
      <Text size="xs" color="inherit" weight="semi">
        {text}
      </Text>
    ),
  },

  {
    key: 'action',
    title: '',
    dataIndex: 'id',
    render: (_, tableData) => <TaxesTableAction tax={tableData.tax} />,
  },
]

type TaxRatesTableProps = {
  taxes: CleanTax[]
  setShowTaxRatesDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

export const TaxRatesTable: React.FC<TaxRatesTableProps> = ({
  taxes,
  setShowTaxRatesDrawer,
}) => {
  const data =
    taxes?.map<TaxRatesTableData>((tax) => {
      const { createdAt, description, id, isActive, name, rate } = tax
      return {
        id,
        name,
        description,
        rate,
        created: format(Date.parse(createdAt), 'MMM dd, yyy'),
        status: isActive ? 'active' : 'inactive',
        tax,
      }
    }) ?? []

  return (
    <>
      <Table
        showSearch
        columns={columns}
        dataSource={data}
        css={{
          [`& ${StyledInputWrap}`]: {
            mr: '3.5rem',
          },
        }}
        actions={
          <Flex gutterX="2">
            {/* <Button
              size="xl"
              appearance="secondary"
              append={<HiArrowCircleDown size="1.3rem" color="#ABB3B9" />}
            >
              Import Taxes
            </Button> */}
            <Button
              size="xl"
              prepend={<HiOutlinePlus color="#fff" />}
              onClick={() => setShowTaxRatesDrawer(true)}
            >
              Add Tax Rate
            </Button>
          </Flex>
        }
      />
    </>
  )
}
