import React from 'react'
import { Content, Item, Root, Trigger, Header } from './drawer-accordion.styles'
import { HiChevronRight, HiChevronUp } from 'react-icons/hi'
import { Text } from 'components/text/text'

const DrawerAccordion = ({
  label,
  action,
  css,
  children,
  type = 'single',
  ...props
}: any) => {
  return (
    <Root
      {...(props as any)}
      type={type}
      css={css}
      defaultValue={label}
      collapsible
    >
      <Item value={label}>
        <Header>
          <Trigger>
            <Text size="xs" color="$primary">
              {label}
            </Text>
            <HiChevronUp
              size="1.7rem"
              aria-hidden
              className="active-color active-icon"
            />
            <HiChevronRight
              size="1.7rem"
              className="inactive-icon"
              aria-hidden
            />
          </Trigger>
          {action && <>{action}</>}
        </Header>
        <Content>{children}</Content>
      </Item>
    </Root>
  )
}
export default DrawerAccordion
