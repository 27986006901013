import Input, { type InputProps } from 'components/input'
import { useToast } from 'components/toast'
import { useUploadFileMutation } from 'generated/__generated_graphql'
import useControllable from 'hooks/useControllable'
import React, { useEffect } from 'react'
import { extractGraphqlErrors } from '../utils/helpers'

export type UploadObject = {
  url?: string | null
  id?: string | number
  name?: string | null
  publicId?: string | null
}

export type ActionStatus = 'idle' | 'loading' | 'success' | 'fail'

export type UseFileUploadProps = React.ComponentProps<typeof Input> &
  InputProps & {
    uploadOnChange?: boolean
    // files?: File[]
    defaultUploadObject?: UploadObject | null
    getUploadedObject: (value: UploadObject) => void
    onUploadStatusChange?: (status: ActionStatus) => void
    multiple?: boolean
    imagesOnly?: boolean
    minMegabyte?: number
    maxMegabyte?: number
  }

const assertFilesAreOnlyImages = (files: File[]) => {
  const validImageTypes = [
    'image/apng',
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp',
    'image/x-icon',
  ]

  return files.every((file) => {
    return validImageTypes.includes(file.type)
  })
}

// const assertFilesAreBelowByteSize = (files: File[], minMegabytes: number) => {
//   return files.every((file) => {
//     return file.size / 1e6 < minMegabytes
//   })
// }

const assertFilesAreAboveByteSize = (files: File[], maxMegabytes: number) => {
  return files.every((file) => {
    return file.size / 1e6 > maxMegabytes
  })
}

const useFileUpload = ({
  uploadOnChange = false,
  defaultUploadObject,
  onUploadStatusChange,
  getUploadedObject,
  imagesOnly,
  maxMegabyte,
}: // files,
UseFileUploadProps) => {
  const [uploadStatus, setUploadStatus] = React.useState<ActionStatus>('idle')
  const [uploadObject, setUploadObject] = useControllable<UploadObject | null>({
    defaultValue: defaultUploadObject
      ? {
          id: defaultUploadObject.id,
          name: defaultUploadObject.name ?? defaultUploadObject.publicId,
          url: defaultUploadObject.url,
        }
      : null,
  })
  // const [pickedFiles, setPickedFiles] = useControllable<File[] | null>({
  //   defaultValue: files,
  // })

  const notify = useToast()

  const [, upload] = useUploadFileMutation()

  useEffect(() => {
    if (defaultUploadObject) {
      setUploadObject({
        id: defaultUploadObject.id,
        name: defaultUploadObject.name ?? defaultUploadObject.publicId,
        url: defaultUploadObject.url,
      })
    }
  }, [defaultUploadObject, setUploadObject])

  useEffect(() => {
    !!onUploadStatusChange && onUploadStatusChange(uploadStatus)
  }, [uploadStatus])

  const inputRef = React.useRef<HTMLInputElement>(null)

  /*
      1. handle change
      2. upload file to remote server if uploadToRemote
      3. delete file from remote server
  */
  function fileInputOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setUploadStatus('idle')

    const files = e.target.files && Array.from(e.target.files)
    const file = files && files[0]

    if (!file) return

    if (imagesOnly) {
      if (!assertFilesAreOnlyImages(files)) {
        notify({ status: 'error', content: 'Please choose images only' })
        return
      }
    }

    if (maxMegabyte) {
      if (assertFilesAreAboveByteSize(files, maxMegabyte)) {
        notify({
          status: 'error',
          content: `Please only choose files that are below ${maxMegabyte}MB`,
        })
        return
      }
    }

    setUploadObject({ name: file.name })

    if (uploadOnChange) {
      uploadFileToRemoteBucket(file)
      return
    }

    setUploadStatus('success')
  }

  function handleRemove() {
    setUploadObject(null)
    setUploadStatus('idle')

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  async function uploadFileToRemoteBucket(file: File) {
    setUploadStatus('loading')

    const reader = new FileReader()

    reader.onload = async function () {
      try {
        const extension = file.type.split('/')[1]

        const response = await upload({
          input: {
            data: String(reader.result),
            extension: extension,
          },
        })

        const error = extractGraphqlErrors(response, 'uploadFile')

        if (error) {
          setUploadStatus('fail')

          notify({
            content: error,
            status: 'error',
          })
          return
        }

        getUploadedObject({
          id: response.data?.uploadFile?.upload?.id,
          name: response.data?.uploadFile?.upload?.publicId,
          url: response.data?.uploadFile?.upload?.url,
        })

        setUploadStatus('success')
      } catch (error) {
        setUploadStatus('fail')
        notify({
          content: 'An error occurred while picking file from your computer',
          status: 'error',
        })
      }
    }

    reader.onerror = function () {
      notify({
        content: 'An error occurred while picking file from your computer',
        status: 'error',
      })
    }

    reader.readAsDataURL(file)
  }

  return {
    uploadStatus,
    uploadObject,
    fileInputOnChange,
    handleRemove,
    inputRef,
  }
}

export default useFileUpload
