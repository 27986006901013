import Button from 'components/button/button'
import { Flex } from 'components/layout'
import Text from 'components/text/text'
import { useToast } from 'components/toast'
import {
  BillingCycle,
  PlanTypeEnum,
  usePlansQuery,
  useSavePlanMutation,
} from 'generated/__generated_graphql'
import { CleanOrganisation, useAppProvider } from 'providers/app-provider'
import React from 'react'
import { FaCheck } from 'react-icons/fa'
import { usePaystackPayment } from 'react-paystack'
import { styled } from 'stitches/stitches.config'
import { extractGraphqlErrors, formatMoney } from 'utils/helpers'

const StyledFaCheck = styled(FaCheck)

type PlanBodyProps = {
  billingCycle: BillingCycle
  planType: PlanTypeEnum
}

type CleanPlan = Exclude<CleanOrganisation['plan'], null | undefined>

const PlanBody: React.FC<PlanBodyProps> = ({ billingCycle, planType }) => {
  const notify = useToast()
  const [selectedPlan, setSelectedPlan] = React.useState('')
  const { organisation } = useAppProvider()

  const config = {
    email: organisation?.users?.[0]?.email as string,
    amount: 100,
    plan: selectedPlan,
    publicKey: process.env.REACT_APP_PAYSTACK_KEY as string,
    metadata: {
      custom_fields: [
        {
          display_name: 'organisation',
          variable_name: 'organisationId',
          value: organisation?.id,
        },
      ],
    },
  }
  const initializePayment = usePaystackPayment(config)

  const [{ fetching: savingPlan }, savePlan] = useSavePlanMutation()

  const isBusinessInNigeria = organisation?.countryCode === 'NG'

  async function handleCheckout(plan: CleanPlan) {
    try {
      if (!isBusinessInNigeria) {
        notify({
          content: 'Initiating checkout...',
          status: 'success',
          duration: 500,
        })

        const response = await savePlan({
          input: {
            planId: plan.id,
            successUrl: `${window.location.origin}/dashboard/settings/organisation/subscriptions`,
            cancelUrl: `${window.location.origin}/dashboard/settings/organisation/plans`,
          },
        })

        const error = extractGraphqlErrors(response, 'savePlan')

        if (error) {
          notify({
            content: error,
            status: 'error',
          })
          return
        }

        const { url } = response.data?.savePlan ?? {}
        window.location.replace(url!)
      } else {
        setSelectedPlan(plan.paystackPlanId as string)
      }
    } catch (error) {
      notify({
        content: 'Something went wrong',
        status: 'error',
      })
    }
  }

  React.useEffect(() => {
    const onSuccess = (reference?: any) => {
      if (reference.status === 'success') {
        window.location.replace(
          `${window.location.origin}/dashboard/settings/organisation/subscriptions`
        )
      }
    }

    initializePayment(onSuccess)
  }, [selectedPlan])

  const [{ data: plansQueryData }] = usePlansQuery({
    variables: { type: planType },
  })
  const { plans } = plansQueryData ?? {}

  const { plan: orgPlan } = organisation ?? {}

  const cyclePlans =
    plans?.filter((plan) => plan.billingCycle === billingCycle) ?? []

  const userPlanIndex = cyclePlans?.findIndex((plan) => plan.id === orgPlan?.id)
  console.log('userPlanIndex', userPlanIndex)

  const StyledTable = styled('table', {
    all: 'unset',
    maxWidth: '100%',
    overflowX: 'scroll',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 50,
    span: {
      p: '1.2rem',
      width: '100%',
      borderBottom: `1px solid $border`,
    },
    'tbody > tr:nth-child(4n+0) > td': {
      borderBottom: 'none',
    },
    'tbody > tr:nth-child(4n+1) > td': {
      borderBottom: 'none',
    },
    'tr > *': {
      px: 24,
      py: 12,
      width: 248,
    },
    '*': {
      textAlign: 'start',
      verticalAlign: 'top',
    },
    'th > :nth-child(1)': {
      py: 8,
    },
    textAlign: 'start',
    '* > p': {
      fontSize: '1.8rem',
      display: 'inline-block',
      fontWeight: '$medium',
    },
    h3: {
      fontWeight: 700,
      fontSize: 'medium',
      mt: 50,
    },
    h4: {
      fontWeight: 700,
      fontSize: 'medium',
    },
    h5: {
      fontWeight: 800,
      fontSize: 'x-large',
    },
    h6: {
      fontSize: '14px',
      color: '$secondary',
    },
    [`& ${StyledFaCheck}`]: {
      color: '$blue',
    },

    [`tr > :nth-child(${userPlanIndex > -1 ? userPlanIndex + 1 : 0})`]: {
      backgroundColor: '$highlightBg',
      color: '$secondary',

      [`& ${StyledFaCheck}`]: {
        color: '$secondary',
      },
    },
  })

  return (
    <StyledTable>
      <thead>
        <tr>
          {cyclePlans?.map((plan, index) => (
            <th key={plan.id}>
              <h4>{plan.name}</h4>
              {isBusinessInNigeria ? (
                <h5>
                  {formatMoney(plan?.paystackPrice ?? (0 as number), '₦')}
                </h5>
              ) : (
                <h5>{formatMoney(plan.price, '$')}</h5>
              )}
              <h6>
                per user / {billingCycle === 'monthly' ? 'month' : 'year'}
              </h6>
              <Button
                appearance={index === userPlanIndex ? 'secondary' : 'primary'}
                disabled={index === userPlanIndex || savingPlan}
                css={{ mt: 32, mb: 68, width: 'inherit', alignSelf: 'center' }}
                onClick={() => handleCheckout(plan)}
              >
                {index === userPlanIndex ? 'Current Plan' : 'Upgrade'}{' '}
              </Button>
            </th>
          ))}
        </tr>
      </thead>

      <tbody style={{ paddingBottom: 50 }}>
        <tr>
          {cyclePlans?.map((plan) => (
            <td key={plan.id}>
              <Flex
                direction={'column'}
                justify={'center'}
                align="center"
                css={{ gap: '$5' }}
              >
                {plan.features.map((feature, index) => (
                  <Text size={'xs'} key={index} align={'start'}>
                    {feature}
                  </Text>
                ))}
              </Flex>
            </td>
          ))}
        </tr>
      </tbody>
    </StyledTable>
  )
}

export default PlanBody
