import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import Text from 'components/text/text'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select/type-ahead-select'
import {
  useBanksQuery,
  useUpdateFinancialAccountMutation,
} from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import React, { useEffect } from 'react'
import { HiArchive } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { CleanFinancialAccount } from '../financial'

interface EditAccountDrawerProps {
  visible: boolean
  onClose: () => void
  financialAccount: CleanFinancialAccount
}

export const EditAccountDrawer: React.FC<EditAccountDrawerProps> = (props) => {
  const { visible, onClose, financialAccount } = props
  const {
    title,
    name,
    accountNumber,
    routingNumber,
    currencyId,
    countryCode,
    linked,
  } = financialAccount

  const toast = useToast()

  useEffect(() => {
    bulkUpdate({
      title: title,
      name: name,
      accountNumber: accountNumber,
      routingNumber: routingNumber,
      currencyId: currencyId,
    })
  }, [])

  const {
    register,
    values,
    setInputValue,
    errors,
    formIsComplete,
    bulkUpdate,
  } = useForm({
    fields: {
      title: title,
      name: name,
      accountNumber: accountNumber,
      routingNumber: routingNumber,
      currencyId: currencyId,
    },
    optional: ['routingNumber', 'name'],
  })

  const [{ data: banksQueryData }] = useBanksQuery({
    variables: {
      countryCode: countryCode as string,
    },
  })

  const { banks } = banksQueryData ?? {}
  const banksMap =
    banks?.map((bank) => {
      return {
        label: bank.name,
        value: bank.name,
      }
    }) ?? []

  // const [{ data: currenciesData }] = useCurrenciesQuery()
  // const { currencies } = currenciesData ?? {}

  // const currencySelectOptions =
  //   currencies?.map((currency) => {
  //     return {
  //       label: currency.name,
  //       value: currency.id,
  //       symbol: currency.symbol,
  //     }
  //   }) ?? []

  function notify(error?: string, status?: 'success' | 'error') {
    toast({
      content: error ?? 'something went wrong',
      status: status,
    })
  }

  const [{ fetching }, updateFinancialAccount] =
    useUpdateFinancialAccountMutation()

  async function handleAddFinancialAccount() {
    try {
      const response = await updateFinancialAccount({
        input: {
          title: values.title as string,
          id: financialAccount.id,
          name: values.name,
          accountNumber: values.accountNumber,
          routingNumber: values.routingNumber,
          currencyId: values.currencyId,
        },
      })
      const error = extractGraphqlErrors(response, 'updateFinancialAccount')
      if (error) {
        notify(error, 'error')
        return
      }
      notify('Financial account updated succesfully', 'success')
      onClose()
    } catch (error) {
      notify(error as string, 'error')
    }
  }

  return (
    <Drawer
      title="Edit Financial Account"
      titleIcon={<HiArchive size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={fetching}
            disabled={!formIsComplete}
            onClick={handleAddFinancialAccount}
            size="md"
          >
            Update Account
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="5" css={{ p: '$5' }}>
        <Input
          {...register('title')}
          defaultValue={title ?? undefined}
          required
          label="Account Name"
          placeholder="Enter name"
          error={errors.title}
        />
        {!linked && (
          <>
            {routingNumber ? (
              <Input
                {...register('routingNumber')}
                defaultValue={routingNumber}
                required
                label="Routing Number"
                placeholder="Add routing number"
                error={errors.routingNumber}
              />
            ) : (
              // <Select
              //   search
              //   searchPlaceholder="Search for Bank"
              //   label="Bank"
              //   placeholder="Select Bank"
              //   options={banksMap}
              //   onChange={(value) => setInputValue('name', value as string)}
              //   error={errors.name}
              //   defaultValue={values.name}
              // />

              <TypeAheadSelect
                label="Bank"
                placeholder="Search for Bank"
                options={banksMap}
                value={values.name}
                onChange={(value) => setInputValue('name', value as string)}
                error={errors.name}
                noOptionsMessage={() => (
                  <Text size={'sm'} weight="medium">
                    No bank accounts
                  </Text>
                )}
              />
            )}
            <Input
              {...register('accountNumber')}
              defaultValue={accountNumber}
              required
              label="Account Number"
              placeholder="Enter bank account number"
              error={errors.accountNumber}
            />
            {/* <Select
              label="Currency"
              search
              searchPlaceholder="Search for Currency"
              placeholder="Select Currency"
              options={currencySelectOptions}
              renderValue={(label: string, option) => (
                <Flex align="center" gutter="2">
                  <Avatar size="medium" title={option.symbol} />
                  <Text size="xs">{label}</Text>
                </Flex>
              )}
              onChange={(value) => setInputValue('currencyId', value as string)}
              error={errors.currencyId}
              defaultValue={values.currencyId}
            /> */}
          </>
        )}
      </Flex>
    </Drawer>
  )
}
