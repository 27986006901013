import { Dropdown, IDropdownProps } from 'components/dropdown/dropdown'
import { Text } from 'components/text/text'
import React, { useEffect, useState } from 'react'
import {
  StyledFilterClearButton,
  StyledFilterFooter,
  StyledFilterOkButton,
} from '../table.styles'
import { IFilterComponent, ITableFilterOptions } from '../table.types'

import CheckBox from 'components/checkbox/checkbox'
import Flex from 'components/layout/flex'

interface IFilterComponentProps extends IFilterComponent {
  trigger: React.ReactNode
  visible?: boolean
  onVisibleChange?: (visible: boolean) => void
}

export const CheckboxFilter: React.FC<IFilterComponentProps> = ({
  trigger,
  options,
  visible,
  header,
  onVisibleChange,
  type,
  colKey,
  onFilter,
  operator,
}) => {
  const [checked, setChecked] = useState<ITableFilterOptions['value'][]>([])

  const handleCheck = (value: ITableFilterOptions['value']) => {
    const alreadyExists = checked.includes(value)

    if (alreadyExists) {
      const checkedCopy = [...checked]
      const index = checkedCopy.findIndex((item) => item === value)
      checkedCopy.splice(index, 1)

      setChecked(checkedCopy)
    } else {
      setChecked([...checked, value])
    }
  }

  useEffect(() => {
    onFilter?.(colKey, type, checked, operator)
  }, [checked])

  const menu: IDropdownProps['menu'] = options?.map((item) => ({
    ...item,
    onClick: (value) => handleCheck(value),
    render: (label) => (
      <Flex
        gutterX="2"
        align="center"
        css={{ minWidth: '31.6rem', py: '0.4rem' }}
      >
        <CheckBox
          checked={checked.includes(item.value)}
          onChange={() => null}
        />
        <Text size="xs" weight="semi" color="inherit">
          {label}
        </Text>
      </Flex>
    ),
  }))

  return (
    <Dropdown
      menu={menu}
      header={header}
      visible={visible}
      onVisibleChange={onVisibleChange}
      closeOnMenuItemClick={false}
      footer={
        <StyledFilterFooter justify="between" align="center">
          <StyledFilterClearButton
            appearance="ghost"
            onClick={() => setChecked([])}
          >
            <Text size="xxs" color="$secondary">
              Clear
            </Text>
          </StyledFilterClearButton>

          <StyledFilterOkButton onClick={() => onVisibleChange?.(false)}>
            <Text size="xxs" color="$white">
              Done
            </Text>
          </StyledFilterOkButton>
        </StyledFilterFooter>
      }
    >
      <>{trigger}</>
    </Dropdown>
  )
}
