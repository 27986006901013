import PageHeader from 'components/page-header/page-header'
import { PurchaseEnum } from 'generated/__generated_graphql'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import { StyledTableContainer } from '../purchases.styles'
import { ExpensesTable } from './expenses-table'

interface ExpensesProps {
  type: PurchaseEnum
}

// const ExpenseTypeText = {
//   expense: {
//     title: 'Create and manage your expenses easily',
//     description:
//       'Record all your expenses here, alter their statuses as and when with very little fuss',
//   },
//   purchaseOrder: {
//     title: 'Create and manage purchase orders easily',
//     description:
//       'Create purchase orders to get the order confirmations at the most competitive rates from your vendors/suppliers before making payment',
//   },
// }

export const Expenses: React.FC<ExpensesProps> = ({ type }) => {
  const isExpense = type === PurchaseEnum.Purchase
  return (
    <>
      <PageHeader title={isExpense ? 'Bills/Expenses' : 'Purchase Orders'} />
      <StyledLayoutContent>
        {/* <PageBanner
          title={
            isExpense
              ? ExpenseTypeText.expense.title
              : ExpenseTypeText.purchaseOrder.title
          }
          description={
            isExpense
              ? ExpenseTypeText.expense.description
              : ExpenseTypeText.purchaseOrder.description
          }
          actionText="Watch demo"
          bgSvg={PurchasesBg}
        /> */}
        <StyledTableContainer fullWidth>
          <ExpensesTable type={type} />
        </StyledTableContainer>
      </StyledLayoutContent>
    </>
  )
}
