import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import { Text } from 'components/text/text'
import { format } from 'date-fns'
import { Billing } from 'generated/__generated_graphql'
import { StyledBadge } from 'pages/settings/settings.styles'
import { formatMoney } from 'utils/helpers'

interface IPaidTableData {
  id: string
  date: string
  amount: string | number
  status: 'paid'
  plan: string
}

type PaidProps = {
  billings: Omit<Billing, 'organisation'>[]
  isBusinessInNigeria: boolean
}

const columns: ITableColumn<IPaidTableData>[] = [
  {
    key: 'date',
    title: 'Date',
    dataIndex: 'date',
  },
  {
    key: 'amount',
    title: 'Amount',
    dataIndex: 'amount',
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (text: IPaidTableData['status']) => (
      <StyledBadge status={text}>
        <Text size="xs" color="inherit" weight="semi">
          {text}
        </Text>
      </StyledBadge>
    ),
  },
  {
    key: 'plan',
    title: 'plan',
    dataIndex: 'plan',
  },
]

export const Paid: React.FC<PaidProps> = ({
  billings,
  isBusinessInNigeria,
}) => {
  const data = billings
    ?.filter((b) => b.status === 'paid')
    .map<IPaidTableData>((billing) => {
      return {
        amount: isBusinessInNigeria
          ? formatMoney(billing.plan.paystackPrice ?? 0, '₦')
          : formatMoney(billing.plan.price, '$'),
        date: format(Date.parse(billing.updatedAt), 'MMM dd, yyyy'),
        id: billing.id,
        plan: billing.plan.name!,
        status: 'paid',
      }
    })

  return <Table columns={columns} dataSource={data} />
}
