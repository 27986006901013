import { Route, Routes } from 'react-router-dom'
import { ChartsOfAccountsPage } from '../../../accounting/screens/chart-of-accounts/chart-of-accounts'
import { BankRulesPage } from '../../../financial/screens/bank-rules/bank-rules'
import { TaxRatesPage } from '../../../taxes/screens/tax-rates/tax-rates'

const Product = () => {
  return (
    <Routes>
      <Route path="/charts-of-accounts" element={<ChartsOfAccountsPage />} />
      <Route path="/tax-rates" element={<TaxRatesPage />} />
      {/* <Route path="/currencies" element={<CurrenciesPage />} /> */}
      <Route path="/bank-rules" element={<BankRulesPage />} />
      {/* <Route path="/online-payments" element={<OnlinePaymentsPage />} /> */}
    </Routes>
  )
}

export default Product
