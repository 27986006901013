import React from 'react'
import { StyledFooter } from '../../auth.styles'

interface IAuthFooterProps extends React.ComponentProps<typeof StyledFooter> {}

export const Footer: React.FC<IAuthFooterProps> = ({ ...props }) => {
  // const year = new Date().getFullYear()
  return (
    <StyledFooter {...props}>
      {/* <Text size="xxs">Copyright {year}, Swipe Technologies Inc</Text>
      <Flex justify="center" align="center" gutter={2}>
        <a href="/">
          <Text size="xxs">Policy</Text>
        </a>
        <a href="/">
          <Text size="xxs">Platform Agreement</Text>
        </a>
      </Flex> */}
    </StyledFooter>
  )
}
