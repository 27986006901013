import Button from 'components/button/button'
import Drawer from 'components/drawer/drawer'
import { Box, Flex } from 'components/layout'
import Separator from 'components/separator/separator'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select'
import { format } from 'date-fns'
import { Invoice, useSendInvoiceMutation } from 'generated/__generated_graphql'
import { useAppProvider } from 'providers/app-provider'
import { useEffect, useState } from 'react'
import { HiDocumentAdd } from 'react-icons/hi'
import { extractGraphqlErrors, formatMoney } from 'utils/helpers'

interface SendInvoiceProps {
  visible: boolean
  onClose: () => void
  onSuccess?: (values?: any) => void
  invoice: Invoice
  type?: 'invoice' | 'offer'
}

type Recipient = {
  email: string
}

const SendInvoice: React.FC<SendInvoiceProps> = ({
  visible,
  onClose,
  invoice,
  type,
}) => {
  const notify = useToast()
  const { organisation } = useAppProvider()
  const [invoiceRecipients, setInvoiceRecipients] = useState<Recipient[]>([])

  const [{ fetching: sendingInvoice }, sendInvoiceMutation] =
    useSendInvoiceMutation()

  async function sendInvoice() {
    try {
      const response = await sendInvoiceMutation({
        input: {
          invoiceId: invoice.id,
          emails: invoiceRecipients.map((recipient) => recipient.email),
        },
      })
      const error = extractGraphqlErrors(response, 'sendInvoice')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: 'Invoice sent successfully',
        status: 'success',
      })
      onClose()
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  useEffect(() => {
    const emails = invoice.emails?.map((email) => ({ email })) ?? []
    setInvoiceRecipients(emails)
  }, [invoice.id])

  const currency = organisation?.currency.symbol

  return (
    <Drawer
      title="Send Invoice"
      fullHeight={false}
      titleIcon={<HiDocumentAdd size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="md"
            disabled={invoiceRecipients.length === 0 || sendingInvoice}
            onClick={sendInvoice}
          >
            {type === 'invoice' ? 'Send Invoice' : 'Send offer'}
          </Button>
        </Flex>
      }
    >
      <Box css={{ padding: '28px 24px' }}>
        <Flex justify="start" direction="column" css={{ rowGap: 5 }}>
          <Text size="xxs">
            Date:{' '}
            <Text color="#171717" size="xxs">
              {format(new Date(invoice.issueDate), 'dd MMM, yyyy')}
            </Text>
          </Text>
          <Text size="xxs">
            Due on:{' '}
            <Text color="#171717" size="xxs">
              {format(new Date(invoice.dueDate), 'dd MMM, yyyy')}
            </Text>
          </Text>
          <Text size="xxs">
            Invoice Number:{' '}
            <Text color="#171717" size="xxs">
              {invoice.reference}
            </Text>
          </Text>
        </Flex>
        <Separator color="#EEF2F4" />

        <Flex justify="start" direction="column" css={{ rowGap: 5 }}>
          <Text size="xxs">
            Amount:{' '}
            <Text size="xs" weight="bold">
              {formatMoney(invoice.amount, currency)}
            </Text>
          </Text>
        </Flex>
        <Separator color="#EEF2F4" />

        <Flex justify="between" css={{ mb: '$6' }}>
          <Flex justify="start" direction="column" css={{ rowGap: 10 }}>
            <Text size="xxs" color="$secondary">
              From
            </Text>
            <Text size="xxs">{organisation?.name}</Text>
            <Text size="xxs">{organisation?.countryCode}</Text>
          </Flex>
          <Flex justify="start" direction="column" css={{ rowGap: 10 }}>
            <Text size="xxs">Prepared for</Text>
            <Text size="xxs">{invoice?.customer?.name}</Text>
            <Text size="xxs">{invoice?.customer?.country}</Text>
            {/* <Text size="xxs">VAT: {invoice?.customer?.tin}</Text> */}
          </Flex>
        </Flex>

        <Flex>
          <TypeAheadSelect
            required
            size="sm"
            isMulti
            isCreatable={true}
            label="Recipients"
            placeholder="Press enter to add emails"
            valueKey="email"
            labelKey="email"
            value={invoiceRecipients?.map((r) => r.email)}
            options={invoiceRecipients}
            onChange={(recipients: string[]) => {
              setInvoiceRecipients(
                recipients.map((recipient) => ({ email: recipient }))
              )
            }}
            noOptionsMessage={() => (
              <Text size={'xxs'}>
                Type in a valid email address then press ENTER / TAB to add
              </Text>
            )}
          />
        </Flex>
      </Box>
    </Drawer>
  )
}

export default SendInvoice
