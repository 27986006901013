import Box from 'components/layout/box'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CreateCustomReportModal from './components/create-custom-report-drawer'
import BalanceSheetPage from './screens/balance-sheet'
import CustomReportsPage from './screens/custom-reports'
import OverviewPage from './screens/overview'
import ProfitAndLossPage from './screens/profits-and-loss'

type Drawer = {
  open: boolean
  type?: string
}
export interface ReportsPageProps {
  setShowDrawer: React.Dispatch<React.SetStateAction<Drawer>>
}

export const Reports = () => {
  const [showDrawer, setShowDrawer] = React.useState<Drawer>({
    open: false,
    type: '',
  })

  return (
    <Box>
      <Routes>
        <Route
          path="/overview"
          element={<OverviewPage setShowDrawer={setShowDrawer} />}
        ></Route>
        <Route
          path="/balance-sheet"
          element={<BalanceSheetPage setShowDrawer={setShowDrawer} />}
        ></Route>
        <Route
          path="/profit-loss"
          element={<ProfitAndLossPage setShowDrawer={setShowDrawer} />}
        ></Route>
        <Route
          path="/custom-reports"
          element={<CustomReportsPage setShowDrawer={setShowDrawer} />}
        ></Route>
      </Routes>

      <CreateCustomReportModal
        visible={showDrawer.open}
        type={showDrawer.type}
        onClose={() =>
          setShowDrawer({
            open: false,
          })
        }
      />
    </Box>
  )
}
