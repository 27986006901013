import icon from 'assets/gif/accounteer-icon.gif'
import { Flex } from 'components/layout'
import PageLoader from 'components/page-loader/page-loader'
import {
  OrganisationQuery,
  OverviewQuery,
  ProfileQuery,
  useOrganisationQuery,
  useOverviewQuery,
  useProfileQuery,
} from 'generated/__generated_graphql'
import React, { PropsWithChildren, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { OperationContext } from 'urql'
import { wayFinder } from 'utils/wayfinder'

export type CleanProfile = Exclude<ProfileQuery['profile'], undefined | null>
export type CleanOrganisation = Exclude<
  OrganisationQuery['organisation'],
  undefined | null
>

const AppProviderState = React.createContext<{
  profile?: CleanProfile
  organisation?: CleanOrganisation
  overviewData?: OverviewQuery
  refetchOverview: (opts?: Partial<OperationContext> | undefined) => void
  refreshApp: () => void
  setOverviewDate: React.Dispatch<React.SetStateAction<Date>>
  overviewDate: Date
}>({
  refreshApp: () => null,
  setOverviewDate: () => null,
  refetchOverview: () => null,
  overviewDate: new Date(Date.now()),
})

export const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [overviewDate, setOverviewDate] = useState(new Date(Date.now()))

  const [
    {
      fetching: organisationFetching,
      data: organisationQueryData,
      error: organisationError,
    },
    queryOrganisation,
  ] = useOrganisationQuery()

  const [
    { fetching: profileFetching, data: profileQueryData, error: profileError },
    queryProfile,
  ] = useProfileQuery()

  const [{ fetching: overviewFetching, data: overviewData }, refetchOverview] =
    useOverviewQuery({
      variables: {
        date: overviewDate.toISOString(),
      },
    })

  const { profile } = profileQueryData ?? {}
  const { organisation } = organisationQueryData ?? {}
  const { id, plan } = organisation ?? {}

  function refreshApp() {
    queryProfile({ requestPolicy: 'network-only' })
    queryOrganisation({ requestPolicy: 'network-only' })
  }

  React.useEffect(() => {
    if (
      !profileFetching &&
      !organisationFetching &&
      !profileError &&
      !organisationError
    ) {
      navigate(wayFinder({ id, plan }, pathname))
    }
  }, [profileFetching, organisationFetching])

  const appProviderValue = React.useMemo(
    () => ({
      profile,
      organisation: organisation ?? undefined,
      overviewData,
      refetchOverview: () => refetchOverview({ requestPolicy: 'network-only' }),
      refreshApp,
      setOverviewDate,
      overviewDate,
    }),
    [profile, organisation, refreshApp]
  )

  const [canShowChildren, setCanShowChildren] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setCanShowChildren(true)
    }, 3700)
  }, [canShowChildren])

  if (!canShowChildren) {
    return (
      <Flex
        align="center"
        justify="center"
        css={{
          height: '100vh',
        }}
      >
        <img
          style={{
            width: '20rem',
          }}
          src={icon}
          alt="loading content..."
        />
      </Flex>
    )
  }

  if (profileFetching && organisationFetching && overviewFetching) {
    return <PageLoader type="gif" />
  }

  return (
    <AppProviderState.Provider value={appProviderValue}>
      {children}
    </AppProviderState.Provider>
  )
}

export function useAppProvider() {
  return React.useContext(AppProviderState)
}
