import Input from 'components/input/input'
import Box from 'components/layout/box'
import Flex from 'components/layout/flex'
import { styled } from 'stitches/stitches.config'

export const StyledTableContainer = styled('div', {
  backgroundColor: '$white',
  borderRadius: '1rem',
  boxShadow: '$tiny',
  width: '92rem',

  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },
})

export const BorderedSection = styled(Flex, {
  flexGrow: 1,
  minWidth: '50%',
  borderBottom: '0.1rem solid $border',
  padding: '2.8rem 2.4rem',

  variants: {
    sideBorder: {
      true: {
        borderRight: '0.1rem solid $border',
      },
    },
  },
})

export const StyledPreviewSection = styled(Flex, {
  padding: '0 2.6rem',
})

export const StyledPreviewTitle = styled(Flex, {
  border: '0.1rem solid $border',
  backgroundColor: '$background',
  padding: '0.8rem 1.6rem',
  borderRadius: '0.4rem',
})

export const StyledPreviewFrame = styled(Box, {
  border: '0.1rem solid $border',
  borderRadius: 8,
  boxShadow: '$subtle',
  // height: '52rem',
  flexGrow: 1,
  position: 'relative',
})

export const StyledPreviewControls = styled(Flex, {
  width: '6.8rem',
  height: '3.2rem',
  borderRadius: 24,
  backgroundColor: '#F1F0F1',
  position: 'absolute',
  bottom: '2.4rem',
  mx: 'auto',
  px: '0.4rem',
  left: 0,
  right: 0,
})

export const StyledPreviewControl = styled('span', {
  borderRadius: '100%',
  width: '2.4rem',
  height: '2.4rem',
  display: 'grid',
  placeItems: 'center',

  variants: {
    active: {
      true: {
        backgroundColor: '$white',
      },
    },
  },
})

export const StyledItemsWrapper = styled(Flex, {
  borderRadius: 4,
  boxShadow: '$subtle',
  border: '0.1rem solid $border',
})

export const StyledNewItemWrapper = styled(Flex, {
  padding: '1.6rem 2.4rem',
  borderTop: '0.1rem solid $border',
})

export const StyledItemsSummary = styled(Flex, {
  borderTop: '0.1rem solid $border',
  padding: '1.6rem 22.5rem',
})

export const StyledItemsTotal = styled(Flex, {
  borderTop: '0.1rem solid $border',
  padding: '1.6rem 22.5rem',
})

export const StyledFigureWrap = styled(Flex, {
  minWidth: '6rem',
})

export const NewItemFormContainer = styled(Flex, {
  backgroundColor: '$white',
  boxShadow: '$normal',
  position: 'fixed',
  zIndex: 1,
})

export const NewItemFormHeader = styled(Flex, {
  borderBottom: '0.1rem solid $border',
  padding: '1.6rem 2.4rem',
})

export const NewItemFooter = styled(Flex, {
  padding: '1.6rem 2.4rem',
  height: '7.2rem',
  borderTop: '0.1rem solid $border',
})

export const NewItemContent = styled(Flex, {
  padding: '1.6rem 2.4rem 3.2rem',
})

export const NewItemFormGrid = styled('div', {
  width: '100%',
  display: 'grid',
  gap: '2rem 1.6rem',

  variants: {
    columns: {
      two: {
        gridTemplateColumns: '1fr 1fr',
      },

      four: {
        gridTemplateColumns: '2fr 2fr 1fr 1fr',
      },
    },
  },
})

export const StyledItemsDropdownHeaderAction = styled(Flex, {
  minWidth: '40rem',
  px: '1.6rem',
  py: '0.8rem',
  minHeight: '3.6rem',
  borderBottom: '0.1rem solid $border !important',

  variants: {
    size: {
      large: {
        minHeight: '4.8rem',
        py: '1.2rem',
        px: '1.4rem',
      },
    },
  },
})

export const StyledItemSearch = styled(Input, {
  '&  .input-wrapper': {
    borderRadius: '0 !important',
    border: 'none !important',
    borderBottom: '0.1rem solid $border !important',
    backgroundColor: '$white !important',
    boxShadow: 'none !important',
  },
})

export const NewTaxCta = styled(Flex, {
  backgroundColor: '$highlightBg',
  height: '4rem',
  px: '1.4rem',
})

export const TaxItem = styled(Flex, {
  padding: '0.8rem 1.2rem',
  borderRadius: 4,
  boxShadow: '$subtle',
  border: '0.1rem solid $border',
  width: '19rem',
})

export const CustomerBox = styled(Flex, {
  boxShadow: '$subtle',
  border: '0.1rem solid $border',
  borderRadius: 4,
  padding: '.75rem 1.2rem',
})
