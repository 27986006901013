import * as RadixTab from '@radix-ui/react-tabs'
import type * as Stitches from '@stitches/react'
import Text from 'components/text/text'
import React, { ComponentProps, ReactNode } from 'react'
import {
  StyledRadixTabRoot,
  StyledRadixTabTrigger,
  StyledRadixTabTriggerList,
  StyledTabIndicator,
} from './tabs.styles'

export type SingleTab = {
  key: string
  title: ReactNode
  content?: ReactNode
}

export type TabPane = {
  children?: ReactNode
  title: string
  key: string
}

export type TabProps = Omit<
  ComponentProps<typeof StyledRadixTabRoot>,
  'onChange'
> & {
  fullWidth?: boolean
  hideBottomBorder?: boolean
  css?: Stitches.CSS
  children?: ReactNode
  tabs?: SingleTab[]
  activeKey?: string
  onChange?: (key: string) => void
  hideDefaultIndicator?: boolean
}

export const TabPane: React.FC<TabPane> = ({ children }) => <>{children}</>

export const Tabs: React.FC<TabProps> = (props) => {
  const {
    css,
    onChange,
    onValueChange,
    tabs,
    activeKey,
    value,
    hideBottomBorder,
    fullWidth = true,
    hideDefaultIndicator,
    ...tabsProps
  } = props

  return (
    <StyledRadixTabRoot
      bottomBorder={!hideBottomBorder}
      fullWidth={fullWidth}
      css={css}
      value={activeKey ?? value}
      onValueChange={onChange ?? onValueChange}
      defaultValue={activeKey ?? value}
      {...tabsProps}
    >
      <StyledRadixTabTriggerList hideBottomBorder={hideBottomBorder}>
        {tabs?.map((tab) => {
          if (typeof tab.title === 'string') {
            return (
              <StyledRadixTabTrigger key={tab.key} value={tab.key}>
                <Text size="xs">{tab.title}</Text>
                {hideDefaultIndicator ? <></> : <StyledTabIndicator />}
              </StyledRadixTabTrigger>
            )
          }

          return (
            <RadixTab.Trigger
              key={tab.key}
              value={tab.key}
              style={{ all: 'unset' }}
            >
              {tab.title}
            </RadixTab.Trigger>
          )
        })}
      </StyledRadixTabTriggerList>

      {tabs?.map((tab) => (
        <RadixTab.TabsContent key={tab.key} value={tab.key}>
          {tab.content}
        </RadixTab.TabsContent>
      ))}
    </StyledRadixTabRoot>
  )
}

Tabs.displayName = 'Tabs'
