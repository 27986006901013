import { Flex } from 'components/layout'
import Text from 'components/text/text'
import React from 'react'
import { BsArrowRightShort } from 'react-icons/bs'
import { styled } from 'stitches/stitches.config'

function CommandButton({
  character,
  children,
  css,
}: {
  character: string
  children?: React.ReactNode
  css?: any
}) {
  const StyledCommandButton = styled('button', {
    all: 'unset',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  })
  return (
    <StyledCommandButton css={css}>
      <Flex
        align="center"
        gutter={1}
        css={{
          background: '$white',
          p: '$1 $2',
          borderRadius: '$1',
          border: '1px solid $border',
          boxShadow: '$subtle',
        }}
      >
        <Text size="xxs" color="$secondary">
          ⌘
        </Text>
        <Text size="xxs" color="$secondary">
          {character}
        </Text>
      </Flex>
      {children && (
        <Flex align="center" gutter={1} css={{ ml: '$1' }}>
          <Text size="xxs" color="$secondary">
            then
          </Text>
          <BsArrowRightShort size="1.5rem" color="#8A979D" />
          <Flex
            align="center"
            gutter={1}
            css={{
              background: '$white',
              p: '$1 $2',
              borderRadius: '$1',
              border: '1px solid $border',
              boxShadow: '$subtle',
            }}
          >
            <Text size="xxs" color="$secondary">
              {children}
            </Text>
          </Flex>
        </Flex>
      )}
    </StyledCommandButton>
  )
}

export default CommandButton
