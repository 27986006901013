export const colors = [
  '#2AAA7C',
  '#398AFA',
  '#614CE5',
  '#F87D38',
  '#5978A1',
  '#38D5F8',
  '#F15656',
  '#9585F4',
  '#12CF5D',
  '#E5A84C',
  '#F83894',
]
