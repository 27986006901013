import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { Span } from 'components/text/span'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import {
  useDeleteTeamMemberMutation,
  useOrganisationQuery,
} from 'generated/__generated_graphql'

import React from 'react'
import { HiTrash } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { Member } from '../../../pages/members'

interface DeleteMemberDrawerProps {
  visible: boolean
  onClose: () => void
  member: Member
}

const DeleteMemberDrawer: React.FC<DeleteMemberDrawerProps> = (props) => {
  const { visible, onClose, member } = props

  const notify = useToast()
  const [{ fetching: deletingMember }, deleteTeamMember] =
    useDeleteTeamMemberMutation()
  const [, queryOrganisation] = useOrganisationQuery()

  const identifier =
    member.firstName && member.lastName
      ? member.firstName + ' ' + member.lastName
      : member.email

  async function submit() {
    try {
      const response = await deleteTeamMember({
        input: {
          memberId: Number(member.id),
        },
      })
      const error = extractGraphqlErrors(response, 'deleteTeamMember')
      if (error) {
        notify({
          content: error,
          status: 'error',
          duration: 1500,
        })
        return
      }
      notify({
        content: identifier + ' has been removed from your organisation',
        status: 'success',
        duration: 3000,
      })
      queryOrganisation({ requestPolicy: 'network-only' })
      onClose()
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
        duration: 1500,
      })
    }
  }

  return (
    <Drawer
      title="Delete Team Member"
      titleIcon={<HiTrash size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      closable={!deletingMember}
      maskClosable={!deletingMember}
      fullHeight={false}
    >
      <Flex css={{ p: '$5', height: '100%' }} direction="column" gutter="3">
        <Text size="sm" color="$primary" weight="bold">
          Are you sure you want to remove{' '}
          <Span css={{ color: '$error' }}>{identifier}</Span> from your
          organisation?
        </Text>

        <Flex
          gutterX="2"
          css={{ alignSelf: 'end', alignItems: 'end', flexGrow: 1 }}
        >
          <Button
            size="md"
            appearance="secondary"
            disabled={deletingMember}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            appearance="danger"
            size="md"
            disabled={deletingMember}
            isLoading={deletingMember}
            onClick={submit}
          >
            Remove member
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default DeleteMemberDrawer
