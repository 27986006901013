import { zodResolver } from '@hookform/resolvers/zod'
import Avatar from 'components/avatar/avatar'
import Button from 'components/button/button'
import Input from 'components/input/input'
import { Box, Flex, Stack } from 'components/layout'
import PageLoader from 'components/page-loader/page-loader'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select'
import {
  Industry,
  Purpose,
  SourceInfo,
  useCreateOrganisationMutation,
  useCurrenciesQuery,
  useIndustriesQuery,
  useOrganisationQuery,
  usePurposesQuery,
  useSourceInfoQuery,
} from 'generated/__generated_graphql'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { styled } from 'stitches/stitches.config'
import { Country, countries } from 'utils/countries'
import { extractGraphqlErrors } from 'utils/helpers'
import { z } from 'zod'

const formSchema = z.object({
  name: z
    .string()
    .min(3, { message: 'Please enter the full name of your company' }),
  industryId: z.string().refine((id) => !isNaN(Number.parseInt(id)), {
    message: 'Please select an industry',
  }),
  countryCode: z.string().min(2, {
    message: 'Please select a country',
  }),
  currencyId: z.string().min(2, {
    message: 'Please select a currency',
  }),
  purposeIds: z
    .array(z.string())
    .nonempty({ message: 'Please select at least one purpose' }),
  source: z
    .array(z.string())
    .nonempty({ message: 'Please select at least one source' }),
})

type FormFields = z.infer<typeof formSchema>

export const Main = styled(Box, {
  width: 435,
  margin: '0 auto',
  marginTop: 100,
})

interface SetupBusinessProps {
  onProceed: () => void
}

const SetupBusiness: React.FC<SetupBusinessProps> = ({ onProceed }) => {
  const notify = useToast()

  const {
    control,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting, isValid, isDirty },
    getValues,
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  const [{ data: industries, fetching: industryFetching }] =
    useIndustriesQuery()
  const [{ data: purposes, fetching: purposeFetching }] = usePurposesQuery()
  const [{ data: currencies, fetching: currencyFetching }] =
    useCurrenciesQuery()
  const [{ data: sourceInfo, fetching: sourceFetching }] = useSourceInfoQuery()

  const [, createOrganization] = useCreateOrganisationMutation()
  const [{ data: orgQueryData }] = useOrganisationQuery()
  const { organisation } = orgQueryData ?? {}

  useEffect(() => {
    if (!isDirty && organisation) {
      const { name, countryCode, currency, industry } = organisation ?? {}
      reset({
        name: name ?? '',
        industryId: industry?.id ?? '',
        countryCode: countryCode ?? '',
        currencyId: currency.id ?? '',
      })
    }
  }, [organisation])

  function onCountrySelect(code: string) {
    const country = countries.find((country) => country.code === code)
    const currency = currencies?.currencies.find(
      (currency) => currency.code === country?.currency?.split(',')[0]
    )

    setValue('countryCode', country?.code ?? '')
    setValue('currencyId', currency?.id ?? '')

    localStorage.setItem('countryCode', country?.code ?? '')
    localStorage.setItem('countryId', currency?.id ?? '')
  }

  async function submit() {
    try {
      const values = getValues()

      const response = await createOrganization({
        input: values,
      })

      const error = extractGraphqlErrors(response, 'createOrganisation')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      onProceed()
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  const loading =
    industryFetching || purposeFetching || currencyFetching || sourceFetching

  if (loading) {
    return <PageLoader></PageLoader>
  }

  return (
    <Main>
      <Box>
        <Stack css={{ mb: 50 }} spacing={4}>
          <Text color="#848C96" size="xs">
            STEP 1 OF 4
          </Text>
          <Text weight="bold" variant="h3" size="md">
            Welcome, lets get you started
          </Text>
          <Text color="#848C96" size="xxs">
            Tell us a bit about yourself to get started ...
          </Text>
        </Stack>
        <form onSubmit={handleSubmit(submit)}>
          <Flex gutterY={4} direction={'column'}>
            <Input
              label="Company name"
              {...(register('name') as any)}
              placeholder="Enter company name"
              required
              error={errors.name?.message}
            />

            <Controller
              control={control}
              name="countryCode"
              render={({ field: { value } }) => {
                return (
                  <TypeAheadSelect
                    label="Where is your company located?"
                    placeholder="Choose country"
                    required
                    options={countries}
                    renderOption={(option: Country) => (
                      <Flex
                        align="center"
                        gutter="1"
                        css={{ width: '100%', height: '$5' }}
                      >
                        <Avatar>{option.emoji}</Avatar>
                        <Flex
                          css={{
                            flexGrow: 1,
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text size="xs">{option.name}</Text>
                          <Text size="xs" color={'black'}>
                            {option.code}
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                    error={errors.countryCode?.message}
                    value={value}
                    onChange={onCountrySelect}
                    labelKey="name"
                    valueKey="code"
                    renderValue={(value: Country) => (
                      <Text size="xs" align={'center'}>
                        {value.name}
                      </Text>
                    )}
                  />
                )
              }}
            />

            {/* <TypeAheadSelect
              label="Preferred currency"
              placeholder="Select currency"
              required
              name="currency"
              options={currencies?.currencies ?? []}
              renderOption={(option: Currency) => {
                return (
                  <Flex
                    align="center"
                    gutter="1"
                    css={{ width: '100%', height: '$5' }}
                  >
                    <Flex
                      css={{
                        flexGrow: 1,
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text size="xs">{option.name}</Text>
                      <Text size="xs" color={'black'}>
                        {option.symbol}
                      </Text>
                    </Flex>
                  </Flex>
                )
              }}
              value={values.currencyId}
              error={errors.currencyId?.message}
              onChange={(value: string) => {
                setInputValue('currencyId', value)
              }}
              labelKey="code"
              valueKey="id"
              renderValue={(value: Currency) => (
                <Text size="xs" align={'center'}>
                  {value.code}
                </Text>
              )}
            /> */}

            <Controller
              control={control}
              name="industryId"
              render={({ field: { onChange, value } }) => {
                return (
                  <TypeAheadSelect
                    label="What does your company do"
                    placeholder="Select industry"
                    required
                    options={industries?.industries ?? []}
                    value={value}
                    onChange={onChange}
                    labelKey="name"
                    valueKey="id"
                    error={errors.industryId?.message}
                    renderValue={(value: Industry) => (
                      <Text size="xs" align={'center'}>
                        {value.name}
                      </Text>
                    )}
                  />
                )
              }}
            />
            <Controller
              control={control}
              name="purposeIds"
              render={({ field: { onChange, value } }) => {
                return (
                  <TypeAheadSelect
                    label="What will you use accounteer for? (Select all that apply)"
                    placeholder="Choose option"
                    required
                    options={purposes?.purposes ?? []}
                    labelKey="name"
                    valueKey="id"
                    isMulti
                    error={errors.purposeIds?.message}
                    value={value}
                    onChange={onChange}
                    renderValue={(value: Purpose) => (
                      <Text size="xs" align={'center'}>
                        {value.name}
                      </Text>
                    )}
                  />
                )
              }}
            />

            <Controller
              control={control}
              name="source"
              render={({ field: { onChange, value } }) => {
                return (
                  <TypeAheadSelect
                    label="How did you hear about us? (Select all that apply)"
                    placeholder="Choose option"
                    required
                    options={sourceInfo?.sourceInfo ?? []}
                    isMulti
                    labelKey="name"
                    valueKey="name"
                    error={errors.source?.message}
                    value={value}
                    onChange={onChange}
                    renderValue={(value: SourceInfo) => (
                      <Text size="xs" align={'center'}>
                        {value.name}
                      </Text>
                    )}
                  />
                )
              }}
            />

            <Button type="submit" disabled={!isValid} isLoading={isSubmitting}>
              Continue
            </Button>
          </Flex>
        </form>
      </Box>
    </Main>
  )
}

export default SetupBusiness
