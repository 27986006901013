import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import Flex from 'components/layout/flex'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import Text from 'components/text/text'
import { format } from 'date-fns'
import {
  ProductSubCategory,
  useProductSubCategoriesQuery,
} from 'generated/__generated_graphql'
import { StyledOptions } from 'pages/settings/settings.styles'
import { useState } from 'react'
import { HiDotsHorizontal, HiFilter, HiOutlinePlus } from 'react-icons/hi'
import { NewProductCategoryDrawer } from './new-product-category-drawer'

export const ItemsSubCategoryTable = () => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const [currentSubCategory, setCurrentSubCategory] =
    useState<ProductSubCategory>()
  const [{ data: subCategories }] = useProductSubCategoriesQuery()

  function onEdit(data?: ProductSubCategory) {
    setCurrentSubCategory(data)
    setShowDrawer(true)
  }

  const columns: ITableColumn<ProductSubCategory>[] = [
    {
      key: 'name',
      title: 'Category Name',
      dataIndex: 'name',
      render: (name) => (
        <Text size="xs" color="$primary">
          {name}
        </Text>
      ),
    },
    {
      key: 'parent',
      title: 'Parent Category',
      dataIndex: 'productCategory',
      render: (_, category) => (
        <Text size="xs" color="$primary">
          {category?.productCategory.name}
        </Text>
      ),
    },
    {
      key: 'createdAt',
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (date) => (
        <Text size="xs" color="$primary">
          {format(new Date(date), 'dd MMM, yyyy')}
        </Text>
      ),
    },
    {
      key: 'action',
      title: '',
      dataIndex: 'id',
      render: (_, subCategory) => (
        <Flex stretchx justify="end">
          <Dropdown
            placement="bottomRight"
            menu={[
              {
                label: 'Edit',
                onClick: () => onEdit(subCategory),
              },
            ]}
          >
            <StyledOptions appearance="ghost">
              <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
            </StyledOptions>
          </Dropdown>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Table
        showSearch
        columns={columns}
        dataSource={subCategories?.productSubCategories ?? []}
        actions={
          <Flex gutterX="2">
            <Button
              size="xl"
              appearance="secondary"
              prepend={<HiFilter size="1.3rem" color="#ABB3B9" />}
            >
              Filter
            </Button>
            <Button
              size="xl"
              prepend={<HiOutlinePlus color="#fff" />}
              onClick={() => setShowDrawer(true)}
            >
              Create Category
            </Button>
          </Flex>
        }
        pagination={{
          totalCount: subCategories?.productSubCategories?.length ?? 0,
          currentPage: 1,
          perPage: 10,
          onPageChange: () => null,
          onPaginationClick(type) {
            console.log(type)
          },
        }}
      />

      <NewProductCategoryDrawer
        subCategory={currentSubCategory}
        visible={showDrawer}
        onSuccess={() => setShowDrawer(false)}
        onClose={() => setShowDrawer(false)}
      />
    </>
  )
}
