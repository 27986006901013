import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { Span } from 'components/text/span'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import {
  useBankRulesQuery,
  useDeleteBankRuleMutation,
} from 'generated/__generated_graphql'
import React from 'react'
import { HiTrash } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { BankRulesTableData } from './bank-rules-table'

interface DeleteBankRuleDrawerProps {
  visible: boolean
  onClose: () => void
  bankRule: BankRulesTableData['bankRule']
}

const DeleteBankRuleDrawer: React.FC<DeleteBankRuleDrawerProps> = (props) => {
  const { visible, onClose, bankRule } = props

  const notify = useToast()
  const [{ fetching: deletingBankRule }, deleteBankRule] =
    useDeleteBankRuleMutation()
  const [, queryBankRules] = useBankRulesQuery()

  async function submit() {
    try {
      const response = await deleteBankRule({
        input: {
          id: bankRule.id,
        },
      })

      const error = extractGraphqlErrors(response, 'deleteBankRule')

      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }

      notify({
        content: 'Bank rule has been deleted successfully',
        status: 'success',
      })

      queryBankRules({ requestPolicy: 'network-only' })
      onClose()
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  return (
    <Drawer
      title="Delete Bank Rule"
      titleIcon={<HiTrash size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onClose}
      closable={!deletingBankRule}
      maskClosable={!deletingBankRule}
      fullHeight={false}
    >
      <Flex css={{ p: '$5', height: '100%' }} direction="column" gutter="3">
        <Text size="xs" color="$primary">
          Are you sure you want to delete this bank rule on{' '}
          <Span css={{ color: '$error' }}>{`${bankRule.bankAccount.title} (${
            bankRule.bankAccount.name || bankRule.bankAccount.routingNumber
          })`}</Span>
          ?
        </Text>
        {/* <Flex direction={'column'}>
          <Text size="xxs" color="$primary" weight="bold">
            DESCRIPTION
          </Text>
          <Text size="xs" color="$secondary">
            {bankRule.description}
          </Text>
        </Flex> */}

        <Flex
          gutterX="2"
          css={{ alignSelf: 'end', alignItems: 'end', flexGrow: 1 }}
        >
          <Button
            size="md"
            appearance="secondary"
            disabled={deletingBankRule}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            appearance="danger"
            size="md"
            disabled={deletingBankRule}
            isLoading={deletingBankRule}
            onClick={submit}
          >
            Delete Bank Rule
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default DeleteBankRuleDrawer
