import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Flex from 'components/layout/flex'
import { Span } from 'components/text/span'
import Text from 'components/text/text'
import { useToast } from 'components/toast'
import {
  useChartOfAccountsQuery,
  useDeleteAccountMutation,
} from 'generated/__generated_graphql'

import React from 'react'
import { HiTrash } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'
import { ChartOfAccountsTableData } from './chart-of-accounts-table'

type DeleteAccountDrawerProps = {
  visible: boolean
  onClose: () => void
  account: ChartOfAccountsTableData['account']
}

const DeleteAccountDrawer: React.FC<DeleteAccountDrawerProps> = (props) => {
  const { visible, onClose, account } = props
  const notify = useToast()

  const [, queryAccounts] = useChartOfAccountsQuery()
  const [{ fetching: isBeingDeleted }, deleteAccount] =
    useDeleteAccountMutation()

  async function onSubmit() {
    try {
      const response = await deleteAccount({
        input: {
          accountId: account.id,
        },
      })

      const error = extractGraphqlErrors(response, 'deleteAccount')

      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }

      notify({
        content: account.name + ' has been successfully deleted',
        status: 'success',
        duration: 2500,
      })

      queryAccounts({ requestPolicy: 'network-only' })
      onClose()
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  return (
    <Drawer
      title={'Delete Account'}
      titleIcon={<HiTrash size="2rem" color="#ABB3B9" />}
      visible={visible}
      closable={!isBeingDeleted}
      maskClosable={!isBeingDeleted}
      onClose={onClose}
      fullHeight={false}
    >
      <Flex
        direction={'column'}
        css={{
          width: '100%',
          height: '100%',
          padding: '3.2rem 2.4rem',
        }}
      >
        <Text size={'xs'} css={{ mb: '3.2rem' }}>
          Are you sure you want to delete{' '}
          <Span css={{ color: '$error' }}>{account.name}</Span>?
        </Text>

        <Flex gutterX="2" align="end" css={{ alignSelf: 'end', flexGrow: 1 }}>
          <Button
            type="reset"
            size="md"
            appearance="secondary"
            disabled={isBeingDeleted}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="md"
            appearance={'danger'}
            isLoading={isBeingDeleted}
            disabled={isBeingDeleted}
            onClick={onSubmit}
          >
            Delete Account
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default DeleteAccountDrawer
