import AnimatedNumber from 'animated-number-react'
import { Box, Flex } from 'components/layout'
import Text from 'components/text/text'
import Tooltip from 'components/tooltip'
import { StyledBox } from 'pages/overview/overview.styles'
import { ReactElement } from 'react'
import { formatMoney } from 'utils/helpers'

interface CardDisplayProps {
  children?: React.ReactNode
  title: string
  subTitle?: ReactElement
  color?: string
  amount?: string
  tooltip: string
  currency?: string
  percentage?: number
}

function CardDisplay({
  children,
  title,
  amount,
  subTitle,
  tooltip,
  currency,
  percentage,
  color,
}: CardDisplayProps) {
  return (
    <StyledBox>
      <Flex direction="column" justify="between" gutter={1}>
        <Flex align="center" gutter={2}>
          {color && (
            <Box
              css={{
                width: '1.25rem',
                height: '1.25rem',
                borderRadius: '50%',
                backgroundColor: color,
              }}
            />
          )}

          <Text size="xs" weight="bold" color="$primary">
            {title}
          </Text>

          <Tooltip align="center" placement="top" description={tooltip}>
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 0C2.9104 0 0 2.68749 0 6C0 9.31444 2.9104 12 6.5 12C10.0896 12 13 9.31444 13 6C13 2.68749 10.0896 0 6.5 0ZM6.5 10.8387C3.60299 10.8387 1.25806 8.67506 1.25806 6C1.25806 3.32673 3.6031 1.16129 6.5 1.16129C9.39593 1.16129 11.7419 3.32591 11.7419 6C11.7419 8.67411 9.39798 10.8387 6.5 10.8387ZM6.5 2.66129C7.10796 2.66129 7.60081 3.11623 7.60081 3.67742C7.60081 4.23861 7.10796 4.69355 6.5 4.69355C5.89204 4.69355 5.39919 4.23861 5.39919 3.67742C5.39919 3.11623 5.89204 2.66129 6.5 2.66129ZM7.96774 8.80645C7.96774 8.96678 7.82692 9.09677 7.65323 9.09677H5.34677C5.17308 9.09677 5.03226 8.96678 5.03226 8.80645V8.22581C5.03226 8.06548 5.17308 7.93548 5.34677 7.93548H5.66129V6.3871H5.34677C5.17308 6.3871 5.03226 6.2571 5.03226 6.09677V5.51613C5.03226 5.3558 5.17308 5.22581 5.34677 5.22581H7.02419C7.19788 5.22581 7.33871 5.3558 7.33871 5.51613V7.93548H7.65323C7.82692 7.93548 7.96774 8.06548 7.96774 8.22581V8.80645Z"
                fill="#A1AAB7"
              />
            </svg>
          </Tooltip>
        </Flex>

        {(percentage as number) >= 0 ? (
          <Text size="lg" weight="semi" css={{ mt: '$2' }} color="$primary">
            {`${percentage ?? 0}%`}
          </Text>
        ) : (
          <AnimatedNumber
            easing="linear"
            value={amount}
            formatValue={(value: number) => formatMoney(value, currency)}
            duration={1000}
            className="animated-number"
          />
        )}

        {subTitle && (
          <Text size="xxs" weight="semi" css={{ mt: '$1' }} color="$secondary">
            {subTitle}
          </Text>
        )}
        {children && (
          <Flex
            align="center"
            justify="between"
            gutter={5}
            direction="column"
            css={{ mt: 50 }}
            stretchx
          >
            {children}
          </Flex>
        )}
      </Flex>
    </StyledBox>
  )
}

function Indicator({ percentage }: { percentage: number }) {
  const trend = percentage >= 0 ? 'up' : 'down'

  if (percentage === 0 || percentage === null) return null

  return (
    <Flex align="center" gutter="1">
      <Text size="xxs" weight="semi" color="$secondary">
        {trend === 'up' ? (
          <>
            Increased by <Text color="$success">{percentage}%</Text>
          </>
        ) : (
          <>
            Decreased by <Text color="$error">{percentage}%</Text>
          </>
        )}
      </Text>
      {trend === 'up' ? (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.861918 6.47132C0.601569 6.21097 0.601569 5.78886 0.861918 5.52851L5.52858 0.861847C5.78893 0.601497 6.21104 0.601497 6.47139 0.861847L11.1381 5.52851C11.3984 5.78886 11.3984 6.21097 11.1381 6.47132C10.8777 6.73167 10.4556 6.73167 10.1953 6.47132L6.66666 2.94273L6.66666 10.6666C6.66666 11.0348 6.36818 11.3333 5.99999 11.3333C5.6318 11.3333 5.33332 11.0348 5.33332 10.6666L5.33332 2.94273L1.80473 6.47132C1.54438 6.73167 1.12227 6.73167 0.861918 6.47132Z"
            fill="#46A758"
          />
        </svg>
      ) : (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1381 5.52868C11.3985 5.78903 11.3985 6.21114 11.1381 6.47149L6.47144 11.1382C6.2111 11.3985 5.78898 11.3985 5.52864 11.1382L0.861969 6.47149C0.601619 6.21114 0.601619 5.78903 0.861969 5.52868C1.12232 5.26833 1.54443 5.26833 1.80478 5.52868L5.33337 9.05727L5.33337 1.33341C5.33337 0.965225 5.63185 0.666748 6.00004 0.666748C6.36823 0.666748 6.66671 0.965225 6.66671 1.33341L6.66671 9.05727L10.1953 5.52868C10.4557 5.26833 10.8778 5.26833 11.1381 5.52868Z"
            fill="#EA4335"
          />
        </svg>
      )}
      vs last month
    </Flex>
  )
}

export { CardDisplay, Indicator }
