import { zodResolver } from '@hookform/resolvers/zod'
import DateInput from 'components/date-input/date-input'
import Input from 'components/input/input'
import { Box } from 'components/layout'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import TypeAheadSelect from 'components/type-ahead-select/type-ahead-select'
import { useLookupTypesQuery } from 'generated/__generated_graphql'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { HiDocumentAdd, HiPencilAlt } from 'react-icons/hi'
import { z } from 'zod'
import {
  BankRuleActionButton,
  BankRuleActionOption,
  BankRuleContent,
  BankRuleDetail,
  BankRuleFooter,
  BankRuleHeader,
  BankRuleSection,
} from '../bank-rules'
import { Action } from './add-bank-rules-drawer'

const formSchema = z.object({
  lookupSectionId: z.string().refine((id) => !isNaN(Number.parseInt(id)), {
    message: 'Please select a section of your transactions for lookup',
  }),
  ruleId: z.string().refine((id) => !isNaN(Number.parseInt(id)), {
    message: 'Please select a rule',
  }),
  fact: z.string().min(1, {
    message:
      'Please enter an amount, word or phrase (3+ letters) which will be present in your transaction narration or details',
  }),
})

type FormFields = z.infer<typeof formSchema>

export type BankRuleType = FormFields

type BankRuleCardProps = {
  bankRule: BankRuleType
  savedBankRuleIndex: number
  addBankRule: (bankRule: BankRuleType) => void
  editBankRule: (bankRule: BankRuleType) => void
  deleteBankRule: () => void
  setActionInProgress: React.Dispatch<React.SetStateAction<Action>>
  actionInProgress: Action
}

export const BankRuleCard: React.FC<Partial<BankRuleCardProps>> = (props) => {
  const {
    bankRule,
    savedBankRuleIndex,
    addBankRule,
    deleteBankRule,
    actionInProgress,
    setActionInProgress,
    editBankRule,
  } = props

  const {
    control,
    register,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty },
    getValues,
    watch,
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  const [{ data: lookupTypesData }] = useLookupTypesQuery()

  const { lookupSectionId, ruleId, fact } = watch()

  const lookupSections =
    lookupTypesData?.lookUpTypes.map((lup) => ({
      label: lup.name,
      value: lup.id,
    })) ?? []

  const rules =
    lookupTypesData?.lookUpTypes
      .find((lup) => lup.id === lookupSectionId)
      ?.rules?.map((rule) => ({
        label: rule.name,
        value: rule.id,
      })) ?? []

  useEffect(() => {
    if (bankRule) reset(bankRule)
  }, [bankRule])

  useEffect(() => {
    if (isDirty) {
      reset({
        lookupSectionId,
        fact: undefined,
        ruleId: undefined,
      })
    }
  }, [lookupSectionId])

  function onSubmit() {
    if (inAddMode) {
      addBankRule && addBankRule(getValues())
    }

    if (inEditMode) {
      editBankRule && editBankRule(getValues())
      setInEditMode(false)
    }
  }

  function onEdit() {
    if (actionInProgress === 'IDLE') {
      setActionInProgress && setActionInProgress!('EDITING')
    }
    setInEditMode(true)
  }

  function onDelete() {
    deleteBankRule && deleteBankRule()
    if (!inEditMode) setActionInProgress && setActionInProgress!('IDLE')
  }

  function onCancel() {
    setInEditMode(false)
    setActionInProgress && setActionInProgress!('IDLE')
  }

  const [inEditMode, setInEditMode] = useState(false)
  const inAddMode = !savedBankRuleIndex

  const icon = savedBankRuleIndex && (
    <>
      {actionInProgress === 'ADDING' ? (
        <HiDocumentAdd size="2rem" color="#ABB3B9" />
      ) : (
        <HiPencilAlt size="2rem" color="#ABB3B9" />
      )}
    </>
  )

  const title =
    savedBankRuleIndex !== undefined
      ? `Bank Rule ${savedBankRuleIndex}`
      : actionInProgress === 'ADDING'
      ? 'New Bank Rule'
      : 'Edit Bank Rule'

  const lookupIsDate = lookupSections
    .find((ls) => ls.value === lookupSectionId)
    ?.label.toLowerCase()
    .includes('date')

  const lookupIsAmount = lookupSections
    .find((ls) => ls.value === lookupSectionId)
    ?.label.toLowerCase()
    .includes('amount')

  return (
    <Box>
      <BankRuleSection direction="column" shadow={inAddMode || inEditMode}>
        <BankRuleHeader justify="between" align="center">
          <Flex align="center" gutterX="2">
            {icon}
            <Text size="xs" color="$primary">
              {title}
            </Text>
          </Flex>

          {!!savedBankRuleIndex && !inEditMode && (
            <Flex align="center" gutterX="2">
              <BankRuleActionOption
                type="button"
                appearance="ghost"
                disabled={actionInProgress !== 'IDLE'}
                onClick={onEdit}
              >
                <Text size="xs" color="$primary">
                  Edit
                </Text>
              </BankRuleActionOption>
              {deleteBankRule && (
                <>
                  <Text size="lg" color="$border">
                    |
                  </Text>
                  <BankRuleActionOption
                    type="button"
                    appearance="ghost"
                    onClick={onDelete}
                  >
                    <Text size="xs" color="$error">
                      Delete
                    </Text>
                  </BankRuleActionOption>
                </>
              )}
            </Flex>
          )}
        </BankRuleHeader>

        <BankRuleContent direction="column" gutterY="3" bodered={inAddMode}>
          {(inAddMode || inEditMode) && (
            <>
              <Controller
                control={control}
                name="lookupSectionId"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TypeAheadSelect
                      required
                      label="Lookup Section"
                      placeholder="Select a section for the lookup"
                      onChange={onChange}
                      value={value}
                      options={lookupSections}
                      error={errors.lookupSectionId?.message}
                    />
                  )
                }}
              />

              <Controller
                control={control}
                name="ruleId"
                render={({ field: { onChange, value } }) => {
                  return (
                    <TypeAheadSelect
                      value={value}
                      required
                      label="Rule"
                      placeholder="Select a bank rule to be applied"
                      options={rules}
                      onChange={onChange}
                      error={errors.ruleId?.message}
                      noOptionsMessage={() => (
                        <Text size={'xs'}>Select a lookup section first</Text>
                      )}
                    />
                  )
                }}
              />

              {!lookupIsDate && !lookupIsAmount && (
                <Input
                  {...(register('fact') as any)}
                  value={fact}
                  type={'text'}
                  required
                  label="Fact"
                  placeholder={'Eg: debited SPOTIFY AB STOCKHOLM SE'}
                  error={errors.fact?.message}
                />
              )}

              {lookupIsAmount && (
                <Input
                  {...(register('fact', {
                    setValueAs: (value) =>
                      !isNaN(value) ? String(Number.parseFloat(value)) : value,
                  }) as any)}
                  value={fact}
                  type={'number'}
                  required
                  label="Fact"
                  placeholder={'0.00'}
                  error={errors.fact?.message}
                />
              )}

              {lookupIsDate && (
                <Controller
                  control={control}
                  name="fact"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <DateInput
                        value={new Date(value)}
                        onChange={(e) => {
                          const date = (e.target.value as Date).toISOString()
                          onChange(date)
                        }}
                        required
                        label="Fact"
                        error={errors.fact?.message}
                      />
                    )
                  }}
                />
              )}
            </>
          )}

          {!!savedBankRuleIndex && !inEditMode && (
            <>
              <BankRuleDetail direction="column" gutterY="1">
                <Text size="xs" color="$primary">
                  Lookup Section
                </Text>
                <Text size="xs" color="$secondary">
                  {
                    lookupSections.find(
                      (lookupSection) => lookupSection.value === lookupSectionId
                    )?.label
                  }
                </Text>
              </BankRuleDetail>

              <BankRuleDetail direction="column" gutterY="1">
                <Text size="xs" color="$primary">
                  Rule
                </Text>
                <Text size="xs" color="$secondary">
                  {rules.find((rule) => rule.value == ruleId)?.label}
                </Text>
              </BankRuleDetail>

              <BankRuleDetail direction="column" gutterY="1">
                <Text size="xs" color="$primary">
                  Fact
                </Text>
                <Text size="xs" color="$secondary">
                  {/* {fact instanceof Date
                    ? format(fact)
                    : fact} */}
                  {fact}
                </Text>
              </BankRuleDetail>
            </>
          )}
        </BankRuleContent>

        {(inAddMode || inEditMode) && (
          <BankRuleFooter stretchx wrap="nowrap" align="center" gutterX="3">
            <BankRuleActionButton
              appearance="ghost"
              type="button"
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Cancel
            </BankRuleActionButton>
            <BankRuleActionButton
              type="button"
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isValid}
            >
              Save
            </BankRuleActionButton>
          </BankRuleFooter>
        )}
      </BankRuleSection>
    </Box>
  )
}

export default BankRuleCard
