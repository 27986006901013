import {
  CreateInvoiceInput,
  InvoiceEnum,
  InvoiceItemInput,
} from 'generated/__generated_graphql'
import React, { PropsWithChildren, useReducer } from 'react'
import { id } from 'utils/helpers'

interface InvoiceProvider {
  invoiceState: InvoiceState
  subTotal: number
  discountValue: number
  taxValue: number
  total: number
}

interface IReducerAction {
  type: string
  payload?: any
}

export interface InvoiceItem extends InvoiceItemInput {
  id: string
}

export type InvoiceState = Omit<CreateInvoiceInput, 'invoiceItems'> & {
  invoiceItems: InvoiceItem[]
  discountRate: number
  taxRate: number
}

const initialInvoiceState: InvoiceState = {
  customerId: '',
  reference: '',
  bankAccountId: '',
  invoiceItems: [],
  message: '',
  taxes: [],
  discounts: [],
  emails: [],
  currency: '566',
  issueDate: new Date(),
  dueDate: new Date(),
  templateId: 'standard',
  isDraft: true,
  uploadId: null,
  discountRate: 0,
  taxRate: 0,
  invoiceType: InvoiceEnum.Invoice,
}

export enum INVOICE_ACTIONS {
  ADD_ITEM = 'ADD_ITEM',
  REMOVE_ITEM = 'REMOVE_ITEM',
  UPDATE_ITEM = 'UPDATE_ITEM',
  UPDATE_INVOICE = 'UPDATE_STATE',
  INITIALIZE_INVOICE = 'INITIALIZE_INVOICE',
  ADD_DISCOUNT = 'ADD_DISCOUNT',
  REMOVE_DISCOUNT = 'REMOVE_DISCOUNT',
  ADD_TAX = 'ADD_TAX',
  REMOVE_TAX = 'REMOVE_TAX',
  SELECT_CUSTOMER = 'SELECT_CUSTOMER',
  DEFAULT_DATA = 'DEFAULT_DATA',
}

function InvoiceReducer(
  state: InvoiceState,
  action: IReducerAction
): InvoiceState {
  switch (action.type) {
    case INVOICE_ACTIONS.DEFAULT_DATA: {
      return action.payload
    }
    case INVOICE_ACTIONS.UPDATE_INVOICE: {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      }
    }
    case INVOICE_ACTIONS.SELECT_CUSTOMER:
      return {
        ...state,
        customerId: action.payload.customerId,
      }

    case INVOICE_ACTIONS.ADD_TAX: {
      return {
        ...state,
        taxes: [action.payload.tax.id],
        taxRate: action.payload.tax.rate,
      }
    }
    case INVOICE_ACTIONS.REMOVE_TAX: {
      return {
        ...state,
        taxes: [],
        taxRate: 0,
      }
    }
    case INVOICE_ACTIONS.ADD_DISCOUNT: {
      return {
        ...state,
        discounts: [action.payload.discount.id],
        discountRate: action.payload.discount.rate,
      }
    }
    case INVOICE_ACTIONS.REMOVE_DISCOUNT: {
      return {
        ...state,
        discounts: [],
        discountRate: 0,
      }
    }
    case INVOICE_ACTIONS.ADD_ITEM: {
      return {
        ...state,
        invoiceItems: [
          ...state.invoiceItems,
          { ...action.payload.item, id: id() },
        ],
      }
    }
    case INVOICE_ACTIONS.REMOVE_ITEM: {
      return {
        ...state,
        invoiceItems: state.invoiceItems.filter(
          (item) => item.id !== action.payload.id
        ),
      }
    }
    case INVOICE_ACTIONS.UPDATE_ITEM: {
      return {
        ...state,
        invoiceItems: state.invoiceItems.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload.item
          }
          return item
        }),
      }
    }
    case INVOICE_ACTIONS.INITIALIZE_INVOICE: {
      return initialInvoiceState
    }
    default:
      return state
  }
}

const InvoiceStateContext = React.createContext<Partial<InvoiceProvider>>({})
const InvoiceDispatchContext =
  React.createContext<React.Dispatch<IReducerAction> | null>(null)

const InvoiceProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [invoiceState, dispatch] = useReducer(
    InvoiceReducer,
    initialInvoiceState
  )

  const subTotal = invoiceState?.invoiceItems?.reduce((acc, item) => {
    return acc + item.quantity * item.unitPrice
  }, 0)

  const discountValue = ((invoiceState.discountRate ?? 0) / 100) * subTotal
  const taxValue = ((invoiceState.taxRate ?? 0) / 100) * subTotal
  const gross = Math.max(0, subTotal! - discountValue!)
  const total = gross + taxValue!

  return (
    <InvoiceStateContext.Provider
      value={{
        invoiceState,
        subTotal,
        total,
        discountValue,
        taxValue,
      }}
    >
      <InvoiceDispatchContext.Provider value={dispatch}>
        {children}
      </InvoiceDispatchContext.Provider>
    </InvoiceStateContext.Provider>
  )
}

export function useInvoiceContext() {
  const state = React.useContext(InvoiceStateContext)
  const dispatch = React.useContext(InvoiceDispatchContext)

  return { state, dispatch }
}

export default InvoiceProvider
