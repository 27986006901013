import { zodResolver } from '@hookform/resolvers/zod'
import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import { StyledDrawerSection } from 'components/drawer/drawer.styles'
import { InputError, InputLabel } from 'components/input'

import Flex from 'components/layout/flex'
import Textarea from 'components/textarea'
import { useToast } from 'components/toast'

import TypeAheadSelect from 'components/type-ahead-select/type-ahead-select'
import {
  useAccountTypeAccountsQuery,
  useBankRulesQuery,
  useCreateBankRuleMutation,
  useFinancialAccountsQuery,
} from 'generated/__generated_graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { HiLightningBolt } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'

import { z } from 'zod'
import BankRuleCard from './bank-rule-card'

const formSchema = z.object({
  bankAccountId: z.string().refine((id) => !isNaN(Number.parseInt(id)), {
    message: 'Please select one of your connected bank accounts',
  }),
  bankRules: z
    .array(
      z.object({
        lookupSectionId: z.string().refine((id) => !isNaN(Number.parseInt(id))),
        ruleId: z.string().refine((id) => !isNaN(Number.parseInt(id))),
        fact: z.string(),
      })
    )
    .nonempty({ message: 'Please add at least on rule' }),
  generalLedgerAccountId: z.coerce
    .number({
      invalid_type_error:
        'Please select an account in the general ledger to associate these rules with',
    })
    .transform((id) => String(id)),
  description: z
    .string()
    .min(3, 'Please describe these bank rules (will be applied to each rule)'),
})

type FormFields = z.infer<typeof formSchema>

type AddBankRulesDrawerProps = {
  visible: boolean
  onClose: () => void
}

export type Action = 'EDITING' | 'ADDING' | 'IDLE'

export const AddBankRulesDrawer: React.FC<AddBankRulesDrawerProps> = (
  props
) => {
  const { visible, onClose } = props

  const notify = useToast()

  const [, createBankRule] = useCreateBankRuleMutation()

  const [, queryBankRules] = useBankRulesQuery()

  const [{ data: financialAccountsQuery }] = useFinancialAccountsQuery()

  const { data: financialAccounts } =
    financialAccountsQuery?.financialAccounts ?? {}

  const [{ data: accountTypeAccountsQueryData }] = useAccountTypeAccountsQuery()
  const { accountTypeAccounts } = accountTypeAccountsQueryData ?? {}

  const financialAccountsSelectOptions =
    financialAccounts?.map((finAcc) => ({
      label: finAcc.title ?? finAcc.name,
      value: finAcc.id,
    })) ?? []

  const glaSelectOptions =
    accountTypeAccounts
      ?.filter((accountTypeAccount) => accountTypeAccount.name !== 'Bank')
      .map((accountTypeAccount, index) => ({
        label: accountTypeAccount.name,
        value: index,
        options: accountTypeAccount?.accounts?.map((account) => ({
          label: account.name,
          value: account.id,
        })),
      })) ?? []

  const {
    control,
    register,
    reset,
    formState: { errors, isSubmitting, isValid },
    getValues,
    watch,
    handleSubmit,
  } = useForm<FormFields>({
    defaultValues: {
      bankRules: [],
    },
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  const [actionInProgress, setActionInProgress] = useState<Action>('IDLE')

  async function onSubmit() {
    try {
      const { bankAccountId, bankRules, description, generalLedgerAccountId } =
        getValues()

      const response = await createBankRule({
        input: {
          bankAccountId: bankAccountId,
          description: description,
          glAccountId: generalLedgerAccountId,
          rules: bankRules.map((bankRule) => ({
            lookUpTypeId: bankRule.lookupSectionId,
            ruleId: bankRule.ruleId,
            fact: bankRule.fact,
            // bankRule.fact instanceof Date
            //   ? (bankRule.fact as Date).toISOString()
            //   : String(bankRule.fact),
          })),
        },
      })

      const error = extractGraphqlErrors(response, 'createBankRule')

      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }

      queryBankRules({ requestPolicy: 'network-only' })

      notify({
        content: 'Bank rule has been added successfully',
        status: 'success',
      })

      onReset()
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  function onReset() {
    reset()
    onClose()
  }

  return (
    <Drawer
      title="Add Bank Rules"
      titleIcon={<HiLightningBolt size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={onReset}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        onReset={onReset}
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <StyledDrawerSection direction="column" gutterY="3">
          <Controller
            control={control}
            name="bankAccountId"
            render={({ field: { onChange, value } }) => {
              return (
                <TypeAheadSelect
                  isDisabled={actionInProgress !== 'IDLE'}
                  required
                  label="Bank Account"
                  placeholder="Select one of your connected bank accounts"
                  options={financialAccountsSelectOptions}
                  value={value}
                  onChange={onChange}
                  error={errors.bankAccountId?.message}
                />
              )
            }}
          />
        </StyledDrawerSection>

        <Flex
          direction={'column'}
          gutterY="4"
          css={{ padding: '3.2rem 2.4rem' }}
        >
          <InputLabel required>Rules</InputLabel>
          <Flex direction="column" gutterY="3">
            <Flex direction="column">
              <Button
                type="button"
                fullWidth
                disabled={actionInProgress !== 'IDLE'}
                onClick={() => setActionInProgress('ADDING')}
              >
                + Add Rule
              </Button>

              {actionInProgress === 'IDLE' && errors.bankRules && (
                <InputError css={{ marginTop: 6 }}>
                  {errors.bankRules?.message}
                </InputError>
              )}
            </Flex>

            {actionInProgress === 'ADDING' && (
              <Controller
                control={control}
                name="bankRules"
                render={({ field: { onChange, value: bankRules } }) => {
                  function saveRuleToList(rule: (typeof bankRules)[number]) {
                    bankRules.push(rule)
                    onChange(bankRules)

                    setActionInProgress('IDLE')
                  }

                  return (
                    <Flex
                      direction={'column'}
                      css={{ width: '100%', height: '100%' }}
                    >
                      <BankRuleCard
                        setActionInProgress={setActionInProgress}
                        addBankRule={(rule) => saveRuleToList(rule)}
                        actionInProgress={actionInProgress}
                      />

                      {actionInProgress === 'ADDING' && errors.bankRules && (
                        <InputError css={{ marginTop: 6 }}>
                          {errors.bankRules?.message}
                        </InputError>
                      )}
                    </Flex>
                  )
                }}
              />
            )}

            <Controller
              control={control}
              name="bankRules"
              render={({ field: { onChange, value: bankRules } }) => {
                function editRuleInList(
                  ruleIndex: number,
                  rule: (typeof bankRules)[number]
                ) {
                  bankRules[ruleIndex] = rule
                  onChange(bankRules)
                  setActionInProgress('IDLE')
                }

                function deleteRuleFromList(ruleIndex: number) {
                  bankRules.splice(ruleIndex, 1)
                  onChange(bankRules)
                }

                return (
                  <Flex
                    direction={'column'}
                    css={{ width: '100%', height: '100%', gap: '1.2rem' }}
                  >
                    {bankRules.map((bankRule, index) => (
                      <BankRuleCard
                        key={index}
                        // add 1 to bankRule's index so the numbering (Bank Rule 1 | Bank Rule x) doesn't start from 0
                        savedBankRuleIndex={index + 1}
                        bankRule={bankRule}
                        setActionInProgress={setActionInProgress}
                        editBankRule={(bankRule) =>
                          editRuleInList(index, bankRule)
                        }
                        deleteBankRule={() => deleteRuleFromList(index)}
                        actionInProgress={actionInProgress}
                      />
                    ))}
                  </Flex>
                )
              }}
            />
          </Flex>
        </Flex>

        <Flex direction={'column'} css={{ padding: '0 2.4rem' }}>
          <Flex direction="column" gutterY="5">
            <Controller
              control={control}
              name="generalLedgerAccountId"
              render={({ field: { onChange, value } }) => {
                return (
                  <TypeAheadSelect
                    isDisabled={actionInProgress !== 'IDLE'}
                    required
                    label="General Ledger Account"
                    placeholder="Select an account"
                    options={glaSelectOptions}
                    onChange={onChange}
                    value={value}
                    error={errors.generalLedgerAccountId?.message}
                  />
                )
              }}
            />

            <Textarea
              {...(register('description') as any)}
              required
              disabled={actionInProgress !== 'IDLE'}
              label="Description"
              placeholder="Add description"
              value={watch('description')}
              error={errors.description?.message}
            />
          </Flex>
        </Flex>

        <Flex
          gutterX="2"
          align={'end'}
          css={{ alignSelf: 'end', flexGrow: 1, padding: '3.2rem 2.4rem' }}
        >
          <Button
            type="reset"
            size="md"
            appearance="secondary"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="md"
            isLoading={isSubmitting}
            disabled={isSubmitting || !isValid || actionInProgress !== 'IDLE'}
          >
            Save
          </Button>
        </Flex>
      </form>
    </Drawer>
  )
}

export default AddBankRulesDrawer
