import { useRect } from '@reach/rect'
import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import Flex from 'components/layout/flex'
import Portal from 'components/portal/portal'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import { Text } from 'components/text/text'
import {
  Discount,
  Tax,
  useDiscountsQuery,
  useProductsQuery,
  useTaxesQuery,
} from 'generated/__generated_graphql'
import useDisclosure from 'hooks/useDisclosure'
import {
  StyledFigureWrap,
  StyledItemsSummary,
  StyledItemsTotal,
  StyledItemsWrapper,
  StyledNewItemWrapper,
} from 'pages/sales/sales.styles'
import ItemDrawer from 'pages/sales/screens/invoices/components/item-drawer'
import SummaryItem from 'pages/sales/screens/invoices/components/summary-item'
import { useRef, useState } from 'react'
import { HiDotsHorizontal, HiOutlinePlus } from 'react-icons/hi'
import { formatMoney } from 'utils/helpers'
import {
  PurchaseItem,
  PURCHASE_ACTIONS,
  usePurchaseContext,
} from '../providers/purchase-provider'
import { ExpenseItemForm } from './expense-item-form'

interface IExpenseItem {
  id: string
  expenseItem: string
  quantity: number
  unitPrice: string
  amount: string
}

interface ExpenseItemsProps {
  currency?: string
}

export const ExpenseItems: React.FC<ExpenseItemsProps> = ({ currency }) => {
  const {
    dispatch,
    state: { purchaseState, subTotal, total, taxValue, discountValue },
  } = usePurchaseContext()
  const newItemFormRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const rect = useRect(containerRef, { observe: true })

  const [{ data: discounts }, refreshDiscounts] = useDiscountsQuery()
  const [{ data: taxes }, refreshTaxes] = useTaxesQuery()
  const [{ data: inventory }] = useProductsQuery()

  const [selectedDiscount, setSelectedDiscount] = useState<Discount>()
  const [selectedTax, setSelectedTax] = useState<Tax>()
  const { isOpen: showDiscountModal, toggle: toggleDiscountModal } =
    useDisclosure()
  const { isOpen: showTaxModal, toggle: toggleTaxModal } = useDisclosure()
  const { isOpen: showItemDropdown, toggle: toggleItemDropdown } =
    useDisclosure(true)

  const [currentItemId, setCurrentItemId] = useState()

  const inventoryItems =
    inventory?.products?.map((inventory) => {
      return {
        id: inventory.id,
        name: inventory.name,
        description: inventory.description,
        quantity: inventory.quantity,
        unitPrice: inventory.salePrice,
        glAccountId: inventory.glAccount?.id,
      }
    }) ?? []

  const purchaseItems = purchaseState?.purchaseItems ?? []

  const columns: ITableColumn<IExpenseItem>[] = [
    {
      key: 'expenseItem',
      title: 'Item',
      dataIndex: 'expenseItem',
    },
    {
      key: 'quantity',
      title: 'Quantity',
      dataIndex: 'quantity',
    },
    {
      key: 'unitPrice',
      title: 'Unit Price',
      dataIndex: 'unitPrice',
    },
    {
      key: 'amount',
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'id',
      render: (id) => (
        <Dropdown
          placement="bottomRight"
          menu={[
            {
              label: 'Edit',
              onClick: () => setCurrentItemId(id),
            },
            {
              label: 'Remove',
              onClick: () => onItemDelete(id),
            },
          ]}
        >
          <Flex stretchx align="center" justify="end">
            <Button appearance="ghost">
              <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
            </Button>
          </Flex>
        </Dropdown>
      ),
    },
  ]

  function onSaveItem(item: Partial<PurchaseItem>) {
    dispatch?.({
      type: PURCHASE_ACTIONS.ADD_ITEM,
      payload: { item: item },
    })
    toggleItemDropdown()
  }

  function onUpdateItem(item: Partial<PurchaseItem>) {
    dispatch?.({
      type: PURCHASE_ACTIONS.UPDATE_ITEM,
      payload: { item: item, id: currentItemId },
    })
    setCurrentItemId(undefined)
  }

  function onItemDelete(id: string) {
    dispatch?.({
      type: PURCHASE_ACTIONS.REMOVE_ITEM,
      payload: { id },
    })
    setCurrentItemId(undefined)
  }

  function onDiscountCreateSuccess(discount: Discount) {
    setSelectedDiscount(discount)
    dispatch?.({
      type: PURCHASE_ACTIONS.ADD_DISCOUNT,
      payload: {
        discount,
      },
    })
    toggleDiscountModal()
    refreshDiscounts({ requestPolicy: 'network-only' })
  }

  function onTaxCreateSuccess(tax: Tax) {
    setSelectedTax(tax)
    dispatch?.({
      type: PURCHASE_ACTIONS.ADD_TAX,
      payload: {
        tax,
      },
    })
    toggleTaxModal()
    refreshTaxes({ requestPolicy: 'network-only' })
  }

  return (
    <StyledItemsWrapper direction="column" ref={containerRef}>
      {purchaseItems.length > 0 && (
        <Flex stretchx>
          <Table
            columns={columns}
            dataSource={purchaseItems.map((item) => ({
              id: item.id,
              expenseItem: item.name,
              quantity: item.quantity,
              unitPrice: formatMoney(item.unitPrice, currency),
              amount: formatMoney(item.unitPrice * item.quantity, currency),
            }))}
            emptyProps={{
              type: 'cta',
              title: `Create your first item`,
              action: (
                <Button
                  onClick={toggleItemDropdown}
                  prepend={<HiOutlinePlus color="#fff" />}
                >
                  Add New Item
                </Button>
              ),
            }}
          />
        </Flex>
      )}
      <StyledNewItemWrapper>
        {purchaseItems.length > 0 && (
          <Button
            appearance="secondary"
            prepend={<HiOutlinePlus color="#398AFA" />}
            onClick={toggleItemDropdown}
          >
            <Text color="$blue">Add New Item</Text>
          </Button>
        )}

        {showItemDropdown && (
          <ExpenseItemForm
            type="New"
            hasItems={purchaseItems.length > 0}
            ref={newItemFormRef}
            onSaveItem={onSaveItem}
            onCancel={toggleItemDropdown}
            inventoryItems={inventoryItems as any}
            css={{
              top: rect?.y ?? 0,
              left: rect?.x,
              width: rect?.width,
            }}
          />
        )}

        {currentItemId && (
          <Portal>
            <ExpenseItemForm
              hasItems={purchaseItems.length > 0}
              type="Edit"
              ref={newItemFormRef}
              onSaveItem={onUpdateItem}
              item={purchaseItems.find((item) => item.id === currentItemId)}
              onCancel={() => setCurrentItemId(undefined)}
              css={{
                top: rect?.y ?? 0,
                left: rect?.x,
                width: rect?.width,
              }}
            />
          </Portal>
        )}
      </StyledNewItemWrapper>
      <StyledItemsSummary direction="column" gutterY="3">
        <Flex align="center" justify="between" stretchx>
          <Text size="xs" weight="semi" color="$primary">
            Subtotal
          </Text>
          <StyledFigureWrap>
            <Text size="xs" weight="semi" color="$secondary">
              {formatMoney(subTotal, currency)}
            </Text>
          </StyledFigureWrap>
        </Flex>

        <SummaryItem
          items={discounts?.discounts ?? []}
          currency={currency}
          type="discount"
          item={selectedDiscount}
          total={discountValue ?? 0}
          onItem={(item) => {
            if (!item) {
              dispatch?.({ type: PURCHASE_ACTIONS.REMOVE_DISCOUNT })
              setSelectedDiscount(item)
              return
            }
            dispatch?.({
              type: PURCHASE_ACTIONS.ADD_DISCOUNT,
              payload: {
                discount: item,
              },
            })
            setSelectedDiscount(item as Discount)
          }}
          onNewItem={toggleDiscountModal}
        />
        <SummaryItem
          items={(taxes?.taxes?.data ?? []) as any}
          currency={currency}
          type="tax"
          item={selectedTax}
          total={taxValue ?? 0}
          onItem={(item) => {
            if (!item) {
              dispatch?.({ type: PURCHASE_ACTIONS.REMOVE_TAX })
              setSelectedDiscount(item)
              return
            }
            dispatch?.({
              type: PURCHASE_ACTIONS.ADD_TAX,
              payload: {
                tax: item,
              },
            })
            setSelectedTax(item as Tax)
          }}
          onNewItem={toggleTaxModal}
        />
      </StyledItemsSummary>
      <StyledItemsTotal align="center" justify="between">
        <Text size="xs" weight="semi" color="$primary">
          Total
        </Text>
        <Text size="xs" weight="semi" color="$primary">
          {formatMoney(total ?? 0, currency)}
        </Text>
      </StyledItemsTotal>
      <ItemDrawer
        type="discount"
        visible={showDiscountModal}
        onClose={toggleDiscountModal}
        onSuccess={onDiscountCreateSuccess}
      />
      <ItemDrawer
        type="tax"
        visible={showTaxModal}
        onClose={toggleTaxModal}
        onSuccess={onTaxCreateSuccess}
      />
    </StyledItemsWrapper>
  )
}
