import Box from 'components/layout/box'
import { Route, Routes } from 'react-router-dom'
import { TaxRatesPage } from './screens/tax-rates/tax-rates'

export const Taxes = () => {
  return (
    <Box>
      <Routes>
        <Route path="/tax-rates" element={<TaxRatesPage />}></Route>
      </Routes>
    </Box>
  )
}
