import Box from 'components/layout/box'
import { PurchaseEnum } from 'generated/__generated_graphql'
import { Route, Routes } from 'react-router-dom'
import { Expenses } from './screen/expenses'

export const Purchases = () => {
  return (
    <Box>
      <Routes>
        <Route
          path="/expenses"
          element={<Expenses type={PurchaseEnum.Purchase} />}
        ></Route>
        <Route
          path="/orders"
          element={<Expenses type={PurchaseEnum.PurchaseOrder} />}
        ></Route>
      </Routes>
    </Box>
  )
}
