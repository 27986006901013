import { CleanOrganisation } from 'providers/app-provider'

export function wayFinder(
  organisation?: Partial<Pick<CleanOrganisation, 'id' | 'plan'>>,
  currentRoute?: string
) {
  if (!organisation?.id) {
    return '/onboarding?status=business'
  }

  if (!organisation?.plan) {
    return '/onboarding?status=plans'
  }

  return currentRoute ?? '/dashboard'
}
