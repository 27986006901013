import Button from 'components/button/button'
import { Flex } from 'components/layout'
import PageHeader from 'components/page-header/page-header'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import { StyledBox } from 'pages/overview/overview.styles'
import React from 'react'
import { HiPlus } from 'react-icons/hi'
import { useLocation } from 'react-router-dom'
import { CustomReportsTable } from '../components/custom-reports-table'
import { ReportsPageProps } from '../reports'

const CustomReportsPage = ({ setShowDrawer }: ReportsPageProps) => {
  const shouldOpenDrawer = useLocation().search.includes('openDrawer=true')

  React.useEffect(() => {
    if (shouldOpenDrawer) {
      setShowDrawer({
        open: true,
      })
    }
  }, [shouldOpenDrawer, setShowDrawer])

  return (
    <>
      <PageHeader
        title="Custom Reports"
        extra={
          <Flex align="center" gutter="4">
            <Button
              prepend={<HiPlus />}
              appearance="secondary"
              onClick={() =>
                setShowDrawer({
                  open: true,
                })
              }
            >
              Create custom report
            </Button>
          </Flex>
        }
      />
      <StyledLayoutContent>
        <Flex direction="column" gutter="3">
          <StyledBox
            css={{
              p: 'unset',
            }}
          >
            <CustomReportsTable setShowDrawer={setShowDrawer} />
          </StyledBox>
        </Flex>
      </StyledLayoutContent>
    </>
  )
}

export default CustomReportsPage
