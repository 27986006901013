import { Box, Flex } from 'components/layout'
import Loader from 'components/loader/loader'
import React from 'react'

type PageProps = {
  isLoading?: boolean
  children?: React.ReactNode
  allowMargin?: boolean
}

const Page = React.forwardRef<HTMLDivElement, PageProps>(
  ({ isLoading, children, allowMargin = true }) => {
    if (isLoading) {
      return (
        <Flex align={'center'} justify="center" css={{ height: '90vh' }}>
          <Loader size={'sm'} color="$gray12"></Loader>
        </Flex>
      )
    }

    return <Box css={{ marginBottom: allowMargin ? 100 : 0 }}>{children}</Box>
  }
)

Page.displayName = 'Page'

export default Page
