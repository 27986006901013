import Button from 'components/button/button'
import DateInput from 'components/date-input/date-input'
import { Col, Flex } from 'components/layout'
import Row from 'components/layout/row'
import PageHeader from 'components/page-header/page-header'
import PageLoader from 'components/page-loader/page-loader'
import Text from 'components/text/text'
import { addDays } from 'date-fns'
import { useBalanceSheetQuery } from 'generated/__generated_graphql'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import { StyledBox } from 'pages/overview/overview.styles'
import { useAppProvider } from 'providers/app-provider'
import { HiCalendar, HiChevronDown, HiOutlineDownload } from 'react-icons/hi'
import {
  AccordionTable,
  AccordionTableData,
} from '../components/accordion-table'
import { CardDisplay } from '../components/card-display'
import { ReportsPageProps } from '../reports'

const BalanceSheetPage = ({ setShowDrawer }: ReportsPageProps) => {
  const { organisation } = useAppProvider()
  const currency = organisation?.currency?.symbol as string

  const [{ fetching, data }] = useBalanceSheetQuery()
  const balanceSheet = data?.balanceSheet

  const breakdownMap = balanceSheet?.accountSummary?.map((item) => {
    return {
      id: String(item.total),
      name: item.accountCategoryName,
      total: String(item.total),
      accountCategoryBreakdown: item.accountCategoryBreakdown.map((item) => {
        return {
          accountType: item.accountType,
          data: item.accountTypeBreakdown,
        }
      }),
    }
  })

  if (fetching) return <PageLoader />

  return (
    <>
      <PageHeader
        title="Balance Sheet"
        extra={
          <Flex align="center" gutter="4">
            <DateInput
              prepend={
                <Flex align="center" gutterX={1}>
                  <HiCalendar color="#ABB3B9" />
                  <Text size="xs" color="$secondary">
                    Date:
                  </Text>{' '}
                </Flex>
              }
              append={<HiChevronDown size="1.9rem" color="#ABB3B9" />}
              maximumDate={addDays(new Date(), 0)}
              required
              placeholder="Select Report Date"
              isMonthPicker
            />

            <Button append={<HiOutlineDownload />} appearance="secondary">
              Export
            </Button>
          </Flex>
        }
      />
      <StyledLayoutContent>
        {/* <PageBanner
          title="Balance Sheet Report"
          description="The balance sheet is a snapshot of your business’s financial position at a particular moment in time. It shows what your business owns (assets), what it owes (liabilities), and what is left over for the owners (equity)."
          actionText="Watch demo"
          bgSvg={background}
          appearance="yellow"
        /> */}
        <Flex direction="column" gutter="3">
          <Row gutter="3">
            <Col span={4}>
              <CardDisplay
                title="Assets"
                amount={String(balanceSheet?.assets)}
                currency={currency}
                tooltip="This shows the total payments that have been made to your account within the month."
              />
            </Col>
            <Col span={4}>
              <CardDisplay
                title="Liabilities"
                amount={String(balanceSheet?.liabilities)}
                currency={currency}
                tooltip="This is the money your business has spent on an ongoing, day-to-day basis to run its business for the month."
              />
            </Col>
            <Col span={4}>
              <CardDisplay
                title="Equity"
                amount={String(balanceSheet?.equity)}
                currency={currency}
                tooltip="This is the difference between the gross profit or loss and the total indirect income/expenses of your business within the month."
              />
            </Col>
          </Row>
        </Flex>

        <Flex direction="column" gutter="3" css={{ mt: '$7' }}>
          <Flex align="center" justify="between">
            <Text size="sm" weight="bold" color="$secondary">
              Summary
            </Text>
            <Button
              onClick={() =>
                setShowDrawer({
                  open: true,
                  type: '3',
                })
              }
            >
              Generate Report
            </Button>
          </Flex>

          <StyledBox
            css={{
              p: 'unset',
            }}
          >
            <AccordionTable
              currency={currency}
              data={breakdownMap as AccordionTableData[]}
            />
          </StyledBox>
        </Flex>
      </StyledLayoutContent>
    </>
  )
}

export default BalanceSheetPage
