import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import Flex from 'components/layout/flex'
import React from 'react'
import { HiDotsHorizontal } from 'react-icons/hi'
import { ContactsDetailsDrawer } from './contacts-details-drawer'
import { CleanCustomer } from './contacts-table'
import DeleteCustomerDrawer from './delete-customer-drawer'
import { UpdateContactsDrawer } from './update-contact-drawer'

type ContactsTableActionProps = {
  customer: CleanCustomer
}

const ContactsTableAction: React.FC<ContactsTableActionProps> = (props) => {
  const { customer } = props

  const [showUpdateDrawer, setShowUpdateDrawer] = React.useState<boolean>(false)
  const [showDeleteCustomerDrawer, setShowDeleteCustomerDrawer] =
    React.useState<boolean>(false)
  const [showOverviewDrawer, setShowOverviewDrawer] =
    React.useState<boolean>(false)

  return (
    <>
      <Flex stretchx justify="end">
        <Dropdown
          placement="bottomRight"
          menu={[
            {
              label: 'Preview',
              onClick: () => setShowOverviewDrawer(true),
            },
            {
              label: 'Delete',
              onClick: () => setShowDeleteCustomerDrawer(true),
            },
          ]}
        >
          <Flex stretchx align="center" justify="end">
            <Button appearance="ghost">
              <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
            </Button>
          </Flex>
        </Dropdown>
      </Flex>

      <ContactsDetailsDrawer
        handleEditContact={() => setShowUpdateDrawer(true)}
        visible={showOverviewDrawer}
        customer={customer}
        onClose={() => setShowOverviewDrawer(false)}
      />

      <UpdateContactsDrawer
        visible={showUpdateDrawer}
        customer={customer}
        onClose={() => setShowUpdateDrawer(false)}
      />

      <DeleteCustomerDrawer
        visible={showDeleteCustomerDrawer}
        customer={customer}
        onClose={() => setShowDeleteCustomerDrawer(false)}
      />
    </>
  )
}

export default ContactsTableAction
