import * as RadixAccordion from '@radix-ui/react-accordion'
import Box from 'components/layout/box'
import { Span } from 'components/text/span'
import { Text } from 'components/text/text'
import { HiChevronRight, HiChevronUp } from 'react-icons/hi'
import { formatMoney } from 'utils/helpers'
import { Content, Item, Root, Trigger } from '../../organisation.styles'

const LogsAccordion = ({
  data,
  css,
  currency,
  children,
  type = 'multiple',
  ...props
}: any) => {
  return (
    <Root {...(props as any)} type={type} css={css}>
      <Item value={data.name}>
        <RadixAccordion.Header>
          <Trigger>
            <Box className="active-icon">
              <HiChevronUp aria-hidden className="icon active-color" />
            </Box>
            <Box className="inactive-icon">
              <HiChevronRight className="icon" aria-hidden />
            </Box>
            <Text size="xs" color="$primary">
              {typeof data === 'string' ? data : data.name}{' '}
              {data.action && (
                <Span css={{ color: '$secondary' }}>{data.action}</Span>
              )}
            </Text>
            {data.total && (
              <Text
                css={{
                  ml: 'auto',
                }}
                size="xs"
              >
                {formatMoney(data.total, currency)}
              </Text>
            )}
          </Trigger>
        </RadixAccordion.Header>
        <Content>{children}</Content>
      </Item>
    </Root>
  )
}
export default LogsAccordion
