import { css, styled } from 'stitches/stitches.config'

const SharedTagStyles = css({
  display: 'inline-flex',
  alignItems: 'center',
  fontFamily: '$bold',
  letterSpacing: 0.3,

  textTransform: 'capitalize',

  variants: {
    type: {
      danger: {
        background: '$errorBg',
        color: '$error',
      },

      success: {
        background: '$successBg',
        color: '$success',
      },

      default: {
        background: '$background',
        color: '$primary',
      },

      blue: {
        background: '$highlightBg',
        color: '$blue',
      },
    },

    size: {
      '2xs': { height: 20, px: 8, fontSize: 13 },

      xs: { height: 24, px: 8, fontSize: 13 },

      sm: {
        height: 28,
        px: 8,
        fontSize: 14,
      },

      md: { height: 32, px: 12, fontSize: 16 },

      lg: { height: 40, px: 12, fontSize: 16 },
    },

    shape: {
      pill: {
        position: 'relative',
      },

      rounded: {
        position: 'relative',
      },

      flat: { borderRadius: 0 },
    },
  },

  compoundVariants: [
    { size: 'xxs', shape: 'rounded', css: { borderRadius: 4 } },
    { size: 'xs', shape: 'rounded', css: { borderRadius: 6 } },
    { size: 'sm', shape: 'rounded', css: { borderRadius: 6 } },
    { size: 'md', shape: 'rounded', css: { borderRadius: 6 } },
    { size: 'lg', shape: 'rounded', css: { borderRadius: 8 } },

    { size: 'xxs', shape: 'pill', css: { borderRadius: 10 } },
    { size: 'xs', shape: 'pill', css: { borderRadius: 12 } },
    { size: 'sm', shape: 'pill', css: { borderRadius: 14 } },
    { size: 'md', shape: 'pill', css: { borderRadius: 16 } },
    { size: 'lg', shape: 'pill', css: { borderRadius: 20 } },
  ],

  defaultVariants: {
    type: 'default',
    shape: 'rounded',
    size: 'sm',
  },
})

export const StyledNativeTag = styled('span', SharedTagStyles)

export const StyledButtonTag = styled('button', SharedTagStyles, {
  variants: {
    state: {
      active: {
        borderWidth: '0.2rem',
        borderStyle: 'solid',
        borderColor: 'inherit',
      },

      inactive: {
        border: '0.2rem hidden',
        appearance: 'none',
      },
    },
  },
  defaultVariants: { state: 'inactive' },
})
