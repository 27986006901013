import Flex from 'components/layout/flex'
import Loader from 'components/loader/loader'
import PageHeader from 'components/page-header/page-header'
import { Tabs } from 'components/tabs/tabs'
import { useChartOfAccountsQuery } from 'generated/__generated_graphql'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import { StyledTableContainer } from './chart-of-accounts.styles'
import ChartOfAccountsTable from './components/chart-of-accounts-table'

export const ChartsOfAccountsPage = () => {
  const [{ data: chartOfAccountsQueryData }] = useChartOfAccountsQuery()

  const { data: chartOfAccounts } =
    chartOfAccountsQueryData?.chartOfAccounts ?? {}

  if (!chartOfAccounts)
    return (
      <Flex
        direction={'column'}
        css={{
          height: '100vh',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader size={'md'} />
      </Flex>
    )

  const assetsAccounts = chartOfAccounts.filter(
    (account) => account.accountType?.accountTypeCategory.name === 'Assets'
  )

  const equityAccounts = chartOfAccounts.filter(
    (account) => account.accountType?.accountTypeCategory.name === 'Equity'
  )

  const liabilityAccounts = chartOfAccounts.filter(
    (account) => account.accountType?.accountTypeCategory.name === 'Liabilities'
  )

  const expensesAccounts = chartOfAccounts.filter(
    (account) => account.accountType?.accountTypeCategory.name === 'Expenses'
  )

  const revenueAccounts = chartOfAccounts.filter(
    (account) => account.accountType?.accountTypeCategory.name === 'Revenue'
  )

  const archivedAccounts = chartOfAccounts.filter(
    (account) => account.isArchived
  )

  return (
    <>
      <PageHeader title="Chart of Accounts" />
      <StyledLayoutContent>
        {/* <PageBanner
          title="View and manage your chart of accounts"
          description="The chart of accounts is a list of all your accounts and it's corresponding journal entries."
          actionText="Watch demo"
          bgSvg={background}
        /> */}
        <Flex direction="column" gutter="5">
          <StyledTableContainer>
            <Tabs
              defaultValue="1"
              tabs={[
                {
                  key: '1',
                  title: 'Assets',
                  content: (
                    <ChartOfAccountsTable
                      accounts={assetsAccounts}
                      pagination={{
                        totalCount: assetsAccounts.length,
                        currentPage: 1,
                        perPage: assetsAccounts.length,
                        onPageChange: () => null,
                      }}
                    />
                  ),
                },
                {
                  key: '2',
                  title: 'Equity',
                  content: (
                    <ChartOfAccountsTable
                      accounts={equityAccounts}
                      pagination={{
                        totalCount: equityAccounts.length,
                        currentPage: 1,
                        perPage: equityAccounts.length,
                        onPageChange: () => null,
                      }}
                    />
                  ),
                },
                {
                  key: '3',
                  title: 'Liability',
                  content: (
                    <ChartOfAccountsTable
                      accounts={liabilityAccounts}
                      pagination={{
                        totalCount: liabilityAccounts.length,
                        currentPage: 1,
                        perPage: liabilityAccounts.length,
                        onPageChange: () => null,
                      }}
                    />
                  ),
                },
                {
                  key: '4',
                  title: 'Expenses',
                  content: (
                    <ChartOfAccountsTable
                      accounts={expensesAccounts}
                      pagination={{
                        totalCount: expensesAccounts.length,
                        currentPage: 1,
                        perPage: expensesAccounts.length,
                        onPageChange: () => null,
                      }}
                    />
                  ),
                },
                {
                  key: '5',
                  title: 'Revenue',
                  content: (
                    <ChartOfAccountsTable
                      accounts={revenueAccounts}
                      pagination={{
                        totalCount: revenueAccounts.length,
                        currentPage: 1,
                        perPage: revenueAccounts.length,
                        onPageChange: () => null,
                      }}
                    />
                  ),
                },
                {
                  key: '6',
                  title: 'Archived',
                  content: (
                    <ChartOfAccountsTable
                      accounts={archivedAccounts}
                      pagination={{
                        totalCount: archivedAccounts.length,
                        currentPage: 1,
                        perPage: archivedAccounts.length,
                        onPageChange: () => null,
                      }}
                    />
                  ),
                },
              ]}
            />
          </StyledTableContainer>
        </Flex>
      </StyledLayoutContent>
    </>
  )
}
