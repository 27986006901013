import { ReactComponent as EmptyIcon } from 'assets/svg/empty-transactions.svg'
import { Avatar } from 'components/avatar/avatar'
import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import Flex from 'components/layout/flex'
import Loader from 'components/loader/loader'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import { Tabs } from 'components/tabs/tabs'
import { Text } from 'components/text/text'
import {
  BankRulesQuery,
  useBankRulesQuery,
} from 'generated/__generated_graphql'
import {
  StyledOptions,
  StyledOptionsContainer,
  StyledTableContainer,
} from 'pages/settings/settings.styles'
import React, { useEffect, useState } from 'react'
import { HiDotsHorizontal, HiOutlinePlus, HiPlus } from 'react-icons/hi'
import AddBankRulesDrawer from './add-bank-rules-drawer'
import DeleteBankRuleDrawer from './delete-bank-rule-drawer'
import EditBankRuleDrawer from './edit-bank-rule-drawer'

type CleanBankRule = Exclude<
  BankRulesQuery['bankRules'],
  null | undefined
>['data'][number]

export type BankRulesTableData = {
  id: string
  bank: string
  bankAccountNumber: string
  description: string
  bankRule: CleanBankRule
}

const BankRulesTableDataAction: React.FC<{
  bankRule: BankRulesTableData['bankRule']
}> = ({ bankRule }) => {
  const [showEditBankRuleDrawer, setShowEditBankRuleDrawer] = useState(false)
  const [showDeleteBankRuleDrawer, setShowDeleteBankRuleDrawer] =
    useState(false)

  return (
    <>
      <Flex stretchx justify="end">
        <Dropdown
          placement="bottomRight"
          menu={[
            {
              label: 'Edit',
              onClick: () => setShowEditBankRuleDrawer(true),
            },
            {
              label: 'Delete',
              onClick: () => setShowDeleteBankRuleDrawer(true),
            },
          ]}
        >
          <StyledOptionsContainer stretchx align="center" justify="end">
            <StyledOptions appearance="ghost">
              <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
            </StyledOptions>
          </StyledOptionsContainer>
        </Dropdown>
      </Flex>

      <EditBankRuleDrawer
        bankRule={bankRule}
        visible={showEditBankRuleDrawer}
        onClose={() => setShowEditBankRuleDrawer(false)}
      />

      <DeleteBankRuleDrawer
        bankRule={bankRule}
        visible={showDeleteBankRuleDrawer}
        onClose={() => setShowDeleteBankRuleDrawer(false)}
      />
    </>
  )
}

const columns: ITableColumn<BankRulesTableData>[] = [
  {
    key: 'bank',
    title: 'Bank',
    dataIndex: 'bank',
    render: (text) => (
      <Flex align="center" gutterX="2">
        <Avatar title={text} size="medium" background="#F86638" />
        <Text size="xs">{text}</Text>
      </Flex>
    ),
  },
  {
    key: 'bankAccountNumber',
    title: 'Bank Account Number',
    dataIndex: 'bankAccountNumber',
  },
  {
    key: 'description',
    title: 'Description',
    dataIndex: 'description',
  },
  {
    key: 'action',
    title: '',
    dataIndex: 'id',
    render: (_, tableData) => (
      <BankRulesTableDataAction bankRule={tableData.bankRule} />
    ),
  },
]

export const BankRulesTable = () => {
  const [showAddBankRuleDrawer, setShowAddBankRuleDrawer] =
    useState<boolean>(false)
  const [{ data: bankRulesQueryData }] = useBankRulesQuery()

  const { data: bankRules } = bankRulesQueryData?.bankRules ?? {}

  const data =
    bankRules?.map<BankRulesTableData>((bankRule) => ({
      id: bankRule.id,
      bank: `${bankRule.bankAccount.title} (${
        bankRule.bankAccount.name || bankRule.bankAccount.routingNumber
      })`,
      bankAccountNumber: bankRule.bankAccount.accountNumber,
      description: bankRule.description,
      bankRule,
    })) ?? []

  const query = new URLSearchParams(window.location.search)

  // const navigate = useNavigate()

  useEffect(() => {
    const queryParam = query.get('drawer') as 'open' | 'close'

    if (queryParam) {
      setShowAddBankRuleDrawer(queryParam === 'open')
    }
  }, [])

  if (!bankRules)
    return (
      <Flex
        direction={'column'}
        justify="center"
        align="center"
        css={{ width: '100%', height: '100vh' }}
      >
        <Loader size={'md'} />
      </Flex>
    )

  return (
    <>
      <StyledTableContainer fullWidth>
        <Tabs activeKey="1" tabs={[{ key: '1', title: 'All' }]} />

        <Table
          showSearch
          columns={columns}
          dataSource={data}
          // pagination={{
          //   totalCount: assetAccountsPagination?.totalCount ?? 0,
          //   currentPage: assetAccountsPagination?.currentPage ?? 0,
          //   perPage: PER_PAGE,
          //   onPageChange: setPage,
          //   totalPages: assetAccountsPagination?.totalPages ?? 0,
          // }}
          emptyProps={{
            title: 'No bank rules found',
            subtitle:
              'Create a new bank rule to automatically categorize transactions.',
            icon: <EmptyIcon width="5.6rem" height="5.6rem" />,
            action: (
              <Flex gutterX="2">
                <Button
                  prepend={<HiPlus />}
                  onClick={() => setShowAddBankRuleDrawer(true)}
                >
                  Add New Rule
                </Button>
              </Flex>
            ),
          }}
          actions={
            <Button
              size="xl"
              prepend={<HiOutlinePlus color="#fff" />}
              onClick={() => setShowAddBankRuleDrawer(true)}
            >
              Add New Rule
            </Button>
          }
        />
      </StyledTableContainer>

      <AddBankRulesDrawer
        visible={showAddBankRuleDrawer}
        onClose={() => setShowAddBankRuleDrawer(false)}
      />
    </>
  )
}
