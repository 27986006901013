import Box from 'components/layout/box'
import { Text } from 'components/text/text'
import { Variants, motion } from 'framer-motion'
import { useEffect } from 'react'
import { HiCheckCircle, HiX } from 'react-icons/hi'
import { AlertButton, AlertContent, AlertWrapper } from './toast.styles'
import { ToastProps } from './toast.types'

const toastMotionVariants: Variants = {
  initial: (props) => {
    const { position } = props

    const dir = ['top', 'bottom'].includes(position) ? 'y' : 'x'

    let factor = ['top-right', 'bottom-right'].includes(position) ? 1 : -1
    if (position === 'bottom') factor = 1

    return {
      opacity: 0,
      [dir]: factor * 24,
    }
  },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: [0.4, 0, 0.2, 1],
    },
  },
  exit: {
    opacity: 0,
    scale: 0.85,
    transition: {
      duration: 0.2,
      ease: [0.4, 0, 1, 1],
    },
  },
}

const ToastAlert: React.FC<ToastProps> = ({
  content,
  destroy,
  size,
  position,
  status = 'default',
  duration = 5000,
}: ToastProps) => {
  useEffect(() => {
    if (!duration) return

    const timer = setTimeout(() => {
      destroy()
    }, duration)

    return () => clearTimeout(timer)
  }, [destroy, duration])

  return (
    <motion.div
      layout
      className="toast-alert"
      variants={toastMotionVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      custom={{ position }}
    >
      <AlertWrapper variant={status} size={size}>
        <AlertContent spacing={8} align={'center'} isInline>
          {status === 'default' && (
            <Box css={{ color: '$success' }}>
              <HiCheckCircle size={18} />
            </Box>
          )}
          <Text color="inherit">{content}</Text>
        </AlertContent>
        <AlertButton onClick={destroy}>
          <HiX className="close-icon" size={16}></HiX>
        </AlertButton>
      </AlertWrapper>
    </motion.div>
  )
}

export default ToastAlert
