import Box from 'components/layout/box'
import { Route, Routes } from 'react-router-dom'
import { Invoices } from './screens/invoices/invoices'

export const Sales = () => {
  return (
    <Box>
      <Routes>
        <Route path="/offers" element={<Invoices type="offer" />}></Route>
        <Route path="/invoices" element={<Invoices type="invoice" />}></Route>
      </Routes>
    </Box>
  )
}
