import type * as Stitches from '@stitches/react'
import Flex from 'components/layout/flex'
import { Text } from 'components/text/text'
import React, { useState } from 'react'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import { StyledDrawerSection, StyledDrawerSectionHead } from './drawer.styles'

export interface IProps
  extends Stitches.VariantProps<typeof StyledDrawerSection> {
  title: React.ReactNode
  children: React.ReactNode
  titleExtra?: React.ReactNode
  collapse?: boolean
}

export const CollapsibleSection: React.FC<IProps> = ({
  children,
  title,
  borders = false,
  titleExtra,
  collapse = false,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState(collapse)

  return (
    <StyledDrawerSection
      direction="column"
      gutterY="6"
      borders={borders}
      {...props}
    >
      <StyledDrawerSectionHead
        align="center"
        justify="between"
        gutterX="2"
        onClick={() => setCollapsed(!collapsed)}
      >
        <Flex align="center" gutterX="2">
          {typeof title === 'string' ? (
            <Text variant="h4" size="sm" weight="bold" color="$primary">
              {title}
            </Text>
          ) : (
            title
          )}

          {collapsed ? (
            <HiChevronDown color="#ABB3B9" size="1.6rem" />
          ) : (
            <HiChevronUp color="#ABB3B9" size="1.6rem" />
          )}
        </Flex>
        {titleExtra}
      </StyledDrawerSectionHead>
      {!collapsed && children}
    </StyledDrawerSection>
  )
}
