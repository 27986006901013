import { ReactComponent as EmptyIcon } from 'assets/svg/empty-file-upload.svg'
import Flex from 'components/layout/flex'
import { styled } from 'stitches/stitches.config'

export const StyledFileUploadContainer = styled(Flex, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '$subtle',
  position: 'relative',
  // overflow: 'hidden',
  width: '100%',

  defaultVariants: {
    padded: true,
  },

  variants: {
    padded: {
      true: { padding: '2rem' },
      false: { padding: '0rem' },
    },
  },
})

export const StyledFileUpload = styled('div', {
  position: 'relative',

  defaultVariants: {
    border: 'solid',
  },

  variants: {
    border: {
      solid: {
        [`& ${StyledFileUploadContainer}`]: {
          border: '0.1rem solid $border',
        },
      },
      dashed: {
        [`& ${StyledFileUploadContainer}`]: {
          border: '.1rem dashed $border',
        },
      },
      none: {
        [`& ${StyledFileUploadContainer}`]: {
          border: 'none',
        },
      },
    },
  },
})

export const StyledEmptyIcon = styled(EmptyIcon, {
  width: '5.6rem',
  height: '5.6rem',
})

export const StyledFileInput = styled('input', {
  opacity: 0,
  pointerEvents: 'none',
  position: 'absolute',
  width: '100%',
  height: '100%',
})

export const StyledFileInputLabel = styled('label', {
  display: 'flex',
  flexDirection: 'column',
  gap: 'inherit',
  padding: 'inherit',
  border: 'none',
  cursor: 'pointer',
})

export const StyledButton = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$2',
  transition: 'all .3s',
  cursor: 'pointer',
  fontFamily: '$bold',
  whiteSpace: 'nowrap',
  boxShadow: '0 .1rem .2rem rgb(0 0 0 / 10%)',
  background: '$blue',
  color: '$white',
  border: '0.1rem solid $blue',
  fontSize: '1.4rem',
  borderRadius: '.4rem',
  p: '1.2rem 2.4rem',
})
