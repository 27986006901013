import ServiceImage from 'assets/images/accounteer-as-a-service.png'
import ProductImage from 'assets/images/cloud-accounting.png'
import Button from 'components/button/button'
import { Box, Flex, Stack } from 'components/layout'
import Tag from 'components/tag/tag'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import {
  Plan,
  PlanTypeEnum,
  usePlansQuery,
  useSavePlanMutation,
} from 'generated/__generated_graphql'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from 'stitches/stitches.config'
import { extractGraphqlErrors } from 'utils/helpers'

const Main = styled(Box, {
  width: '80%',
  margin: '0 auto',
  marginTop: 65,
})

const SelectPlan: React.FC = () => {
  return (
    <Main>
      <Box>
        <Stack css={{ mb: 50 }} spacing={4} align="center">
          <Text color="#848C96" size="xs">
            STEP 3 OF 4
          </Text>
          <Text weight="bold" variant="h3" size="md">
            Choose a package that works for you
          </Text>
          <Text color="#848C96" size="xxs">
            Depending on your business needs, you can choose a package and go on
            to select a subscription under that package.
          </Text>
        </Stack>

        <Flex
          justify={'center'}
          align={'center'}
          css={{ flexGrow: 1, width: '100%', gap: 36 }}
        >
          <PlanCard planType={PlanTypeEnum.Product} />
          <PlanCard planType={PlanTypeEnum.Service} />
        </Flex>
      </Box>
    </Main>
  )
}

export default SelectPlan

type PlanCardProps = {
  planType: Exclude<PlanTypeEnum, PlanTypeEnum.All>
}

const PlanCard: React.FC<PlanCardProps> = ({ planType }) => {
  const navigate = useNavigate()
  const notify = useToast()

  const [{ fetching, data }] = usePlansQuery({
    variables: { type: planType },
  })
  const [{ fetching: savingPlan }, savePlan] = useSavePlanMutation()

  const cyclePlans = data?.plans ?? []

  function handlePlan(link: string) {
    const savedPlanName = localStorage.getItem('plan')
    if (typeof savedPlanName !== 'undefined' && !fetching) {
      const savedPlan = cyclePlans.filter(
        (plan) => plan.name == savedPlanName
      )[0]
      if (savedPlan) {
        handleCheckout(savedPlan)
      } else {
        navigate(link)
      }
    }
  }

  async function handleCheckout(plan: Plan) {
    try {
      notify({
        content: 'Initiating checkout...',
        status: 'success',
        duration: 500,
      })

      const response = await savePlan({
        input: {
          planId: plan.id,
          successUrl: `${window.location.origin}/dashboard`,
          cancelUrl: `${window.location.origin}/onboarding?status=plans`,
        },
      })

      const error = extractGraphqlErrors(response, 'savePlan')

      if (error) {
        notify({
          content: error,
          status: 'error',
        })
        return
      }

      const { url } = response.data?.savePlan ?? {}
      window.location.replace(url!)
      localStorage.removeItem('plan')
    } catch (error) {
      notify({
        content: 'Something went wrong',
        status: 'error',
      })
    }
  }

  return (
    <Flex
      direction={'column'}
      css={{
        width: '38.4rem',
        height: '54.9rem',
        border: 'thin',
        borderRadius: '$2',
        backgroundColor: 'white',
        // gap: 11,
        boxShadow: '$subtle',
        transition: '0.2s',

        '&:hover': {
          boxShadow: '0rem 1.2rem 3.2rem rgba(0, 0, 0, 3%)',
        },
      }}
    >
      <img
        src={planType === PlanTypeEnum.Product ? ProductImage : ServiceImage}
        style={{
          all: 'unset',
          width: '100%',
          // height: 239,
          alignSelf: 'center',
          objectFit: 'fill',

          // display: 'none',
        }}
      />
      <Flex
        css={{
          all: 'inherit',
          px: 28,
          pt: 66,
          flexDirection: 'column',
          gap: 11,
        }}
      >
        <Tag
          shape="pill"
          css={{
            color: '$blue',
            backgroundColor: '#F8F9FF',
            width: 'fit-content',
            p: '.6rem .8rem',
            fontSize: 'small',
            fontWeight: 600,
          }}
        >
          {planType === PlanTypeEnum.Product
            ? 'Product package'
            : 'Service package'}
        </Tag>
        <Text weight="bold" css={{ color: '$primary', fontSize: 'large' }}>
          {planType === PlanTypeEnum.Product
            ? 'Cloud Accounting'
            : 'Accounting as a service'}
        </Text>
        <Text weight="regular" css={{ color: '$primary', fontSize: 'small' }}>
          {planType === PlanTypeEnum.Product
            ? 'An online accounting platform that gives you everything you need to effectively manage your finances non-stop by yourself.'
            : 'Not ready to hire in-house? Get all the strategic guidance and information you want whenever you need it with the help of our professional virtual accountants.'}
        </Text>
        <Flex
          css={{
            alignSelf: 'end',
            alignItems: 'end',
            flexGrow: 1,
            width: '100%',
            mb: 27,
          }}
        >
          <Button
            css={{ width: '100%' }}
            appearance={'black'}
            disabled={savingPlan}
            onClick={
              planType === PlanTypeEnum.Product
                ? () =>
                    handlePlan('/onboarding?status=subscribe&planType=product')
                : () =>
                    handlePlan('/onboarding?status=subscribe&planType=service')
            }
          >
            Choose this package
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
