import Button from 'components/button/button'
import Flex from 'components/layout/flex'
import React, { useState } from 'react'
import { HiOutlineLightningBolt } from 'react-icons/hi'
import { CleanTransaction } from '../screens/transactions'
import ReconcileTransactionDrawer from './reconcile-transaction-drawer'
import UnreconcileTransactionDrawer from './unreconcile-transaction-drawer'

export type TransactionsTableActionProps = {
  transaction: CleanTransaction
  statuses: string[]
  refetchTransactions: () => void
  refetchFinancialAccounts: () => void
}

const TransactionsTableAction: React.FC<TransactionsTableActionProps> = (
  props
) => {
  const { transaction, statuses } = props

  const [
    showUnreconcileTransactionDrawer,
    setShowUnreconcileTransactionDrawer,
  ] = useState(false)
  const [showReconciliationDrawer, setShowReconciliationDrawer] =
    useState(false)

  return (
    <>
      <Flex css={{ width: '15rem' }}>
        {statuses.includes('Unreconciled') && (
          <>
            <Button
              fullWidth
              prepend={<HiOutlineLightningBolt color="#F15656" />}
              appearance="secondary"
              onClick={() => setShowReconciliationDrawer(true)}
            >
              Reconcile
            </Button>
          </>
        )}

        {statuses.includes('Reconciled') && (
          <Button
            fullWidth
            prepend={<HiOutlineLightningBolt color="#398AFA" />}
            appearance="secondary"
            onClick={() => setShowUnreconcileTransactionDrawer(true)}
          >
            Unreconcile
          </Button>
        )}
      </Flex>

      <UnreconcileTransactionDrawer
        visible={showUnreconcileTransactionDrawer}
        onClose={() => setShowUnreconcileTransactionDrawer(false)}
        transaction={transaction}
        refetchTransactions={props.refetchTransactions}
        refetchFinancialAccounts={props.refetchFinancialAccounts}
      />

      <ReconcileTransactionDrawer
        visible={showReconciliationDrawer}
        onClose={() => setShowReconciliationDrawer(false)}
        transaction={transaction}
        refetchTransactions={props.refetchTransactions}
        refetchFinancialAccounts={props.refetchFinancialAccounts}
      />
    </>
  )
}

export default TransactionsTableAction
