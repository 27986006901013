import Button from 'components/button/button'
import { Drawer } from 'components/drawer/drawer'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import { Select } from 'components/select/select'
import Text from 'components/text/text'
import { useToast } from 'components/toast'
import TypeAheadSelect from 'components/type-ahead-select'
import {
  ProductCategory,
  ProductSubCategory,
  useCreateProductCategoryMutation,
  useCreateProductSubCategoryMutation,
  useProductCategoriesQuery,
  useUpdateProductCategoryMutation,
  useUpdateProductSubCategoryMutation,
} from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import React, { useEffect, useState } from 'react'
import { HiArchive } from 'react-icons/hi'
import { extractGraphqlErrors } from 'utils/helpers'

interface IProps {
  visible: boolean
  onClose: () => void
  onSuccess?: (values?: any) => void
  fullHeight?: boolean
  category?: ProductCategory
  subCategory?: ProductSubCategory
}

const defaultValues = { name: '', productCategoryId: '' }

type CategoryType = 'category' | 'sub-category'

export const NewProductCategoryDrawer: React.FC<IProps> = ({
  visible,
  onClose,
  onSuccess,
  fullHeight = true,
  category,
  subCategory,
}) => {
  const notify = useToast()
  const { register, values, setInputValue, errors, clearForm, bulkUpdate } =
    useForm({
      fields: defaultValues,
    })
  const [categoryType, setCategoryType] = useState<CategoryType>('category')

  const [{ data: categories }] = useProductCategoriesQuery()
  const [{ fetching: creatingCategory }, createProductCategoryMutation] =
    useCreateProductCategoryMutation()
  const [{ fetching: creatingSubCategory }, createProductSubCategoryMutation] =
    useCreateProductSubCategoryMutation()
  const [{ fetching: updatingCategory }, updateProductCategoryMutation] =
    useUpdateProductCategoryMutation()
  const [{ fetching: updatingSubCategory }, updateProductSubCategoryMutation] =
    useUpdateProductSubCategoryMutation()

  async function createProductCategory() {
    try {
      const response = await createProductCategoryMutation({
        input: { name: values.name },
      })
      const error = extractGraphqlErrors(response, 'createProductCategory')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({ content: 'Category created successfully', status: 'success' })
      onSuccess?.(response.data?.createProductCategory?.productCategory?.id)
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  async function createProductSubCategory() {
    try {
      const response = await createProductSubCategoryMutation({
        input: values,
      })
      const error = extractGraphqlErrors(response, 'createProductSubCategory')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: 'Sub category created successfully',
        status: 'success',
      })
      onSuccess?.(
        response.data?.createProductSubCategory?.productSubCategory
          ?.productCategory.id
      )
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  async function updateProductCategory() {
    try {
      const response = await updateProductCategoryMutation({
        input: { name: values.name, id: category?.id ?? '' },
      })
      const error = extractGraphqlErrors(response, 'updateProductCategory')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: 'Category updated successfully',
        status: 'success',
      })
      onSuccess?.()
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  async function updateProductSubCategory() {
    try {
      const response = await updateProductSubCategoryMutation({
        input: { ...values, id: subCategory?.id ?? '' },
      })
      const error = extractGraphqlErrors(response, 'updateProductSubCategory')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: 'Sub category updated successfully',
        status: 'success',
      })
      onSuccess?.()
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  useEffect(() => {
    if (category) {
      setInputValue('name', category.name ?? '')
      setCategoryType('category')
      return
    }
    if (subCategory) {
      bulkUpdate({
        name: subCategory.name ?? '',
        productCategoryId: subCategory.productCategory.id,
      })
      setCategoryType('sub-category')
      return
    }
    bulkUpdate(defaultValues)
  }, [category?.id, subCategory?.id])

  function createCategory() {
    categoryType === 'category'
      ? createProductCategory()
      : createProductSubCategory()
  }

  function updateCategory() {
    categoryType === 'category'
      ? updateProductCategory()
      : updateProductSubCategory()
  }

  function handleDismiss() {
    setCategoryType('category')
    clearForm()
    onClose()
  }

  const isEdit = category !== undefined || subCategory !== undefined
  const creating =
    creatingCategory ||
    creatingSubCategory ||
    updatingSubCategory ||
    updatingCategory
  const canSave =
    categoryType === 'category'
      ? values.name !== ''
      : values.name !== '' && values.productCategoryId !== ''

  return (
    <Drawer
      fullHeight={fullHeight}
      title="New Product Category"
      titleIcon={<HiArchive size="2rem" color="#ABB3B9" />}
      visible={visible}
      onClose={handleDismiss}
      footer={
        <Flex gutterX="2">
          <Button size="md" appearance="secondary" onClick={handleDismiss}>
            Cancel
          </Button>
          <Button
            size="md"
            disabled={!canSave || creating}
            onClick={isEdit ? updateCategory : createCategory}
          >
            {isEdit ? 'Update' : 'Save'}
          </Button>
        </Flex>
      }
    >
      <Flex direction="column" gutter="5" css={{ p: '$5' }}>
        <Input
          label="Category Title"
          placeholder="Category title ..."
          {...register('name')}
          error={errors.name}
        />
        <TypeAheadSelect
          label="Category Type"
          placeholder="Select a category type"
          value={categoryType}
          options={[
            { label: 'Parent Category', value: 'category' },
            { label: 'Sub Category', value: 'sub-category' },
          ]}
          onChange={(value: CategoryType) => setCategoryType(value)}
        />
        {categoryType === 'sub-category' && (
          <TypeAheadSelect
            label="Parent Category"
            placeholder="Choose a parent category"
            required
            name="category"
            options={categories?.productCategories ?? []}
            onChange={(value: string) =>
              setInputValue('productCategoryId', value)
            }
            labelKey="name"
            valueKey="id"
            value={subCategory?.productCategory.id}
            error={errors.productCategoryId}
            renderValue={(value: ProductCategory) => (
              <Text size="xs" align={'center'}>
                {value.name}
              </Text>
            )}
          />
        )}
      </Flex>
    </Drawer>
  )
}
