import EmptyStateImage from 'assets/png/service dashboard.png'
import { Flex } from 'components/layout'
import { Table } from 'components/table/table'
import { StyledTable } from 'components/table/table.styles'
import { ITableColumn } from 'components/table/table.types'
import Tag from 'components/tag/tag'
import Text from 'components/text/text'
import { format } from 'date-fns'

import React, { ReactNode } from 'react'
import {
  BsCalendar3EventFill,
  BsFillCalendar2CheckFill,
  BsFillCalendarXFill,
} from 'react-icons/bs'
import MeetingsTableDataAction from './meetings-table-action'

export const meetingStatusColors: {
  [key in Meeting['status']]: {
    color: string
    bgColor: string
  }
} = {
  upcoming: {
    color: '#575F6A',
    bgColor: '#ededed',
  },
  completed: {
    color: '#1CA493',
    bgColor: '#E9F6F2',
  },
  canceled: {
    color: '#F15656',
    bgColor: '#fce3e3',
  },
}

export const meetingStatusIcons: {
  [key in Meeting['status']]: {
    icon: ReactNode
  }
} = {
  upcoming: {
    icon: <BsCalendar3EventFill size="1.5rem" />,
  },
  canceled: {
    icon: <BsFillCalendarXFill size="1.5rem" />,
  },
  completed: {
    icon: <BsFillCalendar2CheckFill size="1.5rem" />,
  },
}

export enum MeetingStatus {
  Upcoming = 'upcoming',
  Canceled = 'canceled',
  Completed = 'completed',
}

export type Meeting = {
  id: string
  name: string
  date: string
  status: MeetingStatus
}

export type MeetingsTableData = {
  id: string
  day: string
  time: string
  meetingName: string
  status: string
  meeting: Meeting
}

const columns: ITableColumn<MeetingsTableData>[] = [
  {
    key: 'day',
    title: 'DAY',
    dataIndex: 'day',
    render: (day: string) => (
      <Text size={'xs'} color="$secondary">
        {format(Date.parse(day), 'EEE, dd MMM, yyyy')}
      </Text>
    ),
  },
  {
    key: 'time',
    title: 'TIME',
    dataIndex: 'time',
    render: (time: string) => (
      <Text size={'xs'} color="$secondary">
        {format(Date.parse(time), 'mm:ss aa')}
      </Text>
    ),
  },
  {
    key: 'meetingName',
    title: 'MEETING NAME',
    dataIndex: 'meetingName',
    render: (meetingName) => (
      <Text size={'xs'} color="$grayDark2">
        {meetingName}
      </Text>
    ),
  },

  {
    key: 'status',
    title: 'STATUS',
    dataIndex: 'status',
    render: (status, { meeting }) => (
      <Tag
        css={{
          py: '.6rem',
          color: meetingStatusColors[meeting.status].color,
          backgroundColor: meetingStatusColors[meeting.status].bgColor,
        }}
      >
        <Flex align="center" gutterX="1" justify="between">
          {meetingStatusIcons[meeting.status].icon}
          {status}
        </Flex>
      </Tag>
    ),
  },
  {
    key: 'action',
    title: '',
    dataIndex: 'id',
    render: (_, { meeting }) => {
      return <MeetingsTableDataAction meeting={meeting} />
    },
  },
]

type MeetingsTableProps = {
  meetings: Meeting[]
}

const MeetingsTable: React.FC<MeetingsTableProps> = (props) => {
  const { meetings } = props

  const tableData: MeetingsTableData[] =
    meetings.map((meeting) => ({
      id: meeting.id,
      day: meeting.date,
      time: meeting.date,
      meetingName: meeting.name,
      status: meeting.status,
      meeting: meeting,
    })) ?? []

  if (tableData.length < 1) {
    return (
      <Flex
        direction="column"
        justify="center"
        align="center"
        gutter="1"
        css={{
          width: '100%',
          padding: '4rem',
          backgroundColor: '#fff',
        }}
      >
        <img
          src={EmptyStateImage}
          style={{ height: '12rem', transform: 'translateX(-5.5rem)' }}
        />
        <Flex direction="column" align="center" css={{ gap: '1.1rem' }}>
          <Text
            size="sm"
            weight="bold"
            css={{ fontFamily: '$space', color: '$black', fontWeight: '500' }}
          >
            No completed meetings available yet
          </Text>
          <Text
            size="xxs"
            align="center"
            css={{ color: '$secondary', width: '70%', marginInline: 'auto' }}
          >
            You will be notified when your information has been processed and
            your meeting is ready
          </Text>
        </Flex>
      </Flex>
      // <Flex
      //   direction={'column'}
      //   justify={'center'}
      //   align={'center'}
      //   css={{
      //     width: '100%',
      //     height: '47.4rem',
      //     backgroundColor: '#fff',
      //     gap: '4rem',
      //   }}
      // >
      //   <img
      //     src={EmptyStateImage}
      //     style={{ width: '5.6rem', height: '5.2rem' }}
      //   />
      //   <Flex direction={'column'} align={'center'} css={{ gap: '1.1rem' }}>
      //     <Text size={'md'} weight="bold" css={{ color: '$primary' }}>
      //       No completed meetings available yet
      //     </Text>
      //     <Text size={'sm'} css={{ color: '$secondary' }}>
      //       You will be notified when your information has been processed and
      //       your meeting is ready
      //     </Text>
      //   </Flex>
      // </Flex>
    )
  } else {
    return (
      <Table
        columns={columns}
        dataSource={tableData}
        css={{
          h4: {
            color: '$grayDark2',
          },
          [`& ${StyledTable}`]: {
            backgroundColor: '#fff',
          },
        }}
      />
    )
  }
}

export default MeetingsTable
