import { format, getDay, getDaysInMonth, subMonths } from 'date-fns'
import { ISelectOption } from 'components/select/select.types'
import { range } from 'utils/array'

export const daysOfTheWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const monthsSelect: Array<ISelectOption> = months.map((m) => {
  return {
    key: m,
    value: m,
  }
})

export function getDates(month: number, year: number) {
  const date = new Date(year, month, 1)
  const firstDayOfMonth = getDay(date)
  const prevOffset = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1
  const numberOfDays = getDaysInMonth(date)
  const numberOfDaysInPreviousMonth = getDaysInMonth(subMonths(date, 1))
  //35 is not a magic number! lol - size of calendar grid.
  const nextOffset = 35 - (numberOfDays + prevOffset)

  const prevOffsetDates = range(0, prevOffset - 1)
    .sort((a, b) => b - a)
    .map((day) => {
      return new Date(year, month - 1, numberOfDaysInPreviousMonth - day)
    })
  const datesInMonth = range(1, numberOfDays).map((day) => {
    return new Date(year, month, day)
  })
  const nextOffsetDates = range(1, nextOffset).map((day) => {
    return new Date(year, month + 1, day)
  })

  return [...prevOffsetDates, ...datesInMonth, ...nextOffsetDates]
}

export function getYears(
  minimumDate = new Date(1950, 1, 1),
  maximumDate = new Date(2040, 12, 31)
): Array<ISelectOption> {
  const years: Array<ISelectOption> = []
  const minimumYear = Number(format(minimumDate, 'yyyy'))
  const maximumYear = Number(format(maximumDate, 'yyyy'))

  for (let year = minimumYear; year <= maximumYear; year++) {
    years.push({
      label: `${year}`,
      value: `${year}`,
    })
  }

  return years
}
