import { StyledInputWrapper } from 'components/input/input.styles'
import Flex from 'components/layout/flex'
import { styled } from 'stitches/stitches.config'

export const SelectWrapper = styled('div', StyledInputWrapper, {
  position: 'relative',
  px: 12,
})

export const StyledSelect = styled('select', {})

export const StyledChevron = styled(Flex, {
  position: 'absolute',
  pointerEvents: 'none',
  right: 6,
})
