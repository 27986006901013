import { Dict } from './types'

export const objectKeys = <T extends Dict>(object: T) => {
  return Object.keys(object) as unknown as (keyof T)[]
}

export function merge<T extends Dict, S extends Dict>(
  target: T,
  source: S
): T & S {
  return Object.assign(target, source)
}

export function createCleanObject<T>(object: T) {
  const result: Partial<Record<keyof typeof object, string>> = {}
  Object.keys(object as any).forEach((key) => {
    result[key as keyof typeof object] = ''
  })

  return result
}

export function omit<T extends Dict, K extends keyof T>(object: T, keys: K[]) {
  return Object.keys(object).reduce((obj, key) => {
    if (keys.includes(key as K)) {
      return { ...obj }
    }
    return {
      ...obj,
      [key]: object[key],
    }
  }, {})
}
export function pick<T extends Dict, K extends keyof T>(
  object: T | undefined,
  keys: K[]
) {
  // TODO handle invalid objects properly
  if (!object) {
    return {}
  }

  return Object.keys(object).reduce((obj, key) => {
    if (keys.includes(key as K))
      return {
        ...obj,
        [key]: object[key],
      }
    return { ...obj }
  }, {})
}
export function split<T extends Dict, K extends keyof T>(object: T, keys: K[]) {
  const picked = pick(object, keys)
  const omitted = omit(object, keys)

  return [picked, omitted] as [{ [P in K]: T[P] }, Omit<T, K>]
}
