import Button from 'components/button/button'
import DateInput from 'components/date-input/date-input'
import { Drawer } from 'components/drawer/drawer'
import { Dropdown } from 'components/dropdown/dropdown'
import { FileUpload } from 'components/file-upload/file-upload'
import Input, { InputLabel } from 'components/input'
import { Flex } from 'components/layout'
import Portal from 'components/portal/portal'
import { Span } from 'components/text/span'
import Text from 'components/text/text'
import { useToast } from 'components/toast'
import { addDays } from 'date-fns'
import {
  BankAccount,
  useAccountTypeAccountsQuery,
  useCreateTransactionMutation,
  useCurrenciesQuery,
  useTaxesQuery,
} from 'generated/__generated_graphql'
import useForm from 'hooks/useForm'
import ChartOfAccountsDrawer from 'pages/accounting/screens/chart-of-accounts/components/mutate-account-drawer'
import { NewTaxCta, StyledItemSearch, TaxItem } from 'pages/sales/sales.styles'
import AddTaxRateDrawer from 'pages/taxes/screens/tax-rates/components/add-tax-rate-drawer'
import React from 'react'
import {
  HiCalendar,
  HiChevronDown,
  HiDocumentAdd,
  HiOutlineExternalLink,
  HiOutlinePlus,
  HiTrash,
  HiX,
} from 'react-icons/hi'
import {
  extractGraphqlErrors,
  formatLocaleDate,
  formatMoney,
  truncateText,
} from 'utils/helpers'

interface IProps {
  visible: boolean
  onClose: () => void
  transactionType: 'Expense' | 'Income'
  financialAccounts: Omit<BankAccount, 'bankAccountable'>[]
  selectedFinancialAccount: Omit<BankAccount, 'bankAccountable'> | null
  refetchFinancialAccounts: () => void
  refetchTransactions?: () => void
}

function AddTransactionsDrawer({
  visible,
  onClose,
  transactionType,
  refetchTransactions,
  selectedFinancialAccount,
  refetchFinancialAccounts,
}: IProps) {
  const toast = useToast()
  const [transactionItems, setTransactionItems] = React.useState<any>([
    {
      id: 1,
      title: '',
      quantity: 0,
      price: 0,
      taxId: 0,
      chartOfAccountId: 0,
      vat: false,
      amount: 0,
    },
  ])
  const [uploadValues, setUploadValues] = React.useState<any[]>([])
  const [payload, setPayload] = React.useState<any>({
    transactionItems: [],
  })

  const { register, values, setInputValue, errors, formIsComplete } = useForm({
    fields: {
      transactionDate: '',
      // bankAccountId: selectedFinancialAccount?.id || '',
      description: '',
    },
  })

  const [transactionsDetails, setTransactionsDetails] = React.useState({
    subTotal: 0,
    vat: 0,
    total: 0,
    taxPercentage: [],
  })

  const [{ data: currenciesData }] = useCurrenciesQuery()

  const currencySymbol = currenciesData?.currencies.find(
    (currency) => currency.id === selectedFinancialAccount?.currencyId
  )?.symbol

  function handlePayload(deleteId?: number) {
    if (deleteId) {
      payload.transactionItems = payload.transactionItems.filter(
        (item: any) => item.id !== deleteId
      )
    }
    const uniqueItems = payload.transactionItems.reduce(
      (acc: any, item: any) => {
        const index = acc.findIndex((t: any) => t.id === item.id)
        if (index === -1) {
          acc.push(item)
        } else {
          acc[index] = item
        }
        return acc
      },
      []
    )

    const newPayload = {
      amount: transactionsDetails.total,
      status: transactionType === 'Income' ? 'credit' : 'debit',
      transactionDate: formatLocaleDate(values.transactionDate),
      bankAccountId: selectedFinancialAccount?.id as string,
      description: values.description,
      transactionItems: uniqueItems,
      uploadIds: uploadValues.map((item: any) => item.id),
    }
    setPayload(newPayload)
    return newPayload
  }

  function handleDeleteTranasaction(id: number) {
    if (transactionItems.length === 1) return
    const filteredTransactions = transactionItems.filter(
      (item: any) => item.id !== id
    )
    setTransactionItems(filteredTransactions)
    handlePayload(id)
    calculateTransactionDetails()
  }

  function calculateTransactionDetails() {
    const payload = handlePayload()
    const calculations: any = {}

    calculations.taxPercentage = payload.transactionItems
      .map((item: any) => {
        return item?.tax?.rate ?? 0
      })
      .reduce((acc: number, item: any) => {
        return Number(acc) + Number(item)
      }, 0)

    calculations.total = payload.transactionItems.reduce(
      (acc: number, item: any) => acc + parseInt(item.amount),
      0
    )

    calculations.vat = payload.transactionItems.reduce(
      (acc: number, item: any) => {
        const vat = (calculations?.taxPercentage / 100) * item.amount
        return acc + vat
      },
      0
    )

    calculations.subTotal = payload.transactionItems.reduce(
      (acc: number, item: any) => {
        const vat = (calculations.taxPercentage / 100) * item.amount
        return acc + parseInt(item.amount) - vat
      },
      0
    )

    setTransactionsDetails(calculations)
  }

  function notify(error?: string, status?: 'success' | 'error') {
    toast({
      content: error ?? 'something went wrong',
      status: status,
    })
  }

  const [{ fetching: isCreatingTransaction }, submit] =
    useCreateTransactionMutation()

  async function handleSubmit() {
    const payload = handlePayload()
    payload.transactionItems = payload.transactionItems.map((item: any) => {
      return {
        amount: item?.amount,
        glAccountId: item?.chartOfAccounts?.id,
        quantity: Number(item?.quantity),
        taxId: item?.taxId,
        price: Number(item?.price),
        title: item?.title,
      }
    })

    try {
      const response = await submit({ input: payload })
      const error = extractGraphqlErrors(response, 'createTransaction')

      if (error) {
        notify(error, 'error')
        return
      }
      refetchFinancialAccounts()
      refetchTransactions?.()
      notify('Transaction added successfully', 'success')
      onClose()
    } catch (error) {
      notify(error as string, 'error')
    }
  }

  const disableButton =
    !formIsComplete ||
    isCreatingTransaction ||
    payload?.transactionItems[0]?.amount === 0

  return (
    <>
      <Drawer
        size="large"
        title={
          <Text size="sm">
            Add new {transactionType} to &nbsp;
            {selectedFinancialAccount?.title} &nbsp;
            <Span css={{ color: '$gray' }}>
              (
              {selectedFinancialAccount?.name ||
                selectedFinancialAccount?.routingNumber}
              )
            </Span>
          </Text>
        }
        // title={`Add new ${transactionType} to ${selectedFinancialAccount?.title} (${selectedFinancialAccount?.name})`}
        titleIcon={<HiDocumentAdd size="2rem" color="#ABB3B9" />}
        visible={visible}
        onClose={onClose}
        footer={
          <Flex justify="between" stretchx gutterX="2">
            <Button appearance="secondary" onClick={onClose}>
              Cancel
            </Button>

            <Button
              isLoading={isCreatingTransaction}
              disabled={disableButton}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Flex>
        }
      >
        <Flex css={{ p: '$5' }} gutterY={5} direction="column">
          <Flex align="center" gutter={5} wrap="wrap">
            <DateInput
              css={{ width: '48%' }}
              prepend={<HiCalendar color="#ABB3B9" />}
              append={<HiChevronDown size="1.9rem" color="#ABB3B9" />}
              maximumDate={addDays(new Date(), 0)}
              label="Transaction Date"
              required
              placeholder="Select transaction date"
              name="transactionDate"
              onChange={(e) =>
                setInputValue(
                  'transactionDate',
                  (e.target.value as Date).toISOString()
                )
              }
              error={errors.transactionDate}
            />
            {/* <Select
            css={{ width: '48%' }}
            search
            searchPlaceholder="Search for a bank"
            label="Received To"
            placeholder="Choose an account"
            options={financialAccountsMap}
            name="bankAccountId"
            onChange={(value) =>
              setInputValue('bankAccountId', value as string)
            }
            error={errors.bankAccountId}
            defaultValue={values.bankAccountId}
          /> */}
            <Input
              label="Transaction Reference"
              placeholder="Add reference"
              required
              css={{ width: '48%' }}
              {...register('description')}
              error={errors.description}
            />
          </Flex>
          <Flex
            direction="column"
            gutterY={2}
            css={{
              my: '$1',
              borderTop: '1px solid $border',
              borderBottom: '1px solid $border',
              py: '$6',
            }}
          >
            {transactionItems.map((item: any) => {
              const isFirst = item.id === transactionItems[0].id
              return (
                <Item
                  key={item.id}
                  id={item.id}
                  isFirst={isFirst}
                  payload={payload}
                  currencySymbol={currencySymbol as string}
                  calculateTransactionDetails={calculateTransactionDetails}
                  onDelete={() => handleDeleteTranasaction(item.id)}
                />
              )
            })}
          </Flex>
          <Button
            css={{ width: 'max-content' }}
            appearance="secondary"
            prepend={<HiOutlinePlus color="#398AFA" />}
            onClick={() =>
              setTransactionItems([
                ...transactionItems,
                {
                  id: transactionItems.length + 1,
                },
              ])
            }
          >
            <Text color="$blue">Add New Transaction</Text>
          </Button>
          {/* <FileUpload onChange={(val) => setUploadValues(val)} /> */}
          <Flex align="end" direction="column" gutter={3}>
            <Flex justify="between" gutterX="6" align="center">
              <Text size="xs" weight="semi" color="$primary">
                Sub Total:
              </Text>
              <Text size="xs" weight="semi" color="$secondary">
                {formatMoney(transactionsDetails.subTotal, currencySymbol)}
              </Text>
            </Flex>
            <Flex justify="between" gutterX="6" align="center">
              <Text size="xs" weight="semi" color="$primary">
                VAT:
              </Text>
              <Text size="xs" weight="semi" color="$secondary">
                {formatMoney(transactionsDetails.vat, currencySymbol)}
              </Text>
            </Flex>
            <Flex justify="between" gutterX="6" align="center">
              <Text size="xs" weight="semi" color="$primary">
                Total:
              </Text>
              <Text size="xs" weight="semi" color="$secondary">
                {formatMoney(transactionsDetails.total, currencySymbol)}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Drawer>
    </>
  )
}

export default AddTransactionsDrawer

function Item({
  isFirst,
  onDelete,
  payload,
  calculateTransactionDetails,
  id,
  currencySymbol,
}: {
  isFirst: boolean
  onDelete: () => void
  payload: any
  calculateTransactionDetails: any
  id: number
  currencySymbol: string
}) {
  const [tax, setTax] = React.useState<any>(0)
  const [chartOfAccountId, setChartOfAccountId] = React.useState<any>()
  const [amount, setAmount] = React.useState<number>(0)
  const [shouldClose, setShouldClose] = React.useState<boolean>(false)
  const [isTaxOpen, setIsTaxOpen] = React.useState(false)
  const [isAccountsOpen, setIsAccountsOpen] = React.useState(false)

  const [{ data: taxes }] = useTaxesQuery()
  const [{ data: accountTypeAccountsQueryData }, refetchAccounts] =
    useAccountTypeAccountsQuery()
  const { accountTypeAccounts } = accountTypeAccountsQueryData ?? {}
  const taxesMap = taxes?.taxes?.data?.map((tax) => {
    return {
      label: tax.name,
      onClick: () =>
        setTax({
          id: tax.id,
          rate: tax.rate,
          name: tax.name,
        }),
    }
  })

  const chartOfAccountMap = accountTypeAccounts?.map(
    (accTypeAccounts, index) => {
      return {
        label: '',
        onClick: () => null,
        render: () => {
          return (
            <Flex direction="column" stretchx>
              <Text
                size="xxs"
                weight="bold"
                color="$primary"
                css={{
                  cursor: 'auto',
                  px: '1.6rem',
                  mt: '1.4rem',
                }}
              >
                {accTypeAccounts.name}
              </Text>
              {accTypeAccounts?.accounts?.map((account) => (
                <Text
                  css={{
                    cursor: 'pointer',
                    px: '1.6rem',
                    py: '0.8rem',
                    // mt: '0.4rem',
                    '&:hover': {
                      color: '$highlight',
                      backgroundColor: '$highlightBg',

                      '& *': {
                        color: '$highlight',
                      },
                    },
                  }}
                  key={account.id}
                  size="xxs"
                  weight="semi"
                  color="$secondary"
                  onClick={() => {
                    setChartOfAccountId({
                      id: account.id,
                      parentId: accountTypeAccounts[index].name,
                      name: account.name,
                    })
                    setShouldClose(true)
                  }}
                >
                  {account.name}
                </Text>
              ))}
            </Flex>
          )
        },
      }
    }
  )

  const { values, setInputValue, errors } = useForm({
    fields: {
      id,
      title: '',
      quantity: 1,
      price: 0,
      taxId: tax.id,
      chartOfAccountId,
      amount,
    },
  })

  const watchArray = [
    tax,
    chartOfAccountId,
    amount,
    values.price,
    values.quantity,
    values.title,
  ]

  React.useEffect(() => {
    updatePayload()
  }, watchArray)

  function updatePayload() {
    setAmount(values.quantity * values.price)
    const index = payload.transactionItems.findIndex(
      (item: any) => item.id === values.id
    )

    const newValues: any = {
      ...values,
      tax,
      taxId: tax?.id,
      chartOfAccounts: chartOfAccountId,
      amount,
    }

    if (!index) {
      payload.transactionItems[index] = newValues
    } else {
      payload.transactionItems.push(newValues)
    }

    calculateTransactionDetails()
  }

  return (
    <>
      <Flex
        stretchx
        align="start"
        gutterX="5"
        css={{
          '& > *': { width: '100%' },
        }}
      >
        <Input
          label={isFirst ? 'Title' : ''}
          placeholder="Title"
          name="title"
          required
          onChange={(e) => {
            setInputValue('title', e.target.value)
          }}
          error={errors.title}
        />
        <Input
          label={isFirst ? 'Quantity' : ''}
          placeholder="1.00"
          type="number"
          name="quantity"
          required
          onChange={(e) => {
            setInputValue('quantity', e.target.value)
          }}
          error={errors.quantity}
        />
        <Input
          label={isFirst ? 'Price' : ''}
          placeholder="0.00"
          type="number"
          name="price"
          required
          onChange={(e) => {
            setInputValue('price', e.target.value)
          }}
          error={errors.price}
        />

        <Flex direction="column">
          {isFirst && <InputLabel>Tax</InputLabel>}
          {tax.name ? (
            <TaxItem
              justify="between"
              css={{ py: '1.4rem', width: 'max-content' }}
            >
              <Text size="xxs" weight="semi" color="$primary">
                {truncateText(`${tax.name} (${tax.rate}%)`, 11)}
              </Text>
              <HiX
                role="button"
                color="#ABB3B9"
                onClick={() =>
                  setTax({
                    id: 0,
                    rate: 0,
                    name: '',
                  })
                }
              />
            </TaxItem>
          ) : (
            <Dropdown
              closeOnMenuItemClick
              menu={taxesMap}
              header={
                <>
                  <StyledItemSearch placeholder="Search Tax" />
                  <NewTaxCta
                    role="button"
                    onClick={() => setIsTaxOpen(true)}
                    stretchx
                    justify="between"
                    align="center"
                  >
                    <Flex align="center" gutterX="2">
                      <Text size="xs" color="$blue">
                        Create new Tax
                      </Text>
                    </Flex>
                    <HiOutlineExternalLink color="#398AFA" />
                  </NewTaxCta>
                </>
              }
            >
              <Button
                appearance="ghost"
                prepend={<HiOutlinePlus color="#398AFA" />}
              >
                <Text size="xs" weight="semi" color="$blue">
                  Add Tax
                </Text>
              </Button>
            </Dropdown>
          )}
        </Flex>

        <Flex direction="column">
          {isFirst && <InputLabel>Category</InputLabel>}
          {chartOfAccountId ? (
            <TaxItem
              justify="between"
              css={{ py: '1.4rem', width: 'max-content' }}
            >
              <Text size="xxs" weight="semi" color="$primary">
                {truncateText(chartOfAccountId.name, 10)}
              </Text>
              <HiX
                role="button"
                color="#ABB3B9"
                onClick={() => setChartOfAccountId(undefined)}
              />
            </TaxItem>
          ) : (
            <Dropdown
              menu={chartOfAccountMap}
              closeOnMenuItemClick={shouldClose}
              header={
                <>
                  <StyledItemSearch placeholder="Search Category" />

                  <NewTaxCta
                    role="button"
                    onClick={() => setIsAccountsOpen(true)}
                    stretchx
                    justify="between"
                    align="center"
                  >
                    <Flex align="center" gutterX="2">
                      <Text size="xs" color="$blue">
                        Create new category
                      </Text>
                    </Flex>
                    <HiOutlineExternalLink color="#398AFA" />
                  </NewTaxCta>
                </>
              }
            >
              <Button
                appearance="ghost"
                prepend={<HiOutlinePlus color="#398AFA" />}
              >
                <Text size="xs" weight="semi" color="$blue">
                  Add Category
                </Text>
              </Button>
            </Dropdown>
          )}
        </Flex>

        <Flex direction="column">
          {isFirst && (
            <InputLabel css={{ whiteSpace: 'nowrap' }}>Amount</InputLabel>
          )}
          <Text size="xs" weight="semi" color="$primary">
            {formatMoney(amount, currencySymbol)}
          </Text>
        </Flex>

        <Flex
          css={{
            justifySelf: 'flex-end',
            alignSelf: 'center',
            width: '3rem',
            backgroundColor: isFirst ? 'transparent' : '$errorBg',
            p: '$2',
            borderRadius: '$round',
            height: '3rem',
          }}
          align="center"
          justify="center"
          role="button"
          onClick={onDelete}
        >
          <HiTrash size={20} color={`${isFirst ? 'transparent' : '#F15656'}`} />
        </Flex>
      </Flex>
      <Portal>
        <AddTaxRateDrawer
          visible={isTaxOpen}
          onClose={() => setIsTaxOpen(false)}
        />
      </Portal>
      <Portal>
        <ChartOfAccountsDrawer
          visible={isAccountsOpen}
          onClose={() => {
            setIsAccountsOpen(false)
            refetchAccounts({
              requestPolicy: 'network-only',
            })
          }}
        />
      </Portal>
    </>
  )
}
